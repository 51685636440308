/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Button, Form, Input, message } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OtpInput from "react-otp-input";
import * as userActions from "store/user/actions";
import LoginImg from "assets/image/login-img.svg";
import FreadomLogo from "assets/image/freadom-logo-white.svg";
import { storage as LocalStorage } from "services/config/storage";
import "./Login.scss";
import { USER_ROLES } from "../../utils/constants";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const getTime = () => {
  const current = new Date();
  const curHr = current.getHours();
  if (curHr < 12) {
    return "Morning";
  } else if (curHr < 18) {
    return "Afternoon";
  }
  return "Evening";
};

const otpBoxStyle = {
  // width: '100%',
  width: "56px",
  height: "56px",
  outline: "none",
  fontSize: "24px",
  border: "1px solid #eee",
  borderRadius: "4px",
};

class Login extends Component {
  state = {
    loading: false,
    email: "",
    password: "",
    phone: "",
    loginUsingOtp: false,
    otpSent: false,
    isSendingOtp: false,
    otp: "",
    inputFocused: false,
    userRole: "",
  };

  async componentDidMount() {
    if (LocalStorage.fetch.userData()) {
      this.setState({
        userRole: LocalStorage.fetch.userData().role,
      });
    }
    const res = await this.props.verifyOtpRequest();
    if (res && res.data && res.data.id) {
      this.props.history.push(this.getRedirectionRoute());
    }
  }

  getRedirectionRoute = () => {
    if (
      [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN].includes(this.state.userRole)
    ) {
      return "/teacherListing";
    } else {
      return "/batchesListing";
    }
  };

  handleSubmit = (e) => {
    const { email, password } = this.state;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const loginObj = {
          username: email,
          password: password,
          user_type: "parent",
          // device_id: 1234,
          // device_token: 124,
          device_type: "web",
        };
        this.setState({ loading: true });
        const res = await this.props.userLoginRequest(loginObj);
        if (res && res.auth_token) {
          this.props.history.push("/");
        } else {
          this.setState({ loading: false });
          message.error(this.state.otp ? "Invalid OTP" : "Invalid Credentials");
        }
      }
    });
  };

  sendOtp = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isSendingOtp: true });
        const otpObj = {
          contact_no: this.state.phone,
          action: "login",
          user_type: "parent",
          country_code: this.state.selectedCountry.countryCode,
        };
        const res = await this.props.triggerOtpSend(otpObj);
        if (res.success) {
          this.setState({ otpSent: true, isSendingOtp: false });
        }
      }
    });
  };

  verifyOtp = async (e) => {
    e.preventDefault();
    const otpVerifyObj = {
      contact_no: this.state.phone,
      otp: this.state.otp,
      action: "login",
      user_type: "parent",
      device_type: "web",
    };
    const res = await this.props.verifyOtpRequest(otpVerifyObj);
    if (res && res.auth_token) {
      this.props.history.push("/");
    } else {
      this.setState({ loading: false });
      message.error(this.state.otp ? "Invalid OTP" : "Invalid Credentials");
    }
  };

  handleUpdateState = (key, e) => {
    this.setState({ [key]: e.target.value });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { email, password, loginUsingOtp, phone, otpSent } = this.state;

    return (
      <div className="login-container">
        <div className="left-part">
          <img
            src={LoginImg}
            alt="user illustration"
            className="user-illustration"
          />
          <img src={FreadomLogo} alt="logo" />
          <div className="title">Designed to help you raise</div>
          <div className="gradient-text">A happy reader</div>
          <p className="helper-text">
            Track the progress insights of your children and manage their
            interests to get a more personalised experience
          </p>
        </div>
        <div
          className={
            "right-part" + (this.state.inputFocused ? " input-focused" : "")
          }
        >
          <img src={FreadomLogo} className="mobile-logo" alt="logo" />
          {
            <div className="login-card">
              <div className="heading">Good {getTime()}!</div>
              <div className="sub-heading">Welcome back, User</div>
              {loginUsingOtp ? (
                <Form
                  layout="vertical"
                  onSubmit={this.state.otp ? this.verifyOtp : this.sendOtp}
                >
                  <Form.Item>
                    {getFieldDecorator("phone", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter phone number",
                          validateTrigger: "onBlur",
                        },
                        {
                          len: 10,
                          message: "Must be 10 digits",
                          validateTrigger: "onBlur",
                        },
                        {
                          pattern: "^[0-9]*$",
                          message: "Must be numeric",
                          validateTrigger: "onBlur",
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        size="large"
                        value={phone}
                        placeholder="Enter your phone number"
                        onChange={(e) => this.handleUpdateState("phone", e)}
                      />
                    )}
                  </Form.Item>
                  <OtpInput
                    onChange={(otp) => this.setState({ otp: otp })}
                    numInputs={4}
                    containerStyle={{
                      justifyContent: "space-between",
                      marginBottom: "16px",
                    }}
                    separator={<span></span>}
                    inputStyle={otpBoxStyle}
                    isDisabled={!otpSent}
                  />
                  <Button
                    type="primary"
                    disabled={hasErrors(getFieldsError())}
                    htmlType="submit"
                    loading={this.state.isSendingOtp}
                  >
                    {otpSent ? "Re-Send OTP" : "Send OTP"}
                  </Button>
                  <Button
                    type="primary"
                    // disabled={!otpSent}
                    onClick={this.verifyOtp}
                  >
                    Set token
                  </Button>
                  <Button
                    type="primary"
                    // disabled={!otpSent}
                    onClick={() =>
                      this.props.history.push(this.getRedirectionRoute())
                    }
                  >
                    Admin panel
                  </Button>
                </Form>
              ) : (
                <Form onSubmit={this.handleSubmit} layout="vertical">
                  <div className="label">Email/Phone</div>
                  <Form.Item hasFeedback>
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Please enter an email" },
                        // { type: "email", message: "Not a valid email" }
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        size="large"
                        value={email}
                        placeholder="Enter your email or phone number"
                        onChange={(e) => this.handleUpdateState("email", e)}
                        onFocus={() => this.setState({ inputFocused: true })}
                        onBlur={() => this.setState({ inputFocused: false })}
                      />
                    )}
                  </Form.Item>
                  <div className="label" style={{ display: "inline-block" }}>
                    Password
                  </div>
                  <Form.Item>
                    {getFieldDecorator("password", {
                      rules: [
                        { required: true, message: "Password is required" },
                      ],
                      validateTrigger: "onChange",
                    })(
                      <Input
                        size="large"
                        value={password}
                        placeholder="enter 6 characters or more"
                        type="password"
                        onChange={(e) => this.handleUpdateState("password", e)}
                        onFocus={() => this.setState({ inputFocused: true })}
                        onBlur={() => this.setState({ inputFocused: false })}
                      />
                    )}
                  </Form.Item>
                  <Button
                    type="primary"
                    // disabled={!otpSent}
                    onClick={this.verifyOtp}
                  >
                    Set token
                  </Button>
                  <Button
                    type="primary"
                    // disabled={!otpSent}
                    onClick={() =>
                      this.props.history.push(this.getRedirectionRoute())
                    }
                  >
                    Admin panel
                  </Button>
                  <Button
                    type="primary"
                    // className=""
                    // disabled={hasErrors(getFieldsError())}
                    // htmlType="submit"
                    // loading={this.state.loading}
                    onClick={() =>
                      this.props.history.push(this.getRedirectionRoute())
                    }
                  >
                    Sign In
                  </Button>
                  <a onClick={() => this.setState({ loginUsingOtp: true })}>
                    Sign In using OTP
                  </a>
                </Form>
              )}
            </div>
          }
        </div>
      </div>
    );
  }
}

const connectedComponent = connect(
  ({ user }) => ({ ...user }),
  (dispatch) => bindActionCreators({ ...userActions }, dispatch)
)(Login);

export default Form.create({ name: "Login_Form" })(connectedComponent);
