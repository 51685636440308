import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Input, Pagination, Radio, Table } from "antd";
import debounce from "lodash/debounce";
import has from "lodash/has";
import { storage as LocalStorage } from "services/config/storage";
import * as teacherActions from "store/teacher/actions";
import * as classesActions from "store/classes/actions";
import * as batchesActions from "store/batches/actions";
import * as gradesAction from "../../../../store/grades/actions";
import AdminLayout from "layout/admin";
import {
  DailyHoursCell,
  GradesCell,
  NameCell,
  ProfilePicCell,
} from "./rowCells";
import "./index.scss";
import ListingFilter from "../../../../components/Filter";
import { generateFilterUrl } from "../../../../utils/commonUtils";
import { panelList } from "./filters";

const MIN_CHARS_TO_SEARCH = 2;
const TYPING_TIMEOUT = 500;
const MAX_RESULTS_PER_PAGE = 15;

class TeacherAssignment extends Component {
  debounceSearch;

  constructor(props) {
    super(props);
    this.state = {
      srch:'',
      offset: 0,
      page_size: MAX_RESULTS_PER_PAGE,
      selectedTeacher: "",
      recommendSelectedTeacher: "",
      filters: {
        offset: 0,
        page_size: MAX_RESULTS_PER_PAGE,
      },
      total_count: MAX_RESULTS_PER_PAGE,
      numberOfPages: 1,
      activePageNumber: 1,
      screenTitle: "",
      isRecommendedTeacher: true,
    };
    this.debounceSearch = debounce(this.applyFilters, TYPING_TIMEOUT);
  }

  componentDidMount() {
    if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/");
    } else {
      let route = window.location.pathname.split("/");
      let title = route[2] || "";
      
      this.setState({
        isRecommendedTeacher: title.includes("recommended"),
      });
      //FLV-59
      const states = {
        filter: {...this.state.filters,pattern:undefined}
      };
      this.setState({states});
      this.fetchTeachers(this.state.filters, title.includes("recommended"));
    }

    this.props.getGradeListRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let route = this.props.location?.pathname.split("/");
      let title = route[2] || "";
      //FLV-59
      this.setState({
        isRecommendedTeacher: title.includes("recommended"),
        selectedTeacher: "",
        srch:''
      });
      this.onSearch(null);
      this.fetchTeachers(this.state.filters, title.includes("recommended"));
    }
  }

  fetchTeachers = async (payload, isRecommended) => {
    let filters;
    if (!isRecommended) {
      if (payload && payload.filters) filters = payload.filters;
      else filters = { ...this.state.filters };
    }
    let res = {};
    try {
      let urlParams = generateFilterUrl(filters);
      this.setState({ loading: true });
      if (isRecommended)
        res = this.props.getRecommendedTeachers(
          this.props.match.params.batchID
        );
      else res = await this.props.getTeachers(urlParams);
      if (res) {
        this.setState({
          total_count: res?.data?.total_count,
          numberOfPages: Math.ceil(
            res?.data?.total_count / MAX_RESULTS_PER_PAGE
          ),
          loading: false,
        });
      } else this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  setFilter = (filters) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.applyFilters()
    );
  };

  onSearch = (event) => {
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    if (event?.target?.value) filters.pattern = event.target.value;
    else filters.pattern = this.state.srch;
    this.setState({ filters }, () => context.debounceSearch(filters));
  };

  onTeacherSelected(event) {
    this.setState({
      selectedTeacher: event.target.value,
      recommendSelectedTeacher: event.target.value,
    });
  }

  async onTeacherAssign() {
    let inputbutton = this.props.location?.state?.detail;
    let recommendteacheruserid =
      inputbutton === "batchupdate" || inputbutton === "sessionupdate"
        ? this.props.recommendedTeachers?.data
          ? this.props.recommendedTeachers.data.filter(
              (element) => element.id == this.state.recommendSelectedTeacher
            )
          : []
        : null;
    //console.log("teacheridisher", this.state.selectedTeacher);
    let teacheruserid =
      inputbutton === "batchupdate" || inputbutton === "sessionupdate"
        ? this.props.teachersData?.data
          ? this.props.teachersData.data.teachers.filter(
              (element) => element.id == this.state.selectedTeacher
            )
          : []
        : null;

    let teacherid =
      inputbutton === "batchupdate" || inputbutton === "sessionupdate"
        ? recommendteacheruserid.length !== 0
          ? recommendteacheruserid[0].id
          : teacheruserid[0].id
        : null;
    //console.log("teacheridisher", teacherid);
    if (inputbutton === "batchupdate") {
      await this.props.assignUpdatedTeacherToBatch(
        this.props.match.params.batchID,
        teacherid
      );
    } else if (inputbutton === "sessionupdate") {
      //console.log("props", this.props);
      //  block of code to be executed if the condition1 is false and condition2 is true
      await this.props.setTeacherForSession(
        this.props.location.state.info.id,
        teacherid
      );
    } else {
      await this.props.assignTeacherToBatch(
        this.props.match.params.batchID,
        this.state.selectedTeacher
      );
    }

    this.props.history.push("/adminBatches");
  }

  renderHeader = () => (
    <div className="teacher-listing-header admin-batches-teacher-listing">
      {this.state.isRecommendedTeacher ? (
        <Button
          type="primary"
          className="ant-btn-md export-btn outline"
          onClick={() => {
            this.props.history.push({
              pathname: `/adminBatches/assign-teacher/${this.props.match.params.batchID}`,
              state: {
                detail: this.props.location.state?.detail,
                info: this.props.location.state?.info,
              },
            });
          }}
        >
          VIEW ALL TEACHERS
        </Button>
      ) : (
        <>
          <Input placeholder="Search for teachers" value={this.state.srch}  onChange={(e)=>{this.setState({srch:e.target.value});this.onSearch(e);}} />
          <ListingFilter
            isFilterDropdownVisible={this.state.isFilterDropdownVisible}
            panelList={panelList}
            setDropdownVisibility={(value) =>
              this.setState({ isFilterDropdownVisible: value })
            }
            gradesList={this.props.grades?.gradesList || []}
            onApply={this.setFilter}
          />
        </>
      )}
      <Button
        type="primary"
        className="ant-btn-md export-btn"
        onClick={() => this.onTeacherAssign()}
      >
        ASSIGN
      </Button>
    </div>
  );

  applyFilters = async (updates = {}) => {
    let apifilters = {
      ...this.state.filters,
      ...updates,
    };
    this.fetchTeachers({ filters: apifilters });
  };

  handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        offset: page - 1,
      };
      this.applyFilters(filterUpdates);
    }
  };

  render() {
    let { teachersData, recommendedTeachers = {} } = this.props;
    //console.log("teacherprops", teachersData, recommendedTeachers);
    const {
      total_count,
      activePageNumber,
      filters: { page_size },
      numberOfPages,
    } = this.state;
    let data = [];
    if (this.state.isRecommendedTeacher) data = recommendedTeachers?.data || [];
    else
      data =
        teachersData && has(teachersData, "data.teachers")
          ? teachersData.data.teachers
          : [];
    const selectedRowKey = data.findIndex(
      (teacher) => teacher.id == this.state.selectedTeacher
    );

    data = data.map((teacher) => ({
      ...teacher,
      grade_value: this.props.grades?.gradesList
        ?.filter((grade) => {
          if(teacher.allowed_grade_levels !=null && typeof teacher.allowed_grade_levels !== 'undefined' ) 
          return (
            Object.keys(teacher.allowed_grade_levels).filter(
              (key) => key == grade.id
            ).length > 0
          );
          else
          return false;
        })
        .map((grade) => grade.freadom_grade_name),
    }));
    return [
      <div
        onClick={() => {
          if (this.state.isFilterDropdownVisible)
            this.setState({
              isFilterDropdownVisible: false,
            });
        }}
      >
        <AdminLayout
          title={`Assign Teacher (${
            window.location.pathname.split("/")?.[2]?.includes("recommended")
              ? "Recommended"
              : "All"
          })`}
          showBack={true}
          rightContent={this.renderHeader()}
        >
          <section className="teacher-listing-section admin-teacher-list">
            <Radio.Group
              value={this.state.selectedTeacher}
              onChange={(event) => this.onTeacherSelected(event)}
            >
              <Table
                columns={this.getTheads()}
                dataSource={data}
                pagination={false}
                rowSelection={{
                  type: "radio",
                  selectedRowKeys: [selectedRowKey],
                }}
              />
            </Radio.Group>
            <Pagination
              total={total_count}
              hideOnSinglePage
              onChange={(page) => this.handlePageChange(page)}
              defaultPageSize={page_size}
            />
          </section>
        </AdminLayout>
      </div>,
    ];
  }

  getTheads = () => [
    {
      title: "",
      align: "right",
      width: 80,
      render: (rowData) => <ProfilePicCell data={rowData} />,
    },
    {
      title: "Name",
      render: (rowData) => <NameCell data={rowData} />,
    },
    {
      title: "Grades",
      render: (rowData) => <GradesCell data={rowData} />,
    },
    {
      title: "Last allocated Batch",
      align: "center",
      render: (rowData) => <DailyHoursCell data={{}} />,
    },
    {
      title: "Slots",
      align: "center",
      render: (rowData) => <DailyHoursCell data={rowData} />,
    },
    {
      title: "",
      align: "center",
      render: (rowData) => (
        <div>
          <Radio value={rowData.id} />
        </div>
      ),
    },
  ];
}

export default connect(
  ({ teacher, grades }) => ({ ...teacher, grades }),
  (dispatch) =>
    bindActionCreators(
      {
        ...teacherActions,
        ...batchesActions,
        ...gradesAction,
        ...classesActions,
      },
      dispatch
    )
)(TeacherAssignment);
