import React from 'react';
import "./index.scss";

const Pill = (props) => {

    return (
        <div className={`pill type-${props.type}`}>
            {props.text}
        </div>
    );
}

export default Pill;