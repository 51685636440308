import React, { Component } from "react";
// import { Empty } from "antd";
import ImageGallery from "react-image-gallery";
import { ReactReader } from "react-reader";
import CloseBlackIcon from 'assets/image/close-black.svg';

import "./MediaViewer.scss";

const TEXT = 'text';
const AUDIO = 'audio';
const HTML = 'html';
const IMAGE = 'image';
const PDF = 'pdf';
const EPUB = 'epub';
const VIDEO = 'video';
const YOUTUBE = 'youtube';

class MediaViewer extends Component {

  getYoutubeId = url => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
        return match[2];
    } else {
        return 'error';
    }
  }
  componentDidMount() {
    const { data } = this.props;
    const firstMedia = data[0];
    if (firstMedia.content_type === PDF) {
      window.$("#pdf-view").pdf({
        source: firstMedia.media[0].media_url,
        loadingHeight: 500,
        loadingHTML: 'Loading... Please Wait.',
        title: '',
        bottom: 0,
        height: 700,
        disableZoom: true
      });
    }

  }
  render() {
    const { data } = this.props;
    const firstMedia = data[0];
    return (
      <div className="component-media-viewer">
        <img src={CloseBlackIcon} className="close-icon" alt="close" onClick={this.props.closeMedia} />
        {/* Text */}
        { firstMedia.content_type === TEXT && (
          <div dangerouslySetInnerHTML={{ __html: firstMedia.description }}></div>
        )}
        {/* AUDIO FILES */}
        {firstMedia.media.length !== 0 && (
          firstMedia.content_type === AUDIO && (
            <div className="audio-container">
              <audio controls>
                <source src={firstMedia.media[0].media_url} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
            </div>
          )
        )}
        {/* HTML FILES */}
        {firstMedia.content_type === HTML && (
          <div dangerouslySetInnerHTML={{ __html: firstMedia.description }} />
        )}
        {/* IMAGE FILES */}
        {firstMedia.content_type === IMAGE && (
          <ImageGallery
            items={firstMedia.media.map(m => ({
              original: m.media_url,
              thumbnail: m.media_url
            }))}
            originalClass="img-class"
          />
        )}
        {/* PDF FILES */}
        {firstMedia.content_type === PDF && (
          // <iframe
          //   width="80%"
          //   height="100%"
          //   src={`https://docs.google.com/gview?url=${firstMedia.media[0].media_url}&embedded=true`}
          //   frameBorder="0"
          //   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          //   title="pdf file view"
          // /> 
          // <div className="flip-book-container" src={firstMedia.media[0].media_url} />
          <div id="pdf-view"></div>
        )}
        {/* EPUB FILES */}
        {firstMedia.content_type === EPUB && (
          <ReactReader url={firstMedia.media[0].media_url} showToc />
        )}
        {/* VIDEO FILES */}
        {firstMedia.content_type === VIDEO && (
          <video controls>
            <source src={firstMedia.media[0].media_url} type="video/mp4" />
          </video>
        )}
        {/* YOUTUBE FILES */}
        {firstMedia.content_type === YOUTUBE && (
          <iframe
            width="80%"
            height="100%"
            src={`https://www.youtube.com/embed/${this.getYoutubeId(firstMedia.media[0].media_url)}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="video"
          /> 
        )}
      </div>
    );
  }
}

export default MediaViewer;
