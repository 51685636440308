import React, { Component } from "react";
import { Form, Input, Button } from 'antd';
import { connect } from "react-redux";
import LogoImg from 'assets/image/admin-logo.svg';
import "./index.scss";
import { Checkbox } from 'antd';
import { Link } from "react-router-dom";
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as teacherActions from '../../../store/teacher/actions';
import {bindActionCreators} from 'redux';

class TeacherSetPassword extends Component {

constructor(props) {
    super(props);
    this.state = {
        password: "",
        confirmPassword: "",
        privacyPolicy: false,
        values :queryString.parse(this.props.location.search)
    }
}

componentDidMount() {
  if(!(this.state.values.token) || (this.state.values.token.trim() == "")) {
    this.props.history.replace("/");
  }
}

onChange(fieldType, value) {
    this.setState({
        [fieldType]: value
    })
}

async submitForm() {
  if((this.state.password.trim() !== this.state.confirmPassword.trim())) {
    toast.error("Passwords do not match")
    return;
  }
  if(!this.state.privacyPolicy) {
    toast.error("Please accept the privacy policy");
    return;
  }
  const data = {
    password: this.state.password,
  }
  const result = this.props.updatePassword(data,this.state.values.token)
  if(result) {
    toast.success("Password set successfully.")
  }
  else{
    toast.error("Some error has occured.")
  }
}

render() {
  console.log(this.state)
    return [
      <div className="admin-login-left">
        <a href='/admin' className="admin-logo">
          <img src={LogoImg} alt="Freadom" />
        </a>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          className="custom-form admin-login-form"
        >
          <h1>Welcome</h1>
          <p className="admin-login-desc">Please set up a password to access your account:</p>
          <Form.Item name="password">
            <Input type="password" value={this.state.password} placeholder="Password" onChange={(event)=>{this.onChange("password",event.target.value)}} />
          </Form.Item>
          <Form.Item name="password">
            <Input type="password" value={this.state.confirmPassword} placeholder="Confirm Password" onChange={(event)=>{this.onChange("confirmPassword",event.target.value)}} />
          </Form.Item>
          <Checkbox  checked={this.state.privacyPolicy} onChange={(event)=>{this.onChange("privacyPolicy",event.target.checked)}}>I agree to the Privacy Policy and TnC’s</Checkbox>
          <Button type="primary" htmlType="submit" onClick={async ()=>(await this.submitForm())}>SETUP PASSWORD</Button>
          <p style={{paddingBottom:"50px", marginTop:"10px", fontWeight:"500"}}>Already have login credentials? <span><Link  style={loginButtonStyle} onClick={this.props.onLogin} to="/">Login</Link></span></p>
        </Form>
      </div>
    ];
  }
}

const loginButtonStyle = {
    textTransform: "uppercase",
    color:"#515861",
    textDecoration: "underline",
    fontWeight: "bold"
}

export default withRouter(connect(null,dispatch => bindActionCreators({...teacherActions},dispatch))(TeacherSetPassword));