import React from 'react';
import './StudentDetails.scss'

const StudentDetails = ({
                            program_outcomes = [],
                            address = '',
                            father_details = {},
                            mother_details = {},
                            city,
                            pincode,
                            state,
                            school_monthly_expense,
                            other_classes = [], ...props
                        }) => {
    return (
        <React.Fragment>
            {/* NOTE: Only keep n-1 onboarding questions here. keep the last onboarding question outside the below div else dotted line will overflow */}
            < div className='onboarding-questions-container'>
                <div className="dotted-line">
                    <div className="line-fill"></div>
                </div>
                <div className="onboarding-question-details">
                    <div className="question-title">
                        <div className="question-dot"></div>
                        <h5 className="question-title-text">Basic Information</h5>
                        <div className="question-divider"></div>
                    </div>
                    <div className="question-info">
                        <p>Expected Outcome out of Flive :</p>
                        <p>{program_outcomes?.length > 0 ? <ul>
                            {
                                program_outcomes?.map(item => <li>{item}</li>)
                            }
                        </ul> : '-'}
                        </p>
                    </div>
                </div>

                <div className="onboarding-question-details">
                    <div className="question-title">
                        <div className="question-dot"></div>
                        <h5 className="question-title-text">School</h5>
                        <div className="question-divider"></div>
                    </div>
                    <div className="question-info">
                        <p>Approx monthly expenses :</p>
                        <p>{school_monthly_expense?.toString() ? `₹ ${school_monthly_expense}` : '-'}</p>
                    </div>
                    <div className="question-info">
                        <p>Other classes attended :</p>
                        <p>{other_classes?.map(item => item).join(', ') || '-'}</p>
                    </div>
                </div>

                <div className="onboarding-question-details">
                    <div className="question-title">
                        <div className="question-dot"></div>
                        <h5 className="question-title-text">Address</h5>
                        <div className="question-divider"></div>
                    </div>
                    <div className="question-info">
                        <p>Address Line 1 :</p>
                        <p>{address || '-'}</p>
                    </div>
                    <div className="question-info">
                        <p>State :</p>
                        <p>{state || '-'}</p>
                    </div>
                    <div className="question-info">
                        <p>City :</p>
                        <p>{city || '-'}</p>
                    </div>
                    <div className="question-info">
                        <p>Pincode :</p>
                        <p>{pincode || '-'}</p>
                    </div>
                </div>

            </div>

            {/* NOTE: Keep the last onboarding question group outside of the above div and inside here else the dotted line will overflow it */}
            <div className="onboarding-question-details">
                <div className="question-title">
                    <div className="question-dot"></div>
                    <h5 className="question-title-text">Parent's Employment</h5>
                    <div className="question-divider"></div>
                </div>
                {/* Mother Details */}
                <div className="question-info">
                    <p>Mother's Name :</p>
                    <p>{mother_details?.name || '-'}</p>
                </div>
                <div className="question-info">
                    <p>employer’s name :</p>
                    <p>{mother_details?.employer_name || '-'}</p>
                </div>

                <div className="question-info">
                    <p>salary bracket :</p>
                    <p>{mother_details?.salary_bracket || '-'}</p>
                </div>

                {/* Father Details */}
                <div className="question-info">
                    <p>Father's Name :</p>
                    <p>{father_details?.name || '-'}</p>
                </div>
                <div className="question-info">
                    <p>employer’s name :</p>
                    <p>{father_details?.employer_name || '-'}</p>
                </div>

                <div className="question-info">
                    <p>salary bracket :</p>
                    <p>{father_details?.salary_bracket || '-'}</p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StudentDetails;
