import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Select,
  Form,
  Col,
  Button,
  TimePicker,
  DatePicker as AntDatePicker,
  InputNumber,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import * as lmsActions from "store/lms/actions";
import "./index.scss";
import * as preferenceGroupActions from "../../../../store/preferenceGroups/actions";
import moment from "moment";
import Dropdown from "../../../../components/Dropdown";
import {
  getAllTeachers,
  getZoomID,
  getGrade,
  getTeachers,
  getZoomIDwithParams,
} from "../../../../services/teacher";
import { generateHoursList } from "../../../../utils/commonUtils";
import { PreferenceGroupApiCalls } from "../../../../services/preferenceGroups";
import { toast } from "react-toastify";

// created_at: 1623836453
// group_id: "0087de07-4b71-45be-98d2-6e176a1a19ab"
// is_active: true
// max_students: null
// num_of_batches: 1
// product_type: null
// start_time: 1622505600
// start_topic: null
// status: "INPROGRESS"
// students_opted: 2
// teacher_id: null
// timeslots: [{duration: 3600, id: 928, is_deleted: false, start_second: 26200, weekday: 1},…]
// zoom_account: null

const { Option } = Select;
const propertyFields = [
  //     {
  //     label: 'Day',
  //     value: 'Day'
  // },
  // {
  //     label: 'Time',
  //     value: 'Time'
  // },
  {
    label: "Time Slots",
    value: "timeslots",
  },
  {
    label: "Start Date",
    value: "start_time",
  },
  {
    label: "Teacher ID",
    value: "teacher_id",
  },
  {
    label: "Zoom ID",
    value: "zoom_account",
  },
  {
    label: "Grade",
    value: "grade_id",
  },
  {
    label: "Start Topic",
    value: "start_topic",
  },
  {
    label: "Product Label",
    value: "product_type",
  },
  // ,
  // {
  //     label: 'Number of Strudents',
  //     value: 'students_opted'
  // }
];
// const propertyFields = ['Day', 'Time', 'Start Date', 'Teacher ID',
//     'Zoom ID', 'Grade', 'Start Topic', 'Product Label']
const productLable = [
  {
    label: "FLIVE",
    value: "FLIVE",
  },
  {
    label: "F1",
    value: "F1",
  },
  {
    label: "SummerCAMP",
    value: "SUMMERCAMP",
  },
  {
    label: "FGLOBAL",
    value: "FGLOBAL",
  },
];

const days = [
  {
    label: "M W F",
    value: "1 3 5",
  },
  {
    label: "T TH S",
    value: "2 4 6",
  },
];
// const productLable = ['FREADOM LIVE', 'FREADOM ONE', 'SUMMER CAMP', 'FREADOM GLOBAL'];
// const startTopic = [1, 31, 61, 91];
const startTopic = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 31,
    value: 31,
  },
  {
    label: 61,
    value: 61,
  },
  {
    label: 91,
    value: 91,
  },
];
// const days = [
//     'Sunday',
//     'Monday',
//     'Tuesday',
//     'Wednesday',
//     'Thursday',
//     'Friday',
//     'Saturday'
// ];

class UpdateModal extends PureComponent {
  debounceSearch;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      propertyValues: [],
      buttonFlag: false,
      teacherList: [],
      zoomList: [],
      gradeList: [],
      startTimeForSlot: 0,
    };
  }

  componentDidMount() {
    this.getZoomId();
    this.getGrade();
    //this.getTeacher()
  }

  addNewRow = () => {
    const { propertyValues } = this.state;
    if (
      propertyValues.length == 0 ||
      propertyValues[propertyValues.length - 1].property
    ) {
      let obj = { property: "", value: "" };
      this.setState((prevState) => ({
        propertyValues: [...prevState.propertyValues, obj],
      }));
      this.setState({ buttonFlag: false, error: null });
    } else {
      this.setState({ buttonFlag: true, error: "Please select the property" });
    }
  };

  removeRow = (index) => {
    let temppropertyValueArr = [...this.state.propertyValues];
    temppropertyValueArr.splice(index, 1);
    this.setState({
      propertyValues: temppropertyValueArr,
      buttonFlag: false,
      error: null,
    });
    const propvalset = {};
    temppropertyValueArr.map((pr, i) => {
      propvalset["property" + i] = pr.property;
    });
    //console.log({...propvalset});
    this.props.form.setFieldsValue(propvalset);
  };

  onChangeProperty = (value, index) => {
    let obj = this.state.propertyValues.find((o) => o.property === value);

    let propertyValues = [...this.state.propertyValues];
    // 2. Make a shallow copy of the item you want to mutate
    let propertyValue = { ...propertyValues[index] };
    // 3. Replace the property you're intested in
    if (obj) {
      // propertyValue.error = 'You have already selected this propetry';
      propertyValue.property = "";
      this.setState({
        buttonFlag: true,
        error: "You have already selected this propetry",
      });
    } else {
      propertyValue.property = value;
      propertyValue.error = null;
      this.setState({ buttonFlag: false, error: null });
    }
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    propertyValues[index] = propertyValue;
    // 5. Set the state to our new copy
    this.setState({ propertyValues });
  };

  onChangeValue = (value, index) => {
    let propertyValues = [...this.state.propertyValues];
    // 2. Make a shallow copy of the item you want to mutate
    let propertyValue = { ...propertyValues[index] };
    // 3. Replace the property you're intested in
    propertyValue.value = value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    propertyValues[index] = propertyValue;
    // 5. Set the state to our new copy
    this.setState({ propertyValues, error: null });
  };

  onChangeTimeSlotDay = (value = [], index) => {
    const { startTimeForSlot } = this.state;
    let propertyValues = [...this.state.propertyValues];
    let propertyValue = { ...propertyValues[index] };
    let tempValues = [];
    value.forEach((element) => {
      tempValues.push({
        duration: 3600,
        is_deleted: false,
        start_second: startTimeForSlot,
        weekday: parseInt(element),
      });
    });
    propertyValue.value = tempValues;
    propertyValues[index] = propertyValue;
    this.setState({ propertyValues, error: null });
  };

  onChangeTimeSlotTime = (value, index) => {
    let propertyValues = [...this.state.propertyValues];
    let propertyValue = { ...propertyValues[index] };
    propertyValue.value.forEach((element) => {
      element["start_second"] = value;
    });
    // propertyValue.value = propertyValue;
    propertyValues[index] = propertyValue;
    this.setState({ propertyValues, error: null, startTimeForSlot: value });
  };

  onFormSubmit = async () => {
    let updateFieldsObject = {};
    this.state.propertyValues.forEach((element) => {
      if (element.property) {
        updateFieldsObject[element.property] = element.value;
      }
    });
    if (Object.keys(updateFieldsObject).length > 0) {
      let finalObject = {
        data: {
          ids: this.props.selectedRowsId,
          updated_fields: updateFieldsObject,
        },
      };
      let res = await PreferenceGroupApiCalls.updateMultiplePreferenceGroup(
        finalObject
      );
      if (res) {
        toast.success("Preference group successfully updated!");
        this.setState({ propertyValues: [] });
        this.props.closeModal();
      } else {
        this.setState({ error: "Something went wrong" });
      }
    } else {
      this.setState({ error: "Please select the property" });
    }
  };

  getDisabledDates = (current, minDate, diff = 1) => {
    return current && minDate && current.diff(minDate, "days") < diff;
  };

  getTeacher = async (e) => {
    let res = await getTeachers(`pattern=${e}`);
    res = res.data.teachers;
    let teacherList = [];
    res.map((teacher) => {
      teacherList.push({
        // "label": teacher.user.name,
        label: `${teacher.user.name} (${teacher.teacher_id})`,

        //FLV-154
        //"value": teacher.id
        value: teacher.user.id,
        //FLV-154
      });
      // teacherList.push(teacher.user.name)
    });
    this.setState({ teacherList });
  };
  getZoomId = async (e) => {
    let res = await getZoomID();
    res = res.data.links;
    let zoomList = [];
    res.map((zoomId) => {
      zoomList.push({
        //"label": zoomId.link,
        label: zoomId.zoom_id,
        value: zoomId.id,
      });
    });
    this.setState({ zoomList });
  };
  //FLV-171
  getZoomIdWithPattern = async (e) => {
    let res = await getZoomIDwithParams(`pattern=${e}`);
    res = res.data.links;
    let zoomList = [];
    res.map((zoomId) => {
      zoomList.push({
        //"label": zoomId.link,
        label: zoomId.zoom_id,
        value: zoomId.id,
      });
    });
    this.setState({ zoomList });
  };
  //FLV-171
  getGrade = async (e) => {
    let params = `page_size=50`;
    let res = await getGrade(params);
    res = res.data.grades;
    let gradeList = [];
    res.map((grade) => {
      gradeList.push({
        label: grade.name,
        value: grade.id,
      });
    });
    this.setState({ gradeList });
  };

  renderGetValues = (propertyValue, index) => {
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    switch (propertyValue) {
      case "timeslots":
        return (
          <>
            {getFieldDecorator("day", {
              rules: [{ required: false, message: "Please select value" }],
            })(
              <Dropdown
                //mode="multiple"
                loading={this.state.loading}
                onChange={(value) => {
                  this.onChangeTimeSlotDay(value.split(" "), index);
                }}
                placeholder={"Select Value"}
                options={days}
              />
            )}
          </>
        );
        break;
      case "product_type":
        return (
          <>
            {getFieldDecorator("productDetail", {
              rules: [{ required: false, message: "Please select value" }],
            })(
              <Dropdown
                loading={this.state.loading}
                onChange={(value) => {
                  this.onChangeValue(value, index);
                }}
                placeholder={"Select Value"}
                options={productLable}
              />
            )}
          </>
        );
        break;
      case "start_topic":
        return (
          <>
            {getFieldDecorator("startTopic", {
              rules: [{ required: false, message: "Please select value" }],
            })(
              <Dropdown
                loading={this.state.loading}
                onChange={(value) => {
                  this.onChangeValue(value, index);
                }}
                placeholder={"Select Value"}
                options={startTopic}
              />
            )}
          </>
        );
        break;
      // case 'Time':
      //     return (
      //         <TimePicker use12Hours format="h:mm a" onChange={(time, timeString) => this.onChangeValue(timeString, index)} />
      //     )
      //     break;
      case "start_time":
        return (
          <AntDatePicker
            disabled={this.state.loading}
            format={"DD/MM/YYYY"}
            allowClear={false}
            inputReadOnly={false}
            showToday={false}
            disabledDate={(current) =>
              this.getDisabledDates(current, moment(), 0)
            }
            // onChange={onSelection}
            onChange={(currentVal) => {
              console.log(
                new Date(
                  parseInt(moment(currentVal)?.format("X")) * 1000
                ).setHours(0, 0, 0, 0) / 1000
              );
              //this.onChangeValue(parseInt(moment(currentVal)?.format("X")), index)
              this.onChangeValue(
                new Date(
                  parseInt(moment(currentVal)?.format("X")) * 1000
                ).setHours(0, 0, 0, 0) / 1000,
                index
              );
            }}
            rules={[{ required: true, message: "Please select date!" }]}
          />
        );
        break;
      case "teacher_id":
        return (
          <>
            {getFieldDecorator("teacher_id", {
              rules: [{ required: false, message: "Please choose Teacher." }],
            })(
              <Dropdown
                className="teacherselection"
                //FLV-173
                filter_not_exact={true}
                //FLV-173
                showSearch={true}
                loading={this.state.loading}
                onChange={(value) => {
                  this.onChangeValue(value, index);
                }}
                onSearch={(e) => {
                  this.getTeacher(e);
                }}
                placeholder={"Select Value"}
                optionFilterProp="children"
                options={this.state.teacherList}
              />
            )}
          </>
        );
      case "zoom_account":
        return (
          <>
            {getFieldDecorator("zoom_id", {
              rules: [{ required: false, message: "Please select Zoom Id" }],
            })(
              <Dropdown
                showSearch={true}
                loading={this.state.loading}
                onChange={(value) => {
                  this.onChangeValue(value, index);
                }}
                dropdown_zoom_ID={true}
                placeholder={"Select Value"}
                optionFilterProp="children"
                options={this.state.zoomList}
                //FLV-171
                filter_not_exact={true}
                onSearch={(e) => {
                  this.getZoomIdWithPattern(e);
                }}
                //FLV-171
              />
            )}
          </>
        );
      case "grade_id":
        return (
          <>
            {getFieldDecorator("grade", {
              rules: [{ required: false, message: "Please select Grade" }],
            })(
              <Dropdown
                showSearch={true}
                loading={this.state.loading}
                onChange={(value) => {
                  this.onChangeValue(value, index);
                }}
                placeholder={"Select Value"}
                optionFilterProp="children"
                options={this.state.gradeList}
              />
            )}
          </>
        );
      // case 'students_opted':
      //     return (
      //         <>
      //             {
      //                 getFieldDecorator('max_students', {
      //                     rules: [{
      //                         required: false,
      //                         message: 'Please enter number of students'
      //                     }],
      //                 })
      //                     (<InputNumber min={1}
      //                         disabled={this.state.loading}
      //                         onChange={(value) => this.onChangeValue(value, index)}
      //                         placeholder="Enter Number Of Students"
      //                     />)
      //             }
      //         </>

      //     )
      default:
        return <div style={{ height: 60 }} />;
    }
  };

  renderTime = (index) => {
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        {getFieldDecorator("startTime", {
          rules: [
            {
              required: false,
              message: "Please select start time",
            },
          ],
        })(
          <Dropdown
            loading={this.state.loading}
            onChange={(value) => {
              value = value.toString();
              let [hours, mins] =
                value.length === 3
                  ? [value[0], value[1] + "" + value[2]]
                  : [value[0] + "" + value[1], value[2] + "" + value[3]];
              let seconds = hours * 60 * 60 + mins * 60;
              let timezoneOffsetSeconds = -(
                new Date().getTimezoneOffset() * 60
              );
              let totalTimeInSeconds = seconds - timezoneOffsetSeconds;
              if (totalTimeInSeconds < 0) {
                let dayInSeconds = 86400;
                seconds = dayInSeconds + totalTimeInSeconds;
              } else seconds = totalTimeInSeconds;
              this.onChangeTimeSlotTime(seconds, index);
            }}
            placeholder={"Select Time"}
            options={generateHoursList(8) || []}
          />
        )}
      </>
      // <TimePicker use12Hours format="h:mm a" onChange={(time, timeString) => this.onChangeTimeSlotTime(timeString, index)} />
    );
  };

  render() {
    const { propertyValues } = this.state;
    const { getFieldDecorator, setFieldsValue } = this.props.form;

    return (
      <div style={{ padding: 24 }}>
        <div className="update-modal-maindiv-heading">
          <div className="update-heading--button ">
            <div className="update-modal-heading">Update Slots</div>
            <div
              className="update-add-min-icon"
              onClick={() => this.addNewRow()}
            >
              +
            </div>
          </div>
          <CloseCircleOutlined
            style={{ fontSize: 20 }}
            onClick={() => {
              this.setState({ propertyValues: [] });
              this.props.closeModal();
            }}
          />
        </div>
        <div className="property-values-maindiv">
          {propertyValues.map((pv, index) => (
            <>
              <Col className="update-dropdown-maindiv" span={24}>
                <Col span={8}>
                  <Form.Item label="Property">
                    {getFieldDecorator("property" + index, {
                      rules: [
                        { required: false, message: "Please select property" },
                      ],
                    })(
                      <Dropdown
                        loading={this.state.loading}
                        //value={pv.property}
                        onChange={(value) => {
                          this.onChangeProperty(value, index);
                        }}
                        placeholder={"Select Property"}
                        options={propertyFields}
                      />
                    )}
                  </Form.Item>
                  {/* <Form.Item
                                    label="Property"
                                >
                                    <Select
                                        style={{ width: 160 }}
                                        placeholder={'Select Property'}
                                        // value={pv.property}
                                        // defaultValue={'Select Property'}
                                        onChange={(value) => this.onChangeProperty(value, index)}
                                    >
                                        {propertyFields?.map(item => <Option value={item}>{item}</Option>)}

                                    </Select>
                                </Form.Item> */}
                </Col>
                <Col span={7}>
                  <Form.Item label="Value">
                    {this.renderGetValues(pv.property, index)}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  {pv.property == "timeslots" && pv.value.length > 0 && (
                    <Form.Item label="Value">
                      {this.renderTime(index)}
                    </Form.Item>
                  )}
                </Col>
                <Col span={2}>
                  <div
                    className="update-add-min-icon"
                    onClick={() => this.removeRow(index)}
                  >
                    -
                  </div>
                </Col>
              </Col>
            </>
          ))}
        </div>

        <div className="submit-button-maindiv">
          <div className="error-div">{this.state.error}</div>
          <Button
            disabled={this.state.buttonFlag}
            loading={this.state.loading}
            type="primary"
            className="ant-btn-md export-btn"
            onClick={this.onFormSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ teacher, lms, preferenceGroups }) => ({
    ...teacher,
    ...lms,
    ...preferenceGroups,
  }),
  (dispatch) =>
    bindActionCreators({ ...lmsActions, ...preferenceGroupActions }, dispatch)
)(Form.create({ name: "update-modal" })(UpdateModal));
