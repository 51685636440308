import {StudentApiCalls} from "../../services/student";
import {mutation} from "./mutations";

const handleError = err => {
    console.log("Error in Login action : ", err);
};

// Actions
export const getAdminStudentList = (param = '') => async dispatch => {
  try {
    const result = await StudentApiCalls.getAdminStudentList(param);
    dispatch(mutation.setAdminStudentList(result))
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getFreadomStudentList = (searchText = null, offset) => async dispatch => {
    try {
        const result = await StudentApiCalls.getFreadomStudentList(searchText, offset);
        dispatch(mutation.setFreadomStudentList(result));
        return result;
    } catch (err) {
        handleError(err);
    }
}

export const addStudentToFlive = (payload) => async dispatch => {
    try {
        const result = await StudentApiCalls.addStudentToFlive({
            data: payload
        });
        return result.data;
    } catch (err) {
        handleError(err);
        return undefined;
    }
}

export const clearSearchData = () => async dispatch => {
    try {
        dispatch(mutation.clearSearchData());
    } catch (err) {
        handleError(err);
    }
}
