import React from 'react';
import './ScheduleModal.scss';
import {Modal} from 'antd';
import {returnWeekDay} from "../../utils/commonUtils";

const ScheduleModal = (props) => {
    return (
        <Modal
            wrapClassName="schedule-modal mob-modal-type-2"
            maskStyle={{
                background: "rgba(0,0,0,0.45)"
            }}
            title="Schedule"
            width={950}
            visible={props.visible}
            onOk={null}
            onCancel={props.closeModal}

        >
            <div className="schedules">
                {
                    props.batchDetails?.preference_group?.timeslots.map((time, index) => (
                        <div className="schedule-item">
                            <h2>{returnWeekDay(time.weekday, true)}</h2>
                            <h4>{`${returnTime(time.start_second)} - ${returnTime(time.start_second + time.duration)}`}</h4>
                        </div>
                    ))
                }
            </div>
        </Modal>
    );
}


function returnTime(startSeconds) {
    try {
        let timezoneOffsetSeconds = -(new Date().getTimezoneOffset() * 60)
        let totalStartTimeInSeconds = timezoneOffsetSeconds + startSeconds
        let hourIn24 = Math.floor(totalStartTimeInSeconds / 3600);
        let hourIn12 = hourIn24 % 12 || 12;
        let mins = Math.floor(totalStartTimeInSeconds % 3600 / 60).toString().padStart(2, '0');
        return `${hourIn12}:${mins} ${(hourIn24 < 12 || hourIn24 === 24) ? 'AM' : 'PM'}`
    } catch (e) {
    }
}

export default ScheduleModal;
