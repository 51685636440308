import React from 'react';
import AnnouncementCard from 'components/AnnouncementCard';
import { Modal } from 'antd';
import './index.scss';
import {CrossIcon} from "../../../../../assets/image/svg";

const ViewAnnoucementModal = (props) => {
    return (
        <Modal
            wrapClassName={`view-all-announcement-modal ${props.announcements.length <= 0 ? "mob-modal-type-2" : "ant-modal-mob"} ${props.announcements.length > 0 ? "" : "no-scroll"}`}
            maskStyle={{
                background: "rgba(0,0,0,0.45)"
            }}
            title=""
            visible={props.visible}
            onOk={null}
            onCancel={props.closeModal}
            okText="Add"
            width={413}
            closeIcon={<div className={'anticon'}><CrossIcon width={18} height={18} color={'#524E59'}/></div>}

        >
            <AnnouncementCard
                onClose={props.closeModal}
                onChangeAnnPopup={props.onChangeAnnPopup}
                announcements={props.announcements}
            />
        </Modal>
    );
}

export default ViewAnnoucementModal;
