import React from "react";

const defaultIconColor = '#524E59';

export const GraduationCapIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" fill="none"
                                                   viewBox="0 0 24 20">
    <path fill={color || defaultIconColor} fillRule="evenodd"
          d="M23.307 4.85c.456.192.733.659.683 1.15H24v8c0 .552-.448 1-1 1s-1-.448-1-1V7.425L12 11 .76 6.981C.316 6.823.015 6.41 0 5.94c-.014-.471.26-.902.693-1.088L12 0l11.307 4.85zm-11.98 8.033l.673.241.674-.24L20 10.263V17c-4.584 4-11.417 4-16 0v-6.736l7.327 2.62z"
          clipRule="evenodd"/>
</svg>;

export const PeopleIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" fill="none"
                                            viewBox="0 0 22 24">
    <path fill={color || defaultIconColor} fillRule="evenodd"
          d="M8.038 3c0 1.657-1.343 3-3 3-1.656-.002-2.998-1.344-3-3 0-1.657 1.343-3 3-3s3 1.343 3 3zM20 3c0 1.657-1.343 3-3 3-1.656-.002-2.998-1.344-3-3 0-1.657 1.343-3 3-3s3 1.343 3 3zm-3 15c-1.657 0-3-1.343-3-3 .002-1.656 1.344-2.998 3-3 1.657 0 3 1.343 3 3s-1.343 3-3 3zm0-11c-2.628 0-4.766 1.327-4.962 3h9.924C21.766 8.327 19.628 7 17 7zm-9 8c0-1.657-1.343-3-3-3s-3 1.343-3 3 1.343 3 3 3c1.656-.002 2.998-1.344 3-3zm-8 7.244C0 20.455 2.243 19 5 19s5 1.455 5 3.244V24H0v-1.756zM17 19c-2.757 0-5 1.455-5 3.244V24h10v-1.756C22 20.455 19.757 19 17 19zM.076 10C.272 8.327 2.41 7 5.038 7 7.666 7 9.804 8.327 10 10H.076z"
          clipRule="evenodd"/>
</svg>

export const BackpackIcon = ({color, width, height}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "20"}
                                                             height={height || "24"} fill="none" viewBox="0 0 20 24">
    <path fill={color || defaultIconColor} fillRule="evenodd"
          d="M14 3v1c3.314 0 6 2.686 6 6v12c0 1.105-.895 2-2 2v-8c-.003-2.76-2.24-4.997-5-5H7c-2.76.003-4.997 2.24-5 5v8c-1.105 0-2-.895-2-2V10c0-3.314 2.686-6 6-6V3C6.002 1.344 7.344.002 9 0h2c1.656.002 2.998 1.344 3 3zM8 4h4V3c0-.552-.448-1-1-1H9c-.552 0-1 .448-1 1v1zm5 9H7c-1.657 0-3 1.343-3 3v8h12v-8c0-1.657-1.343-3-3-3zm-6 5h6c.552 0 1-.448 1-1s-.448-1-1-1H7c-.552 0-1 .448-1 1s.448 1 1 1z"
          clipRule="evenodd"/>
</svg>

export const CalenderTimeIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                                  viewBox="0 0 24 24">
    <path fill={color || defaultIconColor} fillRule="evenodd"
          d="M22 2h-3V1c0-.552-.448-1-1-1s-1 .448-1 1v1H7V1c0-.552-.448-1-1-1S5 .448 5 1v1H2c-.53 0-1.039.21-1.414.586C.21 2.96 0 3.47 0 4v18c0 .53.21 1.039.586 1.414C.96 23.79 1.47 24 2 24h9.349c-2.68-2.162-3.892-5.665-3.12-9.021.773-3.356 3.394-5.977 6.75-6.75 3.356-.772 6.859.439 9.021 3.12V4c0-.53-.21-1.039-.586-1.414C23.04 2.21 22.53 2 22 2zM6 7c-.552 0-1-.448-1-1s.448-1 1-1 1 .448 1 1-.448 1-1 1zm11-1c0 .552.448 1 1 1s1-.448 1-1-.448-1-1-1-1 .448-1 1zm0 4c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm-4 7c0 .552.448 1 1 1h4v-4c0-.552-.448-1-1-1s-1 .448-1 1v2h-2c-.552 0-1 .448-1 1z"
          clipRule="evenodd"/>
</svg>

export const SettingsIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" fill="none"
                                              viewBox="0 0 23 24">
    <path fill={color || defaultIconColor} fillRule="evenodd"
          d="M20.187 13.13l1.59 1.31c.768.614.975 1.695.49 2.55l-1.15 2c-.49.849-1.524 1.217-2.44.87l-1.93-.72c-.613.47-1.286.857-2 1.15l-.33 2c-.163.974-1.013 1.684-2 1.67h-2.3c-.988.014-1.837-.696-2-1.67l-.33-2c-.713-.3-1.385-.69-2-1.16l-1.93.72c-.917.347-1.95-.021-2.44-.87l-1.15-2c-.48-.852-.273-1.927.49-2.54l1.59-1.31c-.052-.381-.079-.765-.08-1.15-.009-.384.008-.768.05-1.15L.727 9.52C-.026 8.9-.22 7.826.267 6.98l1.15-2c.49-.849 1.523-1.217 2.44-.87l1.93.72c.615-.47 1.287-.86 2-1.16l.33-2c.163-.974 1.012-1.683 2-1.67h2.3c.987-.013 1.837.696 2 1.67l.33 2c.713.3 1.385.69 2 1.16l1.93-.72c.916-.347 1.95.021 2.44.87l1.15 2c.48.853.272 1.927-.49 2.54l-1.59 1.31c.052.381.078.765.08 1.15-.002.385-.028.769-.08 1.15zm-12.92-1.15c0 2.21 1.79 4 4 4 2.209 0 4-1.79 4-4 0-2.209-1.791-4-4-4-2.21 0-4 1.791-4 4z"
          clipRule="evenodd"/>
</svg>

export const NumberedListIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" fill="none"
                                                  viewBox="0 0 24 23">
    <path fill={color || defaultIconColor} fillRule="evenodd"
          d="M2 6V2c-.552 0-1-.448-1-1s.448-1 1-1h1c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1zm20-1H10c-1.104 0-2-.895-2-2s.896-2 2-2h12c1.105 0 2 .895 2 2s-.895 2-2 2zM10 17h12c1.105 0 2 .895 2 2s-.895 2-2 2H10c-1.104 0-2-.895-2-2s.896-2 2-2zm12-8H10c-1.104 0-2 .895-2 2s.896 2 2 2h12c1.105 0 2-.895 2-2s-.895-2-2-2zM4 17H1c-.552 0-1 .448-1 1s.448 1 1 1h2c-.552 0-1 .448-1 1s.448 1 1 1H1c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1zm-1-4h1c.552 0 1 .448 1 1s-.448 1-1 1H1c-.379 0-.725-.214-.894-.553-.17-.339-.133-.744.094-1.047L2 11H1c-.552 0-1-.448-1-1s.448-1 1-1h3c.379 0 .725.214.895.553.169.339.132.744-.095 1.047L3 13z"
          clipRule="evenodd"/>
</svg>

export const AddIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none"
                                         viewBox="0 0 28 28">
    <path fill={color || "#524E59"} fillRule="evenodd"
          d="M16.436 16.436h8.11c1.36 0 2.444-1.09 2.444-2.436 0-1.354-1.095-2.435-2.444-2.435h-8.11v-8.11c0-1.361-1.09-2.445-2.436-2.445-1.354 0-2.435 1.095-2.435 2.445v8.11h-8.11c-1.361 0-2.445 1.09-2.445 2.435 0 1.354 1.095 2.436 2.445 2.436h8.11v8.11c0 1.36 1.09 2.444 2.435 2.444 1.354 0 2.436-1.095 2.436-2.444v-8.11z"
          clipRule="evenodd"/>
</svg>

export const CircularCrossIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" fill="none"
                                                   viewBox="0 0 30 31">
    <path fill="#ECECEC" fillRule="evenodd"
          d="M4.393 25.675c-5.857-5.866-5.857-15.377 0-21.242 5.858-5.866 15.356-5.866 21.214 0 5.858 5.866 5.858 15.376 0 21.242-5.858 5.866-15.356 5.866-21.214 0z"
          clipRule="evenodd"/>
    <path fill="#524E59" fillRule="evenodd"
          d="M17.088 15.054l3.475-3.48c.584-.585.58-1.517.004-2.095-.58-.58-1.513-.575-2.091.004L15 12.963l-3.476-3.48c-.583-.584-1.514-.58-2.09-.004-.581.582-.576 1.515.003 2.095l3.475 3.48-3.475 3.48c-.584.585-.58 1.517-.004 2.095.58.581 1.513.575 2.091-.004L15 17.145l3.476 3.48c.583.584 1.515.58 2.09.004.581-.582.576-1.515-.003-2.095l-3.475-3.48z"
          clipRule="evenodd"/>
</svg>

export const DownArrow = () => <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" fill="none"
                                    viewBox="0 0 12 8">
    <path stroke="#525860" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1.41l5 5.023 5-5.024"/>
</svg>

export const PencilIcon = ({width = 18, height = 18, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width}
                                                                     height={height} fill="none"
                                                                     viewBox="0 0 18 18">
    <path fill={color || '#9134D8'}
          d="M17.121 5.121c.758-.757 1.054-1.862.777-2.897C17.62 1.188 16.81.38 15.776.102c-1.035-.277-2.14.019-2.898.777l-1.464 1.464 4.243 4.243L17.12 5.12zM14.243 8L10 3.757 1.667 12.09 0 18l5.91-1.667L14.243 8z"/>
</svg>

export const ListReorderIcon = ({color}) => <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none"
                                                 viewBox="0 0 17 16">
    <path fill={color || '#524E59'} fillRule="evenodd"
          d="M5.626 3.237c-.078.19-.263.313-.468.313h-.909v8.5h.909c.205 0 .39.124.468.313.079.19.035.408-.11.553L2.833 15.6.15 12.916c-.145-.145-.189-.363-.11-.553.078-.19.263-.313.468-.313h.909v-8.5H.507c-.205 0-.39-.124-.468-.313-.079-.19-.035-.408.11-.553L2.832 0l2.685 2.684c.144.145.188.363.11.553zm9.957.313H8.5c-.782 0-1.416-.634-1.416-1.417 0-.782.634-1.417 1.416-1.417h7.084c.783 0 1.417.635 1.417 1.417 0 .783-.634 1.417-1.417 1.417zm0 2.833H8.5c-.782 0-1.416.635-1.416 1.417 0 .783.634 1.417 1.416 1.417h7.084C16.366 9.217 17 8.583 17 7.8c0-.782-.634-1.417-1.417-1.417zM8.5 12.05h7.084c.783 0 1.417.635 1.417 1.417 0 .783-.634 1.417-1.417 1.417H8.5c-.782 0-1.416-.634-1.416-1.417 0-.782.634-1.417 1.416-1.417z"
          clipRule="evenodd" opacity=".3"/>
</svg>

export const TimeIcon = ({color, width, height}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || 24}
                                                         height={height || 24} viewBox="0 0 10 10">
    <path fill={color || "#3E2272"} fillRule="nonzero"
          d="M5 0a5 5 0 1 0 0 10A5 5 0 0 0 5 0zm.417 5.833h-2.5a.417.417 0 1 1 0-.833h1.666V2.5a.417.417 0 0 1 .834 0v3.333z"/>
</svg>

export const StudentBatches = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "22"}
                                                               height={height || "24"} fill="none" viewBox="0 0 22 24">
    <path fill={color || "#9134D8"} fillRule="evenodd"
          d="M8.038 3c0 1.657-1.343 3-3 3-1.656-.001-2.998-1.343-3-3 0-1.656 1.343-3 3-3s3 1.344 3 3zM20 3c0 1.657-1.343 3-3 3-1.656-.001-2.998-1.343-3-3 0-1.656 1.343-3 3-3s3 1.344 3 3zm-3 15c-1.657 0-3-1.343-3-3 .002-1.656 1.344-2.998 3-3 1.657 0 3 1.344 3 3 0 1.657-1.343 3-3 3zm0-11c-2.628 0-4.766 1.327-4.962 3h9.924C21.766 8.328 19.628 7 17 7zm-9 8c0-1.656-1.343-3-3-3s-3 1.344-3 3c0 1.657 1.343 3 3 3 1.656-.001 2.998-1.343 3-3zm-8 7.244C0 20.456 2.243 19 5 19s5 1.456 5 3.244V24H0v-1.756zM17 19c-2.757 0-5 1.456-5 3.244V24h10v-1.756C22 20.456 19.757 19 17 19zM.076 10C.272 8.328 2.41 7 5.038 7 7.666 7 9.804 8.327 10 10H.076z"
          clipRule="evenodd"/>
</svg>

export const CalenderNext = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "20"}
                                                             height={height || "20"} fill="#ffffff" viewBox="0 0 20 20">
    <path fill="#000" fillRule="evenodd"
          d="M15.833 1.666h2.5c.442 0 .866.176 1.179.488.312.313.488.737.488 1.18v14.998c0 .443-.176.867-.488 1.18-.313.312-.737.488-1.18.488H1.668c-.442 0-.866-.176-1.179-.489C.176 19.2 0 18.775 0 18.332V3.333c0-.442.176-.866.488-1.179.313-.312.737-.488 1.18-.488h2.499V.833C4.167.373 4.54 0 5 0c.46 0 .833.373.833.833v.833h8.334V.833c0-.46.373-.833.833-.833.46 0 .833.373.833.833v.833zM4.167 5c0 .46.373.833.833.833.46 0 .833-.373.833-.833 0-.46-.373-.833-.833-.833-.46 0-.833.373-.833.833zm4.687 11.485c-.232.188-.547.236-.825.128-.278-.108-.477-.357-.522-.652-.044-.295.072-.591.306-.777l3.353-2.682-3.353-2.683c-.234-.186-.35-.482-.306-.777.045-.295.244-.544.522-.652.278-.108.593-.06.825.128l4.98 3.984-4.98 3.983zM14.167 5c0 .46.373.833.833.833.46 0 .833-.373.833-.833 0-.46-.373-.833-.833-.833-.46 0-.834.373-.834.833z"
          clipRule="evenodd"/>
</svg>

export const CalenderPrev = ({width, height}) => <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="16"
                                                      height="16" viewBox="0 0 1280.000000 1280.000000"
                                                      preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
            d="M3051 12784 c-80 -21 -147 -61 -224 -133 -78 -74 -138 -183 -156 -285 -6 -33 -11 -186 -11 -343 l0 -283 -823 0 c-505 0 -854 -4 -905 -10 -469 -59 -850 -427 -921 -890 -9 -56 -11 -1317 -9 -5020 l3 -4945 23 -80 c113 -387 390 -663 772 -767 l85 -23 5515 0 5515 0 85 23 c383 105 667 389 772 772 l23 85 0 4990 0 4990 -28 90 c-60 195 -155 352 -290 482 -172 165 -378 264 -609 293 -51 6 -400 10 -905 10 l-823 0 0 283 c0 157 -5 310 -11 343 -18 102 -78 211 -156 285 -116 108 -217 149 -373 149 -156 0 -257 -41 -373 -149 -78 -74 -138 -183 -156 -285 -6 -33 -11 -186 -11 -343 l0 -283 -2660 0 -2660 0 0 283 c0 157 -5 310 -11 343 -18 102 -78 211 -156 285 -117 110 -218 150 -378 148 -49 0 -114 -7 -144 -15z m254 -2669 c284 -60 470 -338 411 -615 -44 -210 -205 -372 -411 -415 -38 -8 -86 -15 -105 -15 -63 0 -173 29 -240 62 -85 43 -185 143 -228 228 -33 67 -62 177 -62 240 0 19 7 67 15 105 43 202 205 366 405 409 85 19 130 19 215 1z m6400 0 c102 -22 189 -69 265 -145 40 -40 78 -90 98 -130 33 -67 62 -177 62 -240 0 -63 -29 -173 -62 -240 -68 -134 -211 -243 -363 -275 -38 -8 -86 -15 -105 -15 -63 0 -173 29 -240 62 -134 68 -243 211 -275 363 -8 39 -15 86 -15 105 0 19 7 67 15 105 43 202 205 366 405 409 85 19 130 19 215 1z m-2112 -2670 c80 -21 164 -65 223 -119 234 -210 238 -559 8 -777 -27 -26 -526 -428 -1109 -894 -583 -466 -1061 -850 -1063 -854 -1 -4 476 -389 1060 -856 585 -468 1085 -870 1112 -895 229 -210 226 -564 -7 -777 -158 -144 -395 -175 -588 -78 -44 23 -3261 2587 -3266 2603 -4 13 3214 2577 3268 2604 111 56 248 73 362 43z"/>
    </g>
</svg>

export const CheckIcon = ({width, height, color}) => <svg id="Capa_1" viewBox="0 0 512 512"
                                                          fill={color ? color : "#eeeeee"} width={width || "20"}
                                                          height={height || "20"} xmlns="http://www.w3.org/2000/svg">
    <g>
        <path
            d="m256 0c-141.49 0-256 114.5-256 256 0 141.49 114.5 256 256 256 141.49 0 256-114.5 256-256 0-141.49-114.5-256-256-256zm-60.914 363.99s-88.422-88.458-94.778-94.802c-14.139-14.139-14.139-37.147 0-51.274 14.175-14.175 37.099-14.175 51.274 0l57.244 57.244c7.118 7.118 18.67 7.069 25.728-.085l125.69-127.502c14.127-14.332 37.208-14.429 51.455-.181 14.03 14.03 14.115 36.942.181 51.081-136.493 138.486-162.414 165.507-162.414 165.507-14.985 14.984-39.383 14.997-54.38.012z"/>
    </g>
</svg>

export const GradeAIcon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "15"}
                                                           height={height || "18"} fill={color ? color : "#eeeeee"}
                                                           viewBox="0 0 15 18">
    <path fill="#9134D8" fillRule="evenodd"
          d="M8.7 0H1.45C.65 0 0 .65 0 1.45v14.5c0 .8.65 1.45 1.45 1.45h11.6c.8 0 1.45-.65 1.45-1.45V5.8H8.7V0zm1.45 4.35V0l4.35 4.35h-4.35zm-2.087 7.596l-1.733.217.92-1.842.813 1.625zm2.722-.34l-1.196.15-1.69-3.38c-.136-.229-.383-.369-.649-.369s-.512.14-.648.369l-2.9 5.8c-.18.358-.034.793.324.973.358.179.794.034.973-.325l.551-1.103 3.2-.4.752 1.503c.179.358.614.504.973.325.358-.18.503-.615.324-.973l-.523-1.046.69-.086c.396-.05.678-.412.629-.809-.05-.397-.412-.679-.81-.63z"
          clipRule="evenodd"/>
</svg>

export const LibraryIcon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "16"}
                                                            height={height || "16"} fill={color ? color : "#eeeeee"}
                                                            viewBox="0 0 16 16">
    <path fill="#9134D8" fillRule="evenodd"
          d="M10.667 2.667C10.667 1.194 9.473 0 8 0 6.528 0 5.334 1.194 5.334 2.667c0 1.472 1.194 2.666 2.666 2.666 1.473 0 2.667-1.194 2.667-2.666zM0 6.412v6.57c0 .6.402 1.128.983 1.286L7.333 16V6.667L1.684 5.126c-.4-.11-.83-.026-1.16.227-.33.252-.524.644-.524 1.06zm8.666.255l5.65-1.541c.4-.11.83-.026 1.16.227.33.252.523.644.523 1.06v6.569c0 .6-.402 1.128-.982 1.286L8.667 16V6.667z"
          clipRule="evenodd"/>
</svg>

export const Calender15Icon = ({width, height, color = '#9134D8'}) => <svg xmlns="http://www.w3.org/2000/svg"
                                                                           width={width || "24"}
                                                                           height={height || "24"}
                                                                           fill={color ? color : "#eeeeee"}
                                                                           viewBox="0 0 24 24">
    <path fill={color} fillRule="evenodd"
          d="M22 2h-3V1c0-.552-.448-1-1-1s-1 .448-1 1v1H7V1c0-.552-.448-1-1-1S5 .448 5 1v1H2c-.53 0-1.039.21-1.414.586C.21 2.96 0 3.47 0 4v18c0 .53.21 1.039.586 1.414C.96 23.79 1.47 24 2 24h20c.53 0 1.039-.21 1.414-.586C23.79 23.04 24 22.53 24 22V4.002c0-.531-.21-1.04-.586-1.415C23.04 2.21 22.53 2 22 2zM5 6c0-.552.448-1 1-1s1 .448 1 1-.448 1-1 1-1-.448-1-1zm4 14c.552 0 1-.448 1-1v-8c0-.347-.18-.668-.474-.85-.295-.183-.663-.2-.973-.045l-2 1c-.493.248-.692.848-.445 1.341.246.493.845.694 1.34.449L8 12.618V19c0 .552.448 1 1 1zm4 0c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1-.448 1-1s-.448-1-1-1h-2c-.265 0-.52-.105-.707-.293C12.105 15.52 12 15.265 12 15v-4c0-.265.105-.52.293-.707.187-.188.442-.293.707-.293h3.5c.552 0 1 .448 1 1s-.448 1-1 1H14v2h1c1.657 0 3 1.343 3 3s-1.343 3-3 3h-2zm4-14c0 .552.448 1 1 1s1-.448 1-1-.448-1-1-1-1 .448-1 1z"
          clipRule="evenodd"/>
</svg>

export const CrossIcon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "12"}
                                                          height={height || "11"} fill={color ? color : "#000"}
                                                          viewBox="0 0 12 11">
    <path fill={color ? color : "#000"}
          d="M7.403 5l2.293-2.293c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0L5.988 3.586 3.695 1.293c-.39-.39-1.023-.39-1.414 0-.39.39-.39 1.024 0 1.414L4.574 5 2.281 7.293c-.252.253-.351.621-.259.966.093.345.362.615.707.707.346.093.714-.006.966-.259l2.293-2.293 2.294 2.293c.39.39 1.023.39 1.414 0 .39-.39.39-1.023 0-1.414L7.403 5z"/>
</svg>

export const MegaphoneIcon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "19"}
                                                              height={height || "19"} fill={color ? color : "#eeeeee"}
                                                              viewBox="0 0 18 18">
    <path fill="#333" fillRule="evenodd"
          d="M0 6.032c.001-1.249 1.013-2.26 2.262-2.262h8.029L15.079 0v16.587l-4.788-3.77H7.656l1.284 3.21c.307.773-.07 1.649-.842 1.957-.772.31-1.649-.066-1.96-.837l-1.73-4.33H2.261C1.013 12.816.002 11.804 0 10.556V6.032zm18 2.262c0 1.072-.576 2.06-1.508 2.589V5.705C17.424 6.234 18 7.223 18 8.294z"
          clipRule="evenodd"/>
</svg>

export const RepeatIcon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "13"}
                                                           height={height || "13"} fill={color ? color : "#eeeeee"}
                                                           viewBox="0 -3 14 14">
    <path fill={color ? color : "#000"} fillRule="evenodd"
          d="M2.38 4.884C2.592 2.21 4.807.14 7.488.11c2.852.066 5.13 2.397 5.13 5.251 0 2.854-2.278 5.185-5.13 5.25-.396 0-.716-.32-.716-.715 0-.396.32-.716.716-.716 1.97-.004 3.593-1.548 3.696-3.515.102-1.968-1.353-3.672-3.312-3.88-1.96-.208-3.739 1.153-4.052 3.099h.673c.2-.005.38.115.453.3.073.186.023.397-.125.53L3.072 7.27 1.324 5.714c-.149-.133-.199-.344-.126-.53.074-.185.254-.305.454-.3h.728zm4.033-.955c0-.264.214-.477.478-.477.263 0 .477.213.477.477v1.432H8.8c.264 0 .477.214.477.477 0 .264-.213.478-.477.478H6.413V3.929z"
          clipRule="evenodd"/>
</svg>

export const TickNoBGIcon = ({width, height, color}) => <svg width={width || "11"} height={height || "11"}
                                                             fill={color ? color : "#000"} viewBox="0 0 515.556 515.556"
                                                             xmlns="http://www.w3.org/2000/svg">
    <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/>

    <path fill="#9134D8" fillRule="evenodd"
          d="M8.7 0H1.45C.65 0 0 .65 0 1.45v14.5c0 .8.65 1.45 1.45 1.45h11.6c.8 0 1.45-.65 1.45-1.45V5.8H8.7V0zm1.45 4.35V0l4.35 4.35h-4.35zm-2.087 7.596l-1.733.217.92-1.842.813 1.625zm2.722-.34l-1.196.15-1.69-3.38c-.136-.229-.383-.369-.649-.369s-.512.14-.648.369l-2.9 5.8c-.18.358-.034.793.324.973.358.179.794.034.973-.325l.551-1.103 3.2-.4.752 1.503c.179.358.614.504.973.325.358-.18.503-.615.324-.973l-.523-1.046.69-.086c.396-.05.678-.412.629-.809-.05-.397-.412-.679-.81-.63z"
          clipRule="evenodd"/>
</svg>


export const FilterIcon = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "20"}
                                                           height={height || "20"} fill="none" viewBox="0 0 16 14">
    <path fill="#CCCACE"
          d="M15.794 1.063C15.457.412 14.762 0 14.001 0H1.999C1.234 0 .536.417.202 1.074c-.335.656-.248 1.438.224 2.012L6 9.546V14l3.26-1.56c.453-.214.74-.655.74-1.138V9.545l5.588-6.459c.462-.576.542-1.353.206-2.004v-.02z"/>
</svg>

export const WowImage = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "20"}
                                                         height={height || "20"} fill="none" viewBox="0 0 57 46">
    <path fill="#4E4BD9" d="M0.042 0H58.276V49.915H0.042z"/>
</svg>

export const PeopleIcon2 = ({width, height, color}) => <svg xmlns="http://www.w3.org/2000/svg" width={width || "24"}
                                                            height={height || "20"} fill="none" viewBox="0 0 24 20">
    <path fill={color || "#524E59"} fillRule="evenodd"
          d="M16.5 4.5C16.5 6.985 14.485 9 12 9c-2.484-.003-4.497-2.016-4.5-4.5C7.5 2.015 9.515 0 12 0s4.5 2.015 4.5 4.5zM0 20v-3.297c0-1.91 1.707-3.555 4.146-4.29-.73.942-1.132 2.098-1.146 3.29V20H0zM5.5 4.5c0-.435.044-.87.13-1.296-.362-.13-.744-.2-1.13-.204-1.619-.01-3.033 1.091-3.42 2.663-.387 1.572.354 3.204 1.792 3.947 1.439.743 3.199.402 4.256-.823C6.08 7.604 5.501 6.08 5.5 4.5zM21 15.703c-.014-1.192-.416-2.348-1.146-3.29 2.44.735 4.146 2.38 4.146 4.29V20h-3v-4.297zM23 6.5c-.002-1.932-1.568-3.498-3.5-3.5-.386.004-.768.073-1.13.204.406 1.993-.149 4.06-1.498 5.582.952 1.108 2.493 1.505 3.861.994C22.101 9.27 23.006 7.96 23 6.5zM5 15.703C5 13.11 8.14 11 12 11s7 2.11 7 4.703V20H5v-4.297z"
          clipRule="evenodd"/>
</svg>
export const PeopleAdd = ({width, height, color}) => <svg width={width || "24"} height={height || "24"}
                                                          viewBox="0 0 24 24" fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8 12C11.0376 12 13.5 9.53757 13.5 6.5C13.5 3.46243 11.0376 1 8 1C4.96243 1 2.5 3.46243 2.5 6.5C2.50352 9.53611 4.96389 11.9965 8 12ZM8 14C3.58838 14 0 16.4111 0 19.375V23H16V19.375C16 16.4111 12.4114 14 8 14ZM21 11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H21V15C21 15.5523 20.5523 16 20 16C19.4477 16 19 15.5523 19 15V13H17C16.4477 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11H19V9C19 8.44772 19.4477 8 20 8C20.5523 8 21 8.44772 21 9V11Z"
          fill={color || "#524E59"}/>
</svg>


export const FreadomIcon = ({width, height, color}) => <svg width={width || "21"} height={height || "21"}
                                                            viewBox="0 0 21 21" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20.6557 0L20.6562 2.06999L18.5823 0H20.6557ZM5.14306 7.40553H10.2864L4.11448 4.83891L0 9.45873L2.05716 10.4852L5.14306 7.40553ZM1.02859 12.0244H7.71452L11.829 8.10821L5.14291 7.91804L1.02859 12.0244ZM8.22882 19.2113V12.5384L12.1912 8.43217L12.3433 15.105L8.22882 19.2113ZM12.8578 9.97268L12.8574 15.1058L9.7717 18.185L10.8003 20.2385L15.4298 16.1323L12.8578 9.97268ZM4.11445 16.1311C4.4784 13.5905 6.10963 11.9625 7.20035 13.0512C8.29123 14.1399 6.66016 15.7679 4.11445 16.1311ZM14.3906 3.68026L14.3906 3.68027L14.331 3.72917C12.9577 4.85499 11.5406 6.01726 10.1577 7.17921L12.1706 8.18351L18.18 2.18524L17.288 1.29479C16.3212 2.09693 15.3428 2.89938 14.3909 3.68006L14.3906 3.68025L14.3906 3.68026ZM14.2811 9.47097C14.1067 9.67468 13.9295 9.89533 13.7585 10.1085C13.6606 10.2306 13.5613 10.3546 13.4608 10.4762L12.4557 8.46876L18.4653 2.47049L19.3681 3.37213C17.8326 5.27839 16.207 7.20943 14.2811 9.47097ZM14.0749 3.41903C15.0355 2.6314 16.0248 1.81993 17.0019 1.00975L16.5085 0.517914L8.21267 6.20951L9.76526 6.98371C11.1919 5.78371 12.6564 4.58242 14.0749 3.41903ZM14.0781 10.3535C14.2457 10.1445 14.4184 9.92918 14.5878 9.73127C16.5052 7.48044 18.1247 5.5566 19.6549 3.65753L20.1374 4.13897L14.4345 12.4181L13.6586 10.8691C13.7835 10.7199 13.9084 10.5646 14.0311 10.4119C14.0453 10.3943 14.0594 10.3767 14.0735 10.3592L14.0781 10.3535Z"
          fill="#9134D8"/>
</svg>

export const DefaultUserIcon = () => <svg width="35" height="35" viewBox="0 0 35 35" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M18 1C8.61116 1 1 8.61116 1 18C1 27.3888 8.61116 35 18 35C27.3888 35 35 27.3888 35 18C35 8.61116 27.3888 1 18 1ZM18 8.08333C21.5208 8.08333 24.375 10.9375 24.375 14.4583C24.375 17.9791 21.5208 20.8333 18 20.8333C14.4792 20.8333 11.625 17.9791 11.625 14.4583C11.6292 10.9393 14.4809 8.08754 18 8.08333ZM8.5046 28.4825C11.0997 30.8515 14.4862 32.1655 18 32.1667C21.5138 32.1655 24.9003 30.8515 27.4954 28.4825C26.2973 25.7087 22.5104 23.6667 18 23.6667C13.4899 23.6667 9.70269 25.7087 8.5046 28.4825Z"
          fill="#ECECEC"/>
</svg>

export const MenuIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
    <path d="M3 4H21" stroke="#524E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 11H21" stroke="#524E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 18H21" stroke="#524E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 4H21" stroke="#524E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 11H21" stroke="#524E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 18H21" stroke="#524E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export const TrashCancel = () => <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.5295 3.19596H13.5295C13.8976 3.19596 14.1961 3.49444 14.1961 3.86263C14.1961 4.23082 13.8976 4.5293 13.5295 4.5293H1.52946C1.16127 4.5293 0.862793 4.23082 0.862793 3.86263C0.862793 3.49444 1.16127 3.19596 1.52946 3.19596H3.52946V2.5293C3.52946 1.42473 4.42489 0.529297 5.52946 0.529297H9.52946C10.634 0.529297 11.5295 1.42473 11.5295 2.5293V3.19596ZM4.86279 3.19598H10.1961V2.52932C10.1961 2.16113 9.89765 1.86265 9.52946 1.86265H5.52946C5.16127 1.86265 4.86279 2.16113 4.86279 2.52932V3.19598ZM2.11674 15.276C2.15911 15.9809 2.74384 16.5306 3.45007 16.5293H11.6101C12.3163 16.5306 12.901 15.9809 12.9434 15.276L13.5301 5.86265H1.53007L2.11674 15.276ZM5.17099 8.83595C4.90957 9.09737 4.90957 9.52121 5.17099 9.78262L6.59099 11.196L5.17099 12.6093C4.90957 12.8707 4.90957 13.2945 5.17099 13.556C5.4324 13.8174 5.85624 13.8174 6.11765 13.556L7.53099 12.136L8.94432 13.556C9.11343 13.7251 9.3599 13.7911 9.5909 13.7292C9.82191 13.6673 10.0023 13.4869 10.0642 13.2559C10.1261 13.0249 10.0601 12.7784 9.89099 12.6093L8.47099 11.196L9.89099 9.78262C10.0601 9.61351 10.1261 9.36704 10.0642 9.13603C10.0023 8.90503 9.82191 8.7246 9.5909 8.6627C9.3599 8.6008 9.11343 8.66685 8.94432 8.83595L7.53099 10.256L6.11765 8.83595C5.85624 8.57454 5.4324 8.57454 5.17099 8.83595Z"
          fill="#EB5757"/>
</svg>

export const HourGlass = ({color = '#F2BD1D'}) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M21.0005 22H20.0005C20.012 20.0886 19.4847 18.2126 18.479 16.5872L15.5547 12L18.479 7.41285C19.4847 5.78737 20.012 3.9114 20.0005 2H21.0005C21.5528 2 22.0005 1.55228 22.0005 1C22.0005 0.447715 21.5528 0 21.0005 0H3.00049C2.4482 0 2.00049 0.447715 2.00049 1C2.00049 1.55228 2.4482 2 3.00049 2H4.00049C3.98897 3.9114 4.51628 5.78737 5.52197 7.41285L8.44629 12L5.52197 16.5872C4.51628 18.2126 3.98897 20.0886 4.00049 22H3.00049C2.4482 22 2.00049 22.4477 2.00049 23C2.00049 23.5523 2.4482 24 3.00049 24H21.0005C21.5528 24 22.0005 23.5523 22.0005 23C22.0005 22.4477 21.5528 22 21.0005 22ZM16.0005 6C16.0005 8.20923 12.0005 10 12.0005 10C12.0005 10 8.00049 8.20923 8.00049 6H16.0005ZM12.0005 18C9.23901 18 7.00049 19.7908 7.00049 22H17.0005C17.0005 19.7908 14.762 18 12.0005 18Z"
          fill={color}/>
</svg>
