import {batchesState} from './state';

export const batches = (state = batchesState, action) => {
    switch (action.type) {
        case "SET_BATCHES_LIST":
            return ({
                ...state,
                batchesList: action.data,
            });
        case "SET_CURRENT_BATCH":
            return ({
                ...state,
                currentBatch: action.data.data
            })
        default:
            return (state);
    }
}
