import React, {useEffect, useState} from "react";
import {Col, Form} from 'antd';
import CloneDeep from 'lodash/cloneDeep';
import "./index.scss";

const WEEKDAYS = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',];

const DayButton = ({id, label, selectionHandler, isActive}) => {

    const onSelect = () => {
        selectionHandler(id, !isActive);
    }

    return (
        <li onClick={onSelect} className={isActive ? 'active' : ''}>{label}</li>
    )
}

const DayOfWeeksOff = ({onChange, defaultActiveDays, showValidationError, getFieldDecorator, setFieldsValue}) => {
    const [activeDays, setActiveDays] = useState(defaultActiveDays);

    const selectionHandler = (value, isSelected) => {
        if (value) {
            const weekOffs = CloneDeep(activeDays) || [];
            if (isSelected) {
                weekOffs.push(value)
            } else {
                const indexOf = activeDays.indexOf(value);
                if (indexOf > -1) {
                    weekOffs.splice(indexOf, 1);
                }
            }
            setActiveDays(weekOffs);
            onChange('working_days', weekOffs);
        }
    }

    useEffect(() => {
        setFieldsValue({'working_days': activeDays})
    }, [activeDays])
    return (
        <Col span={20} style={{padding: '8px 8px 8px 0'}}>
            <Form.Item
                label="Days of week"
            >
                {getFieldDecorator('working_days', {
                    rules: [
                        {
                            required: true,
                            message: 'Please select at least one!',
                        },
                    ],
                    trigger: 'onClick',
                    validateTrigger: 'onClick',
                })

                (<ul className="weekday-list">
                    {
                        (
                            WEEKDAYS.map((day, index) => day ?
                                <DayButton isActive={!!(activeDays.find(val => val === (index)))} key={day}
                                           id={index}
                                           label={day}
                                           value={activeDays}
                                           selectionHandler={selectionHandler}/> : null)
                        )
                    }
                </ul>)}
            </Form.Item>
        </Col>
    );
}

export default DayOfWeeksOff;
