import React from "react";
import { Select } from 'antd';
import "./index.scss";

const { Option } = Select;

const Dropdown = ({ onChange, value, options = [], loading = false, defaultValue, placeholder = '', mode = '', showSearch = false, optionFilterProp = "value", ...props }) => {
    return (
        <div className={'root-dropdown'}>
            <Select
                showSearch={showSearch}
                mode={mode}
                disabled={loading}
                value={value}
                onChange={currentVal => {
                    onChange(currentVal)
                }}
                placeholder={placeholder}
                dropdownClassName={["custom-dropdown",props.dropdown_zoom_ID?"dropdown_zoom_ID":""].join(" ")}
                listHeight={150}
                virtual={false}
                defaultValue={defaultValue}
                optionFilterProp={optionFilterProp}
                filterOption={(input, option) => {
                    //FLV-173
                    //return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    return props.filter_not_exact?true:
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    //FLV-173
                }
                }
                filterSort={(optionA, optionB) =>
                    optionA.props.children.toLowerCase().localeCompare(optionB.props.children.toLowerCase())
                }
                {...props}
            >
                {options?.map(item => <Option value={item.value}>{item.label}</Option>)}
            </Select>
        </div>
    );
}

export default Dropdown;
