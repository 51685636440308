import { Activities } from "services/activities";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";

const handleError = err => {
  console.log("Error in Login action : ", err);
};

// Actions
export const getActivitiesRequest = () => async dispatch => {
  try {
    const result = await Activities.getActivitiesList();
    dispatch(mutation.setActivitiesList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getLockedActivityPacks = () => async dispatch => {
  try {
    const params = {
      status: 'locked',
      level: LocalStorage.fetch.defaultChild().level
    }
    const result = await Activities.getActivityPackList(params);
    dispatch(mutation.setLockedActivities(result));
    return result;
  } catch(err) {
    handleError(err);
  }
}
export const getUnLockedActivityPacks = () => async dispatch => {
  try {
    const params = {
      status: 'unlocked',
      level: LocalStorage.fetch.defaultChild().level
    }
    const result = await Activities.getActivityPackList(params)
    dispatch(mutation.setUnLockedActivities(result));
    return result;
  } catch(err) {
    handleError(err);
  }
}

export const getReadingChallengeRequest = () => async dispatch => {
  try {
    const result = await Activities.getReadingChallengeList();
    dispatch(mutation.setReadingList(result));
    return result;
  } catch(err) {
    handleError(err);
  }
}

export const getActivityDetailRequest = (id) => async dispatch => {
  try {
    const result = await Activities.getActivityDetail(id);
    dispatch(mutation.setActivityDetail(result));
    return result;
  } catch(err) {
    handleError(err);
  }
}

export const getActivityQuestionsRequest = (id) => async dispatch => {
  try {
    const result = await Activities.getActivityQuestions(id);
    dispatch(mutation.setActivityQuestionsList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const submitActivityQuizAnswerRequest = (payload) => async dispatch => {
  try {
    const result = await Activities.submitActivityQuizAnswer(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getActivityPackDetailsRequest = (payload) => async dispatch => {
  try {
    const result = await Activities.getActivityPackDetails(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const unlockActivityPackRequest = (payload) => async dispatch => {
  try {
    const result = await Activities.unlockActivityPack(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getReadingChallengeDetailsRequest = (payload) => async dispatch => {
  try {
    const result = await Activities.getReadingChallengeDetails(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getReadingChallengeQuestionsRequest = (payload) => async dispatch => {
  try {
    const result = await Activities.getReadingChallengeQuestions(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
}