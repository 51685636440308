const setCollectionsList = data => ({
    type: "SET_COLLECTIONS_LIST",
    data
  });

const setActiveCollections = data => ({
  type: "SET_ACTIVE_COLLECTIONS",
  data
});


export const mutation = {
  setCollectionsList,
  setActiveCollections
};
