import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MagicStoryActions from "../../store/MagicStory/actions";
import { getCollectionsListRequest as getCollectionsAction } from '../../store/collections/actions';
import CapAnimation from './capAnimation';
// import BubbleAnimation from './bubbleAnimation';
import BubbleAnimation2 from './bubbleAnimation2';
import './MagicStory.scss';
// import MagicStoryCard from 'components/magicStoryCard'

class MagicStory extends Component {
  state = {
    clicked: false,
    step: 1,
    collectionsList: []
  }

  async componentDidMount () {
    const { collections, getCollectionsAction } = this.props;
    // No collections in the store, the fetch them
    if (Array.isArray(collections.collectionsList) && collections.collectionsList.length === 0) {
      const collectionsList = await getCollectionsAction();
      this.setState({collectionsList})
    }
    // Already collections in array, use them
    if (Array.isArray(collections.collectionsList) && collections.collectionsList.length > 0) {
      this.setState({collectionsList: collections.collectionsList})
    }

  }
  handleStartMagic = () => {
    this.setState(prevState => ({clicked: !prevState.clicked}))
  }
  clickCollection = d => {
    const { collectionsList } = this.state;
    const newList = collectionsList.map(c => {
      if(c.id === d.id) {
        return {...c, selected: true}
      } else return c;
    })
    this.setState({collectionsList: newList})
  } 

  render() {
    // const {collections: {collectionsList}} = this.props;
    const { step, collectionsList } = this.state;
    return <div className="view-magic-story">
      <div className="top-section">
        <span className="find-text">
          FIND ME A STORY
        </span>
        <span className="find-sub-text">
          What type of story?
        </span>
      </div>
      <div className="middle-section">
      {step === 1 && collectionsList.length > 0 && <BubbleAnimation2
        list={collectionsList}
        clickBubble={(d) => this.clickCollection(d)}
      />}

      </div>
      <CapAnimation clicked={this.state.clicked} startMagic={this.handleStartMagic} />
      {/* <MagicStoryCard></MagicStoryCard> */}
    </div>;
  }
}
export default connect(
    ({ MagicStory, collections }) => ({ ...MagicStory, collections }),
    dispatch => bindActionCreators({ ...MagicStoryActions, getCollectionsAction }, dispatch)
  )( MagicStory );