/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "antd";

import * as activityActions from "store/activities/actions";
import Navbar from 'components/Navbar';
import SuccessModal from "components/SuccessModal";
import Storycard from "components/Storycard";
import ActivityModal from "components/activityModal";
import ActivityBanner from './ActivityBanner';
// import Loader from 'components/Loader';
import "./Activities.scss";
import SuccessBackground from "assets/image/success-background.svg";

import { Mixpanel } from '../../Mixpanel';

class Activities extends Component {
  state = {
    isModalOpen: false,
    activeActivityData: {},
    activeActivityQuestionsList: [],
    successModalOpened: false,
    successModalData: {},
    isActivityPack: false,
    activeActivityPackData: {},
    activeReadingChallengeData: {},
    activeReadingChallengeQuestionsList: [],
    isReadingChallenge: false
  }
  componentDidMount() {
    this.props.getActivitiesRequest();
    this.props.getLockedActivityPacks();
    this.props.getUnLockedActivityPacks();
    this.props.getReadingChallengeRequest();
  }

  handleOpenModal = async (id, isActivityPack = false, gradeId = "") => {
    let res
    let result
    if(isActivityPack) {
      this.state.isActivityPack = true
      let payload = {
        id: id,
        gradeId: gradeId
      }
      res = await this.props.getActivityPackDetailsRequest(payload)
      this.setState({ activeActivityPackData: res })
    } else {
      let mixPayload = {...this.props.activitiesList.find(a => a.id === id)}
      Mixpanel.track('Do - Activity Open Click', mixPayload)
      res = await this.props.getActivityDetailRequest(id);
      this.setState({ activeActivityData: res });
      result = await this.props.getActivityQuestionsRequest(id)
      this.setState({ activeActivityQuestionsList: result })
    }
    if(res && Object.keys(res).length > 0) {
      this.setState({ isModalOpen: true });
    } else {
      alert('No data found')
    }
    if(this.state.activeActivityPackData.status === 'locked') {
      let mixPayload = {...this.props.lockedActivitiesList.find(a => a.id === id)}
      Mixpanel.track('Do - Locked Activity Pack Open Click', mixPayload)
    } else if(this.state.activeActivityPackData.status === 'unlocked') {
      let mixPayload = {...this.props.unlockedActivitiesList.find(a => a.id === id)}
      Mixpanel.track('Do - Unlocked Activity Pack Open Click', mixPayload)
    }
  }

  closeModal(id, isActivityPack, gradeId) {
    this.setState({
      isModalOpen: false,
      activeActivityData: {},
      activeActivityQuestionsList: [],
      successModalOpened: false,
      successModalData: {},
      isActivityPack: false,
      activeActivityPackData: {},
      activeReadingChallengeData: {},
      activeReadingChallengeQuestionsList: [],
      isReadingChallenge: false
    });
    if(typeof id === 'string') {
      this.handleOpenModal(id, isActivityPack, gradeId)
    }
  }

  openSuccessModal = data => {
    this.setState({
      isModalOpen: false,
      successModalOpened: true,
      successModalData: data
    });
    this.props.getActivitiesRequest();
  };

  closeSuccessModal = () => {
    this.setState({ successModalData: {}, successModalOpened: false })
  }

  openReadingChallenge = async (id) => {
    let mixPayload = {...this.props.readingList[0]}
    Mixpanel.track('Reading Challenge - Take now Click', mixPayload);
    this.setState({ isReadingChallenge: true })
    let res = await this.props.getReadingChallengeDetailsRequest(id)
    this.setState({ activeReadingChallengeData: res })
    let result = await this.props.getReadingChallengeQuestionsRequest(id)
    this.setState({ activeReadingChallengeQuestionsList: result })
    if(Object.keys(res).length > 0) {
      this.setState({ isModalOpen: true });
    } else {
      alert('No data found')
    }
  }

  render() {
    const {
      activitiesList,
      lockedActivitiesList,
      unlockedActivitiesList,
      readingList
    } = this.props
    return [
      <div className="component-activities" key={0}>
        <Navbar />
        <div className="activities">
          <div className="activity-banner-container" onClick={() => this.openReadingChallenge(readingList[0].id)}>
            <ActivityBanner data={readingList[0] || {}}
              style={{marginBottom: '24px'}}/>
          </div>
          {activitiesList.length>0 && <div className="heading">INDIVIDUAL ACTIVITIES</div>}
          <div className="individual-activities">
            {activitiesList.map(a => (
              <div style={{marginBottom: '20px'}} 
                key={a.id}
                onClick={() => this.handleOpenModal(a.id)}>
                <Storycard data={a} type={1} />
              </div>))}
          </div>
          {unlockedActivitiesList.length > 0 && (
            <div className="heading">UNLOCKED ACTIVITY PACKS</div>
          )}
          <div className="individual-activities">
            {unlockedActivitiesList.map(a => (
              <div style={{marginBottom: '20px'}} 
                key={a.id}
                onClick={() => this.handleOpenModal(a.id, true, a.grade)}>
                <Storycard data={a} type={1} />
              </div>))}
          </div>
          {lockedActivitiesList.length > 0 && (
            <div className="heading">LOCKED ACTIVITY PACKS</div>
          )}
          <div className="individual-activities">
            {lockedActivitiesList.map(a => (
              <div style={{marginBottom: '20px'}} 
                key={a.id}
                onClick={() => this.handleOpenModal(a.id, true, a.grade)}>
                <Storycard data={a} type={1} />
              </div>))}
          </div>
        </div>
      </div>,
      <Modal title="Activity Summary"
      centered
      className="activity-modal"
      visible={this.state.isModalOpen}
      onCancel={() => this.closeModal()}
      footer={null}
      key={1}
      destroyOnClose>
        <ActivityModal
          data={this.state.isActivityPack ? this.state.activeActivityPackData : (this.state.isReadingChallenge ? this.state.activeReadingChallengeData : this.state.activeActivityData) }
          questions={this.state.isActivityPack ? [] : (this.state.isReadingChallenge ? this.state.activeReadingChallengeQuestionsList : this.state.activeActivityQuestionsList)}
          openSuccessModal={(data) => this.openSuccessModal(data)}
          close={ (id, isActivityPack, gradeId) => this.closeModal(id, isActivityPack, gradeId) }
          isActivityPack={this.state.isActivityPack}
          isReadingChallenge = { this.state.isReadingChallenge }
        />
      </Modal>,
      <Modal
        visible={this.state.successModalOpened}
        className="success-modal"
        footer={null}
        key={2}
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.96)",
          backgroundImage: `url(${SuccessBackground})`
        }}
        destroyOnClose
      >
        <SuccessModal
          data={this.state.successModalData}
          currentStoryData={ this.state.isReadingChallenge ? this.state.activeReadingChallengeData : this.state.activeActivityData }
          closeSuccess={ this.closeSuccessModal }
        />
      </Modal>
    ];
  }
}

export default connect(
  ({activities}) => ({...activities}),
  dispatch => bindActionCreators({ ...activityActions }, dispatch)
)(Activities);
