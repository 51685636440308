import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Input, Pagination, Table } from "antd";
import debounce from "lodash/debounce";
import has from "lodash/has";
import { storage as LocalStorage } from "services/config/storage";
import * as teacherActions from "store/teacher/actions";
import * as lmsActions from "store/lms/actions";
import AdminLayout from "layout/admin";
import {
  ClassTypeCell,
  ContactDetailsCell,
  DailyHoursCell,
  GradesCell,
  NameCell,
  ProfilePicCell,
  StatusCell,
} from "./rowCells";
import "./index.scss";
import ListingFilter from "../../../../components/Filter";
import { generateFilterUrl } from "../../../../utils/commonUtils";
import { panelList } from "./filters";

const MIN_CHARS_TO_SEARCH = 2;
const TYPING_TIMEOUT = 500;
const MAX_RESULTS_PER_PAGE = 15;

class TeacherListing extends Component {
  debounceSearch;

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        offset: 0,
        page_size: MAX_RESULTS_PER_PAGE,
      },
      total_count: MAX_RESULTS_PER_PAGE,
      numberOfPages: 1,
      activePageNumber: 1,
    };
    this.debounceSearch = debounce(this.applyFilters, TYPING_TIMEOUT);
  }

  async componentDidMount() {
    if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/");
    } else {
      await this.fetchTeachers();
    }
    this.props.getGradesListRequest();
    if (this.props?.location?.state?.filters !== undefined) {
      this.setState({
        filters: this.props?.location?.state?.filters,
      });
      this.setFilter(this.props?.location?.state?.filters);
    }
    // this.updateSavedFilters(this.props.location.state.filters);
  }
  // updateSavedFilters(filters) {
  //   return filters;
  // }

  fetchTeachers = async (payload) => {
    console.log("%cfetchTeachers>>", "color:green");
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...this.state.filters };
    let res = {};
    try {
      let urlParams = generateFilterUrl(filters);
      this.setState({ loading: true });
      res = await this.props.getTeachers(urlParams);
      console.log("res", res);
      if (res) {
        this.setState({
          total_count: res?.data?.total_count,
          numberOfPages: Math.ceil(
            res?.data?.total_count / this.state.filters.page_size
          ),
          loading: false,
        });
      } else this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  setFilter = (filters) => {
    console.log("setFilter>>>", filters);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.applyFilters()
    );
  };

  onSearch = (event) => {
    console.log(this);
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    filters.pattern = event.target.value;
    this.setState({ filters }, () => context.debounceSearch(filters));
  };
  handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        offset: page - 1,
      };
      this.applyFilters(filterUpdates);
    }
  };
  applyFilters = async (updates = {}) => {
    console.log("applyFilters>>>");
    let apifilters = {
      ...this.state.filters,
      ...updates,
    };
    this.fetchTeachers({ filters: apifilters });
  };

  renderHeader = () => (
    <div className="teacher-listing-header">
      <Input placeholder="Search for teachers" onChange={this.onSearch} />
      <ListingFilter
        isFilterDropdownVisible={this.state.isFilterDropdownVisible}
        panelList={panelList}
        setDropdownVisibility={(value) =>
          this.setState({ isFilterDropdownVisible: value })
        }
        gradesList={this.props.gradesList}
        onApply={this.setFilter}
        filters={this.state.filters}
      />
      <Button
        type="primary"
        className="ant-btn-md export-btn"
        onClick={() => this.props.history.push("/addTeacher")}
      >
        ADD
      </Button>
    </div>
  );

  getTheads = () => [
    {
      title: "",
      align: "right",
      width: 80,
      render: (rowData) => <ProfilePicCell data={rowData} />,
    },
    {
      title: "Name",
      render: (rowData) => <NameCell data={rowData} />,
    },
    {
      title: "CONTACT DETAILS",
      render: (rowData) => <ContactDetailsCell data={rowData} />,
    },
    {
      title: "Grades",
      render: (rowData) => <GradesCell data={rowData} />,
    },
    {
      title: "Classes type",
      align: "center",
      render: (rowData) => <ClassTypeCell data={rowData} />,
    },
    {
      title: "daily hours",
      align: "center",
      render: (rowData) => <DailyHoursCell data={rowData} />,
    },
    {
      title: "Status",
      align: "center",
      render: (rowData) => (
        <StatusCell
          data={rowData}
          updateTeacherStatus={this.props.updateTeacherStatus}
        />
      ),
    },
  ];

  getGradeName = (data) => {
    let gradeName = "";
    let gradeIdList = Object.keys(data.allowed_grade_levels);
    if (this.props.gradesList && this.props.gradesList.length > 0) {
      this.props.gradesList.forEach((grade) => {
        if (gradeIdList.includes(grade.id + "")) {
          gradeName = grade.name;
        }
      });
    }
    return gradeName;
  };

  render() {
    const { teachersData } = this.props;
    const {
      total_count,
      activePageNumber,
      filters: { page_size },
      numberOfPages,
    } = this.state;
    console.log("this.props", this.props);
    let data =
      teachersData && has(teachersData, "data.teachers")
        ? teachersData.data.teachers
        : [];
    data = data.map((teacher) => ({
      ...teacher,
      grade_value: this.props.gradesList
        ?.filter((grade) => {
          //FLV-145
          if (!teacher?.allowed_grade_levels && teacher?.allowed_grade_levels === null) return false;
          return (
            Object.keys(teacher?.allowed_grade_levels)?.filter(
              (key) => key == grade?.id
            )?.length > 0
          );
        })
        .map((grade) => grade.name),
    }));
    return [
      <div
        onClick={() => {
          if (this.state.isFilterDropdownVisible)
            this.setState({
              isFilterDropdownVisible: false,
            });
        }}
      >
        <AdminLayout title="Teachers" rightContent={this.renderHeader()}>
          <section className="teacher-listing-section">
            {this.props.gradesList?.length > 0 && (
              <Table
                columns={this.getTheads()}
                dataSource={data}
                loading={this.state.loading}
                pagination={false}
                onRow={(formData, rowIndex) => {
                  return {
                    onClick: (event) => {
                      console.log("this.state.filters", this.state.filters);

                      this.props.history.push({
                        pathname: "/updateTeacher",
                        search: `id=${formData.id}`,
                        state: {
                          formData,
                          id: formData.id,
                          filters: this.state.filters,
                        },
                      });
                    },
                  };
                }}
              />
            )}
            {numberOfPages > 1 ? (
              <Pagination
                total={total_count}
                hideOnSinglePage
                onChange={(page) => this.handlePageChange(page)}
                defaultPageSize={page_size}
              />
            ) : null}
          </section>
        </AdminLayout>
      </div>,
    ];
  }
}

export default connect(
  ({ teacher, lms }) => ({ ...teacher, ...lms }),
  (dispatch) =>
    bindActionCreators({ ...teacherActions, ...lmsActions }, dispatch)
)(TeacherListing);
