const setPreferenceGroups = data => ({
  type: "SET_PREFERENCE_GROUPS",
  data
});



export const mutation = {
  setPreferenceGroups,
};
