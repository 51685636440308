import React, { Component } from 'react'
import './MultiValueInput.scss'

class MultiValueInput extends Component {
  state = {
    text: '',
    list: []
  }

  componentDidMount() {
    this.setState({
      list: JSON.parse(JSON.stringify(this.props.linksList))
    })
  }

  addItemToTheList = () => {
    let arr = JSON.parse(JSON.stringify(this.state.list))
    arr.push(this.state.text)
    this.setState({
      list: arr,
      text: ''
    })
    this.props.onListChange(arr)
  }

  removeItemFromTheList = (listItem, index) => {
    let arr = JSON.parse(JSON.stringify(this.state.list))
    if (index > 0) {
      arr.splice(index - 1, 1)
    } else {
      arr.splice(0, 1)
    }
    this.setState({
      list: arr
    })
    this.props.onListChange(arr)
  }

  render() {
    const {
      text,
      list
    } = this.state

    const {
      linksList
    } = this.props

    return (
      <div className="component-multi-value-input">

        <div className="component-multi-value-input__top-container">
          <div className="component-multi-value-input__input-container">
            <input
              type="text"
              className="component-multi-value-input__input"
              placeholder="Eg. https://www.freadom.com/session1"
              value={text}
              onChange={(e) => this.setState({ text: e.target.value })}
            />
          </div>
          <div
            className="component-multi-value-input__add-icon"
            onClick={this.addItemToTheList}
          >
            +
          </div>
        </div>

        <div className="component-multi-value-input__bottom-container">
          {(linksList && linksList.length > 0)
            &&
            <div className="component-multi-value-input__list-container">
              {linksList.map((l, index) => {
                return (
                  <div
                    className="component-multi-value-input__list-container--list-item"
                    key={index}
                  >
                    <p className="link-text">
                      {/* {l.slice(0, 12) + ' ...'} */}
                      {l}
                    </p>
                    <div
                      className="close-icon"
                      onClick={() => this.removeItemFromTheList(l, index)}
                    >
                      X
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default MultiValueInput