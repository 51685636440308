import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { user } from "./user/reducer";
import { pricing } from "./pricing/reducer";
import { collections } from "./collections/reducer";
import { reports } from "./reports/reducer";
import { story } from "./story/reducer";
import { activities } from "./activities/reducer";
import { teacher } from "./teacher/reducer";
import { batches } from "./batches/reducer";
import { lms } from "./lms/reducer";
import { classes } from "./classes/reducer";
import { student } from "./student/reducer";
import { grades } from "./grades/reducer";
import { subscriptions } from "./subscriptions/reducer";
import { announcements } from "./announcements/reducer";
import { preferenceGroups } from "./preferenceGroups/reducer";

export const rootReducer = {
  user,
  pricing,
  collections,
  story,
  activities,
  teacher,
  lms,
  batches,
  classes,
  student,
  grades,
  announcements,
  subscriptions,
  preferenceGroups,
  reports,
};
const devTool =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : compose
    : compose;

const store = createStore(
  combineReducers(rootReducer),
  compose(applyMiddleware(thunkMiddleware), devTool)
);

export default store;
