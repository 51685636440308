import {Api} from './config/request';

export const getAdminStudentList = (params) => (Api.getRequest_with_Axios(`children/detailed/?${params}`));
// export const getAdminStudentList = (params) => (Api.getRequest(`children/detailed/?${params}`));
export const getFreadomStudentList = (params) => (Api.getRequest_with_Axios(`children/freadom?${params}`));
// export const getFreadomStudentList = (params) => (Api.getRequest(`children/freadom?${params}`));
export const addStudentToFlive = (payload) => (Api.postRequest('children/import', JSON.stringify(payload)));
export const updateStudentProfileRequest = (data, studentId) => Api.putRequest(`children/${studentId}`, data)
export const getStudentProfileRequest = (studentId) => Api.getRequest_with_Axios(`children/${studentId}`);
// export const getStudentProfileRequest = (studentId) => Api.getRequest(`children/${studentId}`);

export const StudentApiCalls = {
    getAdminStudentList,
    getFreadomStudentList,
    addStudentToFlive,
    updateStudentProfileRequest,
    getStudentProfileRequest
}
