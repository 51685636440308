import { AnnouncementApiCalls } from "../../services/announcements";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in Login action : ", err);
};

// Actions
export const getAnnouncements = (batchID) => async dispatch => {
  try {
    const result = await AnnouncementApiCalls.getAnnouncementsForBatch(batchID);
    dispatch(mutation.setAnnouncementList(result))
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const makeAnnoucement = (batchID, payload) => async dispatch => {
  try {
    let result = await AnnouncementApiCalls.addAnnoucementForBatch(batchID, payload);
    result = await AnnouncementApiCalls.getAnnouncementsForBatch(batchID);
    dispatch(mutation.setAnnouncementList(result))
    return result;
  }
  catch (err) {
    handleError(err);
  }
}