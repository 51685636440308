import React from 'react';
import "./index.scss";
import {BackpackIcon, TimeIcon} from 'assets/image/svg';
import DefaultUserIcon from 'assets/image/default-user.png';
import {returnWeekDay} from "../../../../../utils/commonUtils";

const AdminBatchCard = ({data = {}, ...props}) => {
    console.log('.da', data?.children?.slice(0, 3))
    const studentImages = data && data?.children && data?.children?.slice(0, 3).map(({image}, index) => (
        <img src={image ? image : DefaultUserIcon} className="card-profile-image"
             style={{marginLeft: (index == 0) ? "0px" : "-15px"}}/>
    )) || [];

    return (
        <div className='batch-details-card'>
            <div className="card-sidebar"/>
            <div className="card-content">
                <h2>Batch {data?.name}</h2>
                <div className="grades">
                    <div>{getGrade('grade', data)}</div>
                </div>
                <div className="card-footer" onClick={props.onViewAllChildren}>
                    <div className="student-images">
                        {studentImages}
                        {data?.children?.length > 3 && <div className="more">
                            {`+${data?.children?.length - 3}`}
                        </div>
                        }
                    </div>
                    <div className="arrow">
                        &gt;
                    </div>
                </div>
                <div className="classes-container">
                    <p className="title"><BackpackIcon height={16} style={{marginRight: "20px"}} color="#75717A"
                                                       width="15" height="15"/> <span
                        style={{marginLeft: "3px"}}> Total Classes</span></p>
                    <p className="value">{props.classCount}</p>
                </div>
                <div className="spacer"/>
                <div className="timings"><TimeIcon color="#75717A" width="15" height="15"/> Schedule</div>
                {
                    data?.preference_group?.timeslots.map((time, index) => (
                        <p style={{marginLeft: "29px", marginTop: (index == 0) ? "0px" : "10px"}}>
                                <span
                                    style={{
                                        marginRight: "10px",
                                        fontWeight: "500",
                                        fontSize: "15px"
                                    }}>{returnWeekDay(time.weekday)}</span>
                            <span style={{color: "#7E7B83", fontSize: "13px", fontWeight: "500"}}>
                                    {`${returnTime(time.start_second)} - ${returnTime(time.start_second + time.duration)}`}
                                </span>
                        </p>
                    ))
                }
            </div>
        </div>
    )
};

function getGrade(key, data) {
    if (data) {
        return data?.grade?.name
    }
}


function returnTime(startSeconds) {
    try {
        let timezoneOffsetSeconds = -(new Date().getTimezoneOffset() * 60)
        let totalStartTimeInSeconds = timezoneOffsetSeconds + startSeconds
        let hourIn24 = Math.floor(totalStartTimeInSeconds / 3600);
        let hourIn12 = hourIn24 % 12 || 12;
        let mins = Math.floor(totalStartTimeInSeconds % 3600 / 60).toString().padStart(2, '0');
        return `${hourIn12}:${mins} ${(hourIn24 < 12 || hourIn24 === 24) ? 'AM' : 'PM'}`
    } catch (e) {
    }
}

export default AdminBatchCard;
