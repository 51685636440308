const setPricingPlansDetails = data => ({
  type: "SET_PRICING_PLAN",
  data
});

const setPromoDetails = data => ({
  type: "SET_PROMO_DETAILS",
  data
});

export const mutation = {
  setPricingPlansDetails,
  setPromoDetails
};
