import React from 'react';
import emptyAnnouncement from '../../assets/image/emptyAnnouncement.png';
import {Button} from 'antd';
import './AnnouncementsEmpty.scss';

const AnnouncementsEmpty = (props) => {
    return(
        <div className="empty-announcements">
            <img src={emptyAnnouncement} />
            <h1>Oh ohh! You have’t created any announcements yet</h1>
            <p>Click on the button to add a new announcement</p>
            <Button onClick={props.onAdd}>ADD</Button>
        </div>
    );
}

export default AnnouncementsEmpty;