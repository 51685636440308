import {Form, Formik} from 'formik';
import React, {Component} from 'react';
import './StudentForm.scss';
import BasicForm from './Forms/BasicForm';
import {Input, Select} from 'antd';
import SchoolForm from './Forms/SchoolForm';
import AddressForm from './Forms/AddressForm';
import ParentForm from './Forms/ParentForm';
import {toast} from "react-toastify";
import {updateStudentProfile} from "../utils";

const {Option} = Select;
const {TextArea} = Input;

class StudentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitFunc: null
        };
        this.formRef = React.createRef();
    }

    handleEditDetails = async (data) => {
        this.props.updateState({
            isLoading: true
        })
        let {
            details: {
                id, is_deleted, ...restDetails
            }
        } = data;
        let payload = {
            data: {
                details: {
                    ...restDetails
                },
                is_onboarded: data?.is_onboarded
            }
        }
        console.log('Payload', payload)
        let res = {}
        try {
            res = await updateStudentProfile(payload, data?.id)
            if (res) {
                toast.success('Profile updated successfully!')
                this.props.fetchStudentData(res)
                setTimeout(() => this.props.updateState({
                    isEditMode: false,
                    enableReinitialize:true
                }), 700)
            } else
                toast.error('Could not update the profile')

        } catch (e) {
            toast.error('Could not update the profile')
        } finally {
            this.props.updateState({
                isLoading: false
            })
        }
    }

    render() {
        return (
            <div className="student-form">
                <Formik
                    innerRef={this.formRef}
                    enableReinitialize={this.props.enableReinitialize}
                    initialValues={this.props.initialValues}
                    onSubmit={this.handleEditDetails}
                    validationSchema={this.props.validationSchema}
                >
                    {({values, submitForm, setFieldValue}) => (
                        <Form>
                            <BasicForm
                                values={values}
                            />
                            <br/>
                            <SchoolForm
                                values={values}
                            />
                            <br/>
                            <AddressForm
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                            <br/>
                            <ParentForm
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                            <br/>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default StudentForm;
