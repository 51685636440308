import CloneDeep from "lodash/cloneDeep";

export const getGradeObjectBasedOnIds = (gradeLevels, selectedLevel) => {
    return gradeLevels.filter(item => selectedLevel.includes(item.id))
}

export const updateFormDataState = (key, value, state, setState, isBillingDetail = false) => {
    if (key) {
        let formData = state.formData || state
        formData = CloneDeep(formData);
        let tempKeyHighlights = state?.tempKeyHighlights || ['']
        if (isBillingDetail)
            formData.billing_details[key] = value ? +value : value
        else
            formData[key] = value;
        setState({formData, tempKeyHighlights, keyHighlightError: null});
        return formData
    }
}
