import {LmsApiCalls} from "../../services/lms";
import {mutation} from "./mutations";
import {mutation as GradeMutations} from "../grades/mutations";
import axios from "axios";

const handleError = err => {
    console.log("Error in Login action : ", err);
};

const getRequest = async (url) => {
    try {
        const res = await axios.get(url);
        return res.data.result ? res.data.result : res.data;
    } catch (err) {
        console.log(err)
    }
};

// Actions
export const addNewGradeRequest = (payload) => async dispatch => {
    try {
        const result = await LmsApiCalls.addNewGrade({data: payload});
        dispatch(mutation.addNewGrade(result));
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const getGradesListRequest = (params = '') => async dispatch => {
    try {
        const result = await LmsApiCalls.getGradesList(params);
        dispatch(mutation.getGradesList(result.data ? result.data : result));
        dispatch(GradeMutations.setGradeList(result.data));
        return result.data;
    } catch (err) {
        handleError(err);
    }
};

export const getFreadomGradesListRequest = () => async dispatch => {
    try {
        const result = await getRequest('https://dev-api.getfreadom.com/api/misc/v1/grade/');
        dispatch(mutation.getFreadomGradesList(result));
        return result;
    } catch (err) {
        handleError(err);
    }
}

export const updateGradeDataRequest = (id, payload) => async dispatch => {
    try {
        const result = await LmsApiCalls.updateGradeData(id, {data: payload});
        dispatch(mutation.updateGradeData(result));
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const getSessionsListRequest = (pageSize=5,offset=0,gradeId = '', level = '') => async dispatch => {
    try {
        const result = await LmsApiCalls.getSessionsList(
          pageSize,
          offset,
          gradeId,
          level
        );
        if (result) {
            dispatch(mutation.setSessionsList(result.data ? result.data : result))
            return result.data ? result.data : result
        }
    } catch (err) {
        handleError(err)
    }
}

export const updateSessionDataRequest = (id, payload) => async dispatch => {
    try {
        const result = await LmsApiCalls.updateSessionData(id, {data: payload});
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const getSessionDetailsRequest = (id) => async dispatch => {
    try {
        const response = await LmsApiCalls.getSessionDetails(id)
        if (response) {
            dispatch(mutation.setActiveSession(response.data ? response.data : response))
            return response.data ? response.data : response
        }
    } catch (err) {
        handleError(err)
    }
}

export const addNewSessionRequest = (payload) => async dispatch => {
    try {
        const response = await LmsApiCalls.addNewSession({data: payload})
        if (response) {
            return response.data ? response.data : response
        }
    } catch (err) {
        handleError(err)
    }
}
