import libraryIcon from "../../assets/image/library.png";
import moment from "moment";
import React from "react";
import './index.scss'

const NextClass = ({ nextClassToAttend, nextClassTime, ...props }) => {
    return <div className="next-class-card shadow-sm bg-white rounded-6 rounded gradient-color mobile-view">
        <div className="libraryIcon"><img src={libraryIcon} alt={'library icon'} /></div>
        <div className="circle-1" />
        <div className="circle-2" />
        <div className="circle-3" />
        <div className="circle-4" />
        <div className="p-4">
            <div>
                <div className="cardTitle-row next-text-row">
                    <div className=" text-white small next-class">
                        {
                            nextClassTime?.classStartDateTime ?
                                moment().isBetween(nextClassTime.classStartDateTime, nextClassTime.classEndDateTime) ? 'Now' : 'Next Class'
                                : 'Batches not yet started'
                        }
                    </div>
                </div>
                <section className="post-heading">
                    <div className="row">
                        <div className="col-md-11 text-left">
                            <div className="media">
                                <div className="cardTitle-row flex-mobile-view">
                                    <h4 className="cardTitle mb-0 text-white date-month">
                                        {getTimeComponents('date', nextClassTime.classStartDateTime, nextClassTime.classEndDateTime) + " " + getTimeComponents('month', nextClassTime.classStartDateTime, nextClassTime.classEndDateTime)}
                                    </h4>
                                    <div className="time-slot">
                                        {getTimeComponents('timeSlot', nextClassTime.classStartDateTime, nextClassTime.classEndDateTime)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="media-body pl-0">
                    <h4 className="mb-0 ml-0 text-left text-white">{nextClassToAttend ? nextClassToAttend.session?.name : null}</h4>
                    <h6 className="post-text text-white session-desc">{nextClassToAttend ? nextClassToAttend.session.description : null}</h6>
                </section>
            </div>
            {
                nextClassToAttend && (nextClassTime.join == false) &&
                <button onClick={() => window.open(nextClassToAttend?.class_link, "_blank")}
                    className="join-btn-hero"
                    disabled={!nextClassTime.join}
                    title="Button will be enabled before 30 mins of the schedule." alt="Button will be enabled before 30 mins of the schedule."
                    id='join-btn'
                >Join
            </button>
            }
            {
                nextClassToAttend && (nextClassTime.join == true) &&
                <button onClick={() => window.open(nextClassToAttend?.class_link, "_blank")}
                    className="join-btn-hero"
                    disabled={!nextClassTime.join}
                    id='join-btn'
                >Join
            </button>
            }
            {
                nextClassToAttend && nextClassToAttend.zoom_id &&
                <>
                    <h4 className="text-white" > Zoom ID </h4>
                    <p> {nextClassToAttend.zoom_id}</p>
                </>

            }

            {
                nextClassToAttend && nextClassToAttend.zoom_password &&
                <>
                    <h4 className="text-white" > Zoom Password </h4>
                    <p> {nextClassToAttend.zoom_password}</p>
                </>

            }

        </div>
    </div>
}


export function getTimeComponents(key, startTime, endTime) {
    if (startTime && endTime) {
        if (key === 'date')
            return moment(startTime).utc(true).format('D');
        if (key === 'month')
            return moment(startTime).utc(true).format('MMM');
        if (key === 'timeSlot') {
            let start = moment(startTime).utc(true).format('hh:mm A')
            let end = moment(endTime).utc(true).format('hh:mm A')
            return `${start} - ${end}`
        }
    }
    return [];
}

export default NextClass