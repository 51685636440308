const setBatchesList = (data) => ({
    type: "SET_BATCHES_LIST",
    data
});

const setCurrentBatch = (data) => ({
    type: "SET_CURRENT_BATCH",
    data
})


export const mutations = {
    setBatchesList,
    setCurrentBatch,
}
