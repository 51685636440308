import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Login from 'views/Login';
import Pricing from 'views/Pricing';
// import Dashboard from 'views/Dashboard';
import EuroLogin from 'views/EuroLogin';
import Collections from 'views/Collections';
import Activities from 'views/Activities';
import MagicStory from 'views/MagicStory';
import Temp from 'views/MagicStory/bubbleAnimation2.js';
import NotFound from 'components/NotFound';
import AddTeacher from 'views/Admin/Teacher/Create';
import TeacherListing from 'views/Admin/Teacher/Listing';
import AddSubscription from 'views/Admin/Subscription/Create';
import Subscriptions from 'views/Admin/Subscription/Listing';
import AdminLogin from 'views/Admin/Login';
import LmsListing from 'views/Admin/Lms/Listing'
import AddSession from 'views/Admin/Lms/Create/AddSession'
import StudentListing from 'views/Admin/Student/Listing';
import TeacherBatchesListing from 'views/Admin/TeacherBatches/Listing';
import BatchDetails from 'views/Admin/TeacherBatches/Details';
import ClassDetails from '../views/Admin/TeacherBatches/ClassDetails';
import AdminBatches from '../views/Admin/AdminBatches';
import TeacherAssignment from '../views/Admin/AdminBatches/TeacherAssignment';
import AdminBatchDetails from "../views/Admin/AdminBatches/Details";
import AddNewStudent from '../views/Admin/Student/AddNew/index';
import ChildrenAttending from '../views/Admin/AdminBatches/ChildrenAttending';
import PreferenceListing from "../views/Admin/Preferences/Listing";
import PreferenceDetail from '../views/Admin/Preferences/PreferenceDetail';
import CreatePreference from "../views/Admin/Preferences/Create";
import EditStudent from '../views/Admin/Student/EditStudent';

const RootRouter = props => (
    <Router>
        <Switch>
            <Route exact path="/" component={AdminLogin}/>
            <Route exact path="/teacher-login" component={AdminLogin}/>
            <Route exact path="/teacher-set-password" component={AdminLogin}/>
            <Route exact path="/update-password" component={AdminLogin}/>
            <Route exact path="/reset-password" component={AdminLogin}/>
            <Route exact path="/forgot-password" component={AdminLogin}/>
            <Route exact path="/subscriptions" component={Subscriptions}/>
            <Route exact path="/addSubscription" component={AddSubscription}/>
            <Route exact path="/updateSubscription" component={AddSubscription}/>
            <Route exact path="/addTeacher" component={AddTeacher}/>
            <Route exact path="/updateTeacher" component={AddTeacher}/>
            <Route exact path="/teacherListing" component={TeacherListing}/>
            {<Route exact path="/preference-listing" component={PreferenceListing}/>}
            {<Route exact path="/create-preference" component={CreatePreference}/>}
            {<Route exact path="/preference-detail/:preferenceId" component={PreferenceDetail}/>}
            <Route exact path="/batchesListing" component={TeacherBatchesListing}/>
            <Route exact path='/batchesListing/:batchId/childrenAttending' exact component={ChildrenAttending}/>
            <Route exact path="/batchesListing/:batchID/:classType" component={BatchDetails}/>
            <Route exact path="/batchesListing/:batchID/:classType/:classID" component={ClassDetails}/>
            <Route exact path="/lmsListing" component={LmsListing}/>
            <Route exact path="/students" component={StudentListing}/>
            <Route exact path="/students/add" component={AddNewStudent}/>
            {/*<Route exact path="/students/info/:id/:type" component={EditStudent} />*/}
            <Route exact path="/students/info/:id" component={EditStudent}/>
            {/*<Route exact path="/students/:id" component={StudentDetail} />*/}
            <Route exact path="/createSession" component={AddSession}/>
            <Route exact path="/updateSession/:id" component={AddSession}/>
            <Route path="/login" component={Login}/>
            <Route path="/pricing" component={Pricing}/>
            <Route path="/euro-login" component={EuroLogin}/>
            <Route path="/collection/:id" component={Collections}/>
            <Route path="/activities" component={Activities}/>
            <Route path="/magicStory" component={MagicStory}/>
            <Route path="/temp" component={Temp}/>
            <Route exact path='/adminBatches/assign-teacher-recommended/:batchID' component={TeacherAssignment}/>
            <Route exact path='/adminBatches/assign-teacher/:batchID' component={TeacherAssignment}/>
            <Route path='/adminBatches' exact component={AdminBatches}/>
            <Route path='/adminBatches/:batchId/childrenAttending' exact component={ChildrenAttending}/>
            <Route path='/adminBatches/:batchId/:classType' exact component={AdminBatchDetails}/>
            <Route component={NotFound}/>
        </Switch>
    </Router>
);

export default RootRouter;
