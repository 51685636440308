import React, { Component } from 'react';
import './index.scss';
import AdminLayout from 'layout/admin/index';
import BatchCard from 'components/BatchCard';
import AnnouncementCard from 'components/AnnouncementCard';
import ClassList from './ClassList/index';
import { Button, Tabs } from 'antd';
import { CalenderNext, CalenderPrev } from 'assets/image/svg.js';
import AnnouncementModal from 'components/AnnouncementModal';
import AttendanceModal from 'components/AttendanceModal';
import HomeworkModal from 'components/HomeworkModal';
import ChildrenModal from "../../../../components/ChildrenModal";
import ScheduleModal from '../../../../components/ScheduleModal';
import ViewAllAnnouncementModal from './ViewAnnouncementModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as batchesActions from '../../../../store/batches/actions';
import * as announcementActions from '../../../../store/announcements/actions';
import { generateFilterUrl } from "../../../../utils/commonUtils";
import isEmpty from "lodash/isEmpty";
import NextClass from "components/NextClass/NextClass.js"

const { TabPane } = Tabs;

class BatchDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            showAttendancePopup: false,
            showAnnouncementPopup: false,
            showHomeworkPopup: false,
            showChildrenListPopup: false,
            showViewAnnouncementPopup: false,
            showScheduleModal: false,
            typeOfClass: this.props.match.params.classType,
            previousClasses: [],
            upcomingClasses: [],
            filters: {
                previous: {
                    offset: 0
                },
                upcoming: {
                    offset: 0
                }
            }
        }
    }

    async onAnnoucementSubmit(data) {
        await this.props.makeAnnoucement(this.props.match.params.batchID, {
            data: {
                title: data.title,
                description: data.details,
                tags: [data.tag],
            }
        })
        this.setState({
            showAnnouncementPopup: false,
        })
    }

    onChangePopupState(type, sessionId, classID) {
        switch (type) {
            case "attendance":
                this.setState({
                    showAttendancePopup: !this.state.showAttendancePopup,
                    currentClassID: classID,
                });
                break;
            case "homework":
                this.setState({
                    showHomeworkPopup: !this.state.showHomeworkPopup,
                    currentSessionId: sessionId,
                    currentClassID: classID,
                });
                break;
            case "announcement":
                this.setState({
                    showAnnouncementPopup: !this.state.showAnnouncementPopup
                });
                (async () => {
                    await this.props.getAnnouncements(this.props.match.params.batchID);
                })()
                break;
            case "children-list":
                this.setState({
                    showChildrenListPopup: !this.state.showChildrenListPopup,
                });
                break;
            case "view-announcement":
                (async () => {
                    await this.props.getAnnouncements(this.props.match.params.batchID);
                })()
                this.setState({
                    showViewAnnouncementPopup: !this.state.showViewAnnouncementPopup
                })
                break;
            case "schedule":
                this.setState({
                    showScheduleModal: !this.state.showScheduleModal,
                })
                break;
            default:
                return;
        }
    }

    async componentDidMount() {
        this.getClasses()
        await this.props.getCurrentBatchDetails(this.props.match.params.batchID);
        await this.props.getAnnouncements(this.props.match.params.batchID);
    }

    getClasses = async (typeOfClass, fetchFresh) => {
        let filters = { ...this.state.filters }
        let resPrevious = {}, resUpcoming = {}, urlParams = '';
        this.setState({
            isLoading: true
        })
        switch (typeOfClass) {
            case 'previous':
                if (this.state.resPrevious?.has_next) {
                    urlParams = generateFilterUrl(filters.previous)
                    resPrevious = await this.props.getBatchClasses(this.props.match.params.batchID, "previous", urlParams);
                    this.isFetchingMoreData = false
                }
                break;
            case 'upcoming':
                if (this.state.resUpcoming?.has_next) {
                    urlParams = generateFilterUrl(filters.upcoming)
                    resUpcoming = await this.props.getBatchClasses(this.props.match.params.batchID, "upcoming", urlParams);
                    this.isFetchingMoreData = false
                }
                break;
            default:
                urlParams = generateFilterUrl(filters.previous)
                resPrevious = await this.props.getBatchClasses(this.props.match.params.batchID, 'previous', urlParams);
                urlParams = generateFilterUrl(filters.upcoming)
                resUpcoming = await this.props.getBatchClasses(this.props.match.params.batchID, 'upcoming', urlParams)
                this.isFetchingMoreData = false
                break;
        }
        this.setState({
            isLoading: false,
            batchDetails: this.props.currentBatch,
            resPrevious,
            resUpcoming,
            [`previousClasses`]: fetchFresh ? [...resPrevious?.class_sessions] : !isEmpty(resPrevious) ? [...this.state.previousClasses, ...resPrevious?.class_sessions] : [...this.state.previousClasses],
            [`upcomingClasses`]: fetchFresh ? [...resUpcoming?.class_sessions] : (!isEmpty(resUpcoming) && !fetchFresh) ? [...this.state.upcomingClasses, ...resUpcoming?.class_sessions] : [...this.state.upcomingClasses],
        })
    }

    handleTabChange = (typeOfClass = this.state.typeOfClass) => {
        if (typeOfClass === '1') {
            typeOfClass = 'upcoming'
        } else {
            typeOfClass = 'previous'
        }
        this.props.history.push(`/batchesListing/${this.props.match.params.batchID}/${typeOfClass}`)
        if (typeOfClass)
            this.setState({
                typeOfClass,
                filters: {
                    upcoming: {
                        offset: 0
                    },
                    previous: {
                        offset: 0
                    }

                }
            })
    }


    onScrollClasses = (event) => {
        if (
            window.innerHeight < window.innerWidth &&
            (
                (Math.ceil(event.target.scrollHeight - event.target.scrollTop) === (event.target.clientHeight))
                ||
                (Math.floor(event.target.scrollHeight - event.target.scrollTop) === (event.target.clientHeight))
            ) && !this.isFetchingMoreData

        ) {
            this.handlePageChange()
        }
    }

    handlePageChange = () => {
        this.isFetchingMoreData = true
        let { typeOfClass } = this.state;
        let nextPage = ''
        if (typeOfClass === 'previous')
            nextPage = this.state.resPrevious?.next_page
        else
            nextPage = this.state.resUpcoming?.next_page
        this.setState({
            filters: {
                ...this.state.filters,
                [typeOfClass]: {
                    offset: nextPage
                }
            }
        }, () => {
            this.getClasses(typeOfClass)
        })
    }

    render() {
        const titleUpcoming = (
            <p>
                <CalenderNext color="#999999" height={16} /> UPCOMING <span className="m-display-none">CLASSES</span>
            </p>
        )
        const titlePrevious = (
            <p>
                <CalenderPrev color="#999999" height={16} /> PREVIOUS <span className="m-display-none">CLASSES</span>
            </p>
        )
        let nextClassToAttend = this.state.upcomingClasses?.[0]

        let nextClassTime = {
          classStartDateTime: nextClassToAttend?.event?.start_timestamp,
          classEndDateTime: nextClassToAttend?.event?.end_timestamp
        }
        return (
            
            <div className='batch-details teacher-batch'>
                <AdminLayout
                    title=""
                    mobileNav={true}
                >
                    <BatchCard
                        onViewAllChildren={() => (this.onChangePopupState("children-list"))}
                        batchDetails={this.props.currentBatch}
                        numClasses={this.props.currentBatch?.session_count}
                        morphOnMobile={true}
                        onShowSchedule={() => (this.onChangePopupState("schedule"))}
                    />
                    <div className="header">
                        <Button className="back-btn"
                            onClick={() => (this.props.history.push("/batchesListing"))}>&lt; Back</Button>
                        <h1 className="m-display-none">{this.props?.currentBatch?.name || '-'}</h1>
                    </div>
                    <div className="container mobile-column" style={{ position: "relative" }}>
                        <div className='main m-width-100'>
                            <div className="card-container">
                                <Tabs
                                    type="card"
                                    defaultActiveKey={(this.props.match.params.classType == "previous") ? "2" : "1"}
                                    onChange={this.handleTabChange}
                                >
                                    <TabPane tab={titleUpcoming} key="1">
                                        <ClassList
                                            type="upcoming"
                                            classList={this.state.upcomingClasses}
                                            batchID={this.props.match.params.batchID}
                                            onScrollClasses={this.onScrollClasses}
                                            loadMore={this.state.isLoading}
                                            isMoreDataAvailable={this.state.resPrevious?.has_next}
                                            fetchMoreData={this.handlePageChange}
                                        />
                                    </TabPane>
                                    <TabPane tab={titlePrevious} key="2">
                                        <ClassList
                                            classList={this.state.previousClasses}
                                            type="previous"
                                            onChangeAttendancePopup={(sessionId, currentClassID) => {
                                                this.onChangePopupState("attendance", sessionId, currentClassID)
                                            }}
                                            onChangeHomeworkPopup={(sessionId, classID) => {
                                                this.onChangePopupState("homework", sessionId, classID)
                                            }}
                                            isMoreDataAvailable={true}
                                            batchID={this.props.match.params.batchID}
                                            onScrollClasses={this.onScrollClasses}
                                            loadMore={this.state.isLoading}
                                            fetchMoreData={this.handlePageChange}
                                        />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                        <div className='sidebar m-width-100'>
                        <NextClass nextClassTime={nextClassTime} nextClassToAttend={nextClassToAttend} />
                            <BatchCard
                                onViewAllChildren={() => (this.onChangePopupState("children-list"))}
                                batchDetails={this.props.currentBatch}
                                numClasses={this.props.currentBatch?.session_count}
                                noDisplayMobile={true}
                            />
                            <AnnouncementCard
                                onChangeAnnPopup={() => (this.onChangePopupState("announcement"))}
                                onViewAll={() => (this.onChangePopupState("view-announcement"))}
                                announcements={
                                    this.props.announcements ?
                                        (this.props.announcements.length > 2 ? this.props.announcements.slice(0, 2) : this.props.announcements)
                                        :
                                        []
                                }
                            />
                        </div>
                    </div>

                    <AttendanceModal
                        closeModal={async () => {
                            this.onChangePopupState("attendance")
                            this.getClasses('', true)
                        }}
                        visible={this.state.showAttendancePopup}
                        currentClassID={this.state.currentClassID}
                    />
                    <AnnouncementModal
                        closeModal={() => (this.onChangePopupState("announcement"))}
                        visible={this.state.showAnnouncementPopup}
                        onSubmit={(data) => this.onAnnoucementSubmit(data)}
                    />
                    <HomeworkModal
                        currentSessionId={this.state.currentSessionId}
                        currentClassID={this.state.currentClassID}
                        closeModal={async () => {
                            this.onChangePopupState("homework")
                            this.getClasses('', true)
                        }}
                        visible={this.state.showHomeworkPopup}
                    />
                    <ViewAllAnnouncementModal
                        closeModal={() => (this.onChangePopupState("view-announcement"))}
                        onChangeAnnPopup={() => (this.onChangePopupState("announcement"))}
                        visible={this.state.showViewAnnouncementPopup}
                        announcements={this.props.announcements}
                    />
                    <ScheduleModal
                        closeModal={() => (this.onChangePopupState("schedule"))}
                        visible={this.state.showScheduleModal}
                        announcements={this.props.announcements}
                        batchDetails={this.props.currentBatch}
                    />
                </AdminLayout>
                <ChildrenModal batchId={this.props.match.params.batchID} visible={this.state.showChildrenListPopup}
                    closeModal={() => (this.onChangePopupState("children-list"))} />
            </div>
        );
    }
}

export default connect(
    ({ batches, announcements }) => ({
        ...batches,
        ...announcements,
    }),
    dispatch => (bindActionCreators({ ...batchesActions, ...announcementActions }, dispatch))
)(BatchDetails);
