import React from "react";
import { Icon} from "antd";
import smallPlanLogo from "assets/image/smallPlan.png";
import mediumPlanLogo from "assets/image/mediumPlan.png";
import largePlanLogo from "assets/image/largePlan.png";

const planImg= {
  small: smallPlanLogo, 
  medium: mediumPlanLogo, 
  large: largePlanLogo
}


const PricingCard = props => {
  return (
        <div className="pricing-plan" key={props.plan.planId}>
        <h2 className="pricing-header">{props.plan.name}</h2>
        <img src={planImg[props.plan.type]} alt="" className="pricing-img"/>
        <h2 className="pricing-price">&#8377; <span> {props.plan.baseAmount}  </span></h2>        
        <h4 className="pricing-base-days"> for {props.plan.baseDays} days </h4>
        <ul className="pricing-features">
          {props.plan.description.map((feature, index)=> {
            return (<li key={index} className="pricing-features-item"><Icon type="check-circle" theme="filled" /> {feature}</li>)
          })}
        </ul>
        {(props.planSelected)? 
          <button className="pricing-button" onClick={()=>props.purchasePlan()}>
            <span >Pay now</span>
          </button>
        :
          <button className="pricing-button" onClick={()=>props.selectPlan(props.plan.planId)}>
            <span >Choose Plan <Icon type="caret-right" /></span>
          </button>
        }
      </div>
  );
};

export default PricingCard;
