import { mutation } from "./mutations";
import { ReportApiCalls } from "../../services/reports";

const handleError = (err) => {
  console.log("Error in Login action : ", err);
};

// Actions
export const getAllReports = (crmLsqId) => async (dispatch) => {
  try {
    const result = await ReportApiCalls.getAllReportsReq(crmLsqId);
    dispatch(mutation.setReportList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
