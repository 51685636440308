import React, {useEffect, useState} from "react";
import {Col, Form} from 'antd';
import {generateHoursList} from "utils/commonUtils";
import "./index.scss";
import Dropdown from "../../../../components/Dropdown";
import isEmpty from "lodash/isEmpty";
import {TrashCancel} from "../../../../assets/image/svg";
import {returnSlot} from "../../../../utils/commonUtils";

const WEEKDAYS = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const WorkTimings = ({startTime, onChange, error, timeslots = [], setFieldsValue, getFieldDecorator}) => {

    const generateEndTimeList = (current) => {
        const hoursList = generateHoursList()
        const endWorkingHoursList = [];
        if (hoursList && hoursList.length) {
            hoursList.forEach(hour => {
                if (hour.value > current) {
                    endWorkingHoursList.push(hour);
                }
            });
        }
        return endWorkingHoursList;
    }

    const [startTimeList, setStartTimeList] = useState(generateHoursList(8));
    const [endTimeList, setEndTimeList] = useState(generateEndTimeList(800));
    const [workSchedule, setWorkSchedule] = useState({})

    useEffect(() => {
        let obj = {};
        // if (timeslots.length === 0)
        //     return setWorkSchedule(obj)
        timeslots.map(item => {
            // debugger
            if (!workSchedule[item.weekday]) {
                // debugger
                obj[item.weekday] = !isEmpty(obj[item.weekday]) ? [...obj[item.weekday], returnSlot(item)] : [returnSlot(item)]
            } else {
                // debugger
                obj[item.weekday] = workSchedule[item.weekday]
            }
        })
        let transformedSlotsArr = [];
        let weekdays = Object.keys(obj);
        weekdays?.length > 0 && weekdays.map(key => {
            transformedSlotsArr.push(...obj[key])
        })
        setWorkSchedule(obj);
        onChange('timeslots', transformedSlotsArr);
        setFieldsValue({'timeslots': transformedSlotsArr});
    }, [timeslots?.length])
    console.log('workSchedule', workSchedule)

    const onTimeChange = (day, currentValue, typeOfTime, rowIndex) => {
        const endTimeList = generateEndTimeList(currentValue);
        let dayKeys = Object.keys(workSchedule);
        let dayIndex = dayKeys.indexOf(day)
        let obj = Object.values(workSchedule) || [];
        let scheduleArray = obj[dayIndex]
        let itemToUpdate = scheduleArray[rowIndex]
        if (typeOfTime === 'start') {
            itemToUpdate.startTime = currentValue;
            setEndTimeList(endTimeList);
        } else
            itemToUpdate.endTime = currentValue
        itemToUpdate.weekday = +day;
        itemToUpdate.isEdited = true;
        // setWorkSchedule(obj[day]);
        obj = obj.flat()
        setFieldsValue({'timeslots': obj})
        onChange('timeslots', obj)
    }

    const handleAction = (day, actionToPerform, itemIndex) => {
        let obj = {...workSchedule}
        console.log('row', itemIndex)
        if (actionToPerform === 'add') {
            obj[day] = obj[day].map((item => ({...item, isEdited: true})));
            obj[day].push({
                startTime: undefined,
                endTime: undefined,
                weekday: +day
            })
        } else {
            let scheduleArray = obj[day];
            scheduleArray.splice(itemIndex, 1);
            obj[day] = scheduleArray
            console.log(Object.values(obj))
            let timeslots = Object.values(obj)?.flat()
            setFieldsValue({'timeslots': timeslots})
            onChange('timeslots', timeslots)
        }
        console.log('obj is ', obj)
        setWorkSchedule(obj)
    }

    if (isEmpty(workSchedule))
        return null;

    return (
        <Col style={{padding: '8px 8px 8px 0'}} className={'work-timings'}>
            <Form.Item label={'Work Timings'}>
                {

                    Object.keys(workSchedule).map((dayIndex) => {
                        return getFieldDecorator(`timeslots`, {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select at least one!',
                                },
                            ],
                            // trigger: 'onClick',
                            // validateTrigger: 'onClick',
                        })
                        (<Col span={20}
                              style={{padding: '0 0 0 0', display: 'flex', alignItems: 'flex-start'}}>
                            <Col span={2} style={{padding: '33px 13px'}}>
                                <label className={'day-name'}>{WEEKDAYS[dayIndex]}</label>
                            </Col>
                            <Col span={20} style={{padding: '15px 0 0 0'}}>
                                {
                                    workSchedule[dayIndex]?.map((item, index) => {
                                        let rowIndex = index
                                        return <div key={rowIndex}
                                                    style={{display: "flex", alignItems: 'center', padding: '0'}}>
                                            <Col style={{padding: '0 8px 8px 0'}} span={8}>
                                                <Dropdown
                                                    value={item.startTime}
                                                    onChange={currentVal => onTimeChange(dayIndex, currentVal, 'start', rowIndex)}
                                                    placeholder={`Start Time`}
                                                    options={startTimeList || []}/>
                                            </Col>
                                            <div className={'custom-form-label no-uppercase'}
                                                 style={{padding: '0 13px 0 4px', fontSize: 16}}>To
                                            </div>
                                            <Col style={{padding: '0 8px 8px 0'}} span={8}>
                                                <Dropdown
                                                    disabled={!item.startTime}
                                                    value={item.endTime}
                                                    onChange={currentVal => onTimeChange(dayIndex, currentVal, 'end', rowIndex)}
                                                    placeholder="End Time"
                                                    options={endTimeList || []}/>
                                            </Col>
                                            <Col span={8} style={{display: 'flex', padding: '0 0 8px 0'}}>
                                                {
                                                    rowIndex !== 0 &&
                                                    <span
                                                        className={'button'}
                                                        onClick={(e) => {
                                                            handleAction(dayIndex, 'remove', rowIndex)
                                                        }}>
                                                    <TrashCancel/>
                                                    </span>
                                                }
                                                {rowIndex === workSchedule[dayIndex]?.length - 1 &&
                                                <span
                                                    className={'button'}
                                                    onClick={(e) => {
                                                        handleAction(dayIndex, 'add', rowIndex)
                                                    }}>
                                                    <p>Add Slot</p>
                                                </span>}
                                            </Col>
                                        </div>
                                    })
                                }
                            </Col>
                        </Col>)
                    })
                }
                {
                    error && <label className={'timeslots-error'}>{error}</label>
                }
            </Form.Item>
        </Col>
    );
}

export default WorkTimings;
