import { initialState } from "./state";

export const user = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_DETAIL": {
      return Object.assign({}, state, {
        userDetail: action.data,
      });
    }
    case "SET_USER_AUTH_TOKEN": {
      return {
        ...state,
        authToken: action.data
      }
    }
    case "SET_DEFAULT_CHILD": {
      return {
        ...state,
        defaultChildData: action.data
      }
    }
    case "SET_CHILD_LIST": {
      return {
        ...state,
        childList: action.data
      }
    }
    case "SET_USER_DATA": {
      return {
        ...state,
        userData: action.data
      }
    }
    default:
      return state;
  }
};
