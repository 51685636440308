import React, {useEffect} from 'react';
import "./LessonPlan.scss";

export const LessonPlan = ({previews, ...props}) => {
    useEffect(() => {
        if (props.details)
            document.getElementById("lessonPlanContent").innerHTML = props.details;

    }, [props.details])
    return (
        <div className="lesson-plan">
            <div className="content-section">
                <div className="title">DETAILS</div>
                <p id={'lessonPlanContent'} className="content"/>
            </div>
            <div className="seperator-20"/>
            <div className="content-section">
                <div className="title">FREADOM LINKS</div>
                <div className="link-cards">
                    <ul>
                        {props.links?.map(link => (
                            <li>
                                <a href={link} target="_blank">{link}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
