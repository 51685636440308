const addNewTeacher = data => ({
  type: "ADD_NEW_TEACHER",
  data
});

const getTeachers = data => ({
  type: "GET_TEACHERS",
  data
});

const getRecommendedTeachers = data => ({
  type: "GET_RECOMMENDED_TEACHERS",
  data
});

const updateTeacherData = data => ({
  type: "UPDATE_TEACHER_DATA",
  data
});

const updateTeacherStatus = data => ({
  type: "UPDATE_TEACHER_STATUS",
  data: data && data.data && data.data === 'success'
});

export const mutation = {
  addNewTeacher,
  getTeachers,
  getRecommendedTeachers,
  updateTeacherData,
  updateTeacherStatus
};
