export const initialState = {
  newGradeSubmitResponse: null,
  gradesList: null,
  isGradeStatusUpdated: false,
  isGradeDataUpdated: false,
  authToken: undefined,
  freadomGradesList: null,
  sessionsList: null,
  activeSession: null
};
