import React, {useEffect, useState} from "react";
import {Col, Form, Select} from 'antd';
import "./index.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as gradeActions from "../../../../store/grades/actions";
import isEmpty from "lodash/isEmpty";

const {Option} = Select
const GradeRow = ({onChange, activeGrades = [], setFieldsValue, getFieldDecorator, ...props}) => {
    const [gradeSelected, setGrade] = useState(activeGrades);


    useEffect(() => {
        setGrade(activeGrades)

        if (!isEmpty(activeGrades)) {
            let gradeIds = activeGrades?.map(item => (item?.id || item))
            setGrade(gradeIds)
            setFieldsValue({'grades': gradeIds})
        }

    }, [activeGrades])

    useEffect(() => {
        (async () => {
            let res = await props.getGradeListRequest()
        })()

    }, [])
    const onGradeSelection = selection => {
        onChange('grades', selection);
        setFieldsValue({'grades': selection});
        setGrade(selection);
    }
    return (
        <div className={'grade-selector'}>
            <Col span={10} className="gutter-space">
                <Form.Item
                    label="grade"
                    name="grades"
                >
                    {
                        getFieldDecorator('grades', {
                            rules: [{required: true, message: 'Please select grade'}],
                        })
                        (<Select
                                placeholder={'Select Grade'}
                                options={props.gradesList?.map(item => ({value: item.id, label: item.name})) || []}
                                selected={gradeSelected}
                                value={gradeSelected}
                                showArrow={true}
                                mode={'multiple'}
                                disableSearch={true}
                                showSearch={false}
                                onChange={onGradeSelection}
                                optionFilterProp={'label'}
                                dropdownClassName={'multi-select-dropdown'}
                                menuItemSelectedIcon={({...props}) => {
                                    return <div
                                        className={['dropdown-check-mark', gradeSelected.includes(props.value) ? 'active' : ''].join(' ')}
                                    />
                                }}
                            >
                                {
                                    props.gradesList?.map(item => <Option label={item.name}
                                                                          value={item.id}>{item.name}</Option>)
                                }
                            </Select>
                        )}
                </Form.Item>
            </Col>
        </div>
    );
}
export default connect(
    ({grades}) => ({...grades}),
    dispatch => bindActionCreators({...gradeActions}, dispatch))(GradeRow);
