import {Api} from "./config/request";

export const addNewGrade = payload => Api.postRequest('grades/', payload);

export const getGradesList = (params) => Api.getRequest_with_Axios(`grades/?page_size=50${params}`);

// export const getGradesList = (params) => Api.getRequest(`grades/?page_size=50${params}`);

export const updateGradeData = (id, payload) => Api.putRequest(`grades/${id}`, payload);

export const getSessionsList = (pageSize = 5, offset = 0, gradeId = '', level = '') => Api.getRequest_with_Axios(`sessions?page_size=${pageSize}&offset=${offset}${gradeId !== '' ? `&grade_id=${gradeId}` : ''}${level !== '' ? `&grade_level=${level}` : ''}`);

// export const getSessionsList = (pageSize = 5, offset = 0, gradeId = '', level = '') => Api.getRequest(`sessions?page_size=${pageSize}&offset=${offset}${gradeId !== '' ? `&grade_id=${gradeId}` : ''}${level !== '' ? `&grade_level=${level}` : ''}`);

export const updateSessionData = (id, payload) => Api.putRequest(`sessions/${id}`, payload);

export const getSessionDetails = (id) => Api.getRequest_with_Axios(`sessions/${id}`)

// export const getSessionDetails = (id) => Api.getRequest(`sessions/${id}`)

export const addNewSession = (payload) => Api.postRequest(`sessions/`, payload)

export const LmsApiCalls = {
    addNewGrade,
    getGradesList,
    updateGradeData,
    getSessionsList,
    updateSessionData,
    getSessionDetails,
    addNewSession
};
