import React, {Component, useState} from "react";

export const PlanLabel = ({data, format}) => (
    <div>
        <p className="listing-row-primary-text">
            {data[0]}
            <p style={{
                fontWeight: '400'
            }}>
                {data[1]}
            </p>
        </p>
    </div>
);

export const Grades = ({data}) => {
    const [showOtherGrades, setShowOtherGrades] = useState(false)
    return (
        <div className="listing-row-primary-text">{
            data?.length > 1 ?
                    <div className={'grade-cell'}>
                    <p>{data?.[0]?.name}</p>
                    <div onMouseEnter={() => setShowOtherGrades(true)}
                         onMouseLeave={() => setShowOtherGrades(false)} className={'grade-count'}>
                        +<p>{data?.length - 1}</p>
                        {
                            showOtherGrades && <div className={'other-grades-popup'}>
                                {/*<div className={'triangle'}/>*/}
                                {data?.slice(1)?.map(item => item.name).join(', ')}
                            </div>
                        }
                    </div>
                </div>

                :
                <p>{data?.[0]?.name}</p>
        }
        </div>
    );
}

export const TextCol = ({data}) => {
    return (
        <div>
            <p className="listing-row-primary-text">{data}</p>
        </div>
    )
};

export const NumberCell = ({data}) => (
    <div>
        <p className="listing-row-primary-text">{data || '-'}</p>
    </div>
);

export class StatusCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: props.data.is_active
        };
    };

    toggleStatus = (isActive) => {
        this.setState({
            isActive: isActive !== undefined ? isActive : !this.state.isActive
        });
    }

    onStatusUpdate = (e) => {
        e.stopPropagation();
        this.props.updateTeacherStatus(this.props.data.id, !this.state.isActive);
        this.toggleStatus(!this.state.isActive);
    }

    render() {
        const className = this.state.isActive ? 'listing-row-status listing-row-status--active' : 'listing-row-status';
        return (
            <div onClick={this.onStatusUpdate}>
                <span className={className}></span>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.toggleStatus(this.props.data.is_active);
        }
    }
};
