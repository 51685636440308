import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as classesActions from "../../store/classes/actions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { HomeworkContent } from "./HomeworkContent";
import { CrossIcon } from "../../assets/image/svg";

const HomeworkModal = React.memo((props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [classDetails, setClassDetails] = useState({});

  useEffect(() => {
    if (props.visible) {
      (async () => {
        setIsLoading(true);
        let res = await props.getClassDetailsRequest(props.currentSessionId);
        console.log("respisne", res);
        if (res) {
          setClassDetails(res.data);
          setIsLoading(false);
        }
      })();
    }
    return () => {
      setClassDetails({});
    };
  }, [props.visible]);

  const onSubmitHomework = async () => {
    setIsLoading(true);
    let res;
    try {
      let data = {
        is_homework_shared: true,
      };
      res = await props.sendHomeworkRequest(props.currentClassID, { data });
    } catch (e) {
      setIsLoading(false);
      toast.error("Could not send homework!");
    }
    if (res) {
      toast.success("Homework shared successfully!");
      setIsLoading(false);
      props.closeModal();
    }
  };
  console.log("HOMEWORK PROPS", props);
  return (
    <Modal
      wrapClassName="homework-modal mob-modal-type-2"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title="Homework"
      width={650}
      visible={props.visible}
      onOk={onSubmitHomework}
      onCancel={props.closeModal}
      confirmLoading={isLoading}
      okText="SEND HOMEWORK"
      closeIcon={
        <div className={"anticon"}>
          <CrossIcon width={18} height={18} color={"#524E59"} />
        </div>
      }
    >
      <HomeworkContent
        details={classDetails.homework}
        links={classDetails.homework_links}
        previews={classDetails.previews}
      />
    </Modal>
  );
});

export default connect(
  ({ classes }) => ({ classes: { ...classes } }),
  (dispatch) => bindActionCreators({ ...classesActions }, dispatch)
)(HomeworkModal);
