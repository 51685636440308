import React, {useEffect, useState} from "react";
import {Button, Spin} from "antd";
import "./index.scss";
import {CheckIcon} from "assets/image/svg";
import {withRouter} from "react-router-dom";
import moment from "moment";

const CLASS_JOIN_BUFFER_SECONDS = 1800;
const ClassList = ({onScrollClasses, loadMore, isMoreDataAvailable, fetchMoreData, ...props}) => {
    const [showAllClasses, setShowAllClasses] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(loadMore)

    useEffect(() => {
        setIsLoadingMore(loadMore)
    }, [loadMore])

    const handlePageChange = (classInfo) => {
        if (props.type == "previous") {
            props.history.push(
                `/batchesListing/${props.batchID}/previous/${classInfo.session_id}`,
                {
                    classInfo,
                }
            );
        } else {
            props.history.push(
                `/batchesListing/${props.batchID}/upcoming/${classInfo.session_id}`,
                {
                    classInfo,
                }
            );
        }
    };

    const shouldJoinClass = (nextClassStartTime) => {
        const currentDate = moment().utc(true);
        let nextClassTime = moment(nextClassStartTime).utc(true);
        let currentTime = currentDate;
        //if next class time is under 30mins
        return (
            currentTime.valueOf() <= nextClassTime.valueOf() &&
            nextClassTime.diff(currentTime, "seconds") <= CLASS_JOIN_BUFFER_SECONDS
        );
    };

    const mapPropsToListData = (classes) => {
        if (classes == null) {
            return [];
        }
        return classes.map((c) => {
            return {
                date: moment(c.event.start_timestamp).utc(true).format("Do"),
                month: moment(c.event.start_timestamp).utc(true).format("MMM"),
                time: (
                    <React.Fragment>
                        {moment(c.event.start_timestamp).utc(true).format("hh:mm A") + " "}-
                        {" " + moment(c.event.end_timestamp).utc(true).format("hh:mm A")}
                    </React.Fragment>
                ),
                subject: c.session.name,
                description: c.session.description,
                attendanceComplete: c.is_attendance_marked,
                homeworkComplete: c.is_homework_shared,
                joinClass: shouldJoinClass(c.event.start_timestamp),
                sessionId: c.session_id,
                classInfo: c,
                classLink: c.class_link,
                id: c.id,
            };
        });
    };

    const listItems = mapPropsToListData(props.classList).map((info, index) => {
        const attendanceCheck =
            props.type == "previous" && info.attendanceComplete ? (
                <CheckIcon height={15} color="#219653"/>
            ) : null;
        const homeworkCheck =
            props.type == "previous" && info.homeworkComplete ? (
                <CheckIcon height={15} color="#219653"/>
            ) : null;
        return (
            <div
                key={index}
                className={
                    props.type == "previous" ? "list-item previous" : "list-item upcoming"
                }
            >
                <div className="item-container vertical">
                    <h2 className="date">
                        <span>{info.date + " " + info.month}</span>
                    </h2>
                    <div className="time">{info.time}</div>
                </div>
                <div
                    className="item-container vertical pointer"
                    onClick={() => handlePageChange(info.classInfo)}
                >
                    <h2>{info.subject}</h2>
                    <div>
                        <p>{info.description}</p>
                        {/* START: Mobile JOIN and view Details Buttons does not appear on desktop */
                            info.joinClass ?
                                <Button className="join-btn-mobile  display-desk-none"
                                        onClick={() => window.open(info.classLink, "_blank")}>
                                    JOIN
                                </Button>
                                :
                                ((index == 0) ?
                                        <Button className="view-details-mobile display-desk-none">
                                            VIEW DETAILS
                                        </Button>
                                        :
                                        null
                                )
                            /* END: Mobile JOIN and view Details Buttons */
                        }
                    </div>
                </div>
                <div
                    className={`${
                        info.attendanceComplete
                            ? "item-container completed"
                            : "item-container"
                    } m-display-none`}
                >
                    {props.type == "previous" ? (
                        <Button
                            onClick={() =>
                                props.onChangeAttendancePopup(info.sessionId, info.id)
                            }
                        >
                            {attendanceCheck}{" "}
                            {props.type == "previous" ? "ATTENDANCE" : "JOIN"}
                        </Button>
                    ) : info.joinClass ? (
                        <Button onClick={() => window.open(info.classLink, "_blank")}>
                            JOIN
                        </Button>
                    ) : null}
                </div>

                {index == 0 ? (
                    <div className="blue-circle">
                        <div className="inner"/>
                    </div>
                ) : null}
            </div>
        );
    });

    return (
        <div onScroll={onScrollClasses} className={`class-list ${showAllClasses ? "no-overflow" : ""}`}>
            {listItems}
            {isLoadingMore && <div className={'load-more'}>
                <Spin/>
                Loading...
            </div>}
            <div className="see-all-container">
                <Button
                    className="display-desk-none see-all-btn"
                    onClick={() => {
                        if (showAllClasses && isMoreDataAvailable) {
                            return fetchMoreData(props.type)
                        }
                        setShowAllClasses(!showAllClasses)
                    }}
                >
                    {showAllClasses ? isMoreDataAvailable ? "Show More" : "See Less" : "See All"}
                    {
                        isLoadingMore && <Spin/>
                    }
                </Button>
            </div>
            <div className="class-list-blue-bar display-desk-none"/>
        </div>
    );
};
export default withRouter(ClassList);
