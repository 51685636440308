import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Table,
  Input,
  Pagination,
  Select,
  DatePicker,
  TimePicker,
} from "antd";
import "antd/dist/antd.css";
import AdminLayout from "layout/admin/index";
import Pill from "../../../components/Pill";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storage as LocalStorage } from "services/config/storage";
import * as batchesActions from "../../../store/batches/actions";
import * as teacherActions from "store/teacher/actions";
import DefaultUserIcon from "assets/image/default-user.png";
import { PencilIcon } from "../../../assets/image/svg";
import ListingFilter from "../../../components/Filter/index";
import debounce from "lodash/debounce";
import { generateFilterUrl } from "../../../utils/commonUtils";
import { panelList } from "./filters";

import moment from "moment";
import { resetWarningCache } from "prop-types";
import { filter } from "lodash";

const TYPING_TIMEOUT = 500;
const MAX_RESULTS_PER_PAGE = 15;

const { Option } = Select;
class AdminBatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filters: {
        offset: 0,
        page_size: MAX_RESULTS_PER_PAGE,
        isBatchList: 2,
      },
      total_count: MAX_RESULTS_PER_PAGE,
      numberOfPages: 1,
      activePageNumber: 1,
      display: false,
      options: [],
      search: "",
      teachersid: 0,
      type: "Filter By",
      searchText: "",
      searchedColumn: "",
      current_page: 1,
    };
    this.debounceSearch = debounce(this.applyFilters, TYPING_TIMEOUT);
  }

  async componentDidMount() {
    this.fetchBatches("isBatchList=2");

    // let res = await this.props.getBatchesListRequest("&isBatchList=2");

    // if (res)
    //   this.setState({
    //     loading: false,
    //   });
    // this.setState({ loading: false });

    if (this.props?.location?.state?.filters !== undefined) {
      this.setState({
        filters: this.props?.location?.state?.filters,
      });
      this.setFilter(this.props?.location?.state?.filters);
    }
  }

  renderPill(status) {
    switch (status.toLowerCase()) {
      case "upcoming":
        return <Pill text={status.toUpperCase()} type={1} />;
      case "completed":
        return <Pill text={status.toUpperCase()} type={3} />;
      case "unassigned":
        return <Pill text={status.toUpperCase()} type={4} />;
      default:
        return <Pill text={status.toUpperCase()} type={1} />;
    }
  }

  fetchBatches = async (payload) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...this.state.filters };
    let res = {};
    try {
      let urlParams = generateFilterUrl(filters);

      this.setState({ loading: true });
      res = await this.props.getBatchesListRequest(urlParams);

      if (res) {
        this.setState({
          total_count: res?.count,
          numberOfPages: Math.ceil(res?.count / this.state.filters.page_size),
          loading: false,
        });
      } else this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };
  handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        offset: page - 1,
      };
      this.setState({ current_page: page });
      this.applyFilters(filterUpdates);
    }
  };
  renderStudentImages(profileURLs) {
    return (
      <div className="student-images j-c-c">
        {profileURLs.slice(0, 3).map((profilePhoto, index) => (
          <img
            key={index}
            src={profilePhoto}
            className="card-profile-image"
            style={{ marginLeft: index == 0 ? "0px" : "-15px" }}
          />
        ))}
        {profileURLs.length > 3 ? (
          <div className="more">+{profileURLs.length - 3}</div>
        ) : null}
      </div>
    );
  }

  renderGrades(name) {
    return (
      <div className="d-flex grades-container j-c-c">
        {
          <React.Fragment>
            <div>{name}</div>
          </React.Fragment>
        }
      </div>
    );
  }

  renderTeacherAssigned(batch) {
    return batch.teacher_id ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>{//FLV-144
        batch.teacher ? batch.teacher.user?.name+" ("+batch.teacher_id+")" : null}</div>

        {batch.status == "ONGOING" || batch.status == "ASSIGNED" ? (
          <Button
            onClick={() =>
              this.props.history.push({
                pathname: `/adminBatches/assign-teacher-recommended/${batch.batch_id}`,
                state: {
                  detail: "batchupdate",
                  filters: this.state.filters,
                },
              })
            }
          >
            <PencilIcon width={17} height={17} />
          </Button>
        ) : null}
      </div>
    ) : (
      <Button
        className="btn-outline-purple"
        onClick={() =>
          this.props.history.push(
            `/adminBatches/assign-teacher-recommended/${batch.batch_id}`
          )
        }
      >
        ASSIGN TEACHER
      </Button>
    );
  }

  renderBatchSchedule(schedules) {
    const weekDays = {
      1: "M",
      2: "T",
      3: "W",
      4: "Th",
      5: "F",
      6: "Sa",
      7: "Su",
    };
    return schedules.map((schedule) => weekDays[schedule.weekday]).join(" ");
  }

  batchesDataSource = () =>
    this.props.batches?.batchesList?.batches?.map((batch, index) => ({
      // ...batch,
      batchname: batch?.name,
      grade: this.renderGrades(batch.grade?.name),
      batchschedule: batch.preference_group
        ? this.renderBatchSchedule(batch.preference_group.timeslots)
        : "",
      students: this.renderStudentImages(
        batch.children.map((child) =>
          child.image ? child.image : DefaultUserIcon
        )
      ),
      status: this.renderPill(batch.status),
      teacherassigned: this.renderTeacherAssigned(batch),
      batch_id: batch.batch_id,
      name: batch?.name,
      key: parseInt(Math.random() * 10000),
    }));
  applyFilters = async (updates = {}) => {
    let apifilters = {
      ...this.state.filters,
      ...updates,
    };

    this.fetchBatches({ filters: apifilters });
  };

  setFilter = (filters) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.applyFilters()
    );
  };
  onCellClick = (record) => {
    this.props.history.push(`/adminBatches/${record.batch_id}/previous`, {
      userType: "admin",
      batchId: record.batch_id,
      batchName: record?.name,
      state: {
        updatedfilter: this.state.filters,
        type: this.state.type,
        search: this.state.search,
      },
    });
  };
  onSearch = (event) => {
    // console.log("event heree", event.target);
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    filters.name = event.target.id == "batches" ? event.target?.value : "";
    // filters.no_of_students =
    //   event.target.id == "studentnumber" ? event.target?.value : 0;
    // filters.teacher_id = 0;
    // filters.date = 0;
    this.setState({ filters }, () => context.debounceSearch(filters));
  };
  onSearchNumber = (event) => {
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    let delelteteacher = delete filters.teacher_id;
    let deletedate = delete filters.date;
    let deletestart_date = delete filters.start_date;
    filters.no_of_students = event.target.value;
    this.setState({ filters }, () => context.debounceSearch(filters));
  };
  onTeacherSearch = async (e) => {
    this.setState({ search: e.target.value });
    let res = await this.props.getTeachers(`pattern=${e.target.value}`);

    this.setState({ options: res.data.teachers });

    // console.log("res", res, this.state.teachersid);
  };
  renderDate = (date, string) => {
    // console.log(date, " ", string);
    // this.setState({ date: string });
    // let datetoday = new Date(`${string}`);
    // console.log(datetoday);
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    let delelteteacher = delete filters.teacher_id;
    let deleltenumber = delete filters.no_of_students;
    let deletestart_date = delete filters.start_date;
    let utcdate = Date.UTC(
      date?._d.getUTCFullYear(),
      date?._d.getUTCMonth(),
      date?._d.getUTCDate()
    );
    // let utcdate =
    //   (date._d.getTime() + date._d.getTimezoneOffset() * 60 * 1000) / 1000;
    // let newdate = date ? Math.round(new Date(utcdate).getTime() / 1000) : null;
    //console.log(utcdate);
    filters.date = date ? utcdate / 1000 : null;

    this.setState({ filters }, () => context.debounceSearch(filters));
  };
  //FLV-166
  render_Start_Date = (date, string) => {
    // console.log(date, " ", string);
    // this.setState({ date: string });
    // let datetoday = new Date(`${string}`);
    // console.log(datetoday);
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    let delelteteacher = delete filters.teacher_id;
    let deleltenumber = delete filters.no_of_students;
    let deletedate = delete filters.date;
    let utcdate = Date.UTC(
      date?._d.getUTCFullYear(),
      date?._d.getUTCMonth(),
      date?._d.getUTCDate()
    );
    // let utcdate =
    //   (date._d.getTime() + date._d.getTimezoneOffset() * 60 * 1000) / 1000;
    // let newdate = date ? Math.round(new Date(utcdate).getTime() / 1000) : null;
    //console.log(utcdate);
    filters.start_date = date ? utcdate / 1000 : null;

    this.setState({ filters }, () => context.debounceSearch(filters));
  };
  //FLV-166
  // renderDate = (date) => {
  //   console.log("event trigger", date, date._d, date._d.valueOf(), date.unix());
  // };
  setInputSearch = async (value) => {
    let res = await this.props.getTeachers(`pattern=${value}`);
    this.setState({ teachersid: res.data.teachers[0].user.id });
    this.setState({ search: value });
    this.setState({ display: false });
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    let deleltenumber = delete filters.no_of_students;
    let deletedate = delete filters.date;
    let deletestart_date = delete filters.start_date;
    if (this.state.search == "") {
      delete filters.teacher_id;
    } else {
      filters.teacher_id = this.state.teachersid;
    }

    this.setState({ filters }, () => context.debounceSearch(filters));
  };
  resetall = () => {
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    let deleltenumber = delete filters.no_of_students;
    let deletedate = delete filters.date;
    let deletestart_date = delete filters.start_date;
    let delelteteacher = delete filters.teacher_id;
    let deleltepattern = delete filters.pattern;
    this.setState({ filters, current_page: 1 }, () =>
      context.debounceSearch(filters)
    );
    this.setState({ search: "" ,
    //FLV-166
    type: "Filter By",
    //FLV-166
  });
  };
  renderHeader = () => (
    <div className="teacher-listing-header">
      <>
        <Input
          placeholder="Search for batches"
          id="batches"
          onChange={this.onSearch}
        />
        <Select
          className="selectfilter"
          value={this.state.type}
          onChange={(value) => this.setState({ type: value })}
        >
          <Option value="teacher">Teacher</Option>
          <Option value="number">Number of Students</Option>
          <Option value="date">Date</Option>
          {/* FLV-166 */}
          <Option value="start_date">Start Date</Option>
          {/* FLV-166 */}
          {/* <Option value="dateandtime">Date and Time</Option> */}
        </Select>
        {this.state.type == "number" ? (
          <Input
            placeholder="Search by numbers"
            className="studentinputs"
            id="studentnumber"
            onChange={this.onSearchNumber}
          />
        ) : null}
        {this.state.type == "teacher" && (
          <div className="Container">
            <Input
              placeholder="Search for teachers"
              className="teacherinputs"
              id="teachers"
              value={this.state.search}
              onChange={this.onTeacherSearch}
              onClick={() =>
                this.setState((prevState) => ({
                  display: !prevState.display,
                }))
              }
            />
            {this.state.display && (
              <div className="autoContainer">
                {this.state.options.slice(0, 10).map((v, i) => {
                  return (
                    <div
                      className="options"
                      onClick={() => this.setInputSearch(v.user.name)}
                    >
                      <span>{v.user.name}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {this.state.type == "date" ? (
          <DatePicker
            className="inputs"
            style={{ width: "290px" }}
            onChange={this.renderDate}
          ></DatePicker>
        ) : // <MyDTPicker renderDate={this.renderDate} />
        null}
        {//FLV-166
        this.state.type == "start_date" ? (
          <DatePicker
            className="inputs"
            style={{ width: "290px" }}
            onChange={this.render_Start_Date}
          ></DatePicker>
        ) : 
        null
        //FLV-166
        }
        {this.state.type == "dateandtime" ? (
          <DatePicker
            style={{ width: "290px" }}
            showTime
            onChange={this.renderDate}
            onOk={this.renderDate}
          />
        ) : null}
        {/* <ListingFilter
        isFilterDropdownVisible={this.state.isFilterDropdownVisible}
        panelList={panelList}
        setDropdownVisibility={(value) =>
          this.setState({ isFilterDropdownVisible: value })
        }
        gradesList={[]}
        onApply={this.setFilter}
      /> */}
        <Button
          style={{ height: "48px" }}
          type="primary"
          onClick={this.resetall}
        >
          Reset
        </Button>
      </>
    </div>
  );

  render() {
    const {
      total_count,
      activePageNumber,
      filters: { page_size },
      numberOfPages,
      current_page,
    } = this.state;
    const COLUMNS = columns.map((col) => {
      if (!col.clickable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => {
          return {
            record,
            editable: col.editable,
            dataindex: col.dataIndex,
            onClick: () => this.onCellClick(record),
          };
        },
      };
    });
    return [
      <div className="admin-batches">
        <AdminLayout title="Batches" rightContent={this.renderHeader()}>
          <section className="teacher-listing-section">
            <Table
              loading={this.state.loading}
              pagination={false}
              className="admin-batches-listing-table"
              dataSource={this.batchesDataSource()}
              columns={COLUMNS}
            />

            {numberOfPages > 1 ? (
              <Pagination
                total={total_count}
                current={current_page}
                hideOnSinglePage
                onChange={this.handlePageChange}
                defaultPageSize={page_size}
              />
            ) : null}
          </section>
        </AdminLayout>
      </div>,
    ];
  }
}

export default connect(
  ({ batches, teacher }) => ({ batches, teacher }),
  (dispatch) =>
    bindActionCreators({ ...batchesActions, ...teacherActions }, dispatch)
)(AdminBatches);

const columns = [
  {
    title: "SERIAL NUMBER",
    dataIndex: "serialnumber",
    key: "serialnumber",
    clickable: true,
  },
  {
    title: "BATCH NAME",
    dataIndex: "batchname",
    key: "batchname",
    clickable: true,
  },
  {
    title: "BATCH SCHEDULE",
    dataIndex: "batchschedule",
    key: "batchschedule",
    clickable: true,
  },
  {
    title: "GRADE",
    dataIndex: "grade",
    key: "grade",
    clickable: true,
  },
  {
    title: "STUDENTS",
    dataIndex: "students",
    key: "students",
    clickable: true,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    clickable: true,
  },
  {
    title: "TEACHER ASSIGNED",
    dataIndex: "teacherassigned",
    key: "teacherassigned",
  },
];
