import React from 'react';
import "./index.scss";
import {BackpackIcon, TimeIcon} from 'assets/image/svg';
import DefaultUserIcon from '../../assets/image/default-user.png';
import {convertSecondsToTime} from "../../utils/commonUtils";

function returnTime(startSeconds) {
    try {
        let timezoneOffsetSeconds = -(new Date().getTimezoneOffset() * 60)
        let totalStartTimeInSeconds = timezoneOffsetSeconds + startSeconds
        let hourIn24 = Math.floor(totalStartTimeInSeconds / 3600);
        let hourIn12 = hourIn24 % 12 || 12;
        let mins = Math.floor(totalStartTimeInSeconds % 3600 / 60).toString().padStart(2, '0');
        return `${hourIn12}:${mins} ${(hourIn24 < 12 || hourIn24 === 24) ? 'AM' : 'PM'}`
    } catch (e) {
    }
}

const BatchCard = (props) => {

    const getScheduleFromPreferences = (preferences) => (
        preferences.map(preference => {
            let startTime = convertSecondsToTime(preference.start_second,true,true)
            let endTime = convertSecondsToTime(preference.start_second + preference.duration,true,true)
            return ({
                day: days[preference.weekday],
                time: `${startTime} - ${endTime}`
            })
        })
    );

    const mapData = (data) => {
        if (data == null) {
            return {};
        }
        return ({
            name: data.name,
            grade: data.grade.name,
            numClasses: 28,
            schedule: getScheduleFromPreferences(data.preference_group.timeslots),
            studentImages: data.children.map(child => (child.image ? child.image : DefaultUserIcon))
        })
    }

    const batchInfo = mapData(props.batchDetails)
    const studentImages = (batchInfo.studentImages) ?
        batchInfo.studentImages.slice(0, 3).map((profilePhoto, index) => (
            <img key={index} src={profilePhoto ? profilePhoto : DefaultUserIcon} alt="" className="card-profile-image"
                 style={{marginLeft: (index == 0) ? "0px" : "-15px"}}/>
        ))
        :
        null
    const more = (batchInfo.studentImages) ? batchInfo.studentImages.length : 0;

    return (
        <div
            className={`batch-details-card ${props.noDisplayMobile ? "m-display-none" : ""} ${props.morphOnMobile ? "batch-card-mobile mobile-page-header" : ""}`}>

            {/* Batch card content for desktop view */}
            <div className="card-sidebar"></div>
            <div className="card-content">
                <h2>{batchInfo?.name || '-'}</h2>
                <div className="grades">
                    <div>{batchInfo.grade}</div>
                </div>

                {/* Batch card content for mobile virw */}
                <div className="card-mobile-content">
                    <div onClick={props.onViewAllChildren}>
                        <h1>{studentImages?.length}</h1>
                        <h5>STUDENTS</h5>
                    </div>
                    <div onClick={props.onShowSchedule}>
                        <h1>{batchInfo?.schedule?.map(time => <span>{time.day}</span>)}</h1>
                        <h5>SCHEDULE</h5>
                    </div>
                    <div>
                        <h1>{props.numClasses}</h1>
                        <h5>CLASSES</h5>
                    </div>
                </div>
                <div className="card-footer" onClick={props.onViewAllChildren}>
                    <div className="student-images">
                        {studentImages}
                        {
                            ((more - 3) > 0) ?
                                <div className="more">
                                    +{more - 3}
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="arrow">
                        &gt;
                    </div>
                </div>
                <div className="classes-container">
                    <p className="title"><BackpackIcon height={16} style={{marginRight: "20px"}} color="#75717A"
                                                       width="15" height="15"/> <span
                        style={{marginLeft: "3px"}}>Total Classes</span></p>
                    <p className="value">{props.numClasses}</p>
                </div>
                <div className="spacer"></div>
                <div className="timings"><TimeIcon color="#75717A" width="15" height="15"/> Schedule</div>
                {
                    (batchInfo.schedule) ?
                        batchInfo.schedule.map((time, index) => (
                            <p key={index} style={{marginLeft: "22px", marginTop: (index == 0) ? "0px" : "10px"}}>
                                <span
                                    style={{marginRight: "10px", fontWeight: "500", fontSize: "15px"}}>{time.day}</span>
                                <span style={{color: "#7E7B83", fontSize: "13px", fontWeight: "500"}}>{time.time}</span>
                            </p>
                        ))
                        :
                        null
                }
            </div>
        </div>
    );
};

const days = ["", "M", "T", "W", "Th", "F", "Sa", "Su"]
export default BatchCard;
