import { Pricing } from "services/pricing";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in Login action : ", err);
};

// Actions
export const pricingPlans = () => async dispatch => {
  const type= ["small", "medium", "large"];
  try {
    const result = await Pricing.getPricingPlans();
    dispatch(mutation.setPricingPlansDetails(result));
    return result.map((plan, index)=> {
      plan.type= type[index];
      plan.description= plan.description.split("<:>");
      return plan;
    });
  } catch (err) {
    handleError(err);
  }
};

export const isValidPromo = (promoCode) => async dispatch => {
  try {
    var result = await Pricing.getPromoDetails({promocode: promoCode});
    if(result!= null) {
      result= {...result.result};
      result.days= result.days_value;
      result.discount= result.percentage_value;
      result.isValid= !result.is_used;
    }
    dispatch(mutation.setPromoDetails(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const paymentInitiate = (paymentObj) => async dispatch => {
  try {
    var result = await Pricing.initiatePayment(paymentObj);
    console.log(result);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const makePayment = (orderObj, planObj, childObj, parentObj, paymentStatus) => async dispatch => {
  try {
    // using razorpay sdk to initiate payment
    let options = {
      "key": "rzp_live_B9xTjhKgl2HczV",
      "order_id": orderObj.result.order_id,
      "amount": planObj.baseAmount*100,
      "name": childObj.name,
      "description": planObj.header,
      "image": "https://internals.freadom.in/img/freadomlogo.svg",
      "handler": async function (response){
        // change the number of dates if the user list updates.
        paymentStatus(response);
        return response;
      },
      "prefill": {
        "name": parentObj.name,
        "email": parentObj.email,
        "contact": parentObj.contact_no
      },
      "theme": {
        "color": "#552d83"
      }
    };
    
    let rzp = new window.Razorpay(options);
    rzp.open();

  } catch (err) {
    handleError(err);
  }
};
