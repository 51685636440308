// Libraries
import React, {Component} from 'react'
import {sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import QueryString from 'query-string'

// Redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as lmsActions from "store/lms/actions";

// Components
import AdminLayout from 'layout/admin';
import GradeCard from './GradeCard';
import GradeModal from 'components/GradeModal'

// Ant Design
import {Pagination, Select, Spin, Switch, Table} from 'antd';

// SCSS
import "./index.scss";

// Local Storage
import {storage as LocalStorage} from "services/config/storage";

// Icons & Images
import {AddIcon, ListReorderIcon, PencilIcon} from 'assets/image/svg'
import EmptyGradeThumbnail from '../../../../assets/image/empty-grades-thumbnail.png'

// Constants

const {Option} = Select;

const DragHandle = sortableHandle(() => (
    <ListReorderIcon style={{cursor: 'pointer'}}/>
));

const SortableItem = sortableElement(props => <tr {...props} />);

class Lms extends Component {
    state = {
        offset: 0,
        pageSize: 15,
        resultCount: 0,
        showModal: false,
        activeView: '',
        activeGrade: '',
        activeLevel: undefined,
        editGradeData: null,
        loading: true
    }

    sessionsTableColumns = [
        {
            title: 'SNO.',
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
            defaultSortOrder: 'ascend',
            render: text => {
                return (
                    <div className="sessions-table__number-col-container">
                        {/*<DragHandle style={{ cursor: 'pointer' }} />*/}
                        <p
                            className="sessions-table__text"
                        >{text}</p>
                    </div>
                )
            }
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
            render: text => <p className="sessions-table__text--bold">{text}</p>
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
            render: text => <p className="sessions-table__text">{text}</p>
        },
        {
            title: 'STATUS',
            key: 'status',
            render: (text, record) => {
                console.log('.', record.is_active)
                return (
                    <div className="sessions-table__status-col-container">
                        <Switch
                            defaultChecked={record.is_active}
                            checked={record.is_active}
                            onChange={async () => {
                                await this.props.updateSessionDataRequest(record.id, {
                                    ...record,
                                    is_active: !record.is_active
                                })
                                await this.props.getSessionsListRequest(
                                    this.state.pageSize,
                                    this.state.offset,
                                    this.state.activeGrade
                                )
                                this.props.getGradesListRequest()
                            }}
                            className={`sessions-table__status-col-container--ant-switch ${true ? 'white-switch' : ''}`}
                        />
                        {record?.is_active && <div
                            className="sessions-table__status-col-container--edit-icon pointer"
                            onClick={() => this.props.history.push(`/updateSession/${record.id}?grade=${record.grade}`)}
                        >
                            <PencilIcon/>
                        </div>}
                    </div>
                )
            }
        },
    ]

    onSortEnd = ({oldIndex, newIndex}) => {
        const {dataSource} = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            console.log('Sorted items: ', newData);
            this.setState({dataSource: newData});
        }
    };

    DraggableBodyRow = ({className, style, ...restProps}) => {
        const {dataSource} = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    componentDidMount() {
        const queryParams = this.props.location.search ? QueryString.parse(this.props.location.search) : ''
        let activeView = ''
        if (queryParams.currentView && queryParams.currentView === 'SESSIONS') {
            activeView = 'SESSIONS'
        } else {
            activeView = 'GRADES'
        }
        this.setState({
            activeView: activeView,
            loading: true
        })

        if (!LocalStorage.fetch.authToken()) {
            this.props.history.push("/");
        }
        this.props.getGradesListRequest();
        this.props.getFreadomGradesListRequest();
        if (queryParams.grade)
            this.setActiveGrade(queryParams.grade)
        else
            this.setDefaultGradeAndLevels()
    }

    setDefaultGradeAndLevels = () => {
        this.setState({
            loading: false,
            activeGrade: this.props.gradesList?.[0]?.id || '',
        }, async () => {
            await this.props.getSessionsListRequest(
                this.state.pageSize,
                this.state.offset,
                this.state.activeGrade
            );
        })
    }
    changeTab = (tab) => {
        this.setState({
            activeView: tab,
            offset: 0,
            activeGrade: this.props.gradesList?.[0]?.id || '',
        }, async () => {
            let res;
            if (tab == "SESSIONS") {
                res = await this.props.getSessionsListRequest(
                    this.state.pageSize,
                    this.state.offset,
                    this.state.activeGrade
                );
            } else {
                res = await this.props.getGradesListRequest();
            }
            if (res)
                this.setState({
                    loading: false
                })

        })
        if (tab === 'SESSIONS') {
            /*const res = this.props.getSessionsListRequest(this.state.pageSize, this.state.offset);
            this.setState({
              resultCount: res.total_count,
            });*/
        } else {
            this.props.history.push('/lmsListing')
        }
    }

    renderHeader = () => {
        return (
            <>
                {(this.props.gradesList && this.props.gradesList.length > 0)
                    ?
                    <div className="lms-header">
                        <div className="lms-header__view-switcher">
                            <p
                                className={`lms-header__view-switcher--switch-text ${this.state.activeView === 'SESSIONS' ? 'active' : ''}`}
                                onClick={() => this.changeTab('SESSIONS')}
                            >
                                Sessions
                            </p>
                            <p
                                className={`lms-header__view-switcher--switch-text ${this.state.activeView === 'GRADES' ? 'active' : ''}`}
                                onClick={() => this.changeTab('GRADES')}
                            >
                                Grades
                            </p>
                        </div>
                    </div>
                    :
                    <div></div>
                }
            </>
        )
    }

    setActiveLevel = (value) => {
        this.setState({activeLevel: value, offset: 0}, async () => {
            if (this.state.activeGrade !== "") {
                const res = await this.props.getSessionsListRequest(
                    this.state.pageSize,
                    this.state.offset,
                    this.state.activeGrade,
                    value
                );
            }
        });

    }

    setQueryParamsInUrl = (grade,) => {
        if (window?.history?.pushState) {
            let newUrl = new URL(window.location);
            newUrl.searchParams.set('grade', grade);
            window.history.pushState({}, '', newUrl);
        }
    }

    setActiveGrade(gradeId) {
        this.setState({
            loading: true,
            activeGrade: gradeId,
            offset: 0,
            activeLevel: undefined
        }, async () => {
            const res = await this.props.getSessionsListRequest(
                this.state.pageSize,
                this.state.offset,
                gradeId
            );
            if (res)
                this.setState({
                    loading: false
                })
        })
    }

    addSessionButtonClickHandler = () => {
        if (this.state.activeGrade !== '' && this.state.activeLevel !== '') {
            // let activeGradeName = this.props.gradesList.find(grade => grade.id === this.state.activeGrade).name
            this.props.history.push(`/createSession?grade=${this.state.activeGrade}&level=${this.state.activeLevel}`)
        } else {
            alert('Please select a Level to add new session.')
        }
    }

    scrollGradesListContainer = (direction) => {
        let gradeListDiv = document.querySelector('.sessions-listing-section__header-container--grades-listing')
        if (direction === 'FORWARD') {
            gradeListDiv.scrollLeft += 100
        } else if (direction === 'BACK') {
            gradeListDiv.scrollLeft -= 100
        }
    }

    render() {
        const {
            showModal,
            activeView,
            activeGrade,
            editGradeData,
        } = this.state
        console.log('activeLevel', activeGrade)
        const {
            // gradesList = [1, 2, 3, 4, 5, 6]
            gradesList,
            freadomGradesList,
            sessionsList
        } = this.props
        return (
            <>
                <AdminLayout
                    title={`${activeView === "SESSIONS" ? "LMS" : "Grades & Levels"}`}
                    rightContent={this.renderHeader()}
                >
                    {this.state.loading &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        opacity: 0.7,
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        width: '80%',
                        zIndex: 999,
                        background: "#fff"
                    }}>
                        <Spin/>
                    </div>}
                    <div className="view-lms">
                        {/* Grades View STARTS HERE */}
                        {activeView === "GRADES" && (
                            <>
                                {gradesList && gradesList.length > 0 ?
                                    (
                                        <>
                                            <section className="grades-listing-section">
                                                {gradesList &&
                                                gradesList.length > 0 &&
                                                gradesList.map((grade, index) => {
                                                    return (
                                                        <GradeCard
                                                            gradeData={grade}
                                                            key={grade.freadom_grade_id}
                                                            onGradeCardClick={(data) =>
                                                                this.setState({
                                                                    showModal: true,
                                                                    editGradeData: data,
                                                                })
                                                            }
                                                            onSwitchToggled={(data) =>
                                                                this.props.updateGradeDataRequest(grade.id, {
                                                                    is_active: data,
                                                                })
                                                                    .then(res => {
                                                                        this.props.getGradesListRequest()
                                                                        this.setState({showModal: false})
                                                                    })
                                                            }
                                                        />
                                                    );
                                                })}
                                            </section>
                                            <div
                                                className="grades-listing-section__add-grade-container"
                                                onClick={() =>
                                                    this.setState({
                                                        showModal: true,
                                                        editGradeData: null,
                                                    })
                                                }
                                            >
                                                <AddIcon/>
                                                <p className="grades-listing-section__add-grade-container--text">
                                                    ADD GRADES
                                                </p>
                                            </div>
                                        </>
                                    ) : this.state.loading ?
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            opacity: 0.7,
                                            height: '100%',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            zIndex: 999,
                                            background: "#fff"
                                        }}>
                                            <Spin/>
                                        </div> :
                                        (
                                            <div className="empty-grades-view">
                                                <img
                                                    src={EmptyGradeThumbnail}
                                                    alt="Empty Grades Thumbnail"
                                                    className="empty-grades-view__thumbnail"
                                                />
                                                <p className="empty-grades-view__text">
                                                    No grades added yet
                                                </p>
                                                <p className="empty-grades-view__sub-text">
                                                    Start adding grades and levels
                                                </p>
                                                <button
                                                    className="ant-btn empty-grades-view__button"
                                                    onClick={() =>
                                                        this.setState({
                                                            showModal: true,
                                                        })
                                                    }
                                                >
                                                    ADD GRADE
                                                </button>
                                            </div>
                                        )}
                            </>
                        )}
                        {/* Grades View ENDS HERE */}

                        {/* Sessions View STARTS HERE */}
                        {activeView === "SESSIONS" && (
                            <>
                                <div className="sessions-listing-section">
                                    <div className="sessions-listing-section__header-container">
                                        {/* Grades Listing */}
                                        <div
                                            className="sessions-listing-section__header-container--grades-listing-container">
                                            <p
                                                className="grades-listing-container__prev-button"
                                                onClick={() => this.scrollGradesListContainer("BACK")}
                                            >
                                                &#10094;
                                            </p>
                                            <div className="sessions-listing-section__header-container--grades-listing">
                                                {gradesList &&
                                                gradesList.length > 0 &&
                                                gradesList.map((grade, index) => {
                                                    return (
                                                        <div
                                                            className={`sessions-listing-section__header-container--grades-text ${
                                                                activeGrade == grade.id ? "active" : ""
                                                            }`}
                                                            onClick={() => {
                                                                this.setQueryParamsInUrl(grade.id)
                                                                this.setActiveGrade(grade.id)
                                                            }}
                                                            key={index}
                                                        >
                                                            {grade.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <p
                                                className="grades-listing-container__next-button"
                                                onClick={() =>
                                                    this.scrollGradesListContainer("FORWARD")
                                                }
                                            >
                                                &#10095;
                                            </p>
                                        </div>

                                        <div className="flex align-center">
                                            {/* Add Section Button */}
                                            <button
                                                className="ant-btn button-primary"
                                                onClick={() => this.addSessionButtonClickHandler()}
                                            >
                                                Add Session
                                            </button>
                                        </div>
                                    </div>
                                    <Table
                                        columns={this.sessionsTableColumns}
                                        dataSource={
                                            sessionsList && sessionsList.length > 0
                                                ? sessionsList
                                                : []
                                        }
                                        pagination={false}
                                        rowKey="index"
                                        // components={{
                                        //   body: {
                                        //     wrapper: DraggableContainer,
                                        //     row: this.DraggableBodyRow,
                                        //   },
                                        // }}
                                    />
                                    <Pagination
                                        defaultCurrent={1}
                                        current={this.state.offset + 1}
                                        total={this.props.sessionsCount}
                                        pageSize={this.state.pageSize}
                                        onChange={(page) => {
                                            this.setState({offset: page - 1}, async () => {
                                                await this.props.getSessionsListRequest(
                                                    this.state.pageSize,
                                                    this.state.offset,
                                                    this.state.activeGrade,
                                                    this.state.activeLevel
                                                );
                                            });
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {/* Sessions View ENDS HERE */}
                    </div>
                </AdminLayout>

                {/* Modals for Creating/Editing grades and sessions. */}
                {showModal && (
                    <GradeModal
                        isEdit={editGradeData === null ? false : true}
                        freadomGradesList={freadomGradesList}
                        editGradeData={editGradeData}
                        // selectedFreadomGrade={'Nursery'}
                        // freadomLevelsList={['Level 1', 'Level 2', 'Level 4', 'Level 7', 'Level 8']}
                        // selectedFreadomLevel={'Level 7'}
                        // customLevelsList={[]}
                        onCloseClick={() => this.setState({showModal: false})}
                    />
                )}
            </>
        );
    }
}

export default connect(
    ({lms}) => ({...lms}),
    dispatch => bindActionCreators({...lmsActions}, dispatch)
)(Lms);
