import React from "react";
import {Col, DatePicker as AntDatePicker, Form} from 'antd';
import moment from 'moment';
import "./index.scss";

const DatePicker = ({onChange, loading = false, getFieldDecorator, isRequired=true}) => {

    const getDisabledDates = (current, minDate, diff = 1) => {
        return current && minDate && current.diff(minDate, 'days') < diff;
    }

    const onSelection = (key, selectedDate) => {
        const dateTimeStamp = selectedDate && parseInt(moment(selectedDate)?.format("X"))
        onChange(key, dateTimeStamp)
    }
    return (
        <React.Fragment>
            <Col span={9}>
                <Form.Item
                    label="START DATE"
                >
                    {
                        getFieldDecorator('startDate', {
                            rules: [
                                {required: isRequired, message: 'Please select start date!'},
                            ],
                        })
                        (
                            <AntDatePicker
                                disabled={loading}
                                format={'DD/MM/YYYY'}
                                allowClear={false}
                                inputReadOnly={false}
                                showToday={false}
                                disabledDate={current => getDisabledDates(current, moment(), 0)}
                                // onChange={onSelection}
                                onChange={currentVal => onSelection('startDate', currentVal)}
                                rules={[{required: true, message: 'Please select date!'}]}
                            />)
                    }
                </Form.Item>
            </Col>
        </React.Fragment>
    );
}

export default DatePicker;
