import { Api } from "./config/request";

export const getBatchesList = (params) => Api.getRequest_with_Axios(`batches/?${params}`);
// export const getBatchesList = (params) => Api.getRequest(`batches/?${params}`);
export const assignTeacherToBatch = (payload) =>
  Api.postRequest("batches/assign-teacher", payload);
export const assignUpdatedTeacherToBatch = (payload) =>
  Api.postRequest("batches/update-teacher", payload);
export const getBatchDetails = (batchID) =>
  Api.getRequest(`batches/${batchID}`);
export const getBatchChildren = (batchID) => Api.getRequest_with_Axios(`batches/${batchID}/children`);
// export const getBatchChildren = (batchID) => Api.getRequest(`batches/${batchID}/children`);
export const startBatchRequest = (payload) =>
  Api.postRequest(`preference_groups/batch_convert/`, payload);
export const getClassesForBatch = (batchId, typeOfClass, params) => {
  let url = "";
  if (typeOfClass === "upcoming") url += `upcoming=True`;
  else url += `previous=True`;
  return Api.getListRequest(`classes?${url}&batch_id=${batchId}&${params}`);
};

export const deleteStudentFromBatch = (batch_id, child_id,reason='') =>
  Api.deleteRequest(`batches/${batch_id}/remove_child/${child_id}`,{    "data":{  reason  }});
export const addStudentToBatch = (batch_id, child_id,reason='') =>
  Api.postRequest(`batches/${batch_id}/add_child/${child_id}`,{    "data":{  reason  }});
export const batchesApiCalls = {
  getBatchesList,
  assignTeacherToBatch,
  assignUpdatedTeacherToBatch,
  getBatchDetails,
  getClassesForBatch,
  getBatchChildren,
  startBatchRequest,
  deleteStudentFromBatch,
  addStudentToBatch,
};
