import React, { useState } from "react";
import { Form, Input, Button } from 'antd';
import { connect } from "react-redux";
import LogoImg from 'assets/image/admin-logo.svg';
import "./index.scss";

const SetupPassword = ({ formSubmit, auth }) => {

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const onSubmit = () => {
    if (
      password && password.length &&
      confirmPassword && confirmPassword.length &&
      password === confirmPassword
    ) {
      formSubmit({ password }, 'eyJhbGciOiJSUzI1NiJ9.eyJvcHRpbXVzLmdldGZyZWFkb20uY29tL2lkIjogIjExIiwgIm9wdGltdXMuZ2V0ZnJlYWRvbS5jb20vdHlwZSI6ICJ1c2VyIiwgImlhdCI6IDE2MDE2MzEwNTksICJpc3MiOiAib3B0aW11cy5nZXRmcmVhZG9tLmNvbSIsICJleHAiOiAxNjAxNjMyODU5fQ.dUt87kvUHvnRfPXmqbE4ZHp5fCFk6-EhLFoa32KZhmOJLHoxw4IY0KP3OpW-hXI6-8yI1sj79gu74vQwSLSbZZcI1VhQCsP8RxDEHCHPNK5t3_nA5_cEtYT40UAPayE6rCrkDhRftqdpJyjDOBhsltWjBLqwk_sBb2-HH7XGLi2TiPRQwsj8OnLfC_8hzksE6qn6ubEhZQfOV5pJNcLcsTFU8djr7LRNUjcpvKaeekW0ySJs-O0TU4KxRN-fzvO4KK7i3gMCRZ7FMz8YbzXih2Z4R7i_UeoBYxRJuy4E1953J4Eu5qu0ia7BPUXJLr1cxwY1YNAUD39yLdMzpBq-59GxUoGDbbxR6jCIoTjIF2s5VKep12O8S8eiR0rzXs24YW1sIX71AxrGWIZnC4gGb8Liv-4pD64Xi2QgzKvKjXytVK_tdnMRdAFo9AlYCzYJXc2BeBAvu713puSBWhLB-R-gLPa8_fId3vd54UKpaOcFtjp6eoQEyOyso312yptN');
    }
  }

  const isSubmitEnabled = () => password && password.length && confirmPassword && confirmPassword.length;

  return [
    <div className="admin-login-left">
      <a href='/admin' className="admin-logo">
        <img src={LogoImg} alt="Freadom" />
      </a>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        className="custom-form admin-login-form"
      >
        <h1>Welcome!</h1>
        <p className="admin-login-desc">Please set up a password to access your account:</p>
        <Form.Item name="password">
          <Input type="password" placeholder="Password" value={password} onChange={currentVal => setPassword(currentVal.target.value)} />
        </Form.Item>
        <Form.Item name="password">
          <Input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={currentVal => setConfirmPassword(currentVal.target.value)} />
        </Form.Item>
        <Button disabled={!isSubmitEnabled()} onClick={onSubmit} type="primary" htmlType="submit">SETUP PASSWORD</Button>
      </Form>
    </div>
  ];
}


export default connect()(SetupPassword);