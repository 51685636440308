const setSubscriptionPack = data => ({
  type: "SET_SUBSCRIPTION_PACK",
  data
});

const setSubscriptionInfoForChild = data => ({
  type: "SET_SUBSCRIPTION_INFO_FOR_CHILD",
  data
})

export const mutation = {
  setSubscriptionPack,
  setSubscriptionInfoForChild,
};
