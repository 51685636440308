import React, {Component} from 'react';
import {Input, Modal} from 'antd';
import "./index.scss";
import {CrossIcon} from "../../assets/image/svg";
import ReactQuill from "react-quill";

const {TextArea} = Input;

class AnnouncementModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tag: "",
            details: "",
        }
    }

    onTextChange(event, fieldKey) {
        switch (fieldKey) {
            case "title":
                console.log("TExt changed", event.target.value);
                this.setState({
                    title: event.target.value
                })
                break;
            case "tag":
                this.setState({
                    tag: event.target.value
                })
                break;
            case "details":
                this.setState({
                    details: event
                })
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <Modal
                wrapClassName="make-announcement-modal mob-modal-type-1"
                maskStyle={{
                    background: "rgba(0,0,0,0.45)"
                }}
                title="Make Announcement"
                width={620}
                visible={this.props.visible}
                onOk={() => {
                    this.props.onSubmit({...this.state})
                    this.setState({
                        title: "",
                        tag: "",
                        details: "",
                    })
                }}
                onCancel={this.props.closeModal}
                okText="Submit"
                closeIcon={<div className={'anticon'}><CrossIcon width={18} height={18} color={'#524E59'}/></div>}
            >
                <p>ANNOUNCEMENT TITLE</p>
                <Input onChange={event => this.onTextChange(event, "title")} value={this.state.title}/>
                <p>TAG</p>
                <Input onChange={event => this.onTextChange(event, "tag")} value={this.state.tag}/>
                <p>DETAILS</p>
                <ReactQuill
                    name="editor"
                    value={this.state.details}
                    onChange={value => this.onTextChange(value, "details")}
                />
            </Modal>
        );
    }
}

export default AnnouncementModal;
