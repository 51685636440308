/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cn from 'classnames';
import * as collectionActions from "store/collections/actions";
import * as storyActions from "store/story/actions";
import Navbar from 'components/Navbar';
import Storycard from "components/Storycard";
import HomeIcon from 'assets/image/home-icon.svg';
import BookSummary from "components/bookSummary";
import SuccessBackground from "assets/image/success-background.svg";
import SuccessModal from "components/SuccessModal";
// import Loader from 'components/Loader';
import "./Collections.scss";

const inactiveGradient = 'linear-gradient(to bottom, #7c7c7c, #7c7c7c), linear-gradient(to bottom, rgba(83, 60, 107, 0.18), rgba(85, 60, 109, 0.73))';
const activeGradient = 'linear-gradient(to bottom, rgba(133, 216, 249, 0), #38a0e7)';

class Collections extends Component {
  state= {
    collectionsData: [],
    isModalOpen: false,
    activeStoryData: {},
    successModalData: {},
    successModalOpened: false
  }
  componentDidMount () {
    const { match: {params: { id }} } = this.props;
    this.getData(id);
    if (this.props.collectionsList.length === 0) {
      this.props.getCollectionsListRequest();
    }
  }
  componentDidUpdate (prevProps) {
    this.scrollToCollection();
    const { match: {params: { id: oldId }} } = prevProps
    const { match: {params: { id }} } = this.props;
    if (oldId !== id) {
      this.getData(id);
      // this.scrollToCollection();
    }
  }
  getData = async (id) => {
    const res = await this.props.getCollectionsById(id);
    // this.setState({collectionsData: res}, () => this.scrollToCollection() )
    this.setState({collectionsData: res})
  }

  handleHomeClick = () => {
    this.props.history.push('/');
  }
  handleCollectionClick = id => {
    this.props.history.push(`/collection/${id}`)
  }
  scrollToCollection = () => {
    // TODO
    // Remove queryselector and use refs
    if (document.querySelector('.active-collection')){
      const left = document.querySelector('.active-collection').offsetLeft;
      document.querySelector('.collection-img-container').scroll(
        left - ((window.screen.width > 1023) ? 200 : 60), 0
      );
    }
  }
  closeModal = () => {
    this.setState({ isModalOpen: false });
    this.setState({ activeStoryData: {} });
    this.setState({ activeStoryQuestion: {} });
  };
  handleOpenModal = async id => {
    this.setState({ isModalOpen: true });
    const res = await this.props.getStoryDetail(id);
    this.setState({ activeStoryData: res });
    if (res.has_mcq) {
      const questionData = await this.props.getMcqRequest(id);
      this.setState({ activeStoryQuestion: questionData });
    }
  };
  bookmark = id => {
    this.setState(prevState => ({
      collectionsData: prevState.collectionsData.map(c => {
        if (c.id === id) {
          return {
            ...c,
            is_liked: !c.is_liked
          }
        } return c
      })
    }))
  }
  render() {
    const { collectionsData } = this.state;
    const { collectionsList } = this.props;
    const { match: {params: { id }} } = this.props;
    return [
      <div className="component-collections" key={0}>
        <Navbar />
        <div className="collection-selector">
          <img src={HomeIcon} alt="home" onClick={this.handleHomeClick} />
          <div className="helper-text">BROWSE OUR COLLECTIONS</div>
          <div className="collection-img-container">
            {collectionsList.map(c => (
              <div key={c.id}>
                <img
                  className={cn("collection-type", {'active-collection': id === c.id})}
                  src={c.image}
                  alt="collection type"
                  onClick={() => this.handleCollectionClick(c.id)}
                />
                {/* <div
                  className={cn("collection-type", {'active-collection': id === c.id})}
                  style={{
                    backgroundImage: `${id === c.id ? activeGradient : inactiveGradient}, url(${c.image})`,
                    backgroundBlendMode : 'color',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                  }}
                  onClick={() => this.handleCollectionClick(c.id)}
                /> */}
              </div>
            ))}
          </div>
        </div>
        <div className="secondary-helper-text">
          STORIES IN{' '}
          {collectionsList.length > 0 && collectionsList.reduce((acc, c) => {return c.id===id ? c.name : acc}, '')}
        </div>
        <div className="collections-list-container">
          {collectionsData.map(c=> (
            <div
              style={{marginBottom: '24px'}}
              onClick={() => this.handleOpenModal(c.id)}
              key={c.id}
            >
              <Storycard key={c.id} data={c} bookmark={this.bookmark} />
            </div>
          ))}
        </div>
      </div>,
      <Modal
      title="Book Summary"
      centered
      // width="60vw"
      className="story-modal"
      // Add check when activeStory is set, then set the modal to open
      visible={this.state.isModalOpen && Boolean(this.state.activeStoryData.id)}
      onCancel={() => this.closeModal()}
      footer={null}
      key={1}
      destroyOnClose
    >
      <BookSummary
        data={this.state.activeStoryData}
        questions={this.state.activeStoryQuestion}
        openSuccessModal={this.openSuccessModal}
        close={this.closeModal}
      />
    </Modal>,
    <Modal
      visible={this.state.successModalOpened}
      className="success-modal"
      footer={null}
      key={2}
      maskStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        backgroundImage: `url(${SuccessBackground})`
      }}
      destroyOnClose
    >
      <SuccessModal
        data={this.state.successModalData}
        currentStoryData={this.state.activeStoryData}
        closeSuccess={() =>
          this.setState({ successModalData: {}, successModalOpened: false })
        }
      />
    </Modal>
    ];
  }
}

export default connect(
  ({collections}) => ({...collections}),
  dispatch => bindActionCreators({ ...collectionActions, ...storyActions }, dispatch)
)(Collections);
