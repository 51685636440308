import React, { Component } from 'react'
import AdminLayout from '../../../../layout/admin/index';
import { Button } from 'antd';
import AddChildrenToBatchModal from '../../../../components/AddChildrenToBatchModal';
import { ChildrenAttendingCards } from '../../../../components/ChildrenModal';
import { withRouter } from 'react-router';
import './ChildrenAttending.scss';

const ADD_MODAL_KEY = 'add-modal';

class ChildrenAttending extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddModal: false,
            refreshStudents: false,
        }
        this.myRef = React.createRef()
    }

    onModalVisibilityChange(key) {
        switch (key) {
            case ADD_MODAL_KEY:
                this.setState({
                    ...this.state,
                    showAddModal: !this.state.showAddModal,
                })
                break;
        }
    }

    renderRightContent() {
        return (
            <div>
                <Button
                    type="primary add-btn"
                    className="ant-btn-md export-btn"
                    onClick={() => (this.onModalVisibilityChange(ADD_MODAL_KEY))}
                >
                    Add students
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div className="children-attending-screen">
                <AdminLayout title="Students Attending" showBack={true}  rightContent={this.renderRightContent()}>
                    <ChildrenAttendingCards ref={this.myRef} innerRef={ref => this.myRef = ref} batchId={this.props?.match?.params?.batchId} visible={this.state.refreshStudents} />
                    {this.state.showAddModal && <AddChildrenToBatchModal onOk={() => {
                        this.setState({
                            refreshStudents: !this.state.refreshStudents,
                        })
                        this.onModalVisibilityChange(ADD_MODAL_KEY);

                    }} batchId={this.props?.match?.params?.batchId} visible={this.state.showAddModal} onClose={() => (this.onModalVisibilityChange(ADD_MODAL_KEY))} />}
                </AdminLayout>
            </div>
        );
    };
}

export default withRouter(ChildrenAttending);
