import React from "react";

export const HomeworkContent = React.memo(({details, links, previews}) => {
    console.log("links are", links);
    return (
        <div className="homework-content">
            <div className="content-section">
                <div className="title">DETAILS</div>
                <p id={'homeworkDetails'} className="content" dangerouslySetInnerHTML={{__html: details || ''}}/>
            </div>
            <div className="seperator-20"/>
            <div className="content-section">
                <div className="title">FREADOM LINKS</div>
                <div className="link-cards">
                    <ul>
                        {links?.map(link => (
                            <li>
                                <a href={link} target="_blank">{link}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
})
const FreadomLinks = React.memo(({homeWorkLinks, previews}) =>
    homeWorkLinks && homeWorkLinks.length > 0 && homeWorkLinks.map(link => (
        <div onClick={() => window.open(previews?.[link]?.url, '_blank ')} className="link-card">
            <img className="card-image" src={previews?.[link]?.image}/>
            <div className="card-footer">
                <h4>{previews?.[link]?.title}</h4>
                {/*<p className="author">By <span className="name">{link.author}</span></p>*/}
                {/*<div className="pills">*/}
                {/*    {*/}
                {/*        link.tags && link.tags.map(tag => (*/}
                {/*            <Pill text={`#${tag}`} type={1}/>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    )))
