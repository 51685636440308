import React from "react";
import "./ActivityBanner.scss";
import { Button } from "antd";

const ActivityBanner = props => {
  const { data } = props;
  const bannerBackground = data.background_image ? `url(${ data.background_image })` : 'linear-gradient(to right, rgb(62, 34, 114), rgba(32, 115, 182, 0.83),rgba(92, 189, 221, 0.33))'
  return (
    <div
      style={{
        background: bannerBackground,
        backgroundSize: "cover, cover",
        backgroundRepeat: "no-repeat, no-repeat",
        ...props.style
      }}
      className="component-activity-banner"
    >
      <div style={{ 
          backgroundColor: 'rgba(0, 0, 0, 0.3)' ,
          padding: '20px 40px'
        }}
        className="text-container">
        <div className="description">{data.description}</div>
        <div className="name">{data.name}</div>
        <Button type="primary"
              className="primary-btn banner-btn"
              size="large">
          Take the challenge
        </Button>
      </div>
    </div>
  );
};

export default ActivityBanner;
