import React, {useEffect} from 'react';
import "./LearningOutcome.scss";

export const LearningOutcomes = ({details = ''}) => {
    useEffect(() => {
        if (details)
            document.getElementById("learningOutcome").innerHTML = details;
    }, [details])
    return (
        <div className="learning-outcome-content">
            <div id={'learningOutcome'} className="content"/>
        </div>
    );
};
