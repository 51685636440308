import React, { Component } from "react";
import { storage as LocalStorage } from "services/config/storage";
import SideNavbar from "./sideNavbar";
import "./index.scss";
import { Button } from "antd";
import { withRouter } from "react-router-dom";
import LogoImg from "assets/image/admin-logo.svg";
import { MenuIcon } from "../../assets/image/svg";

export const Header = ({ title, children }) =>
  title && title.length ? (
    <header className="admin-header">
      <h1>{title}</h1>
      {children}
    </header>
  ) : null;

const maxRespWidth = 700;

const TEACHER_PANEL_ROUTE = "batchesListing";

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: false,
      screenSize: { width: 0, height: 0 },
      showHeader: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    if (!LocalStorage.fetch.authToken()) {
      let currentUrl = this.props.match.url;
      if (
        currentUrl?.split("/")?.some((route) => route === TEACHER_PANEL_ROUTE)
      )
        return this.props.history.push("/teacher-login");
      this.props.history.push("/");
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let showHeader = true;
    if (window.innerWidth <= 700) {
      showHeader = false;
    }
    this.setState({
      showHeader: showHeader,
      screenSize: { width: window.innerWidth, height: window.innerHeight },
    });
  }

  render() {
    const { children, title, rightContent, showBack, containerStyles, path1 } =
      this.props;
    return [
      <div
        className={`admin-container ${
          !this.state.showHeader && this.props.mobileNav ? "no-nav-header" : ""
        }`}
        style={containerStyles}
      >
        <SideNavbar visible={this.state.showNav} />
        <div className="admin-right-content">
          <div className={`mobile-nav`}>
            <Button
              className={`${this.state.showNav ? "on-top" : ""}`}
              onClick={() => {
                this.setState({ showNav: !this.state.showNav });
              }}
            >
              <MenuIcon />
            </Button>
            <img src={LogoImg} />
          </div>
          {showBack && (
            <>
              <Button
                className="back-btn"
                onClick={() => {
                  //FLV-183
                  if(typeof path1 !== 'undefined')
                  {
                    this.props.history.push({
                    pathname: `/${path1}`,
                    state: {
                      filters: this.props?.location?.state?.filters,
                      Teacher_search:this.props?.location?.state?.Teacher_search,
                      Class_Link_search:this.props?.location?.state?.Class_Link_search,
                    },
                  });
                }
                else
                  this.props.history.goBack();
                  //FLV-183
                }}
              >
                &lt; Back
              </Button>
              <div className="seperator-20" />
            </>
          )}
          <Header title={title}>{rightContent}</Header>
          {children}
        </div>
      </div>,
    ];
  }
}

export default withRouter(AdminLayout);
