import { Story } from "services/story";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in story action : ", err);
};

// Actions
export const getTopStoryListRequest = () => async dispatch => {
  try {
    const result = await Story.getTopStories();
    dispatch(mutation.setTopStoryList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getRecommendedStoryRequest = () => async dispatch => {
  try {
    const result = await Story.getRecommendedStories();
    if (result) {
      dispatch(mutation.setRecommendedStoryList(result));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getStoryDetail = id => async dispatch => {
  try {
    const result = await Story.getStoryDetail(id);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getMcqRequest = id => async dispatch => {
  try {
    const result = await Story.getMcq(id);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const submitAnswer = payload => async dispatch => {
  try {
    const result = await Story.submitAnswer(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const bookmarkStory = story => async dispatch => {
  try {
    const result = await Story.bookmarkStory(story.id);
    if (result.success) {
      dispatch(mutation.updateBookmarkedData(story))
    }
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const startStoryRequest = payload => async dispatch => {
  try {
    const result = await Story.startStory({story: payload.id});
    if (result.success) {
      payload.status = result.status
      dispatch(mutation.updateStoryStatus(payload))
    }
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const finishStoryRequest = storyId => async dispatch => {
  try {
    const result = await Story.finishStory(storyId);
    console.log("the result is", result);
    // if (result.success) {
    //   dispatch(mutation.updateStoryStatus(storyId))
    // }
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getOngoingStoriesRequest = () => async dispatch => {
  try {
    const result = await Story.getStoryByStatus('on_going');
    if (result) {
      dispatch(mutation.setOngoingStories(result));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const getLikedStoriesRequest = () => async dispatch => {
  try {
    const result = await Story.getLikedStories();
    if (result) {
      dispatch(mutation.setLikedStories(result));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
}

