import { initialState } from "./state";

export const reports = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REPORT_LIST": {
      return {
        ...state,
        list: action.data,
      };
    }
    default:
      return state;
  }
};
