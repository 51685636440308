var d = new Date().getTime();
// var t = new Date().getTime() / 1000;
// console.log(d, t);
export const panelList = [
  {
    label: "Date",
    value: "Date",
    list: [
      {
        value: d,
        label: "date",
        isActive: false,
      },
    ],
  },
  //   {
  //     label: "Time",
  //     value: "Time",
  //     list: [
  //       {
  //         value: t,
  //         label: "time",
  //         isActive: false,
  //       },
  //     ],
  //   },
];
