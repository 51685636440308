import React, { Component } from 'react';
import AdminLayout from 'layout/admin/index';
import { Button, Input, Spin, Tooltip } from 'antd';
import './index.scss';
import AddStudentCard from '../../../../components/AddStudentCard/index';
import AddStudentModal from './AddStudentModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as StudentActions from '../../../../store/student/actions';
import * as SubscriptionActions from '../../../../store/subscriptions/actions';
import * as GradeActions from '../../../../store/grades/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingOutlined } from "@ant-design/icons";
import { generateFilterUrl } from "../../../../utils/commonUtils";
import uniqBy from 'lodash/uniqBy'

const { Search } = Input;
const MAX_RESULTS_PER_PAGE = 15;
const spinner = <LoadingOutlined style={{ fontSize: 40 }} spin />;

class AddNewStudent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddPopup: false,
            studentList: [],
            selectedStudent: -1,
            searchText: "",
            searchParam: "",
            filters: {
                offset: 0,
            }
        }
    }

    handleModalClose(model_name, index) {
        switch (model_name) {
            case "add-student":
                this.setState({
                    showAddPopup: !this.state.showAddPopup,
                    selectedStudent: index,
                });
                break;
            default:
                break;
        }
    }

    async onAddStudent(data) {
        const selectedStudent = this.props.freadomStudentList.result[this.state.selectedStudent];
        data.child.name = selectedStudent.name
        data.child.image = selectedStudent.image
        data.child.external_id = selectedStudent.id
        data.parent = {
            name: selectedStudent.parent.name,
            email: selectedStudent.parent.email,
            phone_number: "+91" + selectedStudent.parent.contact_no,
            external_id: selectedStudent.parent.id
        }
        let { child, parent } = data;
        const stuInfo = await this.props.addStudentToFlive({ child, parent });
        if (!stuInfo) {
            toast.error("LSQ ID cannot be the same for two children");
            return;
        }
        let res = await this.props.addPackForChild({
            data: {
                subscription_id: stuInfo.subscription.id,
                billing_cycle_id: data.selectedPack.pack_billing_cycles[0].id
            }
        })
        if (!res) {
            toast.error("Could not add student");
            return;
        }
        toast.success("Student successfully added.");
        setTimeout(() => this.props.history.replace("/students"), 500)
    }

    async componentDidMount() {
        await this.props.getSubscriptionPackListRequest();
        await this.props.getGradeListRequest();
    }

    async componentWillUnmount() {
        await this.props.clearSearchData();
    }

    async onSearchStudent(page = 0) {
        if (!this.state.searchText) {
            return;
        }
        this.setState({ loading: true })
        let filters = this.handleFilters()
        filters.offset = page
        try {
            let urlParams = generateFilterUrl(filters)
            let data = await this.props.getFreadomStudentList(urlParams);
            // const students = document.getElementById("add-student-cards").scrollTop = 0;
            if (data?.data?.result.length > 0 || (!data?.data?.has_next && filters.offset === 0))
                this.setState({
                    filters,
                    studentList: filters.offset === 0 ? data?.data?.result : uniqBy([...this.state.studentList, ...data?.data?.result], 'id')
                })
            else if (data?.data?.has_next) {
                await this.onSearchStudent(data?.data?.next_page)
            }

        } catch (e) {
            this.setState({ loading: false })
        }
        this.setState({
            offset: 0,
            searchParam: this.state.searchText,
            loading: false
        })
    }

    handleFilters = () => {
        let filters = {
            ...this.state.filters,
        }
        delete filters.phone
        delete filters.q
        if (this.state.searchText !== '')
            if (isNaN(this.state.searchText)) {
                filters.q = this.state.searchText
                delete filters.phone
            } else {
                filters.phone = this.state.searchText
                delete filters.q
            }
        return filters
    }

    onSearchTextChange(event) {
        if (event.target.value === '')
            return this.setState({
                studentList: [],
                searchText: event.target.value
            })
        this.setState({
            searchText: event.target.value,
        }, async () => {
            if (this.state.searchText?.length >= 3) {
                const students = document.getElementById("add-student-cards").scrollTop = 0;
                await this.onSearchStudent()
            }
        })
    }

    convertData() {
        return this.state.studentList.map(student => ({
            image: student.image,
            name: student.name,
            parent: student.parent.name,
            school: student.school,
            phone: "+91" + student.parent.contact_no,
            email: student.parent.email,
            grade: student.grade.name
        }))
    }

    handlePageChange = async (page) => {
        if (page) {
            this.setState({
                loading: true, filters: {
                    ...this.state.filters,
                    offset: page - 1
                }
            })
            try {
                await this.onSearchStudent(page - 1)
                this.setState({ loading: false })
            } catch (e) {
                this.setState({ loading: false })
            }
        }
    }

    onScroll(event) {
        if (
            (
                (Math.ceil(event.target.scrollHeight - event.target.scrollTop) === (event.target.clientHeight))
                ||
                (Math.floor(event.target.scrollHeight - event.target.scrollTop) === (event.target.clientHeight))
            )
            && this.state.searchParam
        ) {
            if (this.props.freadomStudentList.has_next) {
                this.setState({
                    offset: this.state.offset + 1
                }, async () => (
                    await this.onSearchStudent(this.props.freadomStudentList.next_page)
                ))
            }
        }
    }

    render() {
        const studentData = this.convertData();
        const studentCards = studentData.map((student, index) => (
            <AddStudentCard
                index={index}
                studentInfo={student}
                openModal={() => (this.handleModalClose("add-student", index))}
                selected={this.state.selectedStudent == index ? true : false}
            />
        ))
        return (
            <div className="add-new-student">
                <AdminLayout title="">
                    <div className="header">
                        <div className="p-28">
                            <Button className="back-btn"
                                onClick={() => (this.props.history.replace("/students"))}>&lt; Back</Button>
                            <div className="seperator-15" />
                            <h1>Add New Student</h1>
                            <div className="seperator"></div>
                            <div className="search-student">
                                <p>SEARCH FREADOM</p>
                                <Tooltip
                                    trigger={['hover']}
                                    title={'Enter at least 3 characters/digits'}
                                    placement="right"
                                    overlayClassName="numeric-input"
                                >
                                    <Search
                                        placeholder="Enter name or phone number"
                                        style={{ width: 450 }}
                                        value={this.state.searchText}
                                        onChange={(event) => (this.onSearchTextChange(event))}
                                    />
                                </Tooltip>
                            </div>
                            <div className="results">
                                <p>{this.state.studentList?.length} <span>results found</span></p>

                            </div>
                        </div>
                        <div className="students">
                            <div onScroll={(event) => (this.onScroll(event))} className="add-student-cards"
                                id="add-student-cards">
                                {studentCards}
                            </div>
                            {this.state.loading && <div className={'load-more'}>
                                <Spin />
                                Loading...
                            </div>}
                        </div>

                        <AddStudentModal
                            grades={this.props.grades}
                            subscriptions={this.props.subscriptions}
                            visible={this.state.showAddPopup}
                            closeModal={() => (this.handleModalClose("add-student"))}
                            onSubmit={async (data) => await this.onAddStudent(data)}
                        />
                    </div>
                </AdminLayout>
            </div>
        )
    }
}

export default connect(
    ({ student, subscriptions, grades }) => ({ ...student, subscriptions: subscriptions, grades: grades }),
    dispatch => bindActionCreators({ ...StudentActions, ...SubscriptionActions, ...GradeActions }, dispatch)
)(AddNewStudent);
