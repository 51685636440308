const setAdminStudentList = data => ({
  type: "SET_ADMIN_STUDENT_LIST",
  data
});

const setFreadomStudentList = data => ({
  type: "SET_FREADOM_STUDENT_LIST",
  data
})

const clearSearchData = () => ({
  type:"CLEAR_SEARCH_DATA",
})
export const mutation = {
  setAdminStudentList,
  setFreadomStudentList,
  clearSearchData,
};
