import React, {Component} from 'react'

// Components
// SCSS
import './GradeCard.scss'

// Icons & Images
import {PencilIcon} from 'assets/image/svg'
import {Switch} from "antd";

class GradeCard extends Component {
    state = {
        isChecked: true
    }

    onSwitchChange = (checked) => {
        this.setState({isChecked: checked})
        this.props.onSwitchToggled(checked)
    }

    render() {
        const {
            isChecked
        } = this.state

        const {
            name = "Nursery",
            freadom_grade_level = "Level 1",
            freadom_grade_name = 'Nursery',
            image = null,
            is_active
        } = this.props.gradeData
        console.log('>>>    ', this.props.gradeData)
        return (
            <div
                className="grade-card__container"
            >
                <Switch
                    defaultChecked={is_active}
                    checked={is_active}
                    onChange={this.onSwitchChange}
                    className={`grade-card__container--ant-switch ${!is_active ? 'white-switch' : ''}`}
                />
                {this.props.gradeData?.is_active && <div
                    className="edit-icon-container"
                    onClick={() => this.props.onGradeCardClick(this.props.gradeData)}
                >
                    <PencilIcon color='#514e59'/>
                </div>
                }
                <img
                    className="grade-card__thumbnail"
                    src={image ? image : 'https://e7.pngegg.com/pngimages/122/668/png-clipart-daffy-duck-donald-duck-looney-tunes-cartoon-good-friday-heroes-logo.png'}
                    alt=""
                />
                <p className="grade-card__title">
                    {name}
                </p>

                <div className="grade-card__mid-section">
                    <div className="grade-card__mid-section--left">
                        <p className="grade-card__mid-section--sub-text">
                            Freadom Grade
                        </p>
                        <p className="grade-card__mid-section--text">
                            {freadom_grade_name}
                        </p>
                    </div>
                    <div className="grade-card__mid-section--right">
                        <p className="grade-card__mid-section--sub-text">
                            Freadom Level
                        </p>
                        <p className="grade-card__mid-section--text">
                            {freadom_grade_level}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default GradeCard
