const addNewGrade = data => ({
  type: "ADD_NEW_GRADE",
  data
});

const getGradesList = data => ({
  type: "GET_GRADES_LIST",
  data
});

const getFreadomGradesList = data => ({
  type: "GET_FREADOM_GRADES_LIST",
  data
});

const updateGradeData = data => ({
  type: "UPDATE_GRADE_DATA",
  data
});

const setSessionsList = data => ({
  type: "SET_SESSIONS_LIST",
  data
})

const setActiveSession = data => ({
  type: "SET_ACTIVE_SESSION",
  data
})

export const mutation = {
  addNewGrade,
  getGradesList,
  updateGradeData,
  getFreadomGradesList,
  setSessionsList,
  setActiveSession
};
