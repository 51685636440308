import { Api } from "./config/request";

export const getTopStories = () => Api.getRequest('story/v1/?top_story=True');

export const getRecommendedStories = () => Api.getListRequest('story/v1/recommended-story/');

export const getStoryDetail = id => Api.getRequest(`story/v1/${id}/`)

export const getMcq = id => Api.getRequest(`story-assessment/v1/story/${id}/question/`);

export const submitAnswer = payload => Api.postRequest('story-assessment/v1/answer/', payload);

export const bookmarkStory = storyId => Api.postRequest(`story/v1/like/?story_id=${storyId}`);

export const startStory = payload => Api.postRequest('story/v1/progress/', payload);

export const finishStory = storyId => Api.putRequest(`story/v1/progress/${storyId}/`);

export const getStoryByStatus = status => Api.getListRequest(`story/v1/?status=${status}`);

export const getLikedStories = () => Api.getListRequest('story/v1/?like=True');

export const Story = {
  getTopStories,
  getRecommendedStories,
  getStoryDetail,
  getMcq,
  submitAnswer,
  bookmarkStory,
  startStory,
  finishStory,
  getStoryByStatus,
  getLikedStories
}