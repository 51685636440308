import React, { Component } from "react";
import { Icon, Popconfirm, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { storage as LocalStorage } from "services/config/storage";
import { bindActionCreators } from "redux";
import * as userActions from "store/user/actions";
import Logo from "assets/image/logo.svg";
import HomeIcon from "assets/image/icons/home-icon.svg";
import DoIcon from "assets/image/icons/do-icon.svg";
import QuizIcon from "assets/image/icons/quiz-icon.svg";
import ReadIcon from "assets/image/icons/read-icon.svg";
import CircleCheck from 'assets/image/circle-check.svg';
import CircleUnCheck from 'assets/image/circle-uncheck.svg';

import "./Navbar.scss";
import { Mixpanel } from "../../Mixpanel";

class Navbar extends Component {
  state = {
    switchingChild: false,
    navbarOpened: false,
    navbarLinks: [
      // {
      //   title: 'Home',
      //   url: '/',
      //   icon: HomeIcon
      // },
      {
        title: 'Read',
        url: '/',
        icon: ReadIcon
      },
      {
        title: 'Activities',
        url: '/activities',
        icon: DoIcon
      },
      // {
      //   title: 'Quiz',
      //   url: '/quiz',
      //   icon: QuizIcon
      // }
    ]
  };
  handleSwitchChild = async id => {
    Mixpanel.track('Child Switch icon click')
    this.setState({ switchingChild: true });
    await this.props.switchChild({ child_id: id });
    window.location.reload();
  };
    logoutUser = () => {
    Mixpanel.track('Logout Event')
    LocalStorage.destroy.all();
    this.props.history.push('/login');
  };
  render() {
    // const { defaultChildData } = this.props;
    const defaultChildData = LocalStorage.fetch.defaultChild();
    const childList = LocalStorage.fetch.childData();
    // const parentData = LocalStorage.fetch.parentData();
    const { navbarLinks } = this.state
    return (
      <div className="navbar-container">
        <span className="hamburger-icon"
          onClick={ () => this.setState({ navbarOpened: true }) }>&#9776;</span>
        <div style={{display: 'flex'}} className="navbar-left">
          <Link to="/">
            <img src={Logo} className="logo" alt="logo" />
          </Link>
          {/* <Link to="/activities">
            <div>Activity</div>
          </Link> */}
          <div className="navbar-links-container flex">
            { navbarLinks.map((link, index) => (
              <Link to={ link.url } className={ (this.props.location.pathname === link.url) ? "active" : '' } key={ index }>
                <div className={ "navbar-link" + ((this.props.location.pathname === link.url) ? " active" : '') }>
                  <img src={ link.icon } className="navbar-links-icon" alt="Navbar icon"/>
                  { link.title }
                </div>
              </Link>
            )) }
          </div>
        </div>
        {defaultChildData && (
          <div className="user-data">
            <Dropdown overlay={
              <div className="child-list-container">
                {childList.list.map(c => (
                  <div className="child-list-item" onClick={() => this.handleSwitchChild(c.id)} key={c.id}>
                    <div>
                      {c.image
                        ? <img src={c.image} alt="user" className="child-image" />
                        : <Avatar className="child-image">{c.name[0]}</Avatar>
                      }
                      {c.name}
                    </div>
                    <img src={c.id === defaultChildData.id ? CircleCheck : CircleUnCheck} alt="check" />
                  </div>
                ))}
              </div>
            } trigger={['click']}>
            <div className="user-pill">
              {/* Commented just in case we need to show parent data. */}
              {/* {parentData.image
                ? <img className="display-picture" src={parentData.image} alt="user" />
                : <Avatar className="display-picture">{parentData.name[0]}</Avatar>
              }
              <div>
                <div className="user-name">{parentData.name}</div>
                <div className="parent-text">Parent</div>
              </div> */}
              {defaultChildData.image
                ? <img className="display-picture" src={defaultChildData.image} alt="user" />
                : <Avatar className="display-picture">{defaultChildData.name[0]}</Avatar>
              }
              <div className="user-name">{defaultChildData.name}</div>
              <Icon type="caret-down" />
            </div>
            </Dropdown>
            <Popconfirm
              placement="bottom"
              title={"Are you sure you want to logout ?"}
              okText="Yes"
              cancelText="No"
              onConfirm={this.logoutUser}
            >
              <div className="swap-icon">
                <Icon type="logout" style={{ color: "#fff" }} />
              </div>
            </Popconfirm>
          </div>
        )}

        <div className="navbar-overlay" style={{ height: this.state.navbarOpened ? '100%' : '0' }}>
          <p className="close-btn" onClick={ () => this.setState({ navbarOpened: false }) }>
            &times;
          </p>
          <div className="overlay-content">
            <div className="navbar-links-container flex">
              { navbarLinks.map((link, index) => (
                <Link to={ link.url } key={ index }>
                  <div className="navbar-link">
                    {/* <img src={ link.icon } className="navbar-links-icon" alt="Navbar icon"/> */}
                    { link.title }
                  </div>
                </Link>
              )) }
              <a>
                <div className="navbar-link" onClick={this.logoutUser}>
                  Logout
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user }) => ({ ...user }),
  dispatch => bindActionCreators({ ...userActions }, dispatch)
)(withRouter(Navbar));
