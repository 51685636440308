import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TruncateString from 'utils/truncate';
import ClockIcon from "assets/image/time.svg";
import FreadoIcon from "assets/image/freado.svg";
import UnlockIcon from "assets/image/padlock-unlock.svg";
import LockIcon from "assets/image/padlock.svg";
import BookmarkActive from 'assets/image/bookmark-active.svg';
import BookmarkInActive from 'assets/image/bookmark-inactive.svg';
import { bookmarkStory } from 'store/story/actions'
import { Mixpanel } from '../../Mixpanel';

import './Storycard.scss';

const gradient = 'linear-gradient(359deg, #552d83, #432870 64%, #361e5f)';
const UNLOCKED = 'unlocked';
const LOCKED = 'locked';
class Storycard extends Component {

  bookmark = e => {
    e.stopPropagation();
    if(this.props.data.is_liked) {
      Mixpanel.track('Remove Story from library', this.props.data)
    } else {
      Mixpanel.track('Add story to library', this.props.data)
    }
    const { bookmarkStory, data } = this.props;
    bookmarkStory(data);
    if (this.props.bookmark) { // To update parent data from collections
      this.props.bookmark(data.id)
    }
  }

  render() {
    const { data, type } = this.props;
    return data ? (
    <div
      className="storycard-container"
      style={{backgroundImage: data.image ? 'url("'+data.image+'")' : gradient}}
    >
      <div className="story-data">
        {data.reading_time && <Pill icon={ClockIcon} text={data.reading_time} />}
        {type === 1 && (data.activity_pack === null
          ? <Pill icon={FreadoIcon} text={data.freadom_point} />
          : <Pill icon={data.status === UNLOCKED ? UnlockIcon : LockIcon} />
        )}
        <div className="bottom-div">
          <div className="title">
            <div className="story-name">{TruncateString(data.name)}</div>
            {type === 0 && <div className="story-author">
              Written by <b>{data.author_name}</b>
            </div>}
          </div>
          {type === 0 && <img
            className="bookmark-icon"
            src={data.is_liked ? BookmarkActive : BookmarkInActive}
            alt="bookmark"
            onClick={this.bookmark}
          />}
        </div>
        {type === 1 && <div className="text-white text-12 story-description">
          {TruncateString(data.description, 60)}
        </div>}
        {/* <div className="story-publisher">Published by Penguin India</div> */}
      </div>
    </div>
    )
    : null
  }
};

const Pill = props => (
  <div className="read-time" style={{ width: props.text ? '' : '26px', height: props.text ? '' : '26px', borderRadius: props.text ? '' : '50%' }}>
    <img src={props.icon} style={{margin: props.text ? '0 6px 0 0' : 'auto'}} alt=""/>
    {props.text || ''}
  </div>
)

Storycard.defaultProps = {
  type: 0, // Type ((0 => Story) ), ((1 => Activity) ) 
}

export default connect(
  null,
  dispatch => bindActionCreators({bookmarkStory}, dispatch)
)(Storycard);