import axios from "axios";
import store from "store/index";
import { storage as LocalStorage } from "services/config/storage";
import { API_URL } from "./apiUrl";

const cachios = require('cachios');

const _authorizationHeaders = () => {
  const token =
    store.getState().user.authToken || LocalStorage.fetch.authToken() || "";
  // const token = LocalStorage.fetch.authToken() || ""

  // DEV Token
  // const token = 'eyJhbGciOiJSUzI1NiJ9.eyJvcHRpbXVzLmdldGZyZWFkb20uY29tL2lkIjogIjEiLCAib3B0aW11cy5nZXRmcmVhZG9tLmNvbS90eXBlIjogInVzZXIiLCAiaWF0IjogMTYwNjIxNjM4NSwgImlzcyI6ICJvcHRpbXVzLmdldGZyZWFkb20uY29tIiwgImV4cCI6IDE2MDc1MTIzODV9.QcmG7LMLRox5i_s3DySzwzNzcKFGVibfjowZakL1q2MUR717UyBcyFIhCQg0FQgX5enMXfYPxJKevS4sJRc4NCkPk5oczV0wAAbIMWkrw0V8yU117wv99tTytBDa6mFqgRJHQK7qvU-61szVUWjkad5mnowdxf_Fg53HV7NFT_M6xEpNVfq6jgMVuofG6SxgMAZUS5i9-nD71x1_11UP9awOMu7_iVJUVF0ym7x6L7qcgN2aUpcpH8CLGlyJa7gU8-L3Pb9thTMxZJHb90O3RhQjKXuRK_j-4V_274tma_BfNwSj5-QXp8Swx2-Y6LX-FrNucg9h1uo7v3PGz7CIQw'

  // PROD Token
  // const token = 'eyJhbGciOiJSUzI1NiJ9.eyJvcHRpbXVzLmdldGZyZWFkb20uY29tL2lkIjogIjEiLCAib3B0aW11cy5nZXRmcmVhZG9tLmNvbS90eXBlIjogInVzZXIiLCAiaWF0IjogMTYwNTEwOTk4MywgImlzcyI6ICJvcHRpbXVzLmdldGZyZWFkb20uY29tIiwgImV4cCI6IDE2MDY0MDU5ODN9.FdrRv9Qes9ScH7o5AB8NZHOmh5iE2XE82QMyD1jwN2dXnxBjugn6nKOSOAvRDtfsfmCvdBaQh0yOTuICH0c8CcauJytAvY8HvIxjL0ybDBp53gNOCs2tWcJ-sS2gWs6oJUogab8DNrZ3t_SOvSOghJiDqyRSc9RXhCe2l_au1wM'

  return {
    Authorization: token ? token : "",
    "api-version": "1.1.0",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*"
  };
};
const _authorizationHeadersFlive = () => {
  const token = LocalStorage.fetch.authToken(true) || "";
  return {
    Authorization: token ? `${token}` : "",
    "api-version": "1.1.0",
    "Content-Type": "application/json",
  };
};
const handleError = (err) => {
  console.log(`Api call error in services -> request.js : `, err);
};

export const getRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await cachios.get(API_URL + url, {
      ttl: 300 /* seconds */,
      headers: Object.assign({}, headers),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};
//FLV-170_2
export const getRequest_with_Axios = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};
//FLV-170_2

export const getExternalRequest = async (url) => {
  try {
    const res = await axios.get(url);
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const getListRequest = async (
  url,
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data;
    // return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const putRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders(),
  absoluteUrl = false
) => {
  try {
    const res = await axios({
      url: absoluteUrl ? url : API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const patchRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data),
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const deleteRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};
export const getFliveRequest = async (
  url,
  is_flive = false,
  headers = _authorizationHeadersFlive()
) => {
  try {
    const api_url = API_URL;
    const res = await axios.get(api_url + url, {
      headers: Object.assign({}, headers),
    });

    return res.data.data.reports ? res.data.data.reports : res.data.data;
  } catch (err) {
    handleError(err);
  }
};
export const Api = {
  deleteRequest,
  getRequest_with_Axios,
  getListRequest,
  getRequest,
  getFliveRequest,
  getExternalRequest,
  postRequest,
  putRequest,
  patchRequest,
};
