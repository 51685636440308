import React from 'react';
import {Col, Input, Row, Select} from 'antd';
import {ErrorMessage, Field} from 'formik';

const {Option} = Select;
const {TextArea} = Input;


const ParentForm = (props) => {
    const {values, setFieldValue} = props;
    return (
        <div className="form-container">
            <h3 className="form-title">Parent’s Employement</h3>

            {/* Mother's Form */}
            <h1 className="form-seconday-title">Mother’s Details</h1>
            <Row>
                <Col span={24}>
                    <span className="error-message">
                        <ErrorMessage
                            name={`details.mother_details`}
                            className="error-message"
                            render={(msg) => {
                                return <p>{typeof msg == "string" ? msg : ""}</p>
                            }}
                        />
                </span>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={9} className="form-column">
                    <p>Mother’s Name</p>
                    <Field
                        placeholder={"Mother’s name"}
                        as={Input}
                        name={"details.mother_details.name"}
                        value={values.details.mother_details.name}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name={`details.mother_details.name`}
                            className="error-message"
                        />
                    </span>
                </Col>
                <Col span={9} className="form-column">
                    <p>Employer’s Name</p>
                    <Field
                        placeholder={"Where the mother works"}
                        as={Input}
                        name={"details.mother_details.employer_name"}
                        value={values.details.mother_details.employer_name}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name={`details.mother_details.employer_name`}
                            className="error-message"
                        />
                    </span>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={12} className="form-column">
                    <p>Salary Bracket</p>
                    <Select
                        placeholder={"Select One"}
                        onChange={(val) => setFieldValue('details.mother_details.salary_bracket', val)}
                        value={values.details.mother_details.salary_bracket}
                    >
                        <Option value={'0-6 LPA'}>0-6 LPA</Option>
                        <Option value={'6-10 LPA'}>6-10 LPA</Option>
                        <Option value={'10+ LPA'}>10+ LPA</Option>
                    </Select>
                    <span className="error-message">
                        <ErrorMessage
                            name={`details.mother_details.salary_bracket`}
                            className="error-message"
                        />
                    </span>
                </Col>
            </Row>

            <br/>
            {/* Father's Form */}
            <h1 className="form-seconday-title">Father's Details</h1>
            <Row>
                <Col span={24}>
                    <span className="error-message">
                        <ErrorMessage
                            name={`details.father_details`}
                            className="error-message"
                            render={(msg) => {
                                return <p>{typeof msg == "string" ? msg : ""}</p>
                            }}
                        />
                    </span>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12} className="form-column">
                    <p>Father’s Name</p>
                    <Field
                        placeholder={"Fathers’s name"}
                        as={Input}
                        name={"details.father_details.name"}
                        value={values.details.father_details.name}
                    />

                </Col>
                <Col span={9} className="form-column">
                    <p>Employer’s Name</p>
                    <Field
                        placeholder={"Where the father works"}
                        as={Input}
                        name={"details.father_details.employer_name"}
                        value={values.details.father_details.employer_name}
                    />

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={9} className="form-column">
                    <p>Salary Bracket</p>
                    <Select
                        placeholder={"Select One"}
                        onChange={(val) => setFieldValue('details.father_details.salary_bracket', val)}
                        value={values.details.father_details.salary_bracket}
                    >
                        <Option value={'0-6 LPA'}>0-6 LPA</Option>
                        <Option value={'6-10 LPA'}>6-10 LPA</Option>
                        <Option value={'10+ LPA'}>10+ LPA</Option>
                    </Select>

                </Col>
            </Row>
        </div>
    )
        ;

}

export default ParentForm;
