import React, {Component} from "react";
import {Button, Col, Form, Input, InputNumber, Row} from 'antd';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CloneDeep from 'lodash/cloneDeep';
import cloneDeep from 'lodash/cloneDeep';
import {storage as LocalStorage} from "services/config/storage";
import * as teacherActions from "store/teacher/actions";
import * as subscriptionActions from "store/subscriptions/actions";
import AdminLayout, {Header} from 'layout/admin';
import Grade from './grade';
import "./index.scss";
import {updateFormDataState} from "../utils";
import {toast} from "react-toastify";

class CreateSubscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            tempKeyHighlights: [''],
            gradeLevels: null,
            ...this.onInit()
        }
        this.keyHighlightsRefs = {}
    };

    componentDidMount() {
        if (!LocalStorage.fetch.authToken()) {
            this.props.history.push("/");
        }
        this.setFormFields(this.state.formData)

    }

    onInit = () => {
        const initialStates = CloneDeep(this.props.location.state);
        if (initialStates) {
            let {id, num_payment_cycles, cycle_duration, mrp_per_cycle, discount_per_cycle} = initialStates?.formData?.pack_billing_cycles?.[0] || {}
            return {
                formData: {
                    ...initialStates.formData,
                    ...initialStates.formData.pack_billing_cycles[0],
                    billing_details: {
                        id,
                        num_payment_cycles, cycle_duration, mrp_per_cycle, discount_per_cycle
                    },
                }
            };
        }
        return {
            formData: {
                course_type: 'NORMAL',
                label: null,
                description: null,
                grades: [],
                billing_details: {
                    num_payment_cycles: 0,
                    cycle_duration: 0,
                    mrp_per_cycle: undefined,
                    discount_per_cycle: 0,
                },
            }
        };
    }
    setFormFields = (state) => {
        let fieldKeys = state && Object.keys(state)
        fieldKeys.forEach((key) => {
            let value = state?.[key];
            if (['label', 'description', 'mrp_per_cycle', 'total_sessions', 'plan_id', 'discount_per_cycle'].includes(key))
                value && this.props.form.setFieldsValue({[key]: value})
        })
    }

    handleState = (stateValue) => this.setState(stateValue);

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({isLoading: true})
                let {
                    formData
                } = this.state
                let payload = {
                    data: formData
                }
                console.log('sss', payload)

                const parentProps = cloneDeep(this.props.location.state);
                let res,
                    successMessage = 'Subscription pack successfully created!',
                    errorMessage = 'Could not create subscription pack!';

                if (parentProps && parentProps?.id) {
                    successMessage = 'Subscription pack successfully updated!';
                    errorMessage = 'Could not update subscription pack!'
                    Object.keys(payload.data).map(key => {
                        if (![
                            "is_active",
                            "grades",
                            "total_sessions",
                            "plan_id",
                            "label",
                            "billing_details",
                            "description"
                        ].includes(key))
                            delete payload.data[key]
                    })

                    payload.data.grades = payload.data.grades.map(item => item?.id || item)
                    res = await this.props.updateSubscriptionPack(parentProps.id, payload)
                } else {
                    res = await this.props.createSubscriptionPackRequest(payload);
                }


                try {
                    if (res) {
                        this.setState({isLoading: false})

                        toast.success(successMessage)
                        setTimeout(() => this.props.history.push('/subscriptions'), 1300)
                    } else {
                        this.setState({isLoading: false})

                        toast.error(errorMessage)
                    }
                } catch (e) {
                    this.setState({isLoading: false})
                    toast.error(errorMessage)
                }
            }
        });
    };
    renderHeader = () => (
        <div className="create-subs-cta">
            <Button
                loading={this.state.isLoading}
                type="primary"
                onClick={this.handleSubmit}
                className="ant-btn-md export-btn"
                htmlType="submit">
                {this.props.location.pathname.includes('update') ? 'Update' : 'Submit'}
            </Button>
            <Button
                onClick={this.props.history.goBack}
                className="ant-btn-md cancel-btn"
                htmlType="">Cancel</Button>
        </div>
    )

    render() {
        const {formData} = this.state;
        const {
            description,
            billing_details: {
                mrp_per_cycle,
                discount_per_cycle,
            },
            grades
        } = formData;
        console.log(',,', formData)
        const {getFieldDecorator, setFieldsValue} = this.props.form;
        return [
            <AdminLayout title="">
                <div className="add-subscription-header">
                    <div>
                        <Button className="back-btn"
                                onClick={() => {
                                    this.props.history.goBack()
                                }}>&lt; Back</Button>
                        <div className="seperator-20"/>
                        <Header
                            title={`${this.props.location.pathname.includes('update') ? 'Edit' : 'Add new'} subscription`}/>
                    </div>
                    {this.renderHeader()}
                </div>
                <section className="add-subscription-wrapper">
                    <Form
                        onSubmit={this.handleSubmit}
                        name="subscription-form"
                        initialValues={{remember: true}}
                        className="subscription-form"
                    >
                        <Row gutter={[16, 16]}>
                            <Col lg={16} md={20} sm={24}>
                                <Col span={10}>
                                    <Form.Item
                                        label="Name of plan"
                                    >
                                        {
                                            getFieldDecorator('label', {
                                                rules: [{required: true, message: 'Please enter pack`s name!'}],
                                            })(<Input placeholder="Enter Name" maxLength={100}
                                                      onChange={currentVal => updateFormDataState('label', currentVal.target.value, this.state, this.handleState)}/>)
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Plan ID"
                                    >
                                        {
                                            getFieldDecorator('plan_id', {
                                                rules: [{required: true, message: 'Please enter plan ID!'}],
                                            })(<Input placeholder="Enter ID" maxLength={100}
                                                      onChange={currentVal => updateFormDataState('plan_id', currentVal.target.value, this.state, this.handleState)}/>)
                                        }
                                    </Form.Item>
                                </Col>
                                <Grade
                                    activeGrades={grades}
                                    setFieldsValue={setFieldsValue}
                                    getFieldDecorator={getFieldDecorator}
                                    onChange={(key, value) => {
                                        updateFormDataState(key, value, this.state, this.handleState)
                                    }}/>
                                <Col span={10}>
                                    <Form.Item
                                        label="No. of sessions"
                                    >
                                        {
                                            getFieldDecorator('total_sessions', {
                                                rules: [{required: true, message: 'Please enter number of sessions'}],
                                            })(
                                                <InputNumber min={30}
                                                             placeholder="Enter No. of sessions"
                                                             value={mrp_per_cycle}
                                                             onChange={value => {
                                                                 updateFormDataState('total_sessions', value, this.state, this.handleState, false)
                                                             }}/>
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={20} style={{padding: '8px 0px 8px 8px'}}>
                                    <Form.Item
                                        label="Description"
                                    >
                                        {
                                            getFieldDecorator('description', {
                                                rules: [{required: true, message: 'Please enter pack`s description!'}],
                                            })
                                            (<Input.TextArea placeholder="Enter description here..." maxLength={300}
                                                             value={description}
                                                             onChange={currentVal => updateFormDataState('description', currentVal.target.value, this.state, this.handleState)}/>
                                            )} </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="MRP"
                                    >
                                        {
                                            getFieldDecorator('mrp_per_cycle', {
                                                rules: [{
                                                    required: true,
                                                    message: 'Please enter MRP'
                                                }],
                                            })
                                            (<InputNumber min={1}
                                                          placeholder="Enter MRP"
                                                          addonBefore="₹"
                                                          value={mrp_per_cycle}
                                                          onChange={value => {
                                                              updateFormDataState('mrp_per_cycle', value, this.state, this.handleState, true)
                                                          }}/>)}
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Discounted Price"
                                    >
                                        {
                                            getFieldDecorator('discount_per_cycle', {
                                                rules: [{
                                                    required: false,
                                                    message: 'Please enter discounted price!'
                                                }],
                                            })(<InputNumber addonBefore="₹"
                                                            placeholder={discount_per_cycle}
                                                            onChange={currentVal => updateFormDataState('discount_per_cycle', currentVal, this.state, this.handleState, true)}/>
                                            )}</Form.Item>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                </section>
                <div className={'footer-image'}>
                    <img src={require('../../../../assets/image/pref-create-bottom-image.svg')}/>
                </div>
            </AdminLayout>
        ];
    }
}

const WrappedForm = Form.create({name: 'subscription-form'})(CreateSubscription);

export default connect(
    ({grades}) => ({...grades})
    , dispatch => bindActionCreators({...teacherActions, ...subscriptionActions}, dispatch))(WrappedForm);
