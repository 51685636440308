import React from 'react';
import {Modal as AntModal} from 'antd';
import "./index.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as batchesActions from "../../store/batches/actions";
import {CrossIcon} from "../../assets/image/svg";


const Modal = ({modalProps, ...props}) => {
    return (
        <AntModal
            wrapClassName="custom__modal mob-modal-type-2"
            maskStyle={{
                background: "rgba(0,0,0,0.45)"
            }}
            width={950}
            visible={props.visible}
            onOk={null}
            onCancel={props.closeModal}
            closeIcon={<div className={'anticon'}><CrossIcon width={18} height={18} color={'#524E59'}/></div>}
            {...modalProps}
        >
            {props.children}
        </AntModal>
    );

}
export default connect(
    ({batches}) => ({batches}),
    dispatch => bindActionCreators({...batchesActions}, dispatch)
)(Modal);
