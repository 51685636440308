import React, { useState } from "react";
import { Form, Input, Button, Modal } from 'antd';
import LogoImg from 'assets/image/admin-logo.svg';
import SuccessIcon from 'assets/image/success-icon.jpg';
import "./index.scss";
import {CrossIcon} from "../../../assets/image/svg";


const ForgotPassword = ({ formSubmit }) => {

  const [teacher_id, setTeacherId] = useState();
  const [isSuccess, setResponse] = useState(false);

  const onSubmit = async () => {
    if (teacher_id && teacher_id.length) {
      const response = await formSubmit({ teacher_id });
      setResponse(response === "success");
    }
  }

  const isSubmitEnabled = () => teacher_id && teacher_id.length;

  return [
    <div className="admin-login-left">
      <a href='/admin' className="admin-logo">
        <img src={LogoImg} alt="Freadom" />
      </a>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        className="custom-form admin-login-form"
      >
        <h1>Forgot password?</h1>
        <p className="admin-login-desc">Please enter your Teacher ID to get password reset link:</p>
        <Form.Item name="teacherId">
          <Input placeholder="Teacher ID" value={teacher_id} onChange={currentVal => setTeacherId(currentVal.target.value)} />
        </Form.Item>
        <Button disabled={!isSubmitEnabled()} onClick={onSubmit} type="primary" htmlType="submit">SEND LINK</Button>
        <p className="sm-desc">Didn’t receive the link? <span className="link-text" onClick={onSubmit}>Resend</span></p>
      </Form>
      <Modal
        title="Basic Modal"
        visible={isSuccess}
        onOk={() => setResponse(false)}
        onCancel={() => setResponse(false)}
        bodyStyle={modalStyles.bodyStyle}
        maskStyle={modalStyles.maskStyle}
        footer={null}
        closeIcon={<div className={'anticon'}><CrossIcon width={18} height={18} color={'#524E59'}/></div>}

      >
        <div className="reset-link-modal">
          <img src={SuccessIcon} className="success-mail-icon" alt="success"/>
          <p className="reset-link-modal-title">Password Reset Link Sent</p>
          <p className="reset-link-modal-desc">A reset link has been sent to your email <span>nxxx@xxxxs.com.</span> Please use it to reset your password.</p>
          <Button onClick={() => setResponse(false)} type="primary" htmlType="submit">GOT IT!</Button>
        </div>
      </Modal>
    </div>
  ];
}


export default ForgotPassword;


const modalStyles = {
  bodyStyle: {
    background: '#ffffff',
    width: 510,
    borderRadius: 10
  },
  maskStyle: {
    background: 'rgba(6, 5, 37, 0.6)'
  }
}
