import { initialState } from "./state";

export const lms = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_GRADE": {
      return Object.assign({}, state, {
        newGradeSubmitResponse: action.data
      });
    }
    case "GET_GRADES_LIST": {
      return {
        ...state,
        gradesList: action.data.grades,
        gradesCount: action.data.total_count,
      }
    }
    case "GET_FREADOM_GRADES_LIST": {
      return Object.assign({}, state, {
        freadomGradesList: action.data
      });
    }
    case "UPDATE_GRADE_DATA": {
      return Object.assign({}, state, {
        isGradeDataUpdated: action.data
      });
    }
    case "SET_SESSIONS_LIST": {
      return Object.assign({}, state, {
        sessionsList: action.data.sessions,
        sessionsCount: action.data.total_count,
      });
    }
    case "SET_ACTIVE_SESSION": {
      return Object.assign({}, state, {
        activeSession: action.data
      });
    }
    default:
      return state;
  }
};
