import { Button, Radio } from "antd";
import React, { Component } from "react";
import "./FeedbackForm.scss";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as classesActions from "../../../store/classes/actions";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: {},
      questions: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getFeedbackQuestions();
    this.getFeedbackAnswers();
  }

  getFeedbackQuestions = async () => {
    let quesRes = await this.props.getFeedbackQuestions(
      this.props.feedbackStudent?.child?.grade
    );
    this.setState({
      questions: quesRes,
    });
  };
  getFeedbackAnswers = async () => {
    let results = await this.props.getFeedbackResults(
      this.props.feedbackStudent?.child?.id
    );
    let data =
      results?.data?.filter(
        (item) =>
          this.props.feedbackStudent?.class_session_id === +item.session_id
      )?.[0] || {};
    let answers = {};
    data.results?.length > 0 &&
      data.results.forEach(({ question_id, answer_id }) => {
        answers[question_id] = answer_id;
      });
    this.setState({ answers });
  };

  onAnswerChange(questionId, answerId, index) {
    console.log(questionId, answerId, index);
    const newAnswers = cloneDeep(this.state.answers);
    newAnswers[questionId] = answerId;
    this.setState({
      answers: newAnswers,
    });
  }

  onSubmit = async () => {
    console.log("answers", this.state.answers);
    if (!isEmpty(this.state.answers)) {
      this.setState({ loading: true });
      let feedbackIds =
        this.state.answers && Object.keys(this.state.answers).join(",");
      let feedbackValues =
        this.state.answers && Object.values(this.state.answers).join(",");
      console.log(feedbackIds);
      console.log(feedbackValues);
      let res = {},
        payload = {
          data: {
            child_id: this.props.feedbackStudent?.child?.id?.toString(),
            session_id:
              this.props.feedbackStudent?.class_session_id?.toString(),
            feedback_id: feedbackIds,
            feedback_value: feedbackValues,
          },
        };
      try {
        res = await this.props.submitFeedback(payload);
        if (res) {
          toast.success("Feedback Submitted");
          setTimeout(() => this.props.onClose(), 700);
        } else toast.error("Could not submit the feedback");
      } catch (e) {
        toast.error("Could not submit the feedback");
      }
      this.setState({ loading: false });
    } else this.props.onClose();
  };

  render() {
    const { questions, loading, answers } = this.state;
    console.log(this.props);
    return (
      <div className="student-feedback-form form-padding">
        <div className="feedback-header  flex justify-between">
          <Button onClick={this.props.onClose}>&lt; Back</Button>
          <Button onClick={this.props.onClose}>x</Button>
        </div>
        <div className="student-info">
          <img
            src={
              this.props.feedbackStudent?.child?.image ||
              "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            }
          />
          <div className="student-name">
            <h2>Feedback for {this.props.feedbackStudent?.child?.name}</h2>
            <h5>
              Select the option which describes{" "}
              {this.props.feedbackStudent?.child?.name} best respective to the
              given statements
            </h5>
          </div>
          <Button
            loading={loading}
            disabled={isEmpty(answers)}
            className="purple-btn"
            onClick={() => {
              this.onSubmit();
            }}
          >
            Done
          </Button>
        </div>
        <div className="feedback-questions">
          {questions.map((question, index) => (
            <div className="feedback-question">
              <h4>
                {index + 1}. {question?.value}
              </h4>
              <div className="answer-options">
                <Radio.Group
                  onChange={(value) =>
                    this.onAnswerChange(
                      question?.id,
                      value?.target?.value,
                      index
                    )
                  }
                  value={
                    this.props.feedbackStudent.attendance_status == "ABSENT"
                      ? 0
                      : this.state.answers[question?.id]
                  }
                >
                  {question?.possible_options?.map((item) => (
                    <Radio value={item.id}>{item?.value}</Radio>
                  ))}
                </Radio.Group>
              </div>
              {}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators({ ...classesActions }, dispatch)
)(FeedbackForm);
