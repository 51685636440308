import { Collections } from "services/collections";
import { mutation } from "./mutations";

const handleError = err => {
  console.log("Error in collections action : ", err);
};

// Actions
export const getCollectionsListRequest = () => async dispatch => {
  try {
    let result = await Collections.getCollectionsList();
    dispatch(mutation.setCollectionsList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getCollectionsById = id => async dispatch => {
  try {
    let result = await Collections.getCollectionById(id);
    // dispatch(mutation.setCollectionsList(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
