import React, {useCallback, useEffect, useState} from 'react';
import './index.scss'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as preferenceGroupActions from "../../../../../store/preferenceGroups/actions";
import {assignPreferenceGroup, removePreferenceGroup} from "../../../../../store/preferenceGroups/actions";
import {convertSecondsToTime, generateFilterUrl, returnWeekDay} from "../../../../../utils/commonUtils";
import moment from "moment";
import {Button} from "antd";
import isEmpty from 'lodash/isEmpty'
import {toast} from "react-toastify";

const PreferenceGroupList = ({childId, existingPrefGroupId, closeModal, ...props}) => {
    const [prefGroups, setPrefGroups] = useState([]);
    const [selectedValue, setSelectedValue] = useState({});
    const [loading, setLoading] = useState(false);
    console.log('existingPrefGroupId', existingPrefGroupId)
    useEffect(() => {
        (async () => {
            await fetchPreferences()
        })()
    }, [])

    const fetchPreferences = useCallback(async (payload) => {
        let filters;
        if (payload && payload.filters)
            filters = payload.filters
        // else
        // filters = {...this.state.filters};
        let res = {}
        try {
            let urlParams = generateFilterUrl(filters);
            setLoading(true);
            res = await props.getPreferenceGroups(urlParams);
            console.log('re', res)
            if (res) {
                setPrefGroups(res?.preference_groups)
            }
        } catch (e) {
        } finally {
            setLoading(false)
        }
    }, [])

    const handleSubmit = async () => {
        let res = {};
        try {
            setLoading(true)
            let payload = {
                data: {
                    child_id: +childId,
                    preference_group_id: existingPrefGroupId
                }
            }
            if (existingPrefGroupId)
                res = await removePreferenceGroup(payload)
            else
                res = true
            if (res) {
                res = undefined;
                payload.data.preference_group_id = selectedValue?.group_id;
                res = await assignPreferenceGroup(payload)
                if (res) {
                    toast.success('Preference Group updated')
                    closeModal()
                } else
                    toast.error('Could not update preference group')

            } else toast.error('Could not update preference group')

        } catch (e) {
            toast.error('Could not update preference group')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={'pref-groups'}>
            <div className={'preference-groups-container'}>
                <div className="card-container">
                    {prefGroups?.map((item, index) => {
                        return <div
                            onClick={() => {
                                setSelectedValue(item)
                            }}
                            className={['pref-group-card', (selectedValue?.group_id || existingPrefGroupId) === item?.group_id ? 'active' : ''].join(' ')}>
                            <div className={'content'}>
                                <p className={'heading'}>
                                    {`Schedule ${++index}`}
                                    {
                                        item.start_time &&
                                        <p className={'start-date'}>{moment(item.start_time * 1000).format('DD/MM/YYYY')}</p>
                                    }
                                </p>
                                <div className={'time-slots-section'}>
                                    {renderTimeSlots(item.timeslots)}
                                </div>
                            </div>
                            {props.bgImage && <div className={'image-container'}>
                                <img src={props.bgImage} alt={'calendar-icon'}/>
                            </div>}
                        </div>
                    })}
                </div>
            </div>
            <div className="button-container">
                <Button
                    className="cancel-btn"
                    size="large"
                    onClick={closeModal}
                >
                    Cancel
                </Button>
                <Button
                    loading={loading}
                    disabled={isEmpty(selectedValue)}
                    type="primary"
                    className="ant-btn"
                    size="large"
                    onClick={handleSubmit}
                >Submit</Button>
            </div>
        </div>
    );
}

function renderTimeSlots(slots) {
    return slots && slots.length > 0 && slots.map(slot => (
        <div className={'time-slot'}>
            <p className={'day-name'}>{returnWeekDay(slot.weekday, true) || ' '}</p>
            {`${convertSecondsToTime(slot.start_second, true, true)} - ${convertSecondsToTime(slot.start_second + slot.duration, true, true)}`}
        </div>
    ))
}

export default connect(
    ({preferenceGroups}) => ({...preferenceGroups}),
    dispatch => bindActionCreators({...preferenceGroupActions}, dispatch)
)(PreferenceGroupList);
