import React, { Component } from "react";
import AdminLayout from "../../../../layout/admin/index";
import "./PreferenceDetail.scss";
import {
  Calender15Icon,
  HourGlass,
  PeopleIcon2,
  TimeIcon,
} from "../../../../assets/image/svg";
import ChildImage from "../../../../assets/image/child-image.png";
import { Button, Table } from "antd";
import ChildrenModal from "../../../../components/ChildrenModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as preferenceGroupActions from "../../../../store/preferenceGroups/actions";
import { removePreferenceGroup } from "../../../../store/preferenceGroups/actions";
import * as batchesGroupActions from "../../../../store/batches/actions";
import moment from "moment";
import {
  convertSecondsToTime,
  getDurationFromSeconds,
} from "../../../../utils/commonUtils";
import DefaultUserIcon from "../../../../assets/image/default-user.png";
import { toast } from "react-toastify";
import {
  GradesCell,
  StudentCell,
  EmailCell,
  MobileNumberCell,
} from "./rowCell";

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

class PreferenceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prefDetails: {},
      showChildrenModal: false,
      activeBucket: {},
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    let prefId = this.props.match.params.preferenceId;
    let res = await this.props.getPreferenceGroupDetail(prefId);
    if (res) {
      this.setState({
        loading: false,
        prefDetails: res.data,
      });
    }
  }

  onModalStateChange(key) {
    switch (key) {
      case "children":
        this.setState({
          showChildrenModal: !this.state.showChildrenModal,
        });
    }
  }

  handleDelete = async (e, rowData) => {
    console.log(e, rowData);
    let res = {};
    let prefId = this.props.match.params.preferenceId;
    e.stopPropagation();
    try {
      this.setState({ loading: true });
      if (prefId) {
        res = await removePreferenceGroup({
          data: {
            child_id: rowData.id,
            preference_group_id: prefId,
          },
        });
      } else {
        res = true;
      }
      this.setState({
        loading: true,
      });
      console.log("reshere", res);
      if (res) {
        res = undefined;
        res = await this.props.getPreferenceGroupDetail(prefId);
        this.setState({
          loading: false,
          prefDetails: res.data,
        });
      }
    } catch (e) {
      console.log("something went wrong");
    } finally {
      this.setState({ loading: false });
    }
  };

  getTheads = () => [
    // {
    //   title: "Day",
    //   align: "center",
    //   render: (rowData) => <Schedule schedules={rowData?.timeslots} />,
    // },
    // {
    //   title: "Time",
    //   render: (rowData) => {
    //     return (
    //       <TimeCell
    //         data={convertSecondsToTime(
    //           rowData?.timeslots?.[0]?.start_second,
    //           true,
    //           true
    //         )}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: "Start date",
    //   render: (rowData) => (
    //     <TimeCell format={"DD/MM/YYYY"} data={rowData.start_time * 1000} />
    //   ),
    //   sorter: (a, b) => a.start_time - b.start_time,
    // },
    // {
    //   title: "Teacher Id",
    //   render: (rowData) => <TeacherCell data={rowData.teacher_id} />,
    // },
    {
      title: "Student Name",
      render: (rowData) => <StudentCell data={rowData?.name} />,
    },
    {
      title: "Mobile Number",
      render: (rowData) => <EmailCell data={rowData?.parent.phone_number} />,
    },
    {
      title: "Email Address",
      render: (rowData) => <MobileNumberCell data={rowData?.parent.email} />,
    },
    {
      title: "Grades",
      render: (rowData) => <GradesCell data={rowData?.grade_name} />,
    },
    { title: "Subscription Pack" },
    {
      title: "Action",
      render: (rowData) => (
        <div>
          <Button
            style={{ cursor: "pointer" }}
            onClick={(e) => this.handleDelete(e, rowData)}
          >
            Delete
          </Button>
        </div>
      ),
    },
    // {
    //   title: "Start Topic",
    //   render: (rowData) => <StartTopicCell data={rowData.start_topic} />,
    // },
    // {
    //   title: "Number of Students",
    //   align: "center",
    //   render: (rowData) => <NumberCell data={rowData.students_opted} />,
    //   sorter: (a, b) => a.students_opted - b.students_opted,
    // },
  ];

  render() {
    console.log("childre", this.state.isLoading);
    console.log("propshere", this.props);
    let {
      start_time,
      timeslots = [],
      num_of_batches,
      waitlist_buckets = [],
    } = this.state.prefDetails;
    let data = waitlist_buckets
      ? waitlist_buckets[0]?.children.map((children, index) => ({
          ...children,
          grade_name: waitlist_buckets[0].grade.name,
        }))
      : null;

    return (
      <div className="preference-details ">
        <AdminLayout
          path1="preference-listing"
          showBack={true}
          title={`Preference Group(${
            (start_time && moment(start_time * 1000).format("DD/MM/YYYY")) || ""
          })`}
        >
          <div className="preference-info flex justify-between items-center ">
            <div className="days">
              <h5>days of week:</h5>
              <h2>
                {timeslots?.map((item, index) => {
                  return [
                    returnWeekDay(item.weekday),
                    timeslots?.length > 1 && index !== timeslots.length - 1 && (
                      <span>{" | "}</span>
                    ),
                  ];
                })}
              </h2>
            </div>
            <div className="info flex">
              <div className="info-item">
                <Calender15Icon color={"#F2BD1D"} />
                <h5>Start Date:</h5>
                <h2>
                  {(start_time &&
                    moment(start_time * 1000).format("DD/MM/YYYY")) ||
                    "-"}
                </h2>
              </div>
              <div className="info-item">
                <TimeIcon color={"#F2BD1D"} />
                <h5>Batch time:</h5>
                <h2>
                  {convertSecondsToTime(
                    timeslots?.[0]?.start_second,
                    true,
                    true
                  )}
                </h2>
              </div>
              <div className="info-item">
                <HourGlass color={"#F2BD1D"} />
                <h5>Duration:</h5>
                <h2>{getDurationFromSeconds(timeslots?.[0]?.duration)}</h2>
              </div>
              <div className="info-item">
                <PeopleIcon2 color={"#F2BD1D"} />
                <h5>Batches:</h5>
                <h2>{num_of_batches}</h2>
              </div>
            </div>
          </div>
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={this.getTheads()}
            dataSource={data}
            onChange={this.onChange}
            loading={this.state.loading}
            pagination={false}
            onRow={(formData, rowIndex) => {
              return {
                onClick: (event) => {
                  this.props.history.push({
                    pathname: `/students/info/${formData?.id}`,
                  });
                },
              };
            }}
          />
          <div className="buckets">
            <Button
              type="primary"
              style={{ position: "absolute", top: "50px", right: "30px" }}
              onClick={() => {
                this.setState(
                  {
                    activeBucket: this.state.prefDetails?.waitlist_buckets[0],
                  },
                  () => this.onModalStateChange("children")
                );
              }}
            >
              Start Batch
            </Button>
            {/* {waitlist_buckets?.map((bucket, index) => (
              <div key={index} className="bucket">
                <div>
                  <h4>Bucket {index + 1}:</h4>
                  <div className="student-images">
                    {bucket?.children?.map((child) => (
                      <img src={child.image || DefaultUserIcon} />
                    ))}
                  </div>
                </div>
                <div>
                  
                </div>
              </div> }
            ))*/}
          </div>
          <ChildrenModal
            batchId={this.props.match.params.preferenceId}
            fetchData={false}
            data={this.state.activeBucket}
            visible={this.state.showChildrenModal}
            closeModal={() => this.onModalStateChange("children")}
          >
            <div className="preference-details">
              <Button
                loading={this.state.isLoading}
                disabled={this.state.isLoading}
                onClick={async () => {
                  this.setState({
                    isLoading: true,
                  });
                  let data = {
                    preference_groups: [this.props.match.params.preferenceId],
                    // bucketKey: this.state.activeBucket?.bucket_key,
                    // childrenIds: this.state.activeBucket?.children?.map(
                    //   (item) => item?.id
                    // ),
                  };
                  let res = {};

                  try {
                    res = await this.props.startBatch(data);
                  } catch (e) {
                    toast.error("Could not start the batch!");
                    this.setState({
                      isLoading: false,
                    });
                  }
                  if (res) {
                    //FLV-54
                    //toast.success("Batch started successfully!");
                    toast.success("Batch creation in progress!");
                    //FLV-54
                    this.setState({
                      showChildrenModal: false,
                    });
                  }
                  //FLV-54
                  else {
                    //toast.success("Batch started successfully!");
                    toast.error("Could not start the batch!");
                  }
                  //FLV-54
                  this.setState({
                    isLoading: false,
                  });
                }}
                className="primary-btn-pref start-batch"
              >
                Start Batch
              </Button>
            </div>
          </ChildrenModal>
          <img
            src={ChildImage}
            style={{ zIndex: -1 }}
            className="child-image"
          />
        </AdminLayout>
      </div>
    );
  }
}

function returnWeekDay(weekday) {
  switch (weekday) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
  }
}

const sampleImageUrl =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Pierre-Person.jpg/220px-Pierre-Person.jpg";

export default connect(
  ({ preferenceGroups, batches }) => ({ ...preferenceGroups, ...batches }),
  (dispatch) =>
    bindActionCreators(
      { ...preferenceGroupActions, ...batchesGroupActions },
      dispatch
    )
)(PreferenceDetail);
