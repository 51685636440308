import React, { useEffect, useState, useRef } from "react";
import { Input, Modal,Button,Select } from "antd";
import { Table } from "antd";
import { columns } from "./constant";
import "./index.scss";
import wowImage from "assets/image/wow.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as batchesActions from "../../store/batches/actions";
import DefaultUserIcon from "../../assets/image/default-user.png";
import { CrossIcon, TrashCancel } from "../../assets/image/svg";
import * as reportActions from "../../store/reports/actions";
const { TextArea } = Input;
const {Option}= Select;
const ChildrenModal = (props) => {
  return (
    <Modal
      wrapClassName="children-modal mob-modal-type-2"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title="Children attending"
      width={950}
      visible={props.visible}
      onOk={null}
      onCancel={props.closeModal}
      okText="Submit"
      closeIcon={
        <div className={"anticon"}>
          <CrossIcon width={18} height={18} color={"#524E59"} />
        </div>
      }
    >
      <ChildrenCards {...props} />
      {props.children}
    </Modal>
  );
};

const ReportModal = (props) => {
  useEffect(() => {
    props.getAllReports(props.child?.crm_lsq_id);
  }, []);
  return (
    <Modal
      wrapClassName="children-modal mob-modal-type-2"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title="Report"
      width={950}
      onOk={null}
      visible={props.visible}
      onCancel={props.closeModal}
      okText="Submit"
      closeIcon={
        <div className={"anticon"}>
          <CrossIcon width={18} height={18} color={"#524E59"} />
        </div>
      }
    >
      <div className="table-container">
        <Table
          pagination={false}
          dataSource={props.reports?.list || []}
          columns={columns}
        />
      </div>
      {/* <ChildrenCards {...props} /> */}
      {props.children}
    </Modal>
  );
};

const ChildrenCards = ({ fetchData = true, data = [], ...props }) => {
  const [childrenData, setChildrenData] = useState(data?.children);
  const [showReport, setShowReport] = useState(false);
  const [presentId, setPresentId] = useState({});

  const [show_modal, setShowModal] = useState(false);
  const [reason,setReason] = useState('');
  const [delete_child_id,setDeleteChildId] = useState('');
  var user = JSON.parse(localStorage.getItem("user_data"));
  //console.log("thisiswhatyouwant", user.user_data.role);

  useEffect(() => {
    if (fetchData) {
      (async () => {
        let res = await props.getBatchChildrenRequest(props.batchId);

        setChildrenData(res);
      })();
    } else setChildrenData(data?.children);
  }, [props.visible]);

  useEffect(() => {
    (async () => {
      let res = await props.getBatchChildrenRequest(props.batchId);
      setChildrenData(res);
    })();
  }, []);

  const setThings = (child) => {
    setShowReport(true);
    setPresentId(child);
  };
  const childrenCards = childrenData?.map((child, index) => (
    <div className="children-card" key={index}>
      <div className="open-report" onClick={() => setThings(child)}>
        <button>Report</button>

        {showReport ? (
          <ReportModal
            {...props}
            child={presentId}
            visible={showReport}
            closeModal={(e) => {
              e.stopPropagation();
              setShowReport(false);
            }}
          />
        ) : null}
      </div>
      {user.user_data.role == "SUPER_ADMIN" ||
      user.user_data.role == "ADMIN" ? (
        <div
          className="delete-icon"
          onClick={async () => {
            let deletestudent =
              user.user_data.role == "SUPER_ADMIN" ||
              user.user_data.role == "ADMIN"
                ? (setShowModal(true),setDeleteChildId(child.id))//await props.deleteStudentFromBatch(props.batchId, child.id)
                : null;
            //let res = await props.getBatchChildrenRequest(props.batchId);
            //setChildrenData(res);
          }}
          style={{ cursor: "pointer" }}
        >
          <TrashCancel />
        </div>
      ) : null}
      <div className="d-flex row j-c-sb">
        <img
          className="listing-row-profile-pic"
          style={{ backgroundColor: "white" }}
          src={child.image ? child.image : DefaultUserIcon}
        />
      </div>
      <div className="row d-flex j-c-sb">
        <h2>{child.name}</h2>
        <div className="wow-pic">
          <img
            alt=""
            src={wowImage}
            style={
              child.freadom_child?.is_wow_student
                ? null
                : { visibility: "hidden" }
            }
          />
        </div>
      </div>
      <div className="row d-flex j-c-sb mt-5">
        <div className="row d-flex reverse a-i-fs mt-5">
          <p className="purple-text">
            {child.freadom_child?.freadom_point
              ? child.freadom_child?.freadom_point
              : 0}
          </p>
          <p>Freadom Points</p>
        </div>
        <div className="row d-flex reverse a-i-fs mt-5">
          <p className="purple-text">
            {child.freadom_child?.streak ? child.freadom_child?.streak : 0}
          </p>
          <p>Streak Count</p>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="children-cards-container">
      <div className="children-cards d-flex wrap">{childrenCards}</div>
      <Modal
          visible={show_modal}
          title="Select a Reason for deleting student"
          //onOk={this.handleOk}
         // onCancel={this.handleCancel}
          footer={[
            <Button key="back" className="action-dropdown-button" onClick={() =>{setShowModal(false);setReason('')}}
            >
              Back
            </Button>,
            <Button key="submit" disabled={reason==''}  type="primary" onClick={async()=>{
              await props.deleteStudentFromBatch(props.batchId, delete_child_id,reason);
              let res = await props.getBatchChildrenRequest(props.batchId);
            setChildrenData(res);
            setDeleteChildId('');
            setShowModal(false);setReason('');
            }} //loading={loading} 
            >
              Confirm
            </Button>,
          ]}
        >
          <div style={{ padding: 24 }}>
                <div className="update-modal-maindiv-heading">
                    <div className="update-modal-heading">
                    Select a Reason for Deleting student
                        </div>
                </div><br/>
<label>Reason :- </label>

                <Select value={reason} style={{ width: '100%',backgroundColor:'#f0f0e9' }} onChange={(value)=>{
                    setReason(value)
                }}
                placeholder={"Select Reason"}
                >
      <Option value="Batch Pause">Batch Pause</Option>
      <Option value="Refund">Refund</Option>
    </Select>
                        
            </div>
        </Modal>
    </div>
  );
};

export const ChildrenAttendingCards = connect(
  ({ batches, reports }) => ({ batches, reports }),
  (dispatch) =>
    bindActionCreators({ ...batchesActions, ...reportActions }, dispatch),
  null,
  { forwardRef: true }
)(ChildrenCards);

export default connect(
  ({ batches, reports }) => ({ batches, reports }),

  (dispatch) =>
    bindActionCreators(
      { ...batchesActions, ...reportActions },

      dispatch
    )
)(ChildrenModal);
