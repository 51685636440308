import { Api } from "./config/request";

export const getPricingPlans = () => Api.getExternalRequest('https://internals.freadom.in/api/external/active-pricing-plans');
export const getPromoDetails = (payload) => Api.postRequest('misc/v1/promo-code/', payload);
export const initiatePayment = (payload) => Api.postRequest('payment/v1/payweb/', payload);

export const Pricing = {
  getPricingPlans,
  getPromoDetails,
  initiatePayment
};
