import React, {Component} from 'react';
import {Input, Modal, Pagination, Table,Select,Button} from 'antd';
import './AddChildrenToBatchModal.scss';
import {CrossIcon} from 'assets/image/svg.js';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as batchesActions from "../../store/batches/actions";
import DefaultUserIcon from '../../assets/image/default-user.png';
import * as StudentActions from '../../store/student/actions';
import debounce from 'lodash/debounce'

const{Option} = Select;
const MAX_RESULTS_PER_PAGE = 15;

class AddChildrenToBatchModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alreadyPresentChildren: [],
            childToAdd: {},
            deleteMode: false,
            childrenData: [],
            total_count: MAX_RESULTS_PER_PAGE,
            numberOfPages: 1,
            activePageNumber: 1,
            filters: {
                offset: 0,
                page_size: MAX_RESULTS_PER_PAGE,
            },
            keyToSearch: '',
            reason:'',
            reason_Modal:false,
        }
        this.searchStudents = debounce(this.searchStudents, 400)
    }

    onModeChange(event) {
        this.setState({
            deleteMode: event.target.checked,
            childToAdd: {},
        })
        this.fetchStudents(event.target.checked);
    }

    async componentDidMount() {
        this.fetchStudents(this.state.deleteMode);
        let res = await this.props.getBatchChildrenRequest(this.props.batchId);
        this.setState({
            alreadyPresentChildren: res?.map(r => r.id)
        });
    }

    onSelectStudent(event) {
        this.setState({
            childToAdd: event.target.value
        });
    }

    async fetchStudents(deleteMode, urlParams = '') {
        if (deleteMode) {
            let res = await this.props.getBatchChildrenRequest(this.props.batchId);
            this.setState({
                childrenData: res.map((child, index) => ({
                    key: index + 1,
                    img: child.image || DefaultUserIcon,
                    name: child.name,
                    email: 'hello@grappus.com',
                    phoneNo: 919034567765,
                    add: 'add',
                    studentId: child.id,
                    onChangeListener: (event) => this.onSelectStudent(event),
                }))
            })
        } else {
            const newUrlParams = urlParams ? urlParams : (this.state.keyToSearch ? `name=${this.state.keyToSearch}` : '');
            let res = await this.props.getAdminStudentList(newUrlParams);
            this.setState({
                total_count: res?.data?.total_count,
                numberOfPages: Math.ceil(res?.data?.total_count / MAX_RESULTS_PER_PAGE),
                childrenData: res?.data?.children?.map((child, index) => ({
                    key: index + 1,
                    img: child.image || DefaultUserIcon,
                    name: child.name,
                    email: child.parent.email,
                    phoneNo: child.parent.phone_number,
                    add: 'add',
                    studentId: child.id,
                    onChangeListener: (event) => this.onSelectStudent(event),
                    // created_at: 1607668331
                    // crm_lsq_id: null
                    // details: {address: "23,Street delhi", city: " Anini ", father_details: {…}, id: 1, is_deleted: false, …}
                    // external_id: "168c9adb-80ba-46c7-9d4a-ee983068560f"
                    // grade: {id: 1, name: "Nursery "}
                    // id: 1
                    // image: "https://source.unsplash.com/random?person"
                    // is_deleted: false
                    // is_onboarded: true
                    // name: "Jon"
                    // parent: {created_at: 1607668331, email: "sajal@grappus.com", external_id: "ee2d1824-e2e0-4ce4-8e86-eb139326c52e", id: 3, name: "Jon's Parent", …}
                    // subscription: {id: 1, is_active: true, is_deleted: false, pack_details: {…}, preference_group: {…}, …}
                    // __proto__: Object
                }))
            });

        }
    }
//FLV-139
    searchStudents = (event) => {
        let params = '';
        if (isNaN(parseInt(this.state.keyToSearch))) {
           // filters.name = event?.target?.value
           // delete filters.phone
           params = `offset=${0}&page_size=${MAX_RESULTS_PER_PAGE}&name=${this.state.keyToSearch}`;
        } else {
           // filters.phone = event?.target?.value
           // delete filters.name
           params = `offset=${0}&page_size=${MAX_RESULTS_PER_PAGE}&phone=${this.state.keyToSearch}`;
        }
        this.fetchStudents(this.state.deleteMode, params)
    }

    onOk() {
        console.log("Add or delete data");
    }

    handlePageChange = async (page) => {
        if (page) {
            this.setState({
                filters: {
                    offset: page - 1
                }
            });
            this.fetchStudents(this.state.deleteMode, `offset=${page - 1}&page_size=${MAX_RESULTS_PER_PAGE}${this.state.keyToSearch ? '&name=' + this.state.keyToSearch : ''}`)
        }
    }


    render() {
        let {numberOfPages, childToAdd, total_count, filters: {page_size, offset},reason_Modal,reason} = this.state;

        console.log('cons', childToAdd)
        return (
            <>
            <Modal
                destroyOnClose={true}
                maskStyle={{
                    background: "rgba(0,0,0,0.45)"
                }}
                title="Add Students"
                width={804}
                wrapClassName="add-children-to-batch-modal"
                onCancel={this.props.onClose}
                onOk={async () => {
                    this.setState({reason_Modal:true,reason:''});
                    // await this.props.addStudentToBatch(this.props.batchId, this.state.childToAdd?.studentId);
                    // this.props.onOk();
                }}
                visible={this.props.visible}
                okText={`${this.state.deleteMode ? "Delete" : "Add"} Students`}
                closeIcon={<div className={'anticon'}><CrossIcon width={18} height={18} color={'#524E59'}/></div>}
            >
                <div className="teacher-listing-header">
                    <Input
                        disabled={this.state.deleteMode}
                        placeholder="Search for students"
                        onChange={(event) => {
                            this.setState({
                                keyToSearch: event.target.value,
                            }, 
                            //FLV-139
                            (event) => {
                                this.searchStudents(event)
                            })
                        }}
                        value={this.state.keyToSearch}
                    />
                </div>
                <Table
                    dataSource={this.state.childrenData.filter(cd => !this.state.alreadyPresentChildren?.includes(cd.studentId))}
                    columns={this.getTableColumns()}
                    pagination={false}
                    className="children-attending-table"
                    rowClassName={(record, index) => `child-row ${childToAdd?.studentId === record?.studentId ? 'selected' : ''}`}
                    onRow={(formData, rowIndex) => {
                        return {
                            onClick: event => {
                                console.log('selcted', formData)
                                this.setState({
                                    childToAdd: formData
                                });
                            }
                        };
                    }}
                >

                </Table>
                {numberOfPages > 1 && !this.state.deleteMode ?
                    <Pagination
                        current={offset + 1}
                        defaultCurrent={1}
                        total={total_count}
                        hideOnSinglePage
                        onChange={page => this.handlePageChange(page)}
                        defaultPageSize={page_size}
                    /> : null
                }
            </Modal>
            <Modal
          visible={reason_Modal}
          title="Select a Reason for Adding student"
          footer={[
            <Button key="back" className="action-dropdown-button" onClick={() =>{
                this.setState({reason_Modal:false,reason:''});
            }}
            >
              Back
            </Button>,
            <Button key="submit" disabled={reason==''}  type="primary" onClick={async ()=>{
                await this.props.addStudentToBatch(this.props.batchId, this.state.childToAdd?.studentId,reason);
             this.props.onOk();
             this.setState({reason_Modal:false,reason:''});
            }}
            >
              Confirm
            </Button>,
          ]}
        >
          <div style={{ padding: 24 }}>
                <div className="update-modal-maindiv-heading">
                    <div className="update-modal-heading">
                    Select a Reason for Adding student
                        </div>
                </div><br/>
<label>Reason :- </label>

                <Select value={reason} style={{ width: '100%',backgroundColor:'#f0f0e9' }} onChange={(value)=>{
                    this.setState({reason:value});
                }}
                placeholder={"Select Reason"}
                >
      <Option value="Batch Change">Batch Change</Option>
    </Select>
                        
            </div>
        </Modal>
            </>
        );
    }

    getTableColumns() {
        return [
            {
                title: '',
                dataIndex: 'img',
                key: 'img',
                width: "80px",
                render: (_, record, index) => {
                    console.log(record, "RECORD")
                    return <img src={record?.img}/>
                }
            },
            {
                title: 'NAME',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'EMAIL',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'PHONE NO.',
                dataIndex: 'phoneNo',
                key: 'phoneNo',
            }
        ];
    }
}

export default connect(
    ({batches, student}) => ({batches, student}),
    dispatch => bindActionCreators({...batchesActions, ...StudentActions}, dispatch)
)(AddChildrenToBatchModal);
