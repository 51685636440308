import { Api } from "./config/request";

export const addNewTeacher = (payload) => Api.postRequest("teachers/", payload);

export const getTeachers = (params) =>
  Api.getRequest_with_Axios(`teachers/?${params}`);

// export const getTeachers = (params) => Api.getRequest(`teachers/?${params}`);

export const getAllTeachers = (params) => Api.getRequest(`teachers/?${params}`);

export const getZoomID = () => Api.getRequest(`class_links`);

export const getZoomIDwithParams = (params) =>
  Api.getRequest(`class_links/?${params}`);

export const getGrade = (params) => Api.getRequest(`grades/?${params}`);

export const getRecommendedTeachersRequest = (batchId) =>
  Api.getRequest(`batches/recommended-teacher/?batch_id=${batchId}`);

export const getPresignedUrlRequest = (payload) =>
  Api.postRequest(`misc/get-presigned-url`, payload);

export const updateTeacherData = (id, payload) =>
  Api.putRequest(`teachers/${id}`, payload);

export const updateTeacherStatus = (id, payload) =>
  Api.postRequest(`teachers/${id}/status`, payload);

export const forgotPassword = (payload) =>
  Api.postRequest("teachers/forgot-password", payload);

export const uploadPictureRequest = (url, payload) =>
  Api.putRequest(url, {}, null, true);

export const updatePassword = (payload, headers) =>
  Api.postRequest("users/update-password", payload, headers);

export const TeacherApiCalls = {
  addNewTeacher,
  getTeachers,
  getRecommendedTeachersRequest,
  updateTeacherData,
  updateTeacherStatus,
  forgotPassword,
  updatePassword,
  getPresignedUrlRequest,
  uploadPictureRequest,
};
