import {mutation} from "./mutations";
import {Subscription} from "../../services/subscription";

const handleError = err => {
    console.log("Error in Login action : ", err);
    return err.response || err
};

export const createSubscriptionPackRequest = (payload) => async dispatch => {
    try {
        let response = await Subscription.createSubscription(payload)
        if (response) {
            return response.data || response;
        }
        return undefined
    } catch (err) {
        return handleError(err);
    }
}

export const updateSubscriptionPack = (id, payload) => async dispatch => {
    try {
        const result = await Subscription.updateSubscriptionPackRequest(id, payload);
        if (result)
            return result
        return undefined;
    } catch (err) {
        handleError(err);
    }
};
export const getSubscriptionPackListRequest = () => async dispatch => {
    try {
        let response = await Subscription.getSubscriptionList()
        if (response) {
            dispatch(mutation.setSubscriptionPack(response.data));
            return response.data || response;
        }
        return undefined
    } catch (err) {
        return handleError(err);
    }
}

export const createSubscriptionForChild = (payload) => async dispatch => {
    try {
        const response = await Subscription.createSubscriptionForChild(payload);
    } catch (err) {
        return handleError(err);
    }
}

export const getSubscriptionForChild = (childID) => async dispatch => {
    try {
        const response = await Subscription.getSubscriptionForChild(childID);
        dispatch(mutation.setSubscriptionInfoForChild(response.data))
        return response.data;
    } catch (err) {
        return handleError(err);
    }
}

export const addPackForChild = (payload) => async dispatch => {
    try {
        const response = await Subscription.addPackForChild(payload);
        if (response)
            return response;
        return undefined
    } catch (err) {
        return handleError(err);
    }
}
