import React from "react";

import MagicHatIcon from "assets/image/magic-hat-icon.svg";

const CapAnimation = props => (
  <div
    className={
      "bottom-section" + (props.clicked ? " bottom-to-center" : "")
    }
  >
    <div className="circle-container">
      <div className="circle first" />
      <div className="circle second" />
      <div className="circle third" />
      <div className="circle fourth" />
    </div>
    <img
      src={MagicHatIcon}
      alt="Magic Hat Icon"
      onClick={props.startMagic}
    />
    {!props.clicked && <span className="magic-text">Do the magic</span>}
  </div>
);

export default CapAnimation;
