import React,{Component} from 'react';
import editIcon from  '../../../../assets/image/icons/edit-icon.svg'

const demoProfileURL = "https://st3.depositphotos.com/14846838/19463/v/1600/depositphotos_194639100-stock-illustration-flat-icon-profile-female-student.jpg"

export const ProfilePicCell = ({ data }) => (
    <figure className="listing-row-profile-pic" style={{ backgroundImage: `url(${data.pic ? data.pic : demoProfileURL})` }}></figure>
);
  
export const TextCell = ({ data }) => (
<div>
    <p className="listing-row-primary-text">{data.text}</p>
</div>
);

export class StatusCell extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isActive: props.data ? !!(props.data.status) : false
      };
    };
  
    toggleStatus = (isActive) => {
      this.setState({
        isActive: isActive !== undefined ? isActive : !this.state.isActive
      });
    }
  
    onStatusUpdate = (e) => {
      /*e.stopPropagation();
      this.props.updateTeacherStatus(this.props.data.id, !this.state.isActive);*/
      this.toggleStatus(!this.state.isActive);
    }
  
    render() {
      const className = this.state.isActive ? 'listing-row-status listing-row-status--active' : 'listing-row-status';
      return (
        <div onClick={this.onStatusUpdate}>
          <span className={className}></span>
        </div>
      );
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps.data !== this.props.data) {
        this.toggleStatus(this.props.data.status);
      }
    }
  };