import React, {Component} from "react";
import {Button, Pagination, Table} from 'antd';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {storage as LocalStorage} from "services/config/storage";
import * as subscriptionActions from "store/subscriptions/actions";
import AdminLayout from 'layout/admin';
import "./index.scss";
import {Grades, NumberCell, PlanLabel, StatusCell, TextCol} from "./rowCells";

class SubscriptionListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionPacksList: [],
            filters: {
                page_size: 15
            }
        }
    }

    async componentDidMount() {
        if (!LocalStorage.fetch.authToken()) {
            return this.props.history.push("/");
        }
        this.setState({loading: true})
        let res = await this.props.getSubscriptionPackListRequest()
        if (res)
            this.setState({subscriptionPacksList: res})
        this.setState({loading: false})
    }

    renderHeader = () => (
        <div className="subscription-listing-header">
            <Button type="primary" className="ant-btn-md export-btn"
                    onClick={() => this.props.history.push('/addSubscription')}>ADD NEW</Button>
        </div>
    )

    renderLevels = (item) => {
        if (item.num_levels)
            return `${item.num_levels} ${item.num_levels > 1 ? ' levels' : ' level'}`
        return item.levels_in_pack.map(level => level.name).join(', ')
    }

    getTheads = () => (
        [
            {
                title: 'Plan Name & ID',
                render: rowData => <PlanLabel data={[rowData.label, rowData.plan_id]}/>
            },
            {
                title: 'Grade',
                render: rowData => {
                    return <Grades data={rowData.grades}/>
                }
            },
            {
                title: 'Description',
                render: rowData => <TextCol data={rowData?.description}/>
            },
            {
                title: 'Sessions',
                align: 'center',
                render: rowData => <NumberCell data={rowData?.total_sessions}/>
            },
            {
                title: 'MRP',
                align: 'center',
                render: rowData => <NumberCell data={rowData?.pack_billing_cycles?.[0]?.total_mrp}/>
            }, {
            title: 'discounted price',
            align: 'center',
            render: rowData => <NumberCell data={rowData?.pack_billing_cycles?.[0]?.total_amount}/>
        },
            {
                title: '',
                align: 'center',
                render: rowData => <StatusCell data={rowData}
                                               updateTeacherStatus={(id, status) => {
                                                   let payload = {data: {is_active: status}}
                                                   this.props.updateSubscriptionPack(id, payload)
                                               }}/>
            }
        ]
    );


    render() {
        const {total_count, filters: {page_size}, numberOfPages} = this.state;

        return [
            <AdminLayout title="Subscriptions" rightContent={this.renderHeader()}>
                <section className="subscription-card-section">
                    <Table
                        columns={this.getTheads()}
                        dataSource={this.state.subscriptionPacksList}
                        loading={this.state.loading}
                        pagination={false}
                        rowClassName={(record, index) => ['subscription-row', record.is_deleted ? 'disable' : ''].join(' ')}
                        onRow={(formData, rowIndex) => {
                            return {
                                onClick: event => {
                                    !formData.is_deleted && this.props.history.push({
                                        pathname: '/updateSubscription',
                                        search: `id=${formData.id}`,
                                        state: {formData, id: formData.id}
                                    })
                                }
                            };
                        }}
                    />
                    {numberOfPages > 1 ?
                        <Pagination
                            total={total_count}
                            hideOnSinglePage
                            onChange={page => this.handlePageChange(page)}
                            defaultPageSize={page_size}
                        /> : null
                    }
                </section>
            </AdminLayout>
        ];
    }
}

export default connect(
    ({teacher}) => ({...teacher}),
    dispatch => bindActionCreators({...subscriptionActions}, dispatch)
)(SubscriptionListing);
