import { Api } from './config/request';
//FLV-170_2
//const getPreferenceGroups = (params) => (Api.getRequest(`preference_groups/?${params}`));
const getPreferenceGroups = (params) => (Api.getRequest_with_Axios(`preference_groups/?${params}`));
//FLV-170_2
const getPreferenceGroups_get_pg = (ids) => (Api.getRequest_with_Axios(`preference_groups/get_pg_for_student/${ids}`));
const getPreferenceDetailRequest = (id) => (Api.getRequest_with_Axios(`preference_groups/${id}`));
const createPreferenceGroupRequest = (payload) => (Api.postRequest(`preference_groups/`, payload));
const updatePreferenceGroupRequest = (id, payload) => (Api.putRequest(`preference_groups/${id}`, payload));
const deletePreferenceGroupRequest = (payload) => (Api.deleteRequest(`preference_groups/remove`, payload));
const assignPreferenceGroupRequest = (payload) => (Api.postRequest(`preference_groups/assign`, payload));
const assignPreferenceGroupInStudent = (payload) => (Api.postRequest(`preference_groups/v2/assign`, payload));
const updateMultiplePreferenceGroup = (payload) => (Api.postRequest(`preference_groups/update-multiple`, payload));

export const PreferenceGroupApiCalls = {
    getPreferenceGroups_get_pg,
    getPreferenceGroups,
    getPreferenceDetailRequest,
    createPreferenceGroupRequest,
    updatePreferenceGroupRequest,
    deletePreferenceGroupRequest,
    assignPreferenceGroupRequest,
    assignPreferenceGroupInStudent,
    updateMultiplePreferenceGroup
}
