import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import './assets/stylesheet/global.scss'
import AppRouter from './routes';
import store from './store';
import * as serviceWorker from './serviceWorker';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

moment.updateLocale('en', {
    meridiem: function (hour, minute, isLowerCase) {
        if (hour < 12) {
            return 'A.M.';
        } else {
            return 'P.M.';
        }
    }
});

function mainRender() {
    ReactDOM.render(
        <Provider store={store}>
            <AppRouter/>
            <ToastContainer autoClose={2300} bodyClassName={'toast-body'}/>
        </Provider>,
        document.getElementById('root'))
};

// Adding the rended to setTimeout with zero millisec to tackle FOUC
if (process.env.NODE_ENV !== "production") {
    // Workaround for https://github.com/facebook/create-react-app/issues/6399
    // until it gets fixed upstream
    setTimeout(() => {
        mainRender();
    }, 0);
} else {
    mainRender();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
