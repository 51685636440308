import { mutation } from "./mutations";
import { Classes } from "../../services/classes";

const handleError = (err) => {
  console.log("Error in Login action : ", err);
  return err.response || err;
};

// Actions
export const getClassAttendanceRequest = (classId) => async (dispatch) => {
  try {
    const response = await Classes.getAttendance(classId);
    dispatch(mutation.setClassAttendance(response.data));
    return response;
  } catch (err) {
    return handleError(err);
  }
};

export const markClassAttendanceRequest =
  (classId, payload) => async (dispatch) => {
    try {
      const result = await Classes.markAttendance(classId, payload);
      return result;
    } catch (err) {
      return handleError(err);
    }
  };
export const setZoomLinkRequest = (classId, payload) => async (dispatch) => {
  try {
    const result = await Classes.setZoomLink(classId, payload);
    if (result) return result;
    return undefined;
  } catch (err) {
    return handleError(err);
  }
};
export const sendHomeworkRequest = (classId, payload) => async (dispatch) => {
  try {
    const result = await Classes.sendHomework(classId, payload);
    return result;
  } catch (err) {
    return handleError(err);
  }
};

export const getClassDetailsRequest = (sessionId) => async (dispatch) => {
  try {
    let response = await Classes.getStudentSessionDetails(sessionId);
    return response;
  } catch (err) {
    return handleError(err);
  }
};
export const getFeedbackQuestions = (gradeId) => async (dispatch) => {
  try {
    let response = await Classes.getFeedbackQuestionsReq(gradeId);
    return response;
  } catch (err) {
    return handleError(err);
  }
};
export const setTeacherForSession =
  (classId, teacherId) => async (dispatch) => {
    try {
      let response = await Classes.setTeacherForSessionReq(classId, teacherId);
      return response;
    } catch (err) {
      return handleError(err);
    }
  };
export const getFeedbackResults = (childId) => async (dispatch) => {
  try {
    let response = await Classes.getFeedbackAnswersReq(childId);
    return response;
  } catch (err) {
    return handleError(err);
  }
};
export const submitFeedback = (payload) => async (dispatch) => {
  try {
    const result = await Classes.submitFeedbackReq(payload);
    if (result) return result;
    return undefined;
  } catch (err) {
    return handleError(err);
  }
};

export const updateSessionTime = (payload) => async (dispatch) => {
  try {
    const result = await Classes.submitSessionTime(payload);
    if (result) return result;
    return undefined;
  } catch (err) {
    return handleError(err);
  }
};
