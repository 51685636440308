import React, {Component} from "react";

// Redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as teacherActions from "store/teacher/actions";
import {adminLoginRequest, teacherLogin} from "store/user/actions";

// Components
import Login from './login';
import ForgotPassword from './forgotPassword';
import ResetPassword from './resetPassword';
import SetupPassword from './setupPassword';
import TeacherSetPassword from './teacherSetPassword';

// Local Storage
import {storage as LocalStorage} from "services/config/storage";

// Icons & Images
import AdminLoginBanner from 'assets/image/admin-login-banner.png';
import LoginBanner from 'assets/image/login-banner.png';
// SCSS
import "./index.scss";
import {USER_ROLES} from "../../../utils/constants";

const FORMS = {
    LOGIN: 'teacher-login',
    RESET_PASSWORD: 'reset-password',
    SETUP_PASSWORD: 'update-password',
    FORGOT_PASSWORD: 'forgot-password',
    TEACHER_SET_PASSWORD: 'teacher-set-password',
}

class AdminLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeFormName: FORMS.LOGIN
        }
    };

    componentDidMount() {
        if (LocalStorage.fetch.authToken() && !(this.props.match.url == `/${FORMS.TEACHER_SET_PASSWORD}`)) {
            if (LocalStorage.fetch.userData()) {
                if (LocalStorage.fetch.userData().role === USER_ROLES.SUPER_ADMIN || LocalStorage.fetch.userData().role === USER_ROLES.ADMIN) {
                    this.props.history.push("/teacherListing");
                } else {
                    this.props.history.push("/batchesListing");
                }
            } else {
                this.props.history.push("/teacherListing");
            }
            return;
        }
        const {search, pathname} = this.props.history.location;
        let searchParams = search && search.length ? search.substring(1) : '';
        searchParams = new URLSearchParams(searchParams);
        const auth = searchParams.get('auth');
        this.setState({
            activeFormName: pathname && pathname.length > 1 ? pathname.substring(1) : FORMS.LOGIN,
            auth
        });
    }

    render() {
        const {
            teacherLogin,
            forgotPassword,
            updatePassword,
            adminLoginRequest
        } = this.props;

        const {
            activeFormName,
            auth
        } = this.state;
        let banner = AdminLoginBanner;
        if (this.props.history.location.pathname.includes('teacher'))
            banner = LoginBanner
        return [
            <div className="admin-login-container">
                {activeFormName === FORMS.LOGIN ?
                    <Login
                        adminLoginHandler={adminLoginRequest}
                        teacherLoginHandler={teacherLogin}
                        onForgotPassword={() => this.setState({activeFormName: FORMS.FORGOT_PASSWORD})}
                        history={this.props.history}
                    /> : null
                }
                {activeFormName === FORMS.FORGOT_PASSWORD ?
                    <ForgotPassword
                        formSubmit={forgotPassword}
                    /> : null
                }
                {activeFormName === FORMS.RESET_PASSWORD ?
                    <ResetPassword
                        formSubmit={updatePassword}
                        auth={auth}
                    /> : null
                }
                {activeFormName === FORMS.SETUP_PASSWORD ?
                    <SetupPassword
                        formSubmit={updatePassword}
                        auth={auth}
                    /> : null
                }
                {activeFormName === FORMS.TEACHER_SET_PASSWORD ?
                    <TeacherSetPassword
                        formSubmit={updatePassword}
                        auth={auth}
                        onLogin={() => (this.setState({activeFormName: FORMS.LOGIN}))}
                    /> : null
                }
                <div className="admin-login-right">
                    <img src={banner} className="admin-login-banner" alt="Login"/>
                </div>
            </div>
        ];
    }
}

export default connect(
    ({teacher}) => ({...teacher}),
    dispatch => bindActionCreators({...teacherActions, teacherLogin, adminLoginRequest}, dispatch)
)(AdminLogin);
