import { Api } from "./config/request";

export const userLogin = payload => {
  return Api.postRequest("user/v1/login/", payload);
};

export const getChildrenList = () => Api.getListRequest('user/v1/child/');

export const selectChild = payload => Api.postRequest('user/v1/child-selector/', payload);

export const otpSend = payload => Api.postRequest('user/v1/otp/', payload);

export const verifyOtp = payload => Api.postRequest('token/', {
  "data": {
    "requester_type": "user",
    "attributes": {
      "phone_number": "+911111111119",
      "otp": "4567"
    }
  }
});

export const getUserDetail = () => Api.getRequest('user/v1/');

export const getTokenWithEuroData = (payload, euroHeader) => Api.postRequest('user/v1/euro-login/', payload, euroHeader)

export const teacherLogin = payload => Api.postRequest('token/teacher', payload);

export const adminLogin = payload => Api.postRequest('token/admin', payload);

export const User = {
  userLogin,
  getChildrenList,
  selectChild,
  otpSend,
  verifyOtp,
  getUserDetail,
  getTokenWithEuroData,
  teacherLogin,
  adminLogin
};
