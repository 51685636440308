import React, { Component } from "react";
import { Button, Modal, Icon } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cn from "classnames";
import * as storyActions from 'store/story/actions'

import Quiz from "components/Quiz";
import MediaViewer from "components/MediaViewer";
import MusicImg from "assets/image/album-music.svg";
import ReadStory from "../../assets/image/read-story.png";
import WatchStory from "../../assets/image/video-story.png";
import BookmarkActive from 'assets/image/bookmark-active.svg';
import BookmarkInActive from 'assets/image/bookmark-inactive.svg';
import CloseBlackIcon from 'assets/image/close-black.svg';
import "./bookSummary.scss";

import { Mixpanel } from '../../Mixpanel';

const READ = 'read';
const LISTEN = 'listen';
const WATCH = 'watch';
const NOT_STARTED = 'not_started';
const ON_GOING = 'on_going'
const COMPLETED = 'completed'

class bookSummary extends Component {
  constructor(props){
    super(props);
    this.state = {
      quizOpened: false,
      mediaOpen: false,
      isLiked: false,
      storyData: {}
    }
  }

  lastQuestionAnswered = data => {
    this.setState({quizOpened: false});
    this.props.openSuccessModal(data);
  };

  bookmark = async e => {
    e.stopPropagation();
    if(this.props.data.is_liked) {
      Mixpanel.track('Remove Story from library', this.props.data)
    } else {
      Mixpanel.track('Add story to library', this.props.data)
    }
    const { bookmarkStory, data } = this.props;
    const res = await bookmarkStory(data);
    if (res.success) {
      this.setState(prevState => ({isLiked: !prevState.isLiked}))
    }
  };

  componentDidMount() {
    const { data } = this.props
    this.setState({isLiked: data.is_liked, storyData: data})
  }

  handleStoryReading = async () => {
    const { storyData } = this.state;
    if (storyData.status === NOT_STARTED) {
      Mixpanel.track('Start Reading', this.props.data)
      const { result } = await this.props.startStoryRequest(storyData)
      this.setState(prevState => ({
        storyData: {
          ...prevState.storyData,
          status: result.status,
        }
      }));
    } else if (storyData.status === ON_GOING) {
      Mixpanel.track('Continue Reading', this.props.data)
    } else if (storyData.status === COMPLETED) {
    }
    if(this.props.data.section_data && this.props.data.section_data.length > 0) {
      this.setState({mediaOpen: true});
    } else {
      alert('Invalid media...')
    }
  }

  getStoryReadingText = () => {
    const { storyData } = this.state;
    if (storyData.status === NOT_STARTED) {
      return 'Start Reading';
    } else if (storyData.status === ON_GOING) {
      return 'Continue Reading';
    } else if  (storyData.status === COMPLETED) {
      return 'Read Again';
    } 
    return ''
  }

  finishQuiz = async () => {
    Mixpanel.track('Finish Reading', this.props.data)
    const { storyData } = this.state;
    const { data } = this.props;
    const res = await this.props.finishStoryRequest(storyData.id);
    if (data.has_mcq) {
      this.setState({quizOpened: true});
      Mixpanel.track('QA-After Finish Story', this.props.data)
    } else {
      this.setState(prevState => {
        return {
          storyData: {
            ...prevState.storyData,
            status: res.status
          }
        }
      })
      // Add code to show awesome screen here.
      this.props.openSuccessModal(res.result ? res.result : (res.data ? res.data : res));
    }
  }

  render() {
    const { data, questions } = this.props;
    const { quizOpened, isLiked, storyData } = this.state;
    return (
      <div className={cn("component-summary", {'quiz-active': quizOpened})}>
        {/* <Icon className="close-icon" type="close" /> */}
        <img src={CloseBlackIcon} className="close-icon" alt="close" onClick={this.props.close} />
        <div className="flex left">
          <img
            src={data.background_image}
            alt="book-coverImg"
            className="bg-image"
          />
          <div className="mobile-back" onClick={this.props.close}>
            <Icon type="left" />
            <span style={{textShadow: '0px 2px 3px #333'}}>Back</span>
          </div>
          <div className="mobile-bookmark">
            <img
              src={isLiked ? BookmarkActive : BookmarkInActive}
              alt="bookmark"
              onClick={this.bookmark}
              style={{margin: '16px', cursor: 'pointer'}}
            />
          </div>
          <div className="story-card">
            <div className="name">{data.name}</div>
            <div className="author-name">Written by <b>{data.author_name}</b></div>
          </div>
        </div>
        <div className="division" />
        <div className="flex description-card">
        {quizOpened === false && <React.Fragment>
          <div>
            <img
              src={isLiked ? BookmarkActive : BookmarkInActive}
              alt="bookmark"
              onClick={this.bookmark}
              style={{margin: '16px', cursor: 'pointer'}}
              className="bookmark-icon"
            />
            <div className="flex summary-card">
              <span className="title">DESCRIPTION</span>
              <div className="summary">{data.description}</div>
            </div>
            {data.story_type === LISTEN && <div className="audioBook">
              <img src={MusicImg} alt="album-music" style={{ marginRight: "5px" }} />
              <span className="text">Audiobook</span>
            </div>}
            {data.story_type === READ && <div className="audioBook">
              <img src={ReadStory} alt="audio" style={{ marginRight: "5px" }} />
              <span className="text">Read</span>
            </div>}
            {data.story_type === WATCH && <div className="audioBook">
              <img src={WatchStory} alt="video" style={{ marginRight: "5px" }} />
              <span className="text">Read</span>
            </div>}
          </div>
          <div style={{ padding: "15px", display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              className="primary-btn"
              size="large"
              onClick={this.handleStoryReading}>
              { this.getStoryReadingText() }
            </Button>
            {storyData.status === ON_GOING && <Button
              type="primary"
              className="primary-btn"
              size="large"
              onClick={this.finishQuiz}
            >
              Finish Story
            </Button>}
            {data.has_mcq && (data.status === COMPLETED) && <Button
              type="primary"
              className="primary-btn"
              size="large"
              onClick={() => this.setState({quizOpened: true})}
              style={{marginLeft: '16px'}}
            >
              {data.is_quiz_taken ? 'Review Quiz' : 'Start Quiz'}
            </Button>
          }
          </div>
          </React.Fragment>}
          {quizOpened && (
            <Quiz
              questions={questions}
              finalAnswer={this.lastQuestionAnswered}
              closeQuiz={()=>this.setState({quizOpened: false})}
              isStory={ true }
            />
          )}
        </div>
        <Modal
            centered
            width="100vw"
            className="media-container"
            visible={this.state.mediaOpen && data.section_data && data.section_data.length > 0}
            onCancel={() => this.setState({mediaOpen: false})}
            footer={null}
            key={1}
            destroyOnClose
            closable={false}
          >
            <MediaViewer data={data.section_data} closeMedia={() => this.setState({mediaOpen: false})} />
          </Modal>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({...storyActions}, dispatch)
)(bookSummary);

