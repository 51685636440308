import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Button, Radio,Spin,Modal,Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import * as lmsActions from "store/lms/actions";
import "./index.scss";
import * as preferenceGroupActions from '../../../../store/preferenceGroups/actions';
import { PreferenceGroupApiCalls } from '../../../../services/preferenceGroups'
import { convertSecondsToTime, generateFilterUrl } from "../../../../utils/commonUtils";
import moment from 'moment';
import { toast } from 'react-toastify';

// const slots = [
//     { id: 1, days: 'M T H', time: '4:30P.M.', date: '12/03/2021', users: '6 Users' },
//     { id: 2, days: 'S T H', time: '6:30P.M.', date: '13/03/2021', users: '8 Users' },
//     { id: 3, days: 'M F H', time: '7:30P.M.', date: '17/03/2021', users: '9 Users' }
// ]
const weekDays = {
    1: "M",
    2: "T",
    3: "W",
    4: "Th",
    5: "F",
    6: "Sa",
    7: "Su",
};
class ChoseSlotModal extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedValue: null,
            buttonFlag: true,
            error: null,
            slots:[],
            reason_popup:false,
            reason:'',
        }
    };
    async componentDidMount() {
        await this.studentwiseslot(this.props.selectedStudentId)
    }
    async componentDidUpdate(prevProps) {
        if(this.props.selectedStudentId!= prevProps.selectedStudentId)
        await this.studentwiseslot(this.props.selectedStudentId)
    }
    onChange = (e) => {
        this.setState({ selectedValue: e.target.value, buttonFlag: false, error: null })
    }
    getAllDays = (timeSlots) => {
        let days = timeSlots.map((schedule) => (
            <span className={"weekday"}>{weekDays[schedule.weekday]}</span>
        ));

        return <p className={"listing-row-primary-text"}>{days}</p>;
    }
    
    studentwiseslot = async (ids) => {
        this.setState({ loading:true });
        let res = await PreferenceGroupApiCalls.getPreferenceGroups_get_pg(ids);
        let slots = [];
        if (res) {
            //console.log(res);
            res.data.length>0 && res.data.forEach(pg => {
                slots.push({
                    id: pg.group_id,
                    days: this.getAllDays(pg.timeslots),
                    time: convertSecondsToTime(pg.timeslots?.[0]?.start_second,true,true),
                    date: pg.start_time ? moment(pg.start_time * 1000).format("DD/MM/YYYY") : '-',
                    users: pg.students_opted + ' Users',
                    startTopic: pg.start_topic,
                    //FLV-161
                    product_type:pg?.product_type
                    //FLV-161
                })
            });
            //return slots;
            //FLV-169
            // this.setState({ slots })
        }
        this.setState({ slots, loading:false })
        //FLV-169
    }
    addButtonClick = async () => {
        const { selectedValue,reason } = this.state;
        const { selectedStudentId } = this.props;
        let res = null;
        let obj = {
            "data": {
                child_id: selectedStudentId,
                preference_group_id: selectedValue,
                reason
            }
        }
        res = await PreferenceGroupApiCalls.assignPreferenceGroupInStudent(obj);
        if (res?.data.success) {
            this.setState({ error: null,reason_popup:false,reason:'', })
            toast.success('Preference group successfully added!');
            this.props.closeModal();
            await this.studentwiseslot(this.props.selectedStudentId);
        } else {
            this.setState({ error: "Child already has a preference selected for current subscription plan" })
        }

    }

    render() {
        const { selectedValue, buttonFlag,slots,loading,reason_popup,reason } = this.state;
        const { Option } = Select;

        //this.studentwiseslot(this.props.selectedStudentId);
        return (
            <div style={{ padding: 24 }}>
                <div className="update-modal-maindiv-heading">
                    <div className="update-modal-heading">
                        Chose Slot
                        </div>
                    <CloseCircleOutlined style={{ fontSize: 20 }} onClick={() => {
                        this.setState({ error: null, selectedValue: null, buttonFlag: true });
                        this.props.closeModal();
                    }
                    } />
                </div>
                <div className="body-content-div">
                            
                    <Radio.Group onChange={this.onChange} value={selectedValue}>
                    {/* FLV-169 */}
                    {loading ?<Spin/>//}
                   //{/* FLV-169 */}
                        :<>
                        {
                            slots.length>0 ?
                        <>
                        {/* FLV-161 */}
                        <div className="radio-inner-div" 
                            style={{
                                padding:'12px 10px',
                                //marginRight:'8px'
                            }}>
                                <span className="radio-span-day-text"
                                style={{marginLeft:'16px'}}
                                >Days</span>
                                <span className="radio-span-text">Time</span>
                                <span className="radio-span-text">Date</span>
                                <span className="radio-span-text">Start Topic</span>
                                <span className="radio-span-text">Users</span>
                                <span className="radio-span-text">Product Type</span>
                            </div>
                        {/* FLV-161 */}
                        <div className="radio-main-div" >
                             {slots.map((slot) => (
                                <div key="slot.id" className={slot.id != selectedValue ? "radio-div" : "radio-div radio-div-border "}>
                                    <Radio value={slot.id} >
                                        <div className="radio-inner-div">
                                            <span className="radio-span-day-text">{slot.days}</span>
                                            <span className="radio-span-text">{slot.time}</span>
                                            <span className="radio-span-text">{slot.date}</span>
                                            <span className="radio-span-text">{slot.startTopic || '-'}</span>
                                            <span className="radio-span-text">{slot.users}</span>
                                            <span className="radio-span-text">{slot.product_type}</span>
                                        </div>
                                    </Radio>
                                </div>
                            ))}
                        </div>
                        </>
                            :<h1 style={{width:'100%'}}>No Slots Available</h1>}
                            </>}
                    </Radio.Group>
                </div>
                <div className="error-button-div">
                    <Button disabled={buttonFlag} loading={this.state.loading}
                        type="primary" className="ant-btn-md export-btn"
                        onClick={() =>{this.setState({reason_popup:true,reason:''})} //this.addButtonClick()
                        }
                    >Add</Button>
                    <div className="error-div ">
                        {this.state.error}
                    </div>
                </div>
                <Modal
          visible={reason_popup}
          title="Select a Reason for adding student"
          //onOk={this.handleOk}
         // onCancel={this.handleCancel}
          footer={[
            <Button key="back" className="action-dropdown-button" onClick={() =>{this.setState({reason_popup:false})}}
            >
              Back
            </Button>,
            <Button key="submit" disabled={reason==''}  type="primary" loading={loading} onClick={()=>this.addButtonClick()}
            >
              Confirm
            </Button>,
          ]}
        >
          <div style={{ padding: 24 }}>
                <div className="update-modal-maindiv-heading">
                    <div className="update-modal-heading">
                    Select a Reason for adding student
                        </div>
                </div><br/>
<label>Reason :- </label>

                <Select value={reason} style={{ width: '100%',backgroundColor:'#f0f0e9' }} onChange={(value)=>{
                    this.setState({reason:value})
                }}
                placeholder={"Select Reason"}
                >
      <Option value="New User">New User</Option>
      <Option value="Renewal">Renewal</Option>
      <Option value="Level Transition">Level Transition</Option>
    </Select>
                        
            </div>
        </Modal>
            </div>
        )
    }
}

export default connect(
    ({ teacher, lms, preferenceGroups }) => ({ ...teacher, ...lms, ...preferenceGroups }),
    dispatch => bindActionCreators({ ...lmsActions, ...preferenceGroupActions }, dispatch)
)(Form.create({ name: 'chose-modal' })(ChoseSlotModal))
