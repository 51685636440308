import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Pagination,
  Table,
  Input,
  DatePicker,
  TimePicker,
  Menu,
  Dropdown,
  message,
  Modal,
  Select,
} from "antd";
import debounce from "lodash/debounce";
import { storage as LocalStorage } from "services/config/storage";
import * as lmsActions from "store/lms/actions";
import * as preferenceGroupActions from "../../../../store/preferenceGroups/actions";
import AdminLayout from "layout/admin";
import {
  Duration,
  NumberCell,
  Schedule,
  StatusCell,
  Tag,
  TimeCell,
  TeacherCell,
  ZoomCell,
  GradesCell,
  StartTopicCell,
} from "./rowCells";
import "./index.scss";
import {
  convertSecondsToTime,
  generateFilterUrl,
} from "../../../../utils/commonUtils";
import "antd/dist/antd.css";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";
import { getTeachers,getZoomIDwithParams } from '../../../../services/teacher'
import UpdateModal from "./updateModal";
import MixedFilter from "./Filters/MixedFilter";
import { toast } from "react-toastify";

const MIN_CHARS_TO_SEARCH = 2;
const TYPING_TIMEOUT = 500;
const MAX_RESULTS_PER_PAGE = 10;
const { SubMenu } = Menu;
const { Option, OptGroup } = Select;
const rowSelection = {
  // onChange: (selectedRowKeys, selectedRows) => {
  //   console.log(
  //     `selectedRowKeys: ${selectedRowKeys}`,
  //     "selectedRows: ",
  //     selectedRows
  //   );
  // },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

class PreferenceListing extends Component {
  debounceSearch;

  addZero = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        offset: 0,
        page_size: MAX_RESULTS_PER_PAGE,
      },
      current_page: 1,
      total_count: MAX_RESULTS_PER_PAGE,
      numberOfPages: 1,
      activePageNumber: 1,
      type: "",
      sortedInfo: null,
      selectedRowKeys: [],
      selectedRows: [],
      selectedRowsId: [],
      status: "Pending",
      notes: "-",
      //FLV-156
      Teacher_display: false,
      Teacher_options: [],
      Teacher_search: "",
      teacher_id:"",
      //FLV-156
      //FLV-174
      Class_Link_display: false,
      Class_Link_options: [],
      Class_Link_search: "",
      Class_Link_id:"",
      showDropdown:true,
      //FLV-174
    };
    this.debounceSearch = debounce(this.applyFilters, TYPING_TIMEOUT);
  }
  async componentDidUpdate(prevProps, prevState){
    if(this.state.filters.teacher_id !== prevState.filters.teacher_id)
    {
      //this.fetchPreferences();
      this.debounceSearch();
      return true;
    }
    if(this.state.filters.class_link !== prevState.filters.class_link)
    {
      //this.fetchPreferences();
      this.debounceSearch();
      return true;
    }
  }
  async componentDidMount() {

    if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/");
    } else {
      await this.fetchPreferences();
    }
    this.props.getGradesListRequest();

    if (this?.props?.location?.state?.filters !== undefined) {
      this.setState({
        filters: this?.props?.location?.state?.filters,
        Class_Link_search: this?.props?.location?.state?.Class_Link_search,
        Teacher_search : this?.props?.location?.state?.Teacher_search,
      });
      this.setFilter(this?.props?.location?.state?.filters);
    }
  }

  addZero = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };
//FLV-156
onTeacherSearch = async (e) => {
  this.setState({ Teacher_search: e.target.value });
  if(e.target.value=="")
  {
    let filters = {...this.state.filters,teacher_id:"",offset:0};
     this.setState({ filters,Teacher_display:false });
  }
  else
  {
    let res = await getTeachers(`pattern=${e.target.value}`);
    this.setState({ Teacher_options: res.data.teachers,Teacher_display:true });
  }
  // console.log("res", res, this.state.teachersid);
};
onTeacherSelect = async (name,id) =>{
//await this.fetchPreferences({ filters: {...this.state.filters,teacher_id:id} });
this.setState({
  Teacher_search: name,
  teacher_id:id,
  Teacher_display:false,
  filters:{...this.state.filters,teacher_id:id,offset:0},
});
//this.debounceSearch({...this.state.filters,teacher_id:id})
//this.handlefilter({id:id},"teacher_filter");
}
onTeacherBlur=()=>{
  this.setState({
  Teacher_display:false,
  });
}
//FLV-156
//FLV-174
onClassLinkSearch = async (e) => {
  this.setState({ Class_Link_search: e.target.value });
  if(e.target.value=="")
  {
    let filters = {...this.state.filters,class_link:"",offset:0};
     this.setState({ filters,Class_Link_display:false });
  }
  else
  {
    let res = await getZoomIDwithParams(`pattern=${e.target.value}`);
    this.setState({ Class_Link_options: res.data.links,Class_Link_display:true });
  }
  // console.log("res", res, this.state.teachersid);
};
onClassLinkSelect = async (name,id) =>{
//await this.fetchPreferences({ filters: {...this.state.filters,teacher_id:id} });
this.setState({
  Class_Link_search: name,
  Class_Link_id:id,
  Class_Link_display:false,
  filters:{...this.state.filters,class_link:id,offset:0},
});
//this.debounceSearch({...this.state.filters,teacher_id:id})
//this.handlefilter({id:id},"teacher_filter");
}
//FLV-174
  fetchPreferences = async (payload) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...this.state.filters };
    let res = {};
    try {
      let urlParams = generateFilterUrl(filters);
      this.setState({ loading: true });
      res = await this.props.getPreferenceGroups(urlParams);

      if (res) {
        this.setState({
          total_count: res?.total_count,
          numberOfPages: Math.ceil(
            res?.total_count / this.state.filters.page_size
          ),
          loading: false,
        });
      } else this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  setFilter = (filters) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.applyFilters()
    );
  };

  onChange = (pagination, filteres, sorter = {}, extra) => {
    const { order, column, column: { title } = {} } = sorter;

    this.setState({
      sortedInfo: sorter,
    });

    let context = this;
    let filters = {
      ...this.state?.filters,
    };
    const { ...filter } = filters;

    const table_column_order_obj = {
      "Start dateascend": ["start_time", 0, "Sorted in ascending order"],
      "Start datedescend": ["start_time", 1, "Sorted in descending order"],
      "Number of Studentsascend": [
        "students_opted",
        0,
        "Sorted in ascending order",
      ],
      "Number of Studentsdescend": [
        "students_opted",
        1,
        "Sorted in descending order",
      ],
    };

    if (title) {
      const search_column_order = title + order;

      filter.sort_by = table_column_order_obj[search_column_order][0];
      filter.sort_dir = table_column_order_obj[search_column_order][1];
      message.info(table_column_order_obj[search_column_order][2]);
    } else {
      message.info("Sort cleared");
    }

    this.setState({ filters: filter }, () => context.debounceSearch(filter));
  };
  // onChange = (pagination, filteres, { order, column: { title } } = {}, extra) =>(
  //  let ({order} = sorter)
  //   console.log("display == ", order, title))

  onSearch = (event) => {
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    filters.pattern = event.target.value;
    this.setState({ filters }, () => context.debounceSearch(filters));
  };
  handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        offset: page - 1,
      };
      //FLV-170
      this.setState({ current_page: page,filters:{...this.state.filters,...filterUpdates} });
      //FLV-170
      this.applyFilters(filterUpdates);
    }
  };
  applyFilters = async (updates = {}) => {
    let apifilters = {
      ...this.state.filters,
      ...updates,
    };
    this.fetchPreferences({ filters: apifilters });
  };
  menu = () => <MixedFilter handlefilter={this.handlefilter}
  //FLV-156
    Teacher_display={this.state.Teacher_display}
    Teacher_options={this.state.Teacher_options}
    Teacher_search={this.state.Teacher_search}
    onTeacherSearch = {this.onTeacherSearch}
    onTeacherSelect = {this.onTeacherSelect}
    onTeacherBlur={this.onTeacherBlur}
  //FLV-156
  //FLV-174
  Class_Link_display={this.state.Class_Link_display}
  Class_Link_options={this.state.Class_Link_options}
  Class_Link_search={this.state.Class_Link_search}
  onClassLinkSearch = {this.onClassLinkSearch}
  onClassLinkSelect = {this.onClassLinkSelect}
  // onTeacherBlur={this.onTeacherBlur}
  //FLV-174
   />;

  handlereset = () => {
    let context = this;
    let filters = {
      ...this.state.filters,
    };
    this.setState({
      sortedInfo: null,
    });
    //FLV-180
    setTimeout(()=>this.setState({showDropdown:false}),1);
    setTimeout(()=>this.setState({showDropdown:true}),1);
    //FLV-180

    const {
      sort_by,
      sort_dir,
      start_time,
      time,
      teacher_id,
      product_type,
      offset,
      page_size,
      grade,
      start_topic,
      class_link,
      ...filter
    } = filters;
    //FLV-180
    this.setState({ filters: {...filter,offset: 0,
      page_size: MAX_RESULTS_PER_PAGE,}, current_page: 1,Teacher_search: "",Class_Link_search:"" }, () =>
      context.debounceSearch(filter)
    );
    //FLV-180
  };

  renderHeader = () => (
    <div className="preference-listing-header">
      <Button
        type="primary"
        className="ant-btn-md export-btn"
        onClick={() => this.props.history.push("/create-preference")}
      >
        Create New
      </Button>
      {/* FLV-180 */}
      {this.state.showDropdown && 
      <Dropdown trigger={["click"]} overlay={this.menu}>
        <Button style={{ marginLeft: 10, marginRight: 10 }}>Filter By</Button>
      </Dropdown>
      }
      {/* FLV-180 */}

      <Button
        style={{ height: 48, marginRight: 10 }}
        type="primary"
        onClick={this.handlereset}
      >
        <DeleteOutlined />
      </Button>
      <div className="action-dropdown">
        <Dropdown
          overlay={this.getMenu()}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <Button className="action-dropdown-button">
            Action <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  );

  handlefilter = (event, dataString) => {
    const { item: { props: { children, subMenuKey } = {} } = {} } = event || {};

    this.setState({
      sortedInfo: null,
    });

    let context = this;
    let filters = {
      ...this.state.filters,
    };
    const {
      sort_by,
      sort_dir,
      start_time,
      time,
      product_type,
      grade,
      start_topic,
      offset,
      ...filter
    } = filters;

    if (dataString == "g1" && event?.key !== "sdate0" && event != null) {
      
      let month = parseInt(event?._d.getMonth()) + 1;
      let utcdate =
        month + "-" + event?._d.getDate() + "-" + event?._d.getFullYear();
      filter.start_time = '"' + event ? utcdate : null + '"';
    } else if (dataString == "g2") {
      filter.time =
        this.addZero(event?._d.getHours()) +
        ":" +
        this.addZero(event?._d.getMinutes());
    } else if (subMenuKey == "sub1-menu-") {
      filter.product_type = children;
    } else if (subMenuKey == "sub2-menu-" && event?.key !== "gtype") {
      //FLV-170
      filter.grade = parseInt(event?.key) //+ 1
      ;
      //FLV-170
    } else if (subMenuKey == "sub3-menu-") {
      filter.start_topic = children;
    } else if (event?.key == "sdate0") {
      filter.start_time = children;
    } else if (event?.key == "gtype") {
      filter.grade = children;
    }
    
    if(event == null && dataString == "g1")
    {
      delete filter.start_time;
      delete filters.start_time;
    }

    if(event == null && dataString == "g2")
    {
      delete filter.time;
      delete filters.time;
    }
    
    this.setState({ filters:{...filters, ...filter,offset:0},
      current_page: 1, }, () => context.debounceSearch(filter));
    console.log("eve", event, dataString);
  };

  clickOnCheckBox = (selectedRowKeys, selectedRows) => {
    //FLV-125
    let selectedRowsId = selectedRowKeys;
    // console.log("selectedRowsId);
    //console.log(selectedRowKeys, selectedRowsId)
    this.setState({ selectedRowKeys, selectedRowsId });
  };

  clickOnUpdte = () => {
    this.setState({ isUpdateModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isUpdateModalOpen: false });
    this.setState({ selectedRowKeys: [], selectedRows: [] });
    this.fetchPreferences();
  };

  getTheads = (sortedInfo) => [
    {
      title: "SI NO",
      align: "center",
      render: (rowData) => {
        return <p>{rowData.id}</p>;
      },
    },
    {
      title: "Day",
      align: "center",
      render: (rowData) => <Schedule schedules={rowData?.timeslots} />,
    },
    {
      title: "Time",
      render: (rowData) => {
        // console.log("Rowdata>>>", rowData);

        return (
          <TimeCell
            data={convertSecondsToTime(
              rowData?.timeslots?.[0]?.start_second,
              true,
              true
            )}
          />
        );
      },
    },
    {
      title: "Start date",
      key: "Start date",
      render: (rowData) => (
        <TimeCell format={"DD/MM/YYYY"} data={rowData.start_time * 1000} />
      ),
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.columnKey === "Start date" && sortedInfo.order,
    },
    {//FLV-144
      title: "Teacher Id",
      //width: 300,
      render: (rowData) => <TeacherCell data={
        rowData.teacher?
        rowData.teacher?.user?.name+
        (rowData.teacher?.hasOwnProperty('teacher_id')?
        " ("+rowData.teacher?.teacher_id+")"
        :"")
        :""
      } />,
    },
    {
      title: "Zoom Id",
      //FLV-144
      render: (rowData) => <ZoomCell data={
        (rowData.zoom_details?.hasOwnProperty('zoom_id')?
        rowData.zoom_details?.zoom_id
        :"")
        +
        (rowData.zoom_details?.hasOwnProperty('link')?
        " ("+rowData.zoom_details?.link+")"
        :"")
      } />,
      //ellipsis: true,
      //width: 300,
      //FLV-144
    },
    {
      title: "Grades",
      render: (rowData) => (
        <GradesCell
          data={
            //Fixed issues raised by Sreejith @ 20 Aug 2021
            rowData.grade_id
              ? (this.props.gradesList.length>0
                ?this.props.gradesList.filter((grade)=>grade.id==rowData.grade_id)[0]?.name
                : null)
              : null
            //Fixed issues raised by Sreejith @ 20 Aug 2021
          }
        />
      ),
    },
    {
      title: "Start Topic",
      render: (rowData) => <StartTopicCell data={rowData.start_topic} />,
    },
    {
      title: "Number of Students",
      key: "Number of Students",

      align: "center",
      render: (rowData) => <NumberCell data={rowData.students_opted} />,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder:
        sortedInfo.columnKey === "Number of Students" && sortedInfo.order,
    },
    {
      title: "Product Type",
      render: (rowData) => (
        <p className={"listing-row-primary-text"}>{rowData.product_type}</p>
      ),
    },
    {
      title: "Conversion_status",
      render: (rowData) => (
        <p className={"listing-row-primary-text"}>
          {rowData.conversion_status}
        </p>
      ),
    },
    {
      title: "Notes",
      render: (rowData) => (
        <p className={"listing-row-primary-text"}>
          {rowData?.notes ? rowData?.notes : "-"}
        </p>
      ),
    },
    // {
    //   title: "Action",
    //   render: (rowData) => (
    //     <a
    //       href="#"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //       }}
    //     >
    //       Delte
    //     </a>
    //   ),
    // },
    // {
    //   title: "Duration",
    //   render: (rowData) => (
    //     <Duration data={rowData?.timeslots?.[0]?.duration} />
    //   ),
    // },

    // {
    //   title: "No. of batches",
    //   align: "center",
    //   render: (rowData) => <NumberCell data={rowData.num_of_batches} />,
    // },
    // {
    //   title: "students opted",
    //   align: "center",
    //   render: (rowData) => <NumberCell data={rowData.students_opted} />,
    // },
    // {
    //   title: "Status",
    //   align: "center",
    //   render: (rowData) => <Tag data={rowData?.status} />,
    // },
    // {
    //   title: "",
    //   align: "center",
    //   render: (rowData) => (
    //     <StatusCell
    //       data={rowData}
    //       updateTeacherStatus={this.props.updatePreferenceGroup}
    //     />
    //   ),
    // },
  ];

  // onChange(pagination, filters, sorter, extra) {
  //   console.log("params", pagination, filters, sorter, extra);
  // }

  getMenu = (i) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => this.clickOnUpdte()}>Update</a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={async () => {
            let data = {
              preference_groups: this.state.selectedRowsId,
            };
            let res = {};

            try {
              res = await this.props.startBatch(data);
            } catch (e) {
              toast.error("Could not start the batch!");
            }
            if (res) {//FLV-147
              toast.success("Batch creation in progress!");
            }
            else
            toast.error("Could not start the batch!");

            this.setState({ selectedRowKeys: [], selectedRows: [] });
            this.fetchPreferences();
          }}
        >
          Start Batch
        </a>
      </Menu.Item>
    </Menu>
  );

  render() {
    const { preferenceGroups, gradesList } = this.props;
    const {
      total_count,
      activePageNumber,
      filters: { page_size },
      current_page,
      numberOfPages,
      sortedInfo,
      selectedRowsId,
    } = this.state;
    let sortedInfos = sortedInfo || {};
    //console.log("props", this.props);
    let data = gradesList ? preferenceGroups?.preference_groups : [];
    //FLV-125
    if (typeof data !== "undefined" && data.length > 0)
      data = data.map((data) => {
        return Object.assign({}, data, { key: data.group_id });
      });
    // console.log("data);
    return [
      <div
        onClick={() => {
          if (this.state.isFilterDropdownVisible)
            this.setState({
              isFilterDropdownVisible: false,
            });
        }}
      >
        <AdminLayout
          path1="preference-listing"
          title="Preference Groups"
          rightContent={this.renderHeader()}
        >
          <section className="preference-listing-section">
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
                selectedRowKeys: this.state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) =>
                  this.clickOnCheckBox(selectedRowKeys, selectedRows),
              }}
              columns={this.getTheads(sortedInfos)}
              dataSource={!this.state.loading?data:[]}
              onChange={this.onChange}
              loading={this.state.loading}
              pagination={false}
              onRow={(formData, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.props.history.push({
                      pathname: `/preference-detail/${formData?.group_id}`,
                      state: {
                        filters: this.state.filters,
                        Teacher_search:this.state.Teacher_search,
                        Class_Link_search:this.state.Class_Link_search,
                      },
                    });
                  },
                };
              }}
            />
            {numberOfPages > 1 ? (
              <Pagination
                total={total_count}
                current={current_page}
                hideOnSinglePage
                onChange={this.handlePageChange}
                defaultPageSize={page_size}
              />
            ) : null}
          </section>
        </AdminLayout>
        <div className="update-modal">
          <Modal
            visible={this.state.isUpdateModalOpen}
            onCancel={() => this.closeModal()}
            width={700}
            footer={null}
          >
            <UpdateModal
              closeModal={() => this.closeModal()}
              selectedRowsId={selectedRowsId}
            />
          </Modal>
        </div>
      </div>,
    ];
  }
}

export default connect(
  ({ teacher, lms, preferenceGroups }) => ({
    ...teacher,
    ...lms,
    ...preferenceGroups,
  }),
  (dispatch) =>
    bindActionCreators({ ...lmsActions, ...preferenceGroupActions }, dispatch)
)(PreferenceListing);
