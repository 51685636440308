import React from 'react';
import "./index.scss";
import {TimeIcon} from 'assets/image/svg';
import {withRouter} from 'react-router-dom';
import DefaultUserIcon from '../../assets/image/default-user.png';
import moment from "moment";
import {DAYS} from "../../utils/constants";

const Card = ({isTodaysBatch, ...props}) => {

    const studentImages = props.studentProfileList.map((profilePhoto, index) => (
        <img
            src={profilePhoto.image ? profilePhoto.image : DefaultUserIcon}
            alt=""
            key={index}
            className="card-profile-image"
            style={{marginLeft: (index === 0) ? "0" : "-15px"}}
        />
    ));
    return (
        <div className={['batch-card', isTodaysBatch && 'today-batch'].join(' ')}
             onClick={() => (props.history.push(`/batchesListing/${props.batchID}/previous`))}>
            <div className="card-content">
                <div className="start-time">
                    <div className={['date', isTodaysBatch && 'today-batch'].join(' ')}>
                        <div>{moment(props.start_timestamp).format('DD/MM/YY')}</div>
                    </div>
                    <div className={["time", isTodaysBatch && 'today-batch'].join(' ')}>
                        <TimeIcon color="#000" width="15" height="15"/>
                        Starts at {moment(props.start_timestamp).format('h:mm a')}
                    </div>
                </div>
                <h2 className={["batch-name", isTodaysBatch && 'today-batch'].join(' ')}>{props.batchName || 'Batch A'}</h2>
                <div className={["grades", isTodaysBatch && 'today-batch'].join(' ')}>
                    <div>{props.grade}</div>
                </div>

                {/* onClick={() => props.onViewAllChildren('children-list', props.batchID)} */}
                <div className="card-footer">
                    <div className='flex flex-row flex-wrap items-center'>
                        {
                            props.timeslots?.map((time, index) => <p
                                className={['weekday', isTodaysBatch && 'today-batch'].join(' ')}>{DAYS?.[time?.weekday]}</p>)
                        }
                    </div>
                    <div className={["arrow", isTodaysBatch && 'today-batch'].join(' ')}>
                        &gt;
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Card);
