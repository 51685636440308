import {initialState} from "./state";

export const student = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ADMIN_STUDENT_LIST": {
            return {
                ...state,
                studentList: action.data.data.children,
            }
        }
        case "SET_FREADOM_STUDENT_LIST":
            console.log("OFFSET", action.data.data.offset)
            if (action.data.data.offset == 0) {
                return {
                    ...state,
                    freadomStudentList: action.data.data
                }
            } else {
                return {
                    ...state,
                    freadomStudentList: action.data.data,
                }
            }
        case "CLEAR_SEARCH_DATA":
            return {
                ...state,
                freadomStudentList: {
                    count: 0,
                    result: [],
                },
            }
            break;
        default:
            return state;
    }
};
