import React from 'react';
import './Sidebar.scss';
import {CalenderIcon, ChatIcon, OpenBookIcon} from '../icons'
import {FreadomIcon, PencilIcon} from '../../../../../assets/image/svg';

const PLACEHOLDER_IMAGE = "https://st3.depositphotos.com/14846838/19463/v/1600/depositphotos_194639100-stock-illustration-flat-icon-profile-female-student.jpg"

const Sidebar = ({showPreferenceModal, studentInfo, isEditMode, ...props}) => {
    return (
        <div className="student-details-sidebar">
            <img className='student-img' src={studentInfo?.image || PLACEHOLDER_IMAGE}/>
            <h2 className="student-name">{studentInfo.name}</h2>
            <div className='grade-info'>
                <FreadomIcon/>
                <p>{studentInfo.grade}</p>

            </div>
            <div className='details-fields'>
                <div className='detail'>
                    <p>Phone no:</p>
                    <p>{studentInfo.phone || '-'}</p>
                </div>
                <div className='detail'>
                    <p>Email:</p>
                    <p>{studentInfo.email || '-'}</p>
                </div>
                <div className='detail'>
                    <p>School:</p>
                    <p>{studentInfo.school || '-'}</p>
                </div>
                <div className='detail'>
                    <p>Plan:</p>
                    <p>{studentInfo.plan || '-'}</p>
                </div>
                <div className='detail'>
                    <p>Preference Group:</p>
                    <p>{studentInfo.preferenceGroup || '-'}</p>
                    {
                        isEditMode && <div onClick={() => {
                            showPreferenceModal()
                        }} className={'edit-icon'}>
                            <PencilIcon width={17} height={17}/>
                        </div>
                    }
                </div>
                <div className='detail'>
                    <p>Batch:</p>
                    <p>{studentInfo.batch || '-'}</p>
                </div>
            </div>

            <div className='seperator-line'></div>

            <div className='attendance-info'>
                <div>
                    <h5><OpenBookIcon/><span>SESSIONS TAKEN</span></h5>
                    <h3>{studentInfo.sessionTaken||'-'}</h3>
                </div>
                <div>
                    <h5><CalenderIcon/><span>ATTENDANCE</span></h5>
                    <h3>{studentInfo.attendance||'-'}</h3>
                </div>
            </div>
            <div className='teachers-comment'>
                <h5><ChatIcon/><span>TEACHER COMMENTS</span></h5>
                <div className='comment-box'>
                    <p>"</p>
                    <p className='comment-text'>{studentInfo.teachersComment}</p>
                    <div className='comment-footer'>

                        <div className="teacher-information">
                            <img src={''} alt={'teacher-photo'}/>
                            <div>
                                <h5></h5>
                                <h6></h6>
                            </div>
                        </div>
                        <span>&gt;</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
