import {StudentApiCalls} from "../../../../services/student";

export const updateStudentProfile = async (profileData, studentId) => {
    try {
        return await StudentApiCalls.updateStudentProfileRequest(profileData, studentId);
    } catch (err) {
        return err?.response || err;
    }
}
export const getStudentProfile = async (studentId) => {
    try {
        return await StudentApiCalls.getStudentProfileRequest(studentId);
    } catch (err) {
        return err?.response || err;
    }
}
