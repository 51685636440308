import React, {Component} from 'react';

// SCSS
import "./index.scss";
import AdminLayout from 'layout/admin/index';
import {Empty, Input, Spin} from 'antd';
import {panelListBatches} from './filter';
import Card from 'components/Card/index';
import isEmpty from 'lodash/isEmpty';

// Router
import {withRouter} from "react-router";
import ChildrenModal from "../../../../components/ChildrenModal";

// Redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as batchesActions from "store/batches/actions";
import moment from "moment";
import * as lmsActions from "../../../../store/lms/actions";
import debounce from "lodash/debounce";
import {generateFilterUrl} from "../../../../utils/commonUtils";
import ListingFilter from "../../../../components/Filter";

const TYPING_TIMEOUT = 500;
const MAX_RESULTS_PER_PAGE = 15;

class TeacherBatchesListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            searchText: "",
            batchesList: [],
            showChildrenListPopup: false,
        };
        this.debounceSearch = debounce(this.applyFilters, TYPING_TIMEOUT);

    }

    componentDidMount() {
        this.setState({loading: true})
        this.fetchTeacherBatches()
        this.props.getGradesListRequest()
    }

    fetchTeacherBatches = async (payload) => {
        let filters;
        if (payload && payload.filters)
            filters = payload.filters
        else
            filters = {...this.state.filters};
        let res = {}
        try {
            let urlParams = generateFilterUrl(filters)
            this.setState({loading: true})
            res = await this.props.getBatchesListRequest(urlParams)
            if (res) {
                this.setState({
                    loading: false,
                })
            } else
                this.setState({loading: false})
        } catch (e) {
            this.setState({loading: false})
        }
    }
    applyFilters = async (updates = {}) => {
        let apifilters = {
            ...this.state.filters,
            ...updates
        }
        this.fetchTeacherBatches({filters: apifilters})
    }
    setFilter = (filters) => {
        //because no pagination
        delete filters.offset
        this.setState({
            filters: {
                ...this.state.filters,
                ...filters
            }
        }, () => this.applyFilters());
    }

    onSearch = (event) => {
        let context = this;
        let filters = {
            ...this.state.filters,
        }
        filters.name = event.target.value;
        this.setState({filters}, () => context.debounceSearch(filters))
    };

    onTogglePopup(popupName, batchId) {
        switch (popupName) {
            case "children-list":
                this.setState({
                    showChildrenListPopup: !this.state.showChildrenListPopup,
                    batchId: batchId
                })
                break;
            default:
                break;
        }
    }

    getTimingString = (startTimestamp, endTimestamp) => {
        if (startTimestamp && endTimestamp)
            return `${moment(startTimestamp).utc(true).format('hh:mm A')} 
        - 
        ${moment(endTimestamp).utc(true).format('hh:mm A')}`

    }
    rightContent = () => (
        <div className="teacher-listing-header student-listing-header batches-listing-header">
            <Input
                className="search-box"
                placeholder="Search"
                onChange={(event) => {
                    this.onSearch(event);
                }}
            />
            <ListingFilter
                isFilterDropdownVisible={this.state.isFilterDropdownVisible}
                setDropdownVisibility={(value) => this.setState({isFilterDropdownVisible: value})}
                gradesList={this.props.gradesList}
                panelList={panelListBatches}
                onApply={this.setFilter}
            />
        </div>
    )

    getTodayUpcomingBatch = () => {
        if (this.props.batchesList?.length === 0)
            return
        return this.props.batchesList.filter(batch => {
            let batchStartTimeUnix = new Date(batch?.start_timestamp).getTime()
            // let todayUnix = new Date().getTime()
            let todayUnix = 1619945493531 //2nd may unix
            if (batchStartTimeUnix >= todayUnix) {
                let allotedWeekdays = batch.preference_group.timeslots?.map(item => item.weekday);
                let todayWeekDay = moment().day();

                //also,check for next upcoming session
                // consult BE
                if (allotedWeekdays?.includes(todayWeekDay))
                    return batch
            }
        })
    }

    render() {
        let todayBatch = this.getTodayUpcomingBatch()?.[0] || {};
        console.log('todayBatch', todayBatch)
        return (
            <div onClick={() => {
                if (this.state.isFilterDropdownVisible)
                    this.setState({
                        isFilterDropdownVisible: false
                    })
            }}>
                <AdminLayout title="My Batches" rightContent={this.rightContent()}>
                    <div className="batches-listing">
                        {/*{*/}
                        {/*    !isEmpty(todayBatch) &&*/}
                        {/*    <div className={'today-batch-section'}>*/}
                        {/*        <h3 className={'batch-section-heading'}>Today's Batch</h3>*/}
                        {/*        <Card*/}
                        {/*            {...todayBatch}*/}
                        {/*            timeslots={todayBatch.preference_group?.timeslots || []}*/}
                        {/*            studentProfileList={todayBatch.children?.slice(0, 3) || []}*/}
                        {/*            batchName={todayBatch.name}*/}
                        {/*            grade={todayBatch.grade?.name}*/}
                        {/*            more={(todayBatch.children?.length > 3) ? todayBatch.children?.length - 3 : null}*/}
                        {/*            onViewAllChildren={this.onTogglePopup}*/}
                        {/*            batchID={todayBatch.batch_id}*/}
                        {/*            isTodaysBatch={true}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {/*<h3 className={'batch-section-heading'}>All Batches</h3>*/}
                        {
                            !this.state.loading ?
                                (this.props.batchesList?.length > 0 ?
                                        <div className="cards-list">
                                            {
                                                this.props.batchesList.map((batch, index) => (
                                                    <Card
                                                        {...batch}
                                                        key={index}
                                                        timeslots={batch.preference_group.timeslots || []}
                                                        studentProfileList={batch.children.slice(0, 3)}
                                                        batchName={batch.name}
                                                        grade={batch.grade.name}
                                                        more={(batch.children.length > 3) ? batch.children.length - 3 : null}
                                                        color={colorList[index % colorList.length]}
                                                        onViewAllChildren={this.onTogglePopup}
                                                        batchID={batch.batch_id}
                                                    />
                                                ))
                                            }
                                        </div>
                                        :
                                        <div className={'center-align'}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                        </div>
                                )
                                :
                                <div className={'center-align'}>
                                    <Spin/>
                                </div>
                        }
                    </div>
                    <ChildrenModal batchId={this.state.batchId} visible={this.state.showChildrenListPopup}
                                   closeModal={() => (this.onTogglePopup("children-list", null))}/>
                </AdminLayout>
            </div>
        );
    }
}

const colorList = ["#F4712B", "#F2BD1D", "#F299A9"];

export default connect(
    ({batches, lms}) => ({...batches, ...lms}),
    dispatch => bindActionCreators({...batchesActions, ...lmsActions}, dispatch)
)(withRouter(TeacherBatchesListing));
