import React from "react";
import { DatePicker, TimePicker, Menu,Input } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as lmsActions from "store/lms/actions";
import * as preferenceGroupActions from "../../../../../store/preferenceGroups/actions";
import "./mixedfilters.scss";

const { SubMenu } = Menu;
const handleChange = (e) => console.log(e);

const MixedFilter = ({ 
  handlefilter,
Teacher_options,
Teacher_search,
Teacher_display,
onTeacherSearch,
onTeacherBlur,
onTeacherSelect, 
Class_Link_search,
Class_Link_options,
Class_Link_display,
onClassLinkSearch,
onClassLinkSelect,
...props }) => (
<>
  <Menu
    className="filter-menu"
    onSelect={handlefilter}
    onDeselect={handleChange}
  >
    {console.log("propssssssssss", props)}
    <Menu.ItemGroup key="g1" title="Start Date">
      <DatePicker
        className="datepicker"
        onChange={(e) => handlefilter(e, "g1")}
      ></DatePicker>
      <Menu.Item key="sdate0">EMPTY</Menu.Item>
    </Menu.ItemGroup>
    <Menu.ItemGroup key="g2" title="Time">
      <TimePicker
        style={{ width: "290px ", height: "47px" }}
        onChange={(e) => handlefilter(e, "g2")}
      ></TimePicker>
    </Menu.ItemGroup>
  </Menu>
  <Menu
    className="filter-menu"
    onSelect={handlefilter}
    onDeselect={handleChange}
  >
    <SubMenu key="sub1" title="Product Type">
      <Menu.Item key="ptype0">EMPTY</Menu.Item>
      <Menu.Item key="ptype1">FLIVE</Menu.Item>
      <Menu.Item key="ptype2">F1</Menu.Item>
      <Menu.Item key="ptype3">SUMMERCAMP</Menu.Item>
      <Menu.Item key="ptype4">FGLOBAL</Menu.Item>
    </SubMenu>
    </Menu>
  <Menu
    className="filter-menu"
    onSelect={handlefilter}
    onDeselect={handleChange}
  >
    <SubMenu key="sub2" title="Grade">
      <Menu.ItemGroup style={{ height: 250, overflowY: "scroll" }}>
        <Menu.Item key="gtype">EMPTY</Menu.Item>
        {props.gradesList.map((eachGrade, index) => (
          //FLV-170
          // <Menu.Item key={index}>{eachGrade.name}</Menu.Item>
          <Menu.Item key={eachGrade.id}>{eachGrade.name}</Menu.Item>
          //FLV-170
        ))}
      </Menu.ItemGroup>
    </SubMenu>
    </Menu>
  <Menu
    className="filter-menu"
    onSelect={handlefilter}
    onDeselect={handleChange}
  >
    <SubMenu key="sub3" title="Start Topic">
      <Menu.Item key="stopic0">EMPTY</Menu.Item>
      <Menu.Item key="stopic1">1</Menu.Item>
      <Menu.Item key="stopic2">31</Menu.Item>
      <Menu.Item key="stopic3">61</Menu.Item>
      <Menu.Item key="stopic4">91</Menu.Item>
    </SubMenu>
  </Menu>
  {/* FLV-156 */}
  <div className="Container-Pref-filter" style={{backgroundColor:'white'}}>
    <Input placeholder="Search Teacher" //onBlur={onTeacherBlur} 
    onChange={onTeacherSearch} allowClear value={Teacher_search}/>
    {(Teacher_display && Teacher_options.length>0) && <div className="autoContainers-Pref-filter">
    {Teacher_options.slice(0, 10).map((v, i) => {
                  return (
                    <div
                      className="options"
                      onClick={() => onTeacherSelect(v.user.name,v.user.id)}
                    >
                      <span>{v.user.name}</span>
                    </div>
                  );
                })}
    </div>}
  </div>
    {/* FLV-156 */}
    {/* FLV-174 */}
  <div className="Container-Pref-filter" style={{backgroundColor:'white'}}>
    <Input placeholder="Search Class link" //onBlur={onTeacherBlur} 
    onChange={onClassLinkSearch} allowClear value={Class_Link_search}/>
    {(Class_Link_display && Class_Link_options.length>0) && <div className="autoContainers-Pref-filter">
    {Class_Link_options.slice(0, 10).map((v, i) => {
                  return (
                    <div
                      className="options"
                      //onClick={() => onClassLinkSelect(v.link,v.id)}
                      onClick={() => onClassLinkSelect(v.zoom_id,v.id)}
                    >
                      {/* <span>{v.link}</span> */}
                      <span>{v.zoom_id}</span>
                    </div>
                  );
                })}
    </div>}
  </div>
    {/* FLV-174 */}
</>
);

export default connect(
  ({ teacher, lms, preferenceGroups }) => ({
    ...teacher,
    ...lms,
    ...preferenceGroups,
  }),
  (dispatch) =>
    bindActionCreators({ ...lmsActions, ...preferenceGroupActions }, dispatch)
)(MixedFilter);
