import { TeacherApiCalls } from "../../services/teacher";
import { mutation } from "./mutations";

const handleError = (err) => {
  console.log("Error in Login action : ", err);
};

// Actions
export const addNewTeacher = (payload) => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.addNewTeacher({ data: payload });
    dispatch(mutation.addNewTeacher(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getTeachers =
  (params = "") =>
  async (dispatch) => {
    try {
      const result = await TeacherApiCalls.getTeachers(params);
      dispatch(mutation.getTeachers(result));
      return result;
    } catch (err) {
      handleError(err);
    }
  };

export const getRecommendedTeachers =
  (batchId = "") =>
  async (dispatch) => {
    try {
      const result = await TeacherApiCalls.getRecommendedTeachersRequest(
        batchId
      );
      dispatch(mutation.getRecommendedTeachers(result));
      return result;
    } catch (err) {
      handleError(err);
    }
  };

export const updateTeacherStatus = (id, status) => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.updateTeacherStatus(id, {
      data: { status: `${status}` },
    });
    dispatch(mutation.updateTeacherStatus(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const updateTeacherData = (id, payload) => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.updateTeacherData(id, {
      data: payload,
    });
    dispatch(mutation.updateTeacherData(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const forgotPassword = (payload) => async (dispatch) => {
  try {
    const { data } = await TeacherApiCalls.forgotPassword({ data: payload });
    if (data && data === "success") {
      return data;
    }
    return undefined;
  } catch (err) {
    handleError(err);
    return undefined;
  }
};
export const getPresignedUrl = (payload) => async (dispatch) => {
  try {
    const { data } = await TeacherApiCalls.getPresignedUrlRequest({
      data: payload,
    });
    if (data) {
      return data;
    }
    return undefined;
  } catch (err) {
    handleError(err);
    return undefined;
  }
};
export const uploadPicture = (url) => async (dispatch) => {
  try {
    const { data } = await TeacherApiCalls.uploadPictureRequest(url);
    if (data) {
      return data;
    }
    return undefined;
  } catch (err) {
    handleError(err);
    return undefined;
  }
};

export const updatePassword = (payload, auth) => async (dispatch) => {
  try {
    const headers = {
      Authorization: auth,
      "api-version": "1.1.0",
      "Content-Type": "application/json",
    };
    const { result } = await TeacherApiCalls.updatePassword(
      { data: payload },
      headers
    );
    if (result && result.data && result.data === "success") {
      return result.data;
    }
    return undefined;
  } catch (err) {
    handleError(err);
    return undefined;
  }
};
