export const panelList = [
    {
        label: 'Grades',
        value: 'grades',
        list: []
    },
    {
        label: 'Class Types',
        value: 'course_type',
        list: [
            {
                value: 'TRIAL',
                label: 'Trial Class',
                isActive: false
            },
            {
                value: 'NORMAL',
                label: 'Paid User Class',
                isActive: false
            }
        ]
    },
    {
        label: 'Status',
        value: 'status',
        list: [
            {
                value: 'true',
                label: 'Active',
                isActive: false
            },
            {
                value: 'false',
                label: 'Inactive',
                isActive: false
            }
        ]
    }
];
