import { mutations } from "./mutations";
import { batchesApiCalls } from "../../services/batches";
import { TeacherApiCalls } from "../../services/teacher";
import { Classes } from "../../services/classes";

const handleError = (err) => {
  console.log("Error in Login action : ", err);
  return err.response || err;
};

export const getBatchesListRequest =
  (params = "") =>
  async (dispatch) => {
    try {
      const result = await batchesApiCalls.getBatchesList(params);
      dispatch(mutations.setBatchesList(result.data ? result.data : result));
      return result.data ? result.data : result;
    } catch (err) {
      handleError(err);
    }
  };

export const getBatchChildrenRequest = (batchId) => async (dispatch) => {
  try {
    const result = await batchesApiCalls.getBatchChildren(batchId);
    return result?.data ? result.data : result;
  } catch (err) {
    handleError(err);
  }
};

export const assignTeacherToBatch =
  (batchID, teacherID) => async (dispatch) => {
    try {
      const payload = {
        data: {
          batch_id: batchID,
          teacher_id: teacherID,
        },
      };
      const result = await batchesApiCalls.assignTeacherToBatch(payload);
      return result;
    } catch (err) {
      handleError(err);
    }
  };
export const assignUpdatedTeacherToBatch =
  (batchID, teacherID) => async (dispatch) => {
    try {
      const payload = {
        data: {
          batch_id: batchID,
          teacher_id: teacherID,
        },
      };
      const result = await batchesApiCalls.assignUpdatedTeacherToBatch(payload);
      return result;
    } catch (err) {
      handleError(err);
    }
  };

export const getBatchClasses =
  (batchId, typeOfClass, params = "") =>
  async (dispatch) => {
    try {
      let result = await Classes.getClassesForBatch(
        batchId,
        typeOfClass,
        params
      );
      return result.data || [];
    } catch (e) {
      return handleError(e);
    }
  };

export const startBatch = (data) => async (dispatch) => {
  try {
    const payload = {
      data: {
        preference_groups: data?.preference_groups,
        // preference_group_id: data?.preference_group_id

        //children: data?.childrenIds,
      },
    };
    return await batchesApiCalls.startBatchRequest(payload);
  } catch (e) {
    return handleError(e);
  }
};
export const getCurrentBatchDetails =
  (batchID, fetchTeacher = true) =>
  async (dispatch) => {
    try {
      const result = await batchesApiCalls.getBatchDetails(batchID);
      console.log("Batch detail", result);
      if (fetchTeacher && result.data.teacher_id) {
        const teacherDetails = (
          await TeacherApiCalls.getTeachers("")
        ).data.teachers.find((teacher) => result.data.teacher_id == teacher.id);
        result.data.batchTeacher = teacherDetails;
      }
      console.log("Batch detail", result);
      dispatch(mutations.setCurrentBatch(result));
      return result;
    } catch (err) {
      handleError(err);
    }
  };

export const deleteStudentFromBatch =
  (batchID, childID,reason='') => async (dispatch) => {
    try {
      const result = await batchesApiCalls.deleteStudentFromBatch(
        batchID,
        childID,
        reason
      );
      return result;
    } catch (err) {
      handleError(err);
    }
  };

export const addStudentToBatch = (batchID, childID,reason='') => async (dispatch) => {
  try {
    const result = await batchesApiCalls.addStudentToBatch(batchID, childID,reason);
    return result;
  } catch (err) {
    handleError(err);
  }
};
