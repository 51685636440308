import React, { Component } from "react";

export const ProfilePicCell = ({ data }) => (
  <figure className="listing-row-profile-pic" style={{ backgroundImage: `url(${data.pic})` }}></figure>
);

export const NameCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data.user.name}</p>
    <p className="listing-row-secondary-text">{data.teacher_id}</p>
  </div>
);

export const ContactDetailsCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data.user.email}</p>
    <p className="listing-row-primary-text">{data.user.phone_number}</p>
  </div>
);

export const GradesCell = ({ data }) => {
  return (
    <div>
      <p className="listing-row-primary-text">{data.grade_value?.join(", ")}</p>
    </div>
  )
}

export const ClassTypeCell = ({ data }) => {
  const classTypes = data && data.course_types && data.course_types.length ? data.course_types : [];
  return (
    <div>
      {
        classTypes.map((type, index) =>
          <p
            key={index}
            className={type === 'LIVE' || type === 'NORMAL' ? 'listing-row-class-type listing-row-class-type--red' : 'listing-row-class-type'}
          >
            {type === 'NORMAL' ? 'PAID' : type}
          </p>
        )}
    </div>
  );
}
export const DailyHoursCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data.num_hours_per_day}</p>
  </div>
);

export class StatusCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.data ? !!(props.data.status) : false
    };
  };

  toggleStatus = (isActive) => {
    this.setState({
      isActive: isActive !== undefined ? isActive : !this.state.isActive
    });
  }

  onStatusUpdate = (e) => {
    e.stopPropagation();
    this.props.updateTeacherStatus(this.props.data.id, !this.state.isActive);
    this.toggleStatus(!this.state.isActive);
  }

  render() {
    const className = this.state.isActive ? 'listing-row-status listing-row-status--active' : 'listing-row-status';
    return (
      <div onClick={this.onStatusUpdate}>
        <span className={className}></span>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.toggleStatus(this.props.data.status);
    }
  }
};
