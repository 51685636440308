import {Api} from "./config/request";


export const createSubscription = (payload) => Api.postRequest(`packs/`, payload);
export const getSubscriptionList = () => Api.getListRequest(`packs/`);
export const getSubscriptionForChild = (childID) => Api.getRequest(`subscriptions/?user_id=${childID}`);
export const createSubscriptionForChild = (payload) => Api.postRequest(`subscriptions/`, JSON.stringify(payload));
export const addPackForChild = (payload) => Api.postRequest('subscriptions/add_pack_billing_cycle', JSON.stringify(payload));
export const updateSubscriptionPackRequest = (packId, payload) => Api.putRequest(`packs/${packId}`, payload);


export const Subscription = {
    createSubscription,
    getSubscriptionList,
    getSubscriptionForChild,
    createSubscriptionForChild,
    addPackForChild,
    updateSubscriptionPackRequest
};
