import { Api } from "./config/request";

export const getAttendance = (classId) =>
  Api.getListRequest(`classes/${classId}/attendance`);

export const markAttendance = (classId, payload) =>
  Api.putRequest(`classes/${classId}/attendance`, payload);

export const setZoomLink = (classId, payload) =>
  Api.putRequest(`/classes/${classId}/link`, payload);
export const setTeacherForSessionReq = (classId, teacherId) =>
  Api.getRequest(`classes/${classId}/update-teacher/${teacherId}`);

export const sendHomework = (classId, payload) =>
  Api.putRequest(`classes/homework-status/${classId}`, payload);

export const getStudentSessionDetails = (sessionId) =>
  Api.getListRequest(`sessions/${sessionId}`, true);

export const getFeedbackQuestionsReq = (gradeId) =>
  Api.getRequest(`teachers/get-feedback-questions/${gradeId}`);
export const getFeedbackAnswersReq = (childId) =>
  Api.getRequest(`teachers/get-feedback-results/${childId}`);

export const submitFeedbackReq = (payload) =>
  Api.postRequest(`teachers/submit-feedback`, payload);

export const getClassesForBatch = (batchId, typeOfClass, params) => {
  let url = "";
  if (typeOfClass === "upcoming") url += `upcoming=True`;
  else url += `previous=True`;
  return Api.getListRequest(`classes?${url}&batch_id=${batchId}&${params}`);
};

export const submitSessionTime = (payload)=>{
  return Api.postRequest(`classes/${payload.sessionId}/reschedule`,payload)
}

export const getClassLinks = () =>{
  return Api.getRequest(`/class_links`)
}

export const Classes = {
  setTeacherForSessionReq,
  getAttendance,
  markAttendance,
  sendHomework,
  getClassesForBatch,
  getStudentSessionDetails,
  setZoomLink,
  getFeedbackQuestionsReq,
  getFeedbackAnswersReq,
  submitFeedbackReq,
  submitSessionTime,
  getClassLinks
};


