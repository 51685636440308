import "./index.scss";
import React, { Component } from "react";
import AdminLayout from "layout/admin/index";
import { Button, Input, Pagination, Table, Menu, Dropdown, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { panelListStudent } from "./filter";
import { ProfilePicCell, StatusCell } from "./rowCells";
import Pill from "../../../../components/Pill/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StudentActions from "../../../../store/student/actions";
import debounce from "lodash/debounce";
import {
  convertSecondsToTime,
  generateFilterUrl,
} from "../../../../utils/commonUtils";
import * as GradesActions from "../../../../store/grades/actions";
import ListingFilter from "../../../../components/Filter";
import { PreferenceGroupApiCalls } from "../../../../services/preferenceGroups";
import moment from "moment";
import ChoseSlotModal from "./choseSlotModal";

const TYPING_TIMEOUT = 500;
const MAX_RESULTS_PER_PAGE = 15;

/*const weekDays = {
    1: "M",
    2: "T",
    3: "W",
    4: "Th",
    5: "F",
    6: "Sa",
    7: "Su",
};*/

class StudentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      filters: {
        offset: 0,
        page_size: MAX_RESULTS_PER_PAGE,
      },
      total_count: MAX_RESULTS_PER_PAGE,
      numberOfPages: 1,
      activePageNumber: 1,
      loading: true,
      filterItems: [...panelListStudent],
      isChoseModalOpen: false,
      slots: [],
      selectedStudentId: null,
    };
    this.debounceOnSearch = debounce(this.applyFilters, TYPING_TIMEOUT);
  }

  async componentDidMount() {
    await this.fetchStudents();
    await this.props.getGradeListRequest();
    //await this.fetchPreferencesListOfSlot();
    if (this?.props?.location?.state?.filters !== undefined) {
      this.setState({
        filters: this?.props?.location?.state?.filters,
      });
      this.setFilter(this?.props?.location?.state?.filters);
    }
  }

  /*fetchPreferencesListOfSlot = async () => {
        let res = await PreferenceGroupApiCalls.getPreferenceGroups('in_progress=true');
        let slots = [];
        if (res) {
            res.data.preference_groups.forEach(pg => {
                slots.push({
                    id: pg.group_id,
                    days: this.getAllDays(pg.timeslots),
                    time: convertSecondsToTime(pg.timeslots?.[0]?.start_second),
                    date: pg.start_time ? moment(pg.start_time * 1000).format("DD/MM/YYYY") : '-',
                    users: pg.students_opted + ' Users',
                    startTopic: pg.start_topic
                })
            });
            this.setState({ slots })
        }
    }*/
  /*studentwiseslot = async (ids) => {
        let res = await PreferenceGroupApiCalls.getPreferenceGroups_get_pg(ids);
        let slots = [];
        if (res) {
            res.data.preference_groups.forEach(pg => {
                slots.push({
                    id: pg.group_id,
                    days: this.getAllDays(pg.timeslots),
                    time: convertSecondsToTime(pg.timeslots?.[0]?.start_second),
                    date: pg.start_time ? moment(pg.start_time * 1000).format("DD/MM/YYYY") : '-',
                    users: pg.students_opted + ' Users',
                    startTopic: pg.start_topic
                })
            });
            return slots
            //this.setState({ slots })
        }
    }*/
  /*getAllDays = (timeSlots) => {
        let days = timeSlots.map((schedule) => (
            <span className={"weekday"}>{weekDays[schedule.weekday]}</span>
        ));

        return <p className={"listing-row-primary-text"}>{days}</p>;
    }*/

  fetchStudents = async (payload) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...this.state.filters };
    let res = {};
    try {
      let urlParams = generateFilterUrl(filters);
      this.setState({ loading: true });
      res = await this.props.getAdminStudentList(urlParams);
      if (res) {
        this.setState({
          total_count: res?.data?.total_count,
          numberOfPages: Math.ceil(
            res?.data?.total_count / MAX_RESULTS_PER_PAGE
          ),
          loading: false,
        });
      } else this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  async onSearch(event) {
    let filters = {
      ...this.state.filters,
    };
    delete filters.phone;
    delete filters.name;
    if (event?.target?.value !== "")
      if (isNaN(event?.target?.value)) {
        filters.name = event?.target?.value;
        delete filters.phone;
      } else {
        filters.phone = event?.target?.value;
        delete filters.name;
      }
    filters.offset = 0;
    this.setState({ filters }, () => this.debounceOnSearch(filters));
  }

  handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        offset: page - 1,
      };
      this.setState(
        {
          filters: {
            ...this.state.filters,
            ...filterUpdates,
          },
        },
        () => this.applyFilters(filterUpdates)
      );
    }
  };
  setFilter = (filters) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.applyFilters()
    );
  };

  applyFilters = (updates = {}) => {
    let filters = {
      ...this.state.filters,
      ...updates,
    };
    this.fetchStudents({ filters });
  };

  renderSubscriptionPill(subscription) {
    // const subs = subscription?.pack_details?.label ? subscription?.subscribed_packs[0] : null;
    const subs =
      subscription?.pack_details?.label || subscription?.subscribed_packs[0];
    if (subs) {
      if (!subs || !subs.end_timestamp) return <Pill text={subs} type={5} />;
      else if (
        subs &&
        subs.end_timestamp &&
        moment.unix(subs.end_timestamp)?.local()?.toDate() < new Date()
      )
        return <Pill text={subs} type={5} />;
      else return <Pill text={"EXPIRED"} type={6} />;
    } else {
      return <Pill text={"UNSUBSCRIBED"} type={1} />;
    }
  }

  getScheduleFromPreferences = (preferences) => {
    let schedule = "";
    if (preferences) {
      preferences.forEach((preference) => {
        schedule = schedule + days[preference.weekday] + " ";
      });
    }
    return schedule;
  };

  convertData() {
    return this.props.studentList.map((student, index) => {
      return {
        pic: (
          <ProfilePicCell
            data={{
              pic: student.image,
            }}
          />
        ),
        name: student.name,
        grade: <div className="grade-container">{student.grade.name}</div>,
        school: student.details.school_name,
        parentName:
          student.details.father_details.name.trim() != ""
            ? student.details.father_details.name
            : student.details.mother_details.name,
        contactNo: (
          <div>
            <div style={{ marginBottom: "5px" }}>
              {student.parent.phone_number}
            </div>
            {student.parent.email}
          </div>
        ),
        batchName: student?.batch?.batch_name || "",
        subscription: this.renderSubscriptionPill(student?.subscription),
        preferenceGroup: this.getScheduleFromPreferences(
          student?.subscription?.preference_group?.timeslots
        ),
        edit: <StatusCell />,
      };
    });
  }

  rightContent = () => (
    <div className="teacher-listing-header student-listing-header">
      <Input
        placeholder="Search"
        // value={this.state.searchText}
        onChange={(event) => {
          this.onSearch(event);
        }}
      />
      <ListingFilter
        isFilterDropdownVisible={this.state.isFilterDropdownVisible}
        setDropdownVisibility={(value) =>
          this.setState({ isFilterDropdownVisible: value })
        }
        panelList={panelListStudent}
        gradesList={this.props.grades?.gradesList}
        onApply={this.setFilter}
        filters={this.state.filters}
      />
      <Button
        type="primary"
        className="ant-btn-md export-btn"
        onClick={() => this.props.history.push("/students/add")}
      >
        ADD
      </Button>
    </div>
  );

  getMenu = (i) => {
    return (
      <Menu>
        <Menu.Item>
          <a onClick={(e) => this.clickOnAddSlot(e, i)}>Add Slot</a>
        </Menu.Item>
        <Menu.Item>
          <a>Edit</a>
        </Menu.Item>
      </Menu>
    );
  };

  getTheads = () => {
    return [
      {
        title: "",
        dataIndex: "pic",
        key: "pic",
      },
      {
        title: "NAME",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "CONTACT NO. & EMAIL",
        dataIndex: "contactNo",
        key: "contactNo",
      },
      {
        title: "Flive Grade",
        dataIndex: "grade",
        key: "grade",
      },
      {
        title: "SCHOOL",
        dataIndex: "school",
        key: "school",
      },
      {
        title: "BATCH NAME",
        dataIndex: "batchName",
        key: "batchName",
      },
      {
        title: "PREFERENCE GROUP",
        dataIndex: "preferenceGroup",
        key: "preferenceGroup",
      },
      {
        title: "PLAN",
        align: "center",
        dataIndex: "subscription",
        key: "subscription",
      },
      {
        title: "",
        align: "center",
        render: (t, r, i) => (
          <Dropdown
            overlay={() => this.getMenu(i)}
            placement="bottomLeft"
            onClick={(e) => e.stopPropagation()}
          >
            <Button className="action-dropdown-button">
              Action <DownOutlined />
            </Button>
          </Dropdown>
        ),
      },
    ];
  };

  clickOnAddSlot = (e, i) => {
    e.stopPropagation();
    this.setState({
      isChoseModalOpen: true,
      selectedStudentId: this.props.studentList[i].id,
    });
  };

  closeModal = () => {
    this.setState({ isChoseModalOpen: false });
    this.fetchStudents();
  };

  render() {
    const sampleData = this.convertData();
    let {
      numberOfPages,
      total_count,
      filters: { page_size, offset },
      activePageNumber,
    } = this.state;
    return (
      <div
        onClick={() => {
          if (this.state.isFilterDropdownVisible)
            this.setState({
              isFilterDropdownVisible: false,
            });
        }}
        className="student-listing"
      >
        <AdminLayout
          path1="students"
          title="Students"
          rightContent={this.rightContent()}
        >
          <section className="student-listing-section">
            <Table
              pagination={false}
              loading={this.state.loading}
              className="student-listing-table"
              dataSource={sampleData}
              columns={this.getTheads()}
              onRow={(formData, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.props.history.push(
                      `/students/info/${this.props.studentList[rowIndex].id}`,
                      {
                        studentData: this.props.studentList[rowIndex],
                        filters: this.state.filters,
                      }
                    );
                  },
                };
              }}
            />
            {numberOfPages > 1 ? (
              <Pagination
                current={offset + 1}
                defaultCurrent={1}
                total={total_count}
                hideOnSinglePage
                onChange={(page) => this.handlePageChange(page)}
                defaultPageSize={page_size}
              />
            ) : null}
          </section>
        </AdminLayout>
        <Modal
          visible={this.state.isChoseModalOpen}
          onCancel={() => this.closeModal()}
                    //FLV-161
                    //width={675}
                    width={750}
                    //FLV-161
          footer={null}
        >
          <ChoseSlotModal
            closeModal={() => this.closeModal()}
            selectedStudentId={this.state.selectedStudentId}
          />
        </Modal>
      </div>
    );
  }
}

const days = ["", "M", "T", "W", "Th", "F", "Sa", "Su"];

export default connect(
  ({ student, grades }) => ({ ...student, grades }),
  (dispatch) =>
    bindActionCreators({ ...StudentActions, ...GradesActions }, dispatch)
)(StudentListing);
