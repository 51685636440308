// Libraries
import React, {Component} from 'react'
import QueryString from 'query-string'
import 'react-quill/dist/quill.snow.css';
import {Editor} from '@tinymce/tinymce-react';

// Redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as lmsActions from "store/lms/actions";

// Components
import AdminLayout from 'layout/admin';
import MultiValueInput from 'components/MultiValueInput'

// SCSS
import './AddSessions.scss'

class AddSession extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)

        this.state = {
            name: '',
            description: '',
            activeTab: 'LESSON PLAN',
            homework_links: [],
            lesson_plan_links: [],
            currentGradeName: '',
            currentGradeId: '',
            currentLevel: '',
            isEdit: false,
            lesson_plan_text: '',
            homework_text: '',
            learning_outcome_text: '',
        }
        this.editorRef = React.createRef();
    }

    handleChange(value) {
        if (this.state.activeTab === 'LESSON PLAN') {
            this.setState({
                lesson_plan_text: value
            })
        } else if (this.state.activeTab === 'HOMEWORK') {
            this.setState({
                homework_text: value
            })
        } else if (this.state.activeTab === 'LEARNING OUTCOMES') {
            this.setState({
                learning_outcome_text: value
            })
        }
    }

    componentDidMount() {
        if (this.props.location.pathname.includes('update')) {
            this.setState({
                isEdit: true
            })
            if (this.props.match.params && this.props.match.params.id) {
                this.props.getSessionDetailsRequest(this.props.match.params.id).then(response => {
                    if (response) {
                        let activeTab = 'LESSON PLAN'
                        this.setState({
                            name: response.name,
                            description: response.description,
                            activeTab: activeTab,
                            // freadomWebLinks: links,
                            lesson_plan_text: response.lesson_plan,
                            homework_text: response.homework,
                            learning_outcome_text: response.learning_outcome,
                            initial_lesson_plan_text: response.lesson_plan,
                            initial_homework_text: response.homework,
                            initial_learning_outcome_text: response.learning_outcome,
                            lesson_plan_links: response.lesson_plan_links,
                            homework_links: response.homework_links,
                            isActive: response.is_active
                        })
                    }
                })
            }
        }
        this.props.getGradesListRequest().then(result => {
            const queryParams = this.props.location.search ? QueryString.parse(this.props.location.search) : ''
            let selectedGradeId = queryParams.grade
            let selectedGradeObject = result?.grades?.length > 0 && result?.grades?.find(grade => grade.id === parseInt(selectedGradeId)) || {}
            this.setState({
                currentGradeName: selectedGradeObject ? selectedGradeObject.name : '',
                currentGradeId: selectedGradeId,
                currentLevel: (selectedGradeObject && selectedGradeObject.levels) ? selectedGradeObject.levels.find(level => level.id === parseInt(queryParams.level)).name : '',
                currentLevelId: queryParams.level
            })
        })
    }

    renderHeader = () => {
        return (
            <div className="view-add-session__header">
                {/* Cancel Button */}
                <button
                    className="view-add-session__header--cancel-button"
                    onClick={() => this.props.history.push(`/lmsListing?currentView=SESSIONS&grade=${this.state.currentGradeId}`)}
                >
                    CANCEL
                </button>

                {/* Submit Button */}
                <button
                    disabled={!this.isFormValid(true)}
                    className="ant-btn view-add-session__header--submit-button"
                    onClick={() => this.submitButtonClickHandler()}
                >
                    SUBMIT
                </button>
            </div>
        )
    }

    isFormValid = (hideError) => {
        let {name, description} = this.state;
        let errorObj = {}
        if (name && description)
            return true
        if (!hideError) {
            if (!name)
                errorObj.name = 'Please enter session`s name'
            if (!description)
                errorObj.description = 'Please enter session`s description'
            this.setState({
                errors: errorObj
            })
            return false
        }
    }


    submitButtonClickHandler = () => {
        let payload = {
            name: this.state.name,
            grade: this.state.currentGradeId,
            description: this.state.description,
            lesson_plan: this.state.lesson_plan_text,
            lesson_plan_links: this.state.lesson_plan_links,
            homework: this.state.homework_text,
            homework_links: this.state.homework_links,
            learning_outcome: this.state.learning_outcome_text
        }
        let isValid = this.isFormValid()
        if (isValid) {
            if (!this.state.isEdit) {
                this.props.addNewSessionRequest(payload).then(result => {
                    this.props.getSessionsListRequest().then(result => {
                        this.props.history.push(`/lmsListing?currentView=SESSIONS&grade=${this.state.currentGradeId}`)
                    })
                })
            } else {
                payload.is_active = this.state.isActive
                this.props.updateSessionDataRequest(this.props.match.params.id, payload).then(result => {
                    this.props.getSessionsListRequest().then(result => {
                        this.props.history.push(`/lmsListing?currentView=SESSIONS&grade=${this.state.currentGradeId}`)
                    })
                })
            }
        }
    }

    setFreadomWebLinks(list) {
        if (this.state.activeTab === 'HOMEWORK') {
            this.setState({
                homework_links: list
            })
        } else if (this.state.activeTab === 'LESSON PLAN') {
            this.setState({
                lesson_plan_links: list
            })
        }
    }

    getTextEditorCurrentValue = () => {
        if (this.state.activeTab === 'LESSON PLAN') {
            return this.state.initial_lesson_plan_text
        } else if (this.state.activeTab === 'HOMEWORK') {
            return this.state.initial_homework_text
        } else if (this.state.activeTab === 'LEARNING OUTCOMES') {
            return this.state.initial_learning_outcome_text
        }

        return ''
    }

    getLinksTitleLabel = () => {
        return this.state.activeTab === 'HOMEWORK' ? 'HOMEWORK LINKS' : 'LESSON PLAN LINKS'
    }

    render() {
        const {
            isEdit,
            currentGradeName,
            currentLevel,
            lesson_plan_links,
            homework_links,
            activeTab,
            name,
            description,
            errors = {}
        } = this.state

        return (
            <>
                <AdminLayout
                    title={`${isEdit ? 'Edit' : 'Add'} Session: ${currentGradeName}`}
                    rightContent={this.renderHeader()}
                >
                    <div className="view-add-session">
                        <div className="view-add-session__main-container">
                            <div className="view-add-session__main-container--top-section">
                                <p className="section-title">
                                    SESSION DETAILS
                                </p>

                                <div className="view-add-session-form__row">
                                    <div className="view-add-session-form__column">
                                        <div className="view-add-session-form__control">
                                            <label className="view-add-session-form__control--label">
                                                NAME
                                            </label>
                                            <input
                                                type="text"
                                                className="view-add-session-form__control--input"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => this.setState({
                                                    name: e.target.value,
                                                    errors: {...this.state.errors, name: null}
                                                })}
                                            />
                                            <label className="view-add-session-form__control--error">
                                                {errors?.name}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="view-add-session-form__row">
                                    <div className="view-add-session-form__column">
                                        <div className="view-add-session-form__control">
                                            <label className="view-add-session-form__control--label">
                                                DESCRIPTION
                                            </label>
                                            <textarea
                                                name="descriptionTextArea"
                                                cols="30"
                                                rows="10"
                                                className="view-add-session-form__control--textarea"
                                                placeholder="Add description here..."
                                                value={description}
                                                onChange={(e) => this.setState({
                                                    description: e.target.value,
                                                    errors: {...this.state.errors, description: null}
                                                })}
                                            />
                                            <label className="view-add-session-form__control--error">
                                                {errors?.description}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="view-add-session__main-container--bottom-section">
                                <div className="view-add-session__tabs-switcher">
                                    <p
                                        className={`view-add-session__tabs-switcher--tab ${activeTab === 'LESSON PLAN' ? 'active' : ''}`}
                                        onClick={() => {
                                            this.setState({
                                                activeTab: 'LESSON PLAN',
                                                initial_lesson_plan_text: this.state.lesson_plan_text,
                                                initial_homework_text: this.state.homework_text,
                                                initial_learning_outcome_text: this.state.learning_outcome_text,
                                            });
                                        }}
                                    >
                                        LESSON PLAN
                                    </p>
                                    <p
                                        className={`view-add-session__tabs-switcher--tab ${activeTab === 'HOMEWORK' ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            activeTab: 'HOMEWORK',
                                            initial_lesson_plan_text: this.state.lesson_plan_text,
                                            initial_homework_text: this.state.homework_text,
                                            initial_learning_outcome_text: this.state.learning_outcome_text,
                                        })}
                                    >
                                        HOMEWORK
                                    </p>
                                    <p
                                        className={`view-add-session__tabs-switcher--tab ${activeTab === 'LEARNING OUTCOMES' ? 'active' : ''}`}
                                        onClick={() => this.setState({
                                            activeTab: 'LEARNING OUTCOMES',
                                            initial_lesson_plan_text: this.state.lesson_plan_text,
                                            initial_homework_text: this.state.homework_text,
                                            initial_learning_outcome_text: this.state.learning_outcome_text,
                                        })}
                                    >
                                        LEARNING OUTCOMES
                                    </p>
                                </div>

                                <div className="view-add-session__tabs-details">
                                    <p className="view-add-session__tabs-details--header-text">
                                        {activeTab}
                                    </p>
                                    {/* Text Editor */}
                                    <div className="view-add-session__tabs-details--text-editor-container">
                                        {/* <ReactQuill
                                            name="editor"
                                            value={this.getTextEditorCurrentValue()}
                                            onChange={this.handleChange}
                                        /> */}
                                        <Editor
                                            onInit={(evt, editor) => this.editorRef.current = editor}
                                            onEditorChange={this.handleChange}
                                            initialValue={this.getTextEditorCurrentValue()}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                statusbar: false,
                                                default_link_target: "_blank",
                                                paste_webkit_styles: 'all',
                                                force_br_newlines: true,
                                                remove_linebreaks: false,
                                                apply_source_formatting: true,
                                                paste_retain_style_properties: 'all',
                                                table_column_resizing: 'resizetable',
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic underline backcolor forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | link | table',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </div>

                                    {(activeTab === 'HOMEWORK' || activeTab === 'LESSON PLAN')
                                    &&
                                    <>
                                        <div
                                            className="view-add-session-form__control"
                                            style={{
                                                marginTop: '24px'
                                            }}
                                        >
                                            <label className="view-add-session-form__control--label">
                                                {this.getLinksTitleLabel()}
                                            </label>
                                            {(activeTab === 'HOMEWORK')
                                            &&
                                            <MultiValueInput
                                                onListChange={(list) => this.setFreadomWebLinks(list)}
                                                linksList={homework_links}
                                            />
                                            }
                                            {(activeTab === 'LESSON PLAN')
                                            &&
                                            <MultiValueInput
                                                onListChange={(list) => this.setFreadomWebLinks(list)}
                                                linksList={lesson_plan_links}
                                            />
                                            }
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </AdminLayout>
            </>
        )
    }
}

export default connect(
    ({lms}) => ({...lms}),
    dispatch => bindActionCreators({...lmsActions}, dispatch)
)(AddSession);
