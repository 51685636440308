import React, { Component } from "react";
import { Form, Input, Button } from 'antd';
import { connect } from "react-redux";
import LogoImg from 'assets/image/admin-logo.svg';
import "./index.scss";

class ResetPassword extends Component {

  render() {
    return [
      <div className="admin-login-left">
        <a href='/admin' className="admin-logo">
          <img src={LogoImg} alt="Freadom" />
        </a>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          className="custom-form admin-login-form"
        >
          <h1>Reset Password</h1>
          <p className="admin-login-desc">Please enter a new password:</p>
          <Form.Item name="password">
            <Input type="password" placeholder="New Password" />
          </Form.Item>
          <Form.Item name="password">
            <Input type="password" placeholder="Confirm Password" />
          </Form.Item>
          <Button type="primary" htmlType="submit">RESET PASSWORD</Button>
        </Form>
      </div>
    ];
  }
}

export default connect()(ResetPassword);