import React from "react";
import {Form, Col, InputNumber} from 'antd';
import "./index.scss";

const DailyHoursAndClasses = ({onChange, hoursPerDay, classesPerDay, showValidationError, getFieldDecorator}) => (
    <React.Fragment>
        <Col span={10} style={{padding: '8px 14px 8px 0'}}>
            <Form.Item
                label="daily classes"
            >
                {
                    getFieldDecorator('num_classes_per_day', {
                        rules: [
                            {
                                required: true,
                                message: 'This field is mandatory!'
                            },
                        ],
                    })
                    (<InputNumber
                        min={1}
                        max={20}
                        value={classesPerDay}
                        placeholder="Enter Number"
                        onChange={currentVal => onChange('num_classes_per_day', currentVal)}
                    />)}
            </Form.Item>
        </Col>
        <Col span={10} style={{padding:'8px 19px 8px 0'}}>
            <Form.Item
                label="daily HOURS"
            >
                {
                    getFieldDecorator('num_hours_per_day', {
                        rules: [
                            {
                                required: true,
                                message: 'This field is mandatory!'
                            },
                        ],
                    })
                    (<InputNumber
                        min={1}
                        max={24}
                        value={hoursPerDay}
                        placeholder="Enter Number"
                        onChange={currentVal => onChange('num_hours_per_day', currentVal)}
                    />)}
            </Form.Item>
        </Col>
    </React.Fragment>
);


export default DailyHoursAndClasses;
