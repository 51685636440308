import {initialState} from "./state";

export const subscriptions = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SUBSCRIPTION_PACK":
            return {...state, ...action.data}
        case "SET_SUBSCRIPTION_INFO_FOR_CHILD":
            return {
                ...state,
                childSubscription: action.data
            }
        default:
            return state;
    }
};
