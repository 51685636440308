import React, {Component} from 'react';
import './EditStudent.scss';
import AdminLayout from '../../../../layout/admin';
import {validationSchema} from './constants';
import {PersonIcon} from './icons'
import StudentForm from './StudentForm';
import {Button} from 'antd';
import Sidebar from './Sidebar/Sidebar';
import StudentDetails from './StudentDetails/StudentDetails';
import {returnWeekDay} from "../../../../utils/commonUtils";
import {getStudentProfile} from "./utils";
import Modal from "../../../../components/Modal";
import PreferenceGroupList from "./PreferenceGroupList";

class EditStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      enableReinitialize: true,
      isEditMode: this.props.match.params.type == "edit",
      studentInfo: {
        name: "",
        grade: "",
        phone: "",
        email: "",
        plan: "",
        preferenceGroup: "",
        batch: "",
        school: "",
        sessionTaken: undefined,
        attendance: undefined,
        teachersComment: "",
        teacherName: "",
        teacherSubject: "",
      },
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchStudentData();
  }

  fetchStudentData = async (data) => {
    let studentData =
      data || (await getStudentProfile(this.props.match.params.id));
    studentData = studentData?.data;
    console.log("studen", studentData);
    this.setState({
      studentData: {
        ...studentData,
        details: {
          ...studentData?.details,
          program_outcomes:
            studentData?.details?.program_outcomes?.length > 0
              ? studentData?.details?.program_outcomes
              : [""],
          other_classes:
            studentData?.details?.other_classes?.length > 0
              ? studentData?.details?.other_classes
              : [""],
        },
      },
      studentInfo: this.transformStudentData(studentData),
    });
  };

  transformStudentData = (studentData) => ({
    name: studentData?.name,
    image: studentData?.image,
    grade: studentData?.grade?.name,
    phone: studentData?.parent?.phone_number,
    email: studentData?.parent?.email,
    plan: studentData?.subscription?.pack_details?.label,
    preferenceGroup:
      studentData?.subscription?.preference_group?.timeslots
        ?.map((item) => returnWeekDay(item.weekday))
        ?.join(" ") || "",
    batch: studentData?.batch?.batch_name,
    school: studentData?.details?.school_name,
    sessionTaken: undefined,
    attendance: undefined,
    teachersComment: "",
    teacherName: "",
    teacherSubject: "",
  });

  handleSubmit() {
    this.formRef.current.formRef.current.submitForm();
  }

  render() {
    let { isEditMode, isLoading, studentData, studentInfo } = this.state;
    return (
      <div className="edit-student-details">
        <AdminLayout
          path1="students"
          title={`${isEditMode ? "Edit" : ""} Student Detail`}
          showBack={true}
          rightContent={
            <div>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                className="ant-btn-md export-btn"
                onClick={
                  isEditMode
                    ? () => this.handleSubmit()
                    : () => {
                        this.setState({
                          isEditMode: true,
                        });
                      }
                }
              >
                {isEditMode ? "Confirm Edit" : "Edit Details"}
              </Button>
              {isEditMode ? (
                <Button
                  style={{ marginLeft: "10px" }}
                  type="primary"
                  className="ant-btn-md grey-btn"
                  onClick={() => {
                    this.setState({
                      isEditMode: false,
                    });
                    // this.props.history.replace(`/students/info/${this.props.match.params.id}`)
                  }}
                >
                  Cancel
                </Button>
              ) : null}
            </div>
          }
        >
          <div className="shadow-box-container">
            {/* Student Details Sidebar */}
            <Sidebar
              showPreferenceModal={() =>
                this.setState({
                  showModal: true,
                })
              }
              studentInfo={studentInfo}
              isEditMode={isEditMode}
            />

            {/* Student Form Main */}
            <div className="student-details-info">
              <h4>
                <PersonIcon />
                <span>onboarding questions</span>
              </h4>

              {isEditMode ? (
                // Show Editable Form
                <StudentForm
                  enableReinitialize={this.state.enableReinitialize}
                  initialValues={studentData}
                  ref={this.formRef}
                  validationSchema={validationSchema}
                  updateState={(state) => this.setState(state)}
                  fetchStudentData={this.fetchStudentData}
                />
              ) : (
                // Show Details Screen
                <StudentDetails {...studentData?.details} />
              )}
            </div>
          </div>
        </AdminLayout>
        <Modal
          showCustomButton={false}
          visible={this.state.showModal}
          closeModal={() => {
            this.setState({
              showModal: false,
            });
          }}
          modalProps={{
            okText: "Submit",
            title: "Select a preference group",
          }}
        >
          <PreferenceGroupList
            closeModal={() => {
              this.setState(
                {
                  showModal: false,
                  enableReinitialize: false,
                },
                () => this.fetchStudentData(null, true)
              );
            }}
            existingPrefGroupId={studentData?.subscription?.preference_group_id}
            childId={this.props.match.params.id}
          />
        </Modal>
      </div>
    );
  }
}

export default EditStudent;
