import React, { useEffect, useState } from "react";
import { Checkbox, Collapse, Dropdown, Button } from "antd";
import cloneDeep from "lodash/cloneDeep";
import remove from "lodash/remove";
import "./index.scss";
import { FilterIcon } from "assets/image/svg";
import isEmpty from "lodash/isEmpty";

const { Panel } = Collapse;

const ListingFilter = ({ onApply, panelList, ...props }) => {
  const [filters, handleFilter] = useState(panelList);
  const [activeFilterParams, setFilter] = useState({});
  const [isDropdownVisible, toggleDropdown] = useState(false);

  useEffect(() => {
    if (props.gradesList?.length > 0) {
      panelList[0].list = props.gradesList?.map((grade) => ({
        value: grade.id,
        label: grade.name,
        isActive: false,
      }));
      handleFilter(panelList);
    }
  }, [props.gradesList]);
  useEffect(() => {
    toggleDropdown(props.isFilterDropdownVisible);
  }, [props.isFilterDropdownVisible]);

  const onChange = (panelValue, filterValue, isChecked) => {
    if (panelValue && filterValue) {
      // Update filters array
      const updatedFilters = cloneDeep(filters);
      updatedFilters
        .find((panel) => panel.value === panelValue)
        .list.find((filter) => filter.value === filterValue).isSelected =
        isChecked;
      // Set active filters
      const updatedFilterParams = cloneDeep(activeFilterParams);
      const targetedFilterValue = updatedFilterParams[panelValue] || [];
      if (isChecked) {
        targetedFilterValue.push(filterValue);
      } else {
        remove(targetedFilterValue, (val) => val === filterValue);
      }

      updatedFilterParams[panelValue] = targetedFilterValue;
      console.log("updatedFilterParams", updatedFilterParams);
      console.log("updatedFilters", updatedFilters);
      setFilter(updatedFilterParams);
      handleFilter(updatedFilters);
      onApply({ ...updatedFilterParams, offset: 0 });
    }
  };

  const renderFilterCount = (label, count = 0) => (
    <div className="filter-count">
      {label} <span>{count}</span>
    </div>
  );
  const renderMenu = (
    <div>
      <div className="overlay-filter" />
      <div
        onClick={(e) => e.stopPropagation()}
        className="teacher-filter-list student-listing-filter"
      >
        <Collapse accordion expandIconPosition="right">
          {filters.map((panel, index) => (
            <Panel
              key={index}
              header={renderFilterCount(
                panel.label,
                panel.list.filter((element) => element.isSelected).length
              )}
            >
              {panel.list.map((filter, filterIndex) => (
                <Checkbox
                  key={filterIndex}
                  checked={filter.isSelected}
                  onChange={(currentVal) =>
                    onChange(
                      panel.value,
                      filter.value,
                      currentVal.target.checked
                    )
                  }
                >
                  {filter.label}
                </Checkbox>
              ))}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
  const renderFilterDot = () => {
    if (activeFilterParams) {
      let isFilterApplied = false;
      let activeFilterKeys = Object.keys(activeFilterParams);
      activeFilterKeys.some((key) => {
        if (!isEmpty(activeFilterParams[key])) {
          isFilterApplied = true;
        }
      });
      if (isFilterApplied) return renderFilterCount();
    }
  };

  return (
    <div>
      <div id="teacherListingFilter">
        <p
          className="ant-dropdown-trigger"
          onClick={(e) => {
            console.log("clicked");
            props.setDropdownVisibility(!isDropdownVisible);
            toggleDropdown(!isDropdownVisible);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span>Filter</span>
            {/*{renderFilterDot()}*/}
          </div>
          <FilterIcon />
        </p>
        {isDropdownVisible ? renderMenu : null}
      </div>
    </div>
  );
};

export default ListingFilter;
