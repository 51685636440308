const setTopStoryList = data => ({
  type: "SET_TOP_STORY_LIST",
  data,
});
const setRecommendedStoryList = data => ({
  type: "SET_RECOMMENDED_STORY_LIST",
  data,
});

const setActiveStory = data => ({
  type: "SET_ACTIVE_STORY",
  data,
});

const updateBookmarkedData = data => ({
  type: 'UPDATE_BOOKMARK_DATA',
  data,
});

const setOngoingStories = data => ({
  type: 'SET_ONGOING_STORIES',
  data,
});

const setLikedStories = data => ({
  type: 'SET_LIKED_STORIES',
  data
})

const updateStoryStatus = data => ({
  type: 'UPDATE_STORIES_STATUS',
  data
})

export const mutation = {
  setTopStoryList,
  setActiveStory,
  updateBookmarkedData,
  setRecommendedStoryList,
  setOngoingStories,
  setLikedStories,
  updateStoryStatus
};
  