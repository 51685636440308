import React, {Component} from 'react'
import Swal from 'sweetalert2';

// Redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as lmsActions from "store/lms/actions";

// Components
import {Select} from 'antd';

// SCSS
import './GradeModal.scss'

// Icons & Images
import {CircularCrossIcon} from 'assets/image/svg'

// Constants
import {FREADOM_LEVELS_LIST} from '../../utils/constants'

const {Option} = Select

class GradeModal extends Component {
    state = {
        levelsList: [
            {
                id: 0,
                is_active: true,
                name: ''
            }
        ],
        gradeName: '',
        selectedFreadomLevel: '',
        selectedFreadomGrade: {},
        showValidationError: false
    }

    componentDidMount() {
        if (this.props.isEdit) {
            let selectedFreadomGrade = this.props.freadomGradesList.find(grade => grade.id === this.props.editGradeData.freadom_grade_id)

            let arr = this.state.levelsList
            if (this.props.editGradeData.levels?.length > 0) {
                arr = JSON.parse(JSON.stringify(this.props.editGradeData.levels))
                let obj = this.state.levelsList[0]
                obj.id = arr.length
                arr.push(obj)
            }

            this.setState({
                gradeName: this.props.editGradeData.name,
                selectedFreadomLevel: this.props.editGradeData.freadom_grade_level,
                selectedFreadomGrade: selectedFreadomGrade,
                levelsList: arr,
                isActive: this.props.editGradeData?.is_active
            })
        }
    }

    addNewLevel = () => {
        let arr = JSON.parse(JSON.stringify(this.state.levelsList))
        arr.push({
            id: this.state.levelsList.length,
            name: '',
            is_active: true
        })
        this.setState({levelsList: arr})
    }

    setLevelNameText = (value, id) => {
        let levelIndex = this.state.levelsList.findIndex((level) => level.id === id)
        if (levelIndex !== -1) {
            let arr = JSON.parse(JSON.stringify(this.state.levelsList))
            arr[levelIndex].name = value
            this.setState({levelsList: arr})
        }
    }

    setLevelStatus = (status, id) => {
        let levelIndex = this.state.levelsList.findIndex((level) => level.id === id)
        if (levelIndex !== -1) {
            let arr = JSON.parse(JSON.stringify(this.state.levelsList))
            arr[levelIndex].is_active = status
            this.setState({levelsList: arr})
        }
    }

    setFreadomGrade = (value) => {
        const index = this.props.freadomGradesList.findIndex(grade => grade.id === value)
        this.setState({selectedFreadomGrade: this.props.freadomGradesList[index]})
    }

    setFreadomLevel = (value) => {
        this.setState({selectedFreadomLevel: value})
    }

    isValid = () => {
        if (
            this.state.gradeName !== ''
            &&
            (typeof this.state.selectedFreadomGrade !== 'undefined' && this.state.selectedFreadomGrade !=null)
            &&
            Object.keys(this.state.selectedFreadomGrade).length > 0
            &&
            this.state.selectedFreadomLevel !== ''
        ) {
            this.setState({
                showValidationError: false
            })
            return true
        }
        this.setState({
            showValidationError: true
        })
        return false
    }

    submitButtonClickHandler = () => {
        if (this.isValid()) {
            let payload = {
                name: this.state.gradeName,
                description: '',
                // levels: this.state.levelsList,
                freadom_grade_name: this.state.selectedFreadomGrade.name,
                freadom_grade_level: this.state.selectedFreadomLevel,
                freadom_grade_id: this.state.selectedFreadomGrade.id
            }
            if (!this.props.isEdit) {
                this.props.addNewGradeRequest(payload).then(result => {
                    if (result) {
                        this.props.getGradesListRequest()
                        this.props.onCloseClick()
                    } else {
                        this.props.onCloseClick()
                        Swal.fire({
                            title: 'Error!',
                            text: "Grade with same name already exists.",
                            icon: 'error'
                        })
                    }
                })
            } else {
                payload.is_active = this.state.isActive
                this.props.updateGradeDataRequest(this.props.editGradeData.id, payload).then(result => {
                    this.props.getGradesListRequest()
                    this.props.onCloseClick()
                })
            }
        }
    }

    render() {
        const {
            levelsList,
            gradeName,
            selectedFreadomLevel,
            selectedFreadomGrade,
            showValidationError
        } = this.state

        const {
            isEdit = false,
            freadomGradesList = [],
            editGradeData,
            onCloseClick
        } = this.props

        return (
            <div className="component-grade-modal">
                <div className="component-grade-modal__container">
                    <div
                        className="component-grade-modal__close-button"
                        onClick={onCloseClick}
                    >
                        <CircularCrossIcon/>
                    </div>
                    <p className="component-grade-modal__title-text">
                        {isEdit ? 'Edit' : 'Add'} Grades & Levels
                    </p>
                    <div className="component-grade-modal__section">
                        <p className="component-grade-modal__section--title-text">
                            GRADE DETAILS
                        </p>

                        <div className="form__row">
                            <div className="form__column w-full">
                                <div className="form__control w-full">
                                    <label className="form__control--label">
                                        NAME
                                    </label>
                                    <input
                                        type="text"
                                        className="form__control--input w-full"
                                        value={gradeName}
                                        onChange={(e) => this.setState({gradeName: e.target.value})}
                                    />
                                    {(showValidationError && gradeName === '')
                                    &&
                                    <p className="error-text">
                                        *This field is mandatory
                                    </p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form__row">
                            <div className="form__column">
                                <div className="form__control">
                                    <label className="form__control--label">
                                        FREADOM GRADE
                                    </label>
                                    <Select
                                        // defaultValue={freadomGradesList[0].name}
                                        placeholder="Select Grade"
                                        value={selectedFreadomGrade?.name}
                                        style={{width: 254, height: 56}}
                                        onChange={this.setFreadomGrade}
                                    >
                                        {freadomGradesList.map((grade, index) => 
                                            
                                                (<Option value={grade?.id} key={index}>
                                                    {grade?.name}
                                                </Option>)
                                            
                                        )}
                                    </Select>

                                    {(showValidationError &&
                                    (typeof selectedFreadomGrade !== 'undefined' && selectedFreadomGrade!=null) 
                                    && Object.keys(selectedFreadomGrade).length <= 0)
                                    &&
                                    <p className="error-text">
                                        *This field is mandatory
                                    </p>
                                    }
                                </div>
                            </div>

                            <div className="form__column">
                                <div className="form__control">
                                    <label className="form__control--label">
                                        FREADOM LEVEL
                                    </label>
                                    <Select
                                        placeholder="Select Level"
                                        value={selectedFreadomLevel}
                                        style={{width: 254, height: 56}}
                                        onChange={this.setFreadomLevel}
                                    >
                                        {FREADOM_LEVELS_LIST.map((level, index) => {
                                            return (
                                                <Option value={level} key={index}>
                                                    {level}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                    {(showValidationError && selectedFreadomLevel === '')
                                    &&
                                    <p className="error-text">
                                        *This field is mandatory
                                    </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="component-grade-modal__section">
                        {/* Add Button */}
                        <button
                            className="ant-btn component-grade-modal__button"
                            onClick={this.submitButtonClickHandler}
                        >
                            {isEdit ? 'UPDATE' : 'ADD'} GRADE
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({lms}) => ({...lms}),
    dispatch => bindActionCreators({...lmsActions}, dispatch)
)(GradeModal);
