import React, {useEffect, useState} from "react";
import {Form, Input, Button} from 'antd';
import LogoImg from 'assets/image/admin-logo.svg';
import "./index.scss";

// Local Storage
import {storage as LocalStorage} from "services/config/storage";
import {USER_ROLES} from "../../../utils/constants";

const Login = ({adminLoginHandler, teacherLoginHandler, onForgotPassword, history}) => {

    const [teacher_id, setTeacherId] = useState();
    const [password, setPassword] = useState();
    const [userType, setUserTpe] = useState('admin');
    const [isError, handleError] = useState(false);

    useEffect(() => {
        if (history.location.pathname.includes('teacher')) {
            setUserTpe('teacher')
        }
    }, [])

    const onSubmit = async () => {
        if (teacher_id && teacher_id.length && password && password.length) {
            let auth = null
            if (history.location.pathname.includes('teacher')) {
                auth = await teacherLoginHandler({teacher_id, password});
            } else {
                auth = await adminLoginHandler({email: teacher_id, password});
            }
            if (auth) {
                if (LocalStorage.fetch.userData()) {
                    if (LocalStorage.fetch.userData().role === USER_ROLES.SUPER_ADMIN || LocalStorage.fetch.userData().role === USER_ROLES.ADMIN) {
                        history.push("/teacherListing");
                    } else {
                        history.push("/batchesListing");
                    }
                }
            } else {
                handleError(true);
            }
        }
    }

    const isSubmitEnabled = () => teacher_id && teacher_id.length && password && password.length;

    return [
        <div className="admin-login-left">
            <a href='/admin' className="admin-logo">
                <img src={LogoImg} alt="Freadom"/>
            </a>
            <Form
                name="basic"
                initialValues={{remember: true}}
                className="custom-form admin-login-form"
            >
                <h1>Welcome!</h1>
                <p className="admin-login-desc">{`Please enter your ${userType === 'admin' ? 'Admin' : 'Teacher'} ID and password to login:`}</p>
                <Form.Item name="teacherId">
                    <Input
                        placeholder={`${userType === 'admin' ? 'Admin' : 'Teacher'} ID`}
                        value={teacher_id}
                        onChange={currentVal => {
                            setTeacherId(currentVal.target.value)
                            handleError(false);
                        }}
                    />
                </Form.Item>
                <Form.Item name="password">
                    <Input
                        type="password"
                        value={password}
                        placeholder="Password"
                        onChange={currentVal => {
                            setPassword(currentVal.target.value);
                            handleError(false);
                        }}
                    />
                </Form.Item>
                {isError ? <p className="form-error">Incorrect username or password</p> : null}
                <Button disabled={!isSubmitEnabled()} onClick={onSubmit} type="primary" htmlType="submit">LOGIN</Button>
                <span className="link-text" onClick={onForgotPassword}>Forgot password?</span>
            </Form>
        </div>
    ];
}

export default Login;
