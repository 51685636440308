import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

// React Router
import { withRouter } from "react-router-dom";

// Local Storage
import { storage as LocalStorage } from "services/config/storage";

// Icons & Images
import LogoImg from "assets/image/admin-logo.svg";
import {
  BackpackIcon,
  CalenderTimeIcon,
  GraduationCapIcon,
  NumberedListIcon,
  PeopleIcon,
  PeopleIcon2,
} from "assets/image/svg.js";

// SCSS
import "./index.scss";
import { PeopleAdd } from "../../../assets/image/svg";
import { USER_ROLES } from "../../../utils/constants";

const ADMIN_TOP_NAV = [
  {
    name: "Teachers",
    route: "teacherListing",
    pageRegex: new RegExp(/^(teacherListing|addTeacher|updateTeacher)$/),
    icon: (color) => <GraduationCapIcon color={color} />,
  },
  {
    name: "Students",
    route: "students",
    pageRegex: new RegExp(/^students(([\/a-z0-9-])+)?$/),
    icon: (color) => <PeopleIcon color={color} />,
  },
  {
    name: "Subscriptions",
    route: "subscriptions",
    pageRegex: new RegExp(/^(subscriptions|addSubscription)$/),
    icon: (color) => <CalenderTimeIcon color={color} />,
  },
  {
    name: "LMS",
    route: "lmsListing",
    pageRegex: new RegExp(/^(lmsListing|([a-z]+Session))(([\/a-z0-9-])+)?$/),
    icon: (color) => <NumberedListIcon color={color} />,
  },
  {
    name: "Batches",
    route: "adminBatches",
    pageRegex: new RegExp(/^adminBatches(([\/a-z0-9-])+)?$/),
    icon: (color) => <PeopleIcon2 color={color} />,
  },
  {
    name: "Preferences",
    route: "preference-listing",
    pageRegex: new RegExp(/^(preference-listing|create-preference)$/),
    icon: (color) => <PeopleAdd color={color} />,
  },
];
var user = JSON.parse(localStorage.getItem("user_data"));
const TEACHER_TOP_NAV = [
  {
    name:
      // user?.user_data?.role == "TEACHER"
      //   ? user.user_data.user.user.name + "'s " + "Batches":
      "My batches",
    route: "batchesListing",
    pageRegex: new RegExp(/^batchesListing(([\/a-z0-9-])+)?$/),
    icon: (color) => <BackpackIcon color={color} />,
  },
];

const BOTTOM_NAV = [
  // {
  //   name: 'Settings',
  //   pageRegex: 'settings',
  //   icon: color => <SettingsIcon color={color} />
  // },
  {
    name: "Logout",
    route: "/",
    pageRegex: new RegExp(/^\/$/),
    icon: (color) => <BackpackIcon color={color} />,
  },
];

class SideNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavName: "teacherListing",
      userRole: "",
    };
  }

  componentDidMount() {
    if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/");
    }

    let activePage = "";
    if (this.props.location.pathname.includes("lmsListing")) {
      activePage = "lmsListing";
    } else if (this.props.location.pathname.includes("subscriptions")) {
      activePage = "subscriptions";
    } else if (this.props.location.pathname.includes("classes")) {
      activePage = "classes";
    } else if (this.props.location.pathname.includes("students")) {
      activePage = "students";
    } else if (this.props.location.pathname.includes("teacherListing")) {
      activePage = "teacherListing";
    } else if (this.props.location.pathname.includes("batchesListing")) {
      activePage = "batchesListing";
    } else if (this.props.location.pathname.includes("preference-listing")) {
      activePage = "preference-listing";
    }
    this.setState({
      activeNavName: activePage,
    });

    if (LocalStorage.fetch.userData()) {
      this.setState({
        userRole: LocalStorage.fetch.userData().role,
      });
    }
  }

  onNavPress = (navItem) => {
    if (navItem.name === "Logout") {
      LocalStorage.destroy.authToken();
      let route = "/";
      if (this.isTeacherLogin()) route = "/teacher-login";
      this.props.history.push(route);
    } else if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/");
    } else {
      this.setState(
        {
          activeNavName: navItem.route,
        },
        () => this.props.history.push(`/${navItem.route}`)
      );
    }
  };

  renderNav = (navList) => {
    const activeNavName = window.location.pathname.slice(1);
    if (navList && navList.length) {
      return (
        <ul className="admin-top-nav">
          {navList.map((navItem, index) => {
            const isActive = navItem.pageRegex?.test(
              window.location.pathname.slice(1)
            );
            return (
              <li
                key={index}
                className={isActive ? "active" : ""}
                onClick={() => this.onNavPress(navItem)}
              >
                {navItem.icon(isActive ? "#fff" : undefined)}
                {navItem.name}
              </li>
            );
          })}
        </ul>
      );
    }
  };

  isTeacherLogin = () =>
    ![USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN].includes(this.state.userRole);

  render() {
    return (
      <aside
        className={`admin-sidenav ${this.props.visible ? "nav-visible" : ""}`}
      >
        <a href="/admin" className="admin-logo">
          <img src={LogoImg} alt="Freadom" />
        </a>
        <nav>
          {this.renderNav(
            this.isTeacherLogin() ? TEACHER_TOP_NAV : ADMIN_TOP_NAV
          )}
          {this.renderNav(BOTTOM_NAV)}
        </nav>
      </aside>
    );
  }
}

export default connect()(withRouter(SideNavbar));
