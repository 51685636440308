import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { submitAnswer } from "store/story/actions";
import * as activityActions from "store/activities/actions"
import cn from "classnames";

import "./Quiz.scss";

import { Mixpanel } from '../../Mixpanel';

class Quiz extends Component {
  state = {
    questionNo: 0,
    optionSelected: false,
    rewardData: null
  };

  componentDidMount() {
    const { questions } = this.props;
    const { questionNo } = this.state;
    if (questions[questionNo].given_answer) {
      this.setState({optionSelected: questions[questionNo].given_answer})
    }
  }
  selectOption = async option => {
    if (this.state.optionSelected === false) {
      this.setState({ optionSelected: option.id });
      let result
      if(this.props.isStory) {
        Mixpanel.track('')
        const answerPayload = {
          option: option.id,
          question: option.question,
          story: this.props.questions[this.state.questionNo].story
        }
        result = await this.props.submitAnswer(answerPayload);
      } else {
        Mixpanel.track('Activities Questions Submit Click', this.props.questions[this.state.questionNo])
        const answerPayload = {
          option: option.id,
          question: option.question,
          activity: this.props.questions[this.state.questionNo].activity
        }
        result = await this.props.submitActivityQuizAnswerRequest(answerPayload);
      }
      if (result.result.freadom_point_earned) { // Meaning that this was the last question
        await this.setState({rewardData: result.result});
      }
    }
  };
  proceedToNext = () => {
    const { questions } = this.props;
    const { rewardData } = this.state;
    if (this.state.questionNo + 1 < this.props.questions.length) {
      this.setState(prevState => ({
        optionSelected: questions[prevState.questionNo+1].given_answer || false,
        questionNo: prevState.questionNo + 1
      }));
    } else {
      if (rewardData) {
        this.props.finalAnswer(rewardData);
      } else {
        this.props.closeQuiz();
      }
    }
  };

  render() {
    const { questions } = this.props;
    const { questionNo, optionSelected } = this.state;
    const totalQuestions = questions.length;
    const currentQuestion = questions[questionNo];
    return (questions && currentQuestion ?
      <div className="component-quiz">
        <strong>QUESTION {questionNo + 1}</strong>/{totalQuestions}
        <div style={{display: 'flex', marginTop: '12px'}}>
          {questions.map(q => <div className={cn("ques-stepper", {'active': currentQuestion.id === q.id})} key={q.id} />)}
        </div>
        <div className="question-card-container">
          <div className="question-card">{currentQuestion.name}</div>
          <div className="question-card-dummy"></div>
        </div>
        {currentQuestion.option.map((option, index) => (
          <div
            className={cn(
              "quiz-option",
              {
                "bg-correct":
                  optionSelected &&
                  option.id === currentQuestion.valid_option
              },
              {
                "bg-incorrect":
                  optionSelected &&
                  optionSelected === option.id &&
                  optionSelected !== currentQuestion.valid_option
              }
            )}
            onClick={() => this.selectOption(option)}
            key={option.id}
          >
            {String.fromCharCode(97 + index)}. {option.name}
          </div>
        ))}
        <div onClick={this.proceedToNext} className="next-btn">
          { optionSelected ? (questionNo === totalQuestions - 1 ? "Done >" : "Next >") : "" }
        </div>
      </div>
    : null);
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({ submitAnswer, ...activityActions }, dispatch)
)(Quiz);
