import React, { Component } from "react";
import "./index.scss";
import AdminLayout from "layout/admin/index";
import BatchCard from "./BatchCard";
import AnnouncementCard from "components/AnnouncementCard";
import ClassList from "./ClassList/index";
import { Button, Tabs } from "antd";
import { CalenderNext, CalenderPrev } from "assets/image/svg.js";
import AnnouncementModal from "components/AnnouncementModal";
import AttendanceModal from "components/AttendanceModal";
import HomeworkModal from "components/HomeworkModal";
import ChildrenModal from "../../../../components/ChildrenModal";
import ViewAllAnnouncementModal from "./ViewAnnouncementModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as batchesActions from "../../../../store/batches/actions";
import * as announcementActions from "../../../../store/announcements/actions";
import { generateFilterUrl } from "../../../../utils/commonUtils";
import isEmpty from "lodash/isEmpty";
import NextClass from "components/NextClass/NextClass.js";

const { TabPane } = Tabs;

class AdminBatchDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      showAttendancePopup: false,
      showAnnouncementPopup: false,
      showHomeworkPopup: false,
      showChildrenListPopup: false,
      showViewAnnouncementPopup: false,
      batchId: this.props.match.params?.batchId,
      typeOfClass: this.props.match.params.classType,
      previousClasses: [],
      upcomingClasses: [],
      isLoading: true,
      filters: {
        previous: {
          offset: 0,
        },
        upcoming: {
          offset: 0,
        },
      },
    };
  }

  async componentDidMount() {
    await this.props.getCurrentBatchDetails(this.state.batchId, false);
    this.getClasses();
    await this.props.getAnnouncements(this.props.match.params?.batchId);
  }

  getClasses = async (typeOfClass, fetchFresh) => {
    let filters = { ...this.state.filters };
    let resPrevious = {},
      resUpcoming = {},
      urlParams = "";
    this.setState({
      isLoading: true,
    });
    switch (typeOfClass) {
      case "previous":
        if (fetchFresh || this.state.resPrevious?.has_next) {
          urlParams = generateFilterUrl(filters.previous);
          resPrevious = await this.props.getBatchClasses(
            this.state.batchId,
            "previous",
            urlParams
          );
          this.isFetchingMoreData = false;
        }
        break;
      case "upcoming":
        if (fetchFresh || this.state.resUpcoming?.has_next) {
          urlParams = generateFilterUrl(filters.upcoming);
          resUpcoming = await this.props.getBatchClasses(
            this.state.batchId,
            "upcoming",
            urlParams
          );
          this.isFetchingMoreData = false;
        }
        break;
      default:
        urlParams = generateFilterUrl(filters.previous);
        resPrevious = await this.props.getBatchClasses(
          this.state.batchId,
          "previous",
          urlParams
        );
        urlParams = generateFilterUrl(filters.upcoming);
        resUpcoming = await this.props.getBatchClasses(
          this.state.batchId,
          "upcoming",
          urlParams
        );
        this.isFetchingMoreData = false;
        break;
    }
    this.setState({
      isLoading: false,
      batchDetails: this.props.batches.currentBatch,
      resPrevious,
      resUpcoming,
      [`previousClasses`]: fetchFresh
        ? [...(resPrevious?.class_sessions || [])]
        : !isEmpty(resPrevious)
        ? [...this.state.previousClasses, ...resPrevious?.class_sessions]
        : [...this.state.previousClasses],
      [`upcomingClasses`]: fetchFresh
        ? [...(resUpcoming?.class_sessions || [])]
        : !isEmpty(resUpcoming) && !fetchFresh
        ? [...this.state.upcomingClasses, ...resUpcoming?.class_sessions]
        : [...this.state.upcomingClasses],
    });
  };
  setClassType = async (typeOfClass = this.state.typeOfClass) => {
    if (typeOfClass === "1") {
      typeOfClass = "upcoming";
    } else {
      typeOfClass = "previous";
    }
    this.props.history.push(
      `/adminBatches/${this.props.match.params?.batchId}/${typeOfClass}`
    );
    if (typeOfClass)
      this.setState({
        typeOfClass,
        filters: {
          upcoming: {
            offset: 0,
          },
          previous: {
            offset: 0,
          },
        },
      });
  };
  refreshList = async () => {
    this.getClasses(this.props.match.params.classType, true);
  };

  async onAnnoucementSubmit(data) {
    if (data)
      await this.props.makeAnnoucement(this.props.match.params?.batchId, {
        data: {
          title: data.title,
          description: data.details,
          tags: [data.tag],
        },
      });
    this.setState({
      showAnnouncementPopup: false,
    });
  }

  onChangePopupState(type) {
    switch (type) {
      case "announcement":
        this.setState({
          showAnnouncementPopup: !this.state.showAnnouncementPopup,
        });
        break;
      case "children-list":
        this.setState({
          showChildrenListPopup: !this.state.showChildrenListPopup,
        });
        break;
      case "view-announcement":
        this.setState({
          showViewAnnouncementPopup: !this.state.showViewAnnouncementPopup,
        });
    }
  }

  handleBackNavigation = () => {
    this.props.history.push({
      pathname: "/adminBatches",
      state: {
        filters: this.props?.location?.state?.state.updatedfilter,
        type: this.props?.location?.state?.type,
        search: this.props?.location?.state?.search,
      },
    });
    // this.props.history.push("/adminBatches");
  };

  onScrollClasses = (event) => {
    if (
      (Math.ceil(event.target.scrollHeight - event.target.scrollTop) ===
        event.target.clientHeight ||
        Math.floor(event.target.scrollHeight - event.target.scrollTop) ===
          event.target.clientHeight) &&
      !this.isFetchingMoreData
    ) {
      this.isFetchingMoreData = true;
      if(this.state.resUpcoming?.has_next || this.state.resPrevious?.has_next)this.setState(
        {
          filters: {
            ...this.state.filters,
            [this.state.typeOfClass]: {
              offset: this.state.filters[this.state.typeOfClass].offset + 1,
            },
          },
        },
        () => {
          this.getClasses(this.state.typeOfClass);
        }
      );
    }
  };

  render() {
    const titleUpcoming = (
      <p>
        <CalenderNext color="#999999" height={16} /> UPCOMING CLASSES
      </p>
    );
    const titlePrevious = (
      <p>
        <CalenderPrev color="#999999" height={16} /> PREVIOUS CLASSES
      </p>
    );
    let nextClassToAttend = this.state.upcomingClasses?.[0];

    let nextClassTime = {
      classStartDateTime: nextClassToAttend?.event?.start_timestamp,
      classEndDateTime: nextClassToAttend?.event?.end_timestamp,
    };
    return (
      <div className="batch-details">
        <AdminLayout title="">
          <div className="header">
            <Button className="back-btn" onClick={this.handleBackNavigation}>
              &lt; Back
            </Button>
            <h1>{this.state.batchDetails?.name || ""}</h1>
          </div>
          <div className="container">
            <div className="main">
              <div className="card-container">
                <Tabs
                  onChange={this.setClassType}
                  type="card"
                  defaultActiveKey={
                    this.props.match.params.classType === "previous" ? "2" : "1"
                  }
                >
                  <TabPane tab={titleUpcoming} key="1">
                    <ClassList
                      refreshData={async () => {
                        await this.refreshList();
                      }}
                      type={this.props.match.params.classType}
                      data={this.state.upcomingClasses}
                      onScrollClasses={this.onScrollClasses}
                      loadMore={this.state.isLoading}
                    />
                  </TabPane>
                  <TabPane tab={titlePrevious} key="2">
                    <ClassList
                      refreshData={async () => await this.refreshList()}
                      data={this.state.previousClasses}
                      type={this.props.match.params.classType}
                      onScrollClasses={this.onScrollClasses}
                      loadMore={this.state.isLoading}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
            <div className="sidebar">
              <NextClass
                nextClassTime={nextClassTime}
                nextClassToAttend={nextClassToAttend}
              />
              <BatchCard
                data={this.state.batchDetails}
                classCount={this.state.batchDetails?.session_count}
                onViewAllChildren={() =>
                  this.props.history.push(
                    `/adminBatches/${this.state.batchId}/childrenAttending`
                  )
                }
              />
              <AnnouncementCard
                onChangeAnnPopup={() => this.onChangePopupState("announcement")}
                onViewAll={() => this.onChangePopupState("view-announcement")}
                announcements={
                  this.props.announcements
                    ? this.props.announcements.length > 2
                      ? this.props.announcements.slice(0, 2)
                      : this.props.announcements
                    : []
                }
              />
            </div>
          </div>

          {
            <AttendanceModal
              closeModal={() => this.onChangePopupState("attendance")}
              visible={this.state.showAttendancePopup}
            />
          }
          {
            <AnnouncementModal
              onSubmit={(data) => this.onAnnoucementSubmit(data)}
              closeModal={() => this.onChangePopupState("announcement")}
              visible={this.state.showAnnouncementPopup}
            />
          }
          {
            <HomeworkModal
              closeModal={() => this.onChangePopupState("homework")}
              visible={this.state.showHomeworkPopup}
            />
          }
          <ViewAllAnnouncementModal
            closeModal={() => this.onChangePopupState("view-announcement")}
            onChangeAnnPopup={() => this.onChangePopupState("announcement")}
            visible={this.state.showViewAnnouncementPopup}
            announcements={this.props.announcements}
          />
        </AdminLayout>
        <ChildrenModal
          batchId={this.state.batchId}
          visible={this.state.showChildrenListPopup}
          closeModal={() => this.onChangePopupState("children-list")}
        />
      </div>
    );
  }
}

export default connect(
  ({ batches, announcements }) => ({ batches: batches, ...announcements }),
  (dispatch) =>
    bindActionCreators({ ...batchesActions, ...announcementActions }, dispatch)
)(AdminBatchDetails);
