import React, { Component } from "react";
import moment from "moment";
import { getDurationFromSeconds } from "../../../../utils/commonUtils";

export const TimeCell = ({ data, format }) => (
  <div>
    <p className="listing-row-primary-text">
      {format ? (data && moment(data).format(format)) || "-" : data}
    </p>
  </div>
);

export const TeacherCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data}</p>
  </div>
);
export const EmailCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data}</p>
  </div>
);
export const MobileNumberCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data}</p>
  </div>
);
export const StudentCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data}</p>
  </div>
);
export const GradesCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data}</p>
  </div>
);
export const StartTopicCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data}</p>
  </div>
);

export const Duration = ({ data }) => {
  let duration = getDurationFromSeconds(data);
  return (
    <div>
      <p className="listing-row-primary-text">{duration}</p>
    </div>
  );
};

export const Tag = ({ data }) => {
  let status = data?.toLowerCase();
  return (
    <div>
      <p
        className={[
          "listing-row-status-tag",
          status?.split(" ")?.join("-"),
        ].join(" ")}
      >
        {status}
      </p>
    </div>
  );
};

const weekDays = {
  1: "M",
  2: "T",
  3: "W",
  4: "Th",
  5: "F",
  6: "Sa",
  7: "Su",
};

export const Schedule = ({ schedules }) => {
  let days = schedules.map((schedule) => (
    <span className={"weekday"}>{weekDays[schedule.weekday]}</span>
  ));
  return <p className={"listing-row-primary-text"}>{days}</p>;
};

export const NumberCell = ({ data }) => (
  <div>
    <p className="listing-row-primary-text">{data || "-"}</p>
  </div>
);
