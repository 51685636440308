import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    parent: Yup.object({
        email: Yup.string().email("Invalid email format").required("Email is required").nullable(),
    }),
    details: Yup.object({
        father_details: Yup.object().test('fatherDetails', 'Please fill either father or mother details completely', function (fd) {
            const {mother_details} = this.parent;
            if (!(mother_details.employer_name && mother_details.name && mother_details.salary_bracket)) {
                return fd.employer_name && fd.name && fd.salary_bracket
            }
            return true;
        }),
        mother_details: Yup.object().test('fatherDetails', 'Please fill either father or mother details completely', function (md) {
            const {father_details} = this.parent;
            if (!(father_details.employer_name && father_details.name && father_details.salary_bracket)) {
                return md.employer_name && md.name && md.salary_bracket
            }
            return true;
        }),
        // mother_details: Yup.object().when('father_details', {
        //     is: (fd) => fd.employer_address && fd.employer_name && fd.name && fd.salary_bracket,
        //     then: Yup.object({
        //         employer_address: Yup.string(),
        //         employer_name: Yup.string(),
        //         name: Yup.string(),
        //         salary_bracket: Yup.string(),
        //     }),
        //     otherwise: Yup.object({
        //         employer_address: Yup.string().required("Employer address is required"),
        //         employer_name: Yup.string().required("Employer name is required"),
        //         name: Yup.string().required("Mother's name is required"),
        //         salary_bracket: Yup.string().required("Salary bracket is required"),
        //     }).required(),
        // }),
        school_monthly_expense: Yup.number().typeError("Monthly expenses should be numeric").required("Montly expenses is required"),
        school_name: Yup.string().required("School name is required"),
        pincode: Yup.number().typeError("Pincode should be numeric").required("Pincode is required"),
        address: Yup.string().required("Address is required"),
        city: Yup.string().required("City is required"),
        locality: Yup.string().required("Locality is required"),
        other_classes: Yup.array().of(Yup.string().required('This can\'t be empty')).min(1, 'Atleast one classes should be there'),
        program_outcomes: Yup.array().of(Yup.string().required('This can\'t be empty')).min(1, 'Atleast one expectation should be there'),
        state: Yup.string().required("State is required")
    })
})
