import React from "react";
import {Form, Col, DatePicker} from 'antd';
import moment from 'moment';
import "./index.scss";

const ContractDate = ({onChange, startDate, endDate, showValidationError, isEdit, getFieldDecorator}) => {

    const getDisabledDates = (current, minDate, diff = 1) => {
        return current && minDate && current.diff(minDate, 'days') < diff;
    }

    const onSelection = (key, selectedDate) => {
        const dateTimeStamp = selectedDate && parseInt(moment(selectedDate)?.format("X"))
        onChange(key, dateTimeStamp)
    }

    return (
        <React.Fragment>
            <Col span={10} style={{padding: '8px 8px 8px 0'}}>
                <Form.Item
                    label="CONTRACT START DATE"
                >
                    {
                        getFieldDecorator('contract_start_timestamp', {
                            rules: [
                                {required: true, message: 'Please select date!'},
                            ],
                        })
                        (isEdit
                            ?
                            <DatePicker
                                defaultValue={startDate}
                                allowClear={false}
                                inputReadOnly={false}
                                showToday={false}
                                disabledDate={current => getDisabledDates(current, moment(), 0)}
                                // onChange={onSelection}
                                onChange={currentVal => onSelection('contract_start_timestamp', currentVal)}
                                rules={[{required: true, message: 'Please select date!'}]}
                            />
                            :
                            <DatePicker
                                allowClear={false}
                                inputReadOnly={false}
                                showToday={false}
                                disabledDate={current => getDisabledDates(current, moment(), 0)}
                                // onChange={onSelection}
                                onChange={currentVal => onSelection('contract_start_timestamp', currentVal)}
                                rules={[{required: true, message: 'Please select date!'}]}
                            />)
                    }
                </Form.Item>
            </Col>
            <Col span={10} style={{padding:'8px 8px 8px 0'}}>
                <Form.Item
                    label="CONTRACT END DATE"
                >
                    {
                        getFieldDecorator('contract_end_timestamp', {
                            rules: [
                                {required: true, message: 'Please select date!'},
                            ],
                        })
                        (isEdit
                            ?
                            <DatePicker
                                defaultValue={endDate}
                                allowClear={false}
                                inputReadOnly={false}
                                showToday={false}
                                disabledDate={current => getDisabledDates(current, startDate)}
                                onChange={currentVal => onSelection('contract_end_timestamp', currentVal)}
                            />
                            :
                            <DatePicker
                                allowClear={false}
                                inputReadOnly={false}
                                showToday={false}
                                disabledDate={current => getDisabledDates(current, startDate)}
                                onChange={currentVal => onSelection('contract_end_timestamp', currentVal)}
                            />)
                    }
                </Form.Item>
            </Col>
        </React.Fragment>
    );
}

export default ContractDate;
