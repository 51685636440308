import React, {Component} from 'react';
import {Input, Modal, Select} from 'antd';
import isEmpty from 'lodash/isEmpty';
import "./index.scss";
import {CrossIcon} from "../../../../../assets/image/svg";

const {TextArea} = Input;
const {Option} = Select;

class AddStudentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGrade: "Select a grade",
            selectedPack: "Select a pack",
            selectedLevel: -1,
            lsq: null,
        }
    }

    onSelectionChange(event, type) {
        switch (type) {
            case "grade":
                this.setState({
                    selectedGrade: event,
                    selectedLevel: -1,
                    selectedPack: "Select a pack",
                })
                break;
            case "pack":
                this.setState({
                    selectedPack: event,
                })
                break;
            case "level":
                this.setState({
                    selectedLevel: event,
                })
                break;
            case "lsq":
                this.setState({
                    lsq: event.target.value
                })
                break;
            default:
                break;
        }
    }

    componentWillUnmount() {
        this.setState({
            selectedGrade: "Select a grade",
            selectedPack: "Select a pack",
            selectedLevel: -1,
        })
    }

    render() {
        const gradeList = this.props.grades?.gradesList?.map((grade, index) => (
            //FLV-94
            // <Option value={index}>{grade.name}</Option>
            <Option value={grade.id}>{grade.name}</Option>
        ));

        const subscriptions = !isEmpty(this.props.subscriptions) ? Object.values(this.props.subscriptions) : []
        const subscriptionListItems = subscriptions?.filter(pack => {
            //FLV-94
            //let packsFound = (pack?.grades?.filter(grade => grade.id === this.state.selectedGrade + 1))
            let packsFound = (pack?.grades?.filter(grade => grade.id === this.state.selectedGrade))
            //FLV-47
            return (packsFound?.length > 0 && pack!=null && pack.is_active && !pack.is_deleted)
        })
        const subscriptionList = subscriptionListItems.map(pack => (
            <Option value={pack.id}>{pack?.label}</Option>
        ))
        return (
            <Modal
                wrapClassName="add-student-modal"
                maskStyle={{
                    background: "rgba(0,0,0,0.45)"
                }}
                title="Add Student"
                width={500}
                visible={this.props.visible}
                closeIcon={<div className={'anticon'}><CrossIcon width={18} height={18} color={'#524E59'}/></div>}

                onOk={() => {
                    if (this.state.selectedPack == "Select a pack" || this.state.selectedGrade == "Select a grade" || !this.state.lsq?.trim()) {
                        return;
                    }
                    let selectedPack = subscriptionListItems?.filter(item => item.id === this.state.selectedPack)?.[0]
                    this.props.onSubmit({
                        selectedPack,
                        child: {
                            //FLV-94
                            grade: this.props.grades.gradesList
                            .filter((gr)=>gr.id == this.state.selectedGrade)[0].id,//this.props.grades.gradesList[this.state.selectedGrade].id,
                            crm_lsq_id: this.state.lsq,
                        }
                    })
                    this.setState({
                        selectedGrade: "Select a grade",
                        selectedPack: "Select a pack",
                        selectedLevel: -1,
                        lsq: null
                    })
                }}
                onCancel={() => {
                    this.setState({
                        selectedGrade: "Select a grade",
                        selectedPack: "Select a pack",
                        selectedLevel: -1,
                        lsq: null
                    })
                    this.props.closeModal()
                }}
                okText="Submit"
            >
                <p>FLIVE GRADE</p>
                <Select
                    value={this.state.selectedGrade}
                    onChange={(event) => (this.onSelectionChange(event, "grade"))}
                >
                    {gradeList}
                </Select>
                <p>SUBSCRIPTION NAME</p>
                <Select
                    value={this.state.selectedPack ? this.state.selectedPack : subscriptionListItems[0]}
                    onChange={(event) => (this.onSelectionChange(event, "pack"))}
                    defaultValue={subscriptionListItems[0]}
                >
                    {subscriptionList}
                </Select>
                <p>CRM (LSQ) ID</p>
                <Input
                    onChange={(event) => (this.onSelectionChange(event, "lsq"))}
                    placeholder="Enter LSQ"
                    value={this.state.lsq}
                />
            </Modal>
        );
    }
}

export default AddStudentModal;
