import React, { Component } from "react";
import "./NotFound.scss";

class NotFound extends Component {
  render() {
    return (
      <div className="component-not-found">
        <div>404</div>
        <div style={{ fontSize: "18px" }}>There's nothing here</div>
        <div className="link" onClick={() => this.props.history.push("/")}>
          <b>Click Here</b>
        </div>
      </div>
    );
  }
}

export default NotFound;
