import {initialState} from "./state";

export const teacher = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_NEW_TEACHER": {
            return Object.assign({}, state, {
                newTeacherSubmitResponse: action.data
            });
        }
        case "GET_TEACHERS": {
            return Object.assign({}, state, {
                teachersData: action.data
            });
        }
        case "GET_RECOMMENDED_TEACHERS": {
            return Object.assign({}, state, {
                recommendedTeachers: action.data
            });
        }
        case "UPDATE_TEACHER_STATUS": {
            return Object.assign({}, state, {
                isTeacherStatusUpdated: action.data
            });
        }
        case "UPDATE_TEACHER_DATA": {
            return Object.assign({}, state, {
                isTeacherDataUpdated: action.data
            });
        }
        default:
            return state;
    }
};
