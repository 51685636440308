import React, { Component } from "react";

// Libraries
import CloneDeep from "lodash/cloneDeep";
import cloneDeep from "lodash/cloneDeep";
import Has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

// Ant Design
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as teacherActions from "store/teacher/actions";
import * as lmsActions from "store/lms/actions";

// Local Storage
import { storage as LocalStorage } from "services/config/storage";

// Components
import AdminLayout from "layout/admin";
import DayOfWeeksOff from "./dayOfWeek";
import WorkTimings from "./workTimings";
import GradeLevels from "./gradeLevels";

// SCSS
import "./index.scss";

// Utils
import {
  convertStringTimeToSeconds,
  getVisibleGradesList,
} from "utils/commonUtils";
import Avatar from "../../../../components/Avatar";
import ContractDate from "./contractDate";
import DailyHoursAndClasses from "./dailyHoursAndClasses";
import PreviousWork from "./PreviousWork";
import { returnSlot } from "../../../../utils/commonUtils";

const languages = [
  { label: "Assamese", value: "Assamese" },
  { label: "Bangla", value: "Bangla" },
  { label: "Bodo", value: "Bodo" },
  { label: "English", value: "English" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Hindi", value: "Hindi" },
];

const { Option } = Select;
const defaultStartWorkingTime = undefined;
const defaultEndWorkingTime = undefined;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^[0-9]{10}$/;
const FIELDS_TO_VALIDATE = [
  "pic",
  "name",
  "email",
  "phone_number",
  "languages_spoken",
  "bio",
  "course_types",
  "contract_start_timestamp",
  "contract_end_timestamp",
  "num_classes_per_day",
  "num_hours_per_day",
  "allowed_grade_levels",
  "working_days",
  "teacher_id",
  "work_experience",
];

class CreateTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      selected: [],
      levelSelected: [],
      isSubmitEnable: false,
      showValidationError: false,
      ...this.onInit(),
    };
  }

  componentDidMount() {
    if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/");
      return;
    }
    // this.setState({ isSubmitEnable: this.isAllFieldsAvailable(this.state.formData) });
    if (
      !this.props.gradesList ||
      (this.props.gradesList && this.props.gradesList.length <= 0)
    ) {
      this.props.getGradesListRequest();
    }
    this.setFormFields(this.state.formData);
  }

  onInit = () => {
    const initialStates = cloneDeep(this.props.location.state);
    if (initialStates) {
      if (initialStates && Has(initialStates, "formData.user.phone_number")) {
        initialStates.formData.user.phone_number =
          initialStates.formData.user.phone_number.substring(
            initialStates.formData.user.phone_number.length - 10
          );
      }
      initialStates.formData.autofillTimeslots =
        initialStates.formData.timeslots;
      return initialStates;
    }
    return {
      formData: {
        pic: "",
        user: {
          name: "",
          email: "",
          phone_number: "",
        },
        languages_spoken: [],
        bio: "",
        course_types: [],
        contract_start_timestamp: "",
        contract_end_timestamp: "",
        num_classes_per_day: null,
        num_hours_per_day: null,
        allowed_grade_levels: {},
        working_days: [],
        teacher_id: "",
        work_experience: [],
      },
    };
  };

  setFormFields = (state) => {
    let fieldKeys = state && Object.keys(state);
    fieldKeys.forEach((key) => {
      if (key === "user" && !isEmpty(state?.[key])) {
        let profileKeys = Object.keys(state?.[key]);
        profileKeys.forEach((profileKey) => {
          return this.props.form.setFieldsValue({
            [profileKey]: state?.[key]?.[profileKey],
          });
        });
      }
      let value = state?.[key];
      if (
        value &&
        (key === "contract_end_timestamp" || key === "contract_start_timestamp")
      )
        value = moment(value * 1000);
      value && this.props.form.setFieldsValue({ [key]: value });
    });
  };
  updateFormDataState = (key, value) => {
    if (key) {
      const formData = CloneDeep(this.state.formData);
      formData[key] = value;
      this.setState({ formData });
    }
  };

  isValidValue = (value) => {
    if (typeof value === "object") {
      return isArray(value)
        ? isEmpty(value)
        : Object.values(value).some((item) => this.isValidValue(item));
    }
    return !value;
  };

  onUserDetailsChange = (key, value) => {
    if (key && value) {
      const user = CloneDeep(this.state.formData?.user);
      user[key] = value?.target?.value;
      this.updateFormDataState("user", user);
    }
  };

  onFormSubmit = async () => {
    if (this.isFormValid()) {
      const requestBody = CloneDeep(this.state.formData);
      if (Has(requestBody, "user.phone_number")) {
        requestBody.user.phone_number = `+91${requestBody.user.phone_number}`;
      }
      const parentProps = cloneDeep(this.props.location.state);
      let res;
      requestBody.timeslots = this.createTimeSlots();
      requestBody.status = 1;
      console.log("Payload", requestBody);
      if (parentProps && parentProps.id) {
        delete requestBody.id;
        delete requestBody.status;
        delete requestBody.user.external_id;
        delete requestBody.user.properties;
        delete requestBody.user.id;
        res = await this.props.updateTeacherData(parentProps.id, requestBody);
      } else {
        res = await this.props.addNewTeacher(requestBody);
      }

      if (res && res.data && res.data.id) {
        this.props.history.push("/teacherListing");
      }
    } else if (this.state.showValidationError === false) {
      this.setState({ showValidationError: true });
    }
  };
  createTimeSlots = () => {
    let {
      formData: { timeslots },
    } = this.state;
    let arr = [];
    arr = timeslots.map((item) => ({
      weekday: +item.weekday,
      start_second: item.isEdited
        ? convertStringTimeToSeconds(item.startTime || item?.start_second)
        : item?.start_second,
      duration: item.isEdited
        ? convertStringTimeToSeconds(item.endTime, false) -
          convertStringTimeToSeconds(item.startTime, false)
        : item?.duration,
    }));
    return arr;
  };

  isFormValid = (e) => {
    e && e.preventDefault();
    let isFormValid = false;
    const {
      formData: { timeslots },
    } = this.state;
    this.props.form.validateFieldsAndScroll(
      FIELDS_TO_VALIDATE,
      {},
      async (err, values) => {
        console.log("Error", err);
        console.log("values", values);
        let isTimeslotsTouched = false;
        let slots = timeslots.map((item) => {
          return returnSlot(item);
        });
        if (!isEmpty(slots)) {
          slots.map((item) => {
            isTimeslotsTouched = true;
            if (item.id) {
              isFormValid = true;
              // return true;
            }
            if (item?.startTime && item?.endTime) {
              this.setState({
                errors: {
                  ...this.state.errors,
                  timeslots: null,
                },
              });
              isFormValid = true;
            } else {
              this.setState({
                errors: {
                  ...this.state.errors,
                  timeslots: "Please enter the time slot",
                },
              });
              isFormValid = false;
            }
            // return true;
          });
        } else isFormValid = false;
        if (isTimeslotsTouched && !isFormValid) {
          isFormValid = false;
        } else isFormValid = !err;
      }
    );
    return isFormValid;
  };

  render() {
    console.log("!!!!!props", this.props);
    const { formData, showValidationError, errors } = this.state;
    const {
      user,
      languages_spoken,
      bio,
      teacher_id,
      num_classes_per_day,
      num_hours_per_day,
      contract_start_timestamp,
      contract_end_timestamp,
      working_days,
      timeslots,
      autofillTimeslots,
      allowed_grade_levels,
      work_experience,
    } = formData;
    const { name, email, phone_number } = user || {};
    const { getFieldDecorator, setFieldsValue, getFieldValue } =
      this.props.form;
    console.log("allowed_grade_levels", allowed_grade_levels);

    return [
      <AdminLayout title="">
        <div className="add-teacher-header">
          <Button
            className="add-teacher-header__back-btn"
            onClick={(event) => {
              console.log("this.filters>back", this.state.filters);
              this.props.history.push({
                pathname: "/teacherListing",
                state: {
                  filters: this.state.filters,
                },
              });
            }}
          >
            &lt; Back
          </Button>
          <h1>
            {this.props.location.pathname.includes("update")
              ? "Edit"
              : "Add New"}{" "}
            Teacher
          </h1>
        </div>
        <section className="add-teacher-wrapper">
          <Form
            onSubmit={this.isFormValid}
            name="teacher-create"
            initialValues={{ remember: true }}
            className="teacher-form"
          >
            <Row gutter={[16, 16]}>
              <Col lg={16} md={20} sm={24}>
                <h2 className="heading1">personal details</h2>
                {
                  //TODO: Teacher profile picture integration
                }
                <Col span={24} style={{ padding: "8px 8px 8px 0" }}>
                  <Avatar
                    onChange={this.updateFormDataState}
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    getPresignedUrl={this.props.getPresignedUrl}
                  />
                </Col>
                <Col span={20} style={{ padding: "8px 8px 8px 0" }}>
                  <Form.Item
                    label="Name of teacher"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name!" },
                    ]}
                  >
                    {getFieldDecorator("name", {
                      rules: [
                        { required: true, message: "Please enter your name!" },
                        {
                          pattern: /^[a-zA-Z\s]+$/,
                          message: "Only alphabets allowed",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Name"
                        maxLength={100}
                        value={name}
                        onChange={(currentVal) => {
                          this.onUserDetailsChange("name", currentVal);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={20} style={{ padding: "8px 8px 8px 0" }}>
                  <Form.Item label="Employee Code">
                    {getFieldDecorator("teacher_id", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter employee code!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter employee code"
                        maxLength={17}
                        value={teacher_id}
                        onChange={(currentVal) =>
                          this.updateFormDataState(
                            "teacher_id",
                            currentVal.target.value
                          )
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={10} style={{ padding: "8px 14px 8px 0" }}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email!" },
                    ]}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        { required: true, message: "Please enter your email!" },
                        {
                          pattern: EMAIL_REGEX,
                          message: "Invalid email format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter email"
                        value={email}
                        onChange={(currentVal) =>
                          this.onUserDetailsChange("email", currentVal)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={10} style={{ padding: "8px 21px 8px 0" }}>
                  <Form.Item
                    label="phone no."
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number!",
                      },
                    ]}
                  >
                    {getFieldDecorator("phone_number", {
                      rules: [
                        { required: true, message: "Please enter your phone!" },
                        {
                          pattern: PHONE_REGEX,
                          message: "Invalid phone format!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Phone No."
                        maxLength={10}
                        value={phone_number}
                        onChange={(currentVal) =>
                          this.onUserDetailsChange("phone_number", currentVal)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={20} style={{ padding: "8px 8px 8px 0" }}>
                  <Form.Item label="LANGUAGES SPOKEN">
                    {getFieldDecorator("languages_spoken", {
                      rules: [
                        {
                          required: true,
                          message: "Please select at least one language",
                        },
                      ],
                    })(
                      <Select
                        placeholder={"Select Languages"}
                        selected={languages_spoken}
                        showArrow={true}
                        mode={"multiple"}
                        disableSearch={true}
                        showSearch={false}
                        onChange={(selected) => {
                          this.updateFormDataState(
                            "languages_spoken",
                            selected
                          );
                        }}
                        optionFilterProp={"label"}
                        dropdownClassName={"multi-select-dropdown"}
                        menuItemSelectedIcon={({ ...props }) => {
                          return (
                            <div
                              className={[
                                "dropdown-check-mark",
                                languages_spoken.includes(props.value)
                                  ? "active"
                                  : "",
                              ].join(" ")}
                            />
                          );
                        }}
                      >
                        {languages?.map((item) => (
                          <Option label={item.label} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={20} style={{ padding: "8px 8px 8px 0" }}>
                  <Form.Item label="Bio">
                    {getFieldDecorator("bio", {
                      rules: [
                        { required: true, message: "This field is mandatory" },
                      ],
                    })(
                      <Input.TextArea
                        placeholder="Enter Description here"
                        maxLength={200}
                        value={bio}
                        onChange={(currentVal) =>
                          this.updateFormDataState(
                            "bio",
                            currentVal.target.value
                          )
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={20} style={{ padding: "8px 8px 8px 0" }}>
                  <div className={"separator-line"} />
                </Col>
                <Col span={20} style={{ width: "80.4%" }}>
                  <h2 className="heading1">Current work details</h2>
                </Col>
                <Col span={20} style={{ padding: "8px 8px 8px 0" }}>
                  <Form.Item label="class type">
                    {getFieldDecorator("course_types", {
                      rules: [
                        {
                          required: true,
                          message: "Please select at least one!",
                        },
                      ],
                    })(
                      <Checkbox.Group
                        options={[
                          { label: "TRIAL CLASS", value: "TRIAL" },
                          { label: "PAID USER CLASS", value: "NORMAL" },
                        ]}
                        onChange={(course_types) =>
                          this.updateFormDataState("course_types", course_types)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <ContractDate
                  getFieldDecorator={getFieldDecorator}
                  onChange={this.updateFormDataState}
                  startDate={moment.unix(contract_start_timestamp)}
                  endDate={moment.unix(contract_end_timestamp)}
                  showValidationError={
                    showValidationError &&
                    this.state.formData.contract_start_timestamp ===
                      this.state.formData.contract_end_timestamp
                  }
                  isEdit={this.props.location.pathname.includes("update")}
                />

                <DailyHoursAndClasses
                  getFieldDecorator={getFieldDecorator}
                  hoursPerDay={num_hours_per_day}
                  classesPerDay={num_classes_per_day}
                  onChange={this.updateFormDataState}
                  showValidationError={
                    showValidationError &&
                    (num_classes_per_day === undefined ||
                      num_hours_per_day === undefined)
                  }
                />
                <div className="clearfix"></div>
                {this.props.gradesList && this.props.gradesList.length > 0 && (
                  <GradeLevels
                    allowed_grade_levels={allowed_grade_levels}
                    setFieldsValue={setFieldsValue}
                    getFieldDecorator={getFieldDecorator}
                    onChange={this.updateFormDataState}
                    gradesList={getVisibleGradesList(this.props.gradesList)}
                  />
                )}
                <DayOfWeeksOff
                  getFieldDecorator={getFieldDecorator}
                  setFieldsValue={setFieldsValue}
                  onChange={(key, value) => {
                    this.updateFormDataState(key, value);
                    setTimeout(
                      () =>
                        this.updateFormDataState(
                          "timeslots",
                          value.map((item) => ({ weekday: item }))
                        ),
                      0
                    );
                  }}
                  defaultActiveDays={working_days}
                  showValidationError={
                    showValidationError && working_days.length <= 0
                  }
                />
                <WorkTimings
                  timeslots={timeslots}
                  setFieldsValue={setFieldsValue}
                  getFieldDecorator={getFieldDecorator}
                  onChange={this.updateFormDataState}
                  startTime={formData.start_work_timing}
                  endTime={formData.end_work_timing}
                  error={errors.timeslots}
                />
                <PreviousWork
                  work_experience={work_experience}
                  onChange={this.updateFormDataState}
                  setFieldsValue={setFieldsValue}
                  getFieldDecorator={getFieldDecorator}
                />
                <Col span={20}>
                  <Form.Item>
                    <Button
                      // disabled={!this.isFormValid}
                      className="ant-btn-md export-btn"
                      onClick={this.onFormSubmit}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Form>
        </section>
      </AdminLayout>,
    ];
  }
}

export default connect(
  ({ lms }) => ({ ...lms }),
  (dispatch) =>
    bindActionCreators({ ...teacherActions, ...lmsActions }, dispatch)
)(Form.create({ name: "teacher-create" })(CreateTeacher));
