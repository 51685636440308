import React, {useEffect, useState} from 'react';
import './index.scss'
import AdminLayout from 'layout/admin/index';
import {Button} from 'antd';
import {Calender15Icon, CheckIcon, TimeIcon} from 'assets/image/svg.js'
import {HomeworkContent} from '../../../../components/HomeworkModal/HomeworkContent';
import {LearningOutcomes} from './LearningOutcome/LearningOutcomes';
import {LessonPlan} from './LessonPlan/LessonPlan';
import Pill from '../../../../components/Pill';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as classesActions from "store/classes/actions";
import moment from "moment";
import AttendanceModal from "components/AttendanceModal";
import HomeworkModal from "components/HomeworkModal";
import * as batchesActions from '../../../../store/batches/actions';

const ClassDetails = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [visibleModal, setVisibleModal] = useState(null)
    const [classDetails, setClassDetails] = useState({})

    let buttons = null;
    let upcomingPill = null;
    console.log("PROPS", props);
    const currentClass = props?.location?.state?.classInfo || {}
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            fetchClassDetails()
        })()
    }, [])

    const fetchClassDetails = async () => {
        let res = await props.getClassDetailsRequest(props?.location?.state?.classInfo?.session_id)
        if (res) {
            setClassDetails({...res.data, ...props?.location?.state?.classInfo})
            setIsLoading(false)
        }
    }

    if (props.match.params.classType == "previous") {
        buttons = (
            <div className="d-flex">
                {
                    currentClass?.is_attendance_marked ?
                        <p className="homework-text"><CheckIcon color="#219653" height={16} width={16}/> ATTENDANCE
                        </p> :
                        <Button onClick={() => setVisibleModal('attendance')} className="btn-outline-purple">MARK
                            ATTENDANCE</Button>
                }
            </div>
        );
    }

    if (props.match.params.classType === "upcoming") {
        upcomingPill = (
            <Pill text="UPCOMING" type={1}/>
        );
    }
    const hideModal = async () => {
        fetchClassDetails()
        setVisibleModal(null)
        await props.getBatchClasses(props.match.params.batchID);
    }
    return (
        <div className="class-details">
            <AdminLayout
                containerStyles={{
                    height: 'auto'
                }}
                title=""
                mobileNav={true}
            >
                <div>
                    <Button className="back-btn"
                            onClick={() => (props.history.push(`/batchesListing/${props.match.params.batchID}/${props.match.params.classType}`))}>&lt; Back</Button>
                    <div className="seperator-20 m-display-none"/>
                    <div className="shadow-box mobile-page-header">
                        <div className="seperator-10 display-desk-none"/>
                        <div className="d-flex sb subject-title">
                            <div className="d-flex mb-10">
                                <h1>{classDetails.name}</h1>
                                {upcomingPill}
                            </div>
                            <span className="desk-buttons">{buttons}</span>
                        </div>
                        <div className="class-timings d-flex">
                            <h3>
                                <div className={'icon-container'}>
                                    <Calender15Icon height={20} width={20}/>
                                </div>
                                {getTimeComponents('date', classDetails.event) + " " + getTimeComponents('month', classDetails.event)}
                            </h3>
                            <h3>
                                <div className={'icon-container'}>
                                    <TimeIcon color="#9134D8" height={20} width={20}/>
                                </div>
                                {getTimeComponents('timeSlot', classDetails.event)}
                            </h3>
                        </div>
                        <div className="seperator-20 m-display-none"/>
                        <div className="description">
                            <h5>DESCRIPTION</h5>
                            <p>{classDetails.description}</p>
                        </div>
                        <div className="mob-buttons">
                            {buttons}
                        </div>
                    </div>
                    <div className="shadow-box h-500">
                        <div className="detail-row">
                            <h2>Lesson Plan</h2>
                            <LessonPlan details={classDetails.lesson_plan}
                                        previews={classDetails.previews}
                                        links={classDetails.lesson_plan_links}/>
                        </div>
                        <div className="detail-row">
                            <h2>Homework</h2>
                            <HomeworkContent details={classDetails.homework}
                                             links={classDetails.homework_links}
                                             previews={classDetails.previews}/>
                        </div>
                        <div className="detail-row">
                            <h2>Learning Outcome</h2>
                            <LearningOutcomes details={classDetails.learning_outcome}/>
                        </div>
                    </div>
                </div>
            </AdminLayout>
            <AttendanceModal
                currentClassID={classDetails.id}
                closeModal={async () => await hideModal()}
                visible={visibleModal === 'attendance'}/>
            <HomeworkModal
                currentClassID={classDetails.id}
                currentSessionId={classDetails.session_id}
                closeModal={async () => await hideModal()}
                visible={visibleModal === 'homework'}/>

        </div>
    );
}

function getTimeComponents(key, timeEvents) {
    if (timeEvents) {
        if (key === 'date')
            return moment(timeEvents.start_timestamp).utc(true).format('Do');
        if (key === 'month')
            return moment(timeEvents.start_timestamp).utc(true).format('MMM');
        if (key === 'timeSlot') {
            let startTime = moment(timeEvents.start_timestamp).utc(true)
            let endTime = moment(timeEvents.end_timestamp).utc(true)
            return `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')}`
        }
    }
    return ''
}

export default connect(
    ({classes, batches}) => ({classes: {...classes}, ...batches}),
    dispatch => bindActionCreators({...classesActions, ...batchesActions}, dispatch)
)(ClassDetails);
