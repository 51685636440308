import React, {useEffect, useState} from "react";
import {Col, Form, Select} from 'antd';
import isEmpty from "lodash/isEmpty";
import "./index.scss";

const {Option} = Select
const GradeLevels = ({allowed_grade_levels, activeGrades = [], gradesList = [], setFieldsValue, getFieldDecorator, onChange}) => {

    const [selectedGrades, setSelectedGrades] = useState(activeGrades)


    useEffect(() => {
        if (!isEmpty(allowed_grade_levels)) {
            let gradeIds = Object.keys(allowed_grade_levels)?.map(item => +item)
            setSelectedGrades(gradeIds)
            setFieldsValue({'allowed_grade_levels': gradeIds})
        }
    }, [allowed_grade_levels])
    return (
        <div className={'grade-selector'} style={{display: "flex", alignItems: 'center'}}>
            <Col span={20} className="gutter-space" style={{padding: '8px 8px 8px 0'}}>
                <Form.Item
                    label="Grades"
                >
                    {
                        getFieldDecorator('allowed_grade_levels', {
                            rules: [{required: true, message: 'Please select a grade'}]
                        })(
                            <div>
                                <Select
                                    placeholder={'Select Grades'}
                                    selected={selectedGrades}
                                    value={selectedGrades}
                                    showArrow={true}
                                    mode={'multiple'}
                                    disableSearch={true}
                                    showSearch={false}
                                    onChange={(selected) => {
                                        let obj = {}
                                        selected.map(grade => obj[grade] = [])
                                        setSelectedGrades(selected)
                                        setFieldsValue({allowed_grade_levels: obj})
                                        onChange('allowed_grade_levels', obj);
                                    }}
                                    optionFilterProp={'label'}
                                    dropdownClassName={'multi-select-dropdown'}
                                    menuItemSelectedIcon={({...props}) => {
                                        return <div
                                            className={['dropdown-check-mark', selectedGrades.includes(props.value) ? 'active' : ''].join(' ')}
                                        />
                                    }}
                                >
                                    {
                                        gradesList?.map(item => <Option label={item.name}
                                                                        value={item.id}>{item.name}</Option>)
                                    }
                                </Select>
                            </div>
                        )
                    }
                </Form.Item>
            </Col>
        </div>
    );
}

export default GradeLevels;
