import { PreferenceGroupApiCalls } from "../../services/preferenceGroups";
import { mutation } from "./mutations";
import { batchesApiCalls } from "../../services/batches";

const handleError = (err) => {
  console.log("Error in Preference Groups action : ", err);
};

// Actions
export const getPreferenceGroups =
  (params = "") =>
  async (dispatch) => {
    try {
      let result = await PreferenceGroupApiCalls.getPreferenceGroups(params);
      dispatch(mutation.setPreferenceGroups(result.data));
      return result.data;
    } catch (err) {
      handleError(err);
    }
  };
export const removePreferenceGroup = async (payload) => {
  try {
    return await PreferenceGroupApiCalls.deletePreferenceGroupRequest(payload);
  } catch (err) {
    handleError(err);
  }
};
export const assignPreferenceGroup = async (payload) => {
  try {
    return await PreferenceGroupApiCalls.assignPreferenceGroupRequest(payload);
  } catch (err) {
    handleError(err);
  }
};
export const getPreferenceGroupDetail = (id) => async (dispatch) => {
  try {
    let result = await PreferenceGroupApiCalls.getPreferenceDetailRequest(id);
    if (result) {
      return result;
    }
    return undefined;
  } catch (err) {
    handleError(err);
  }
};

export const createPreferenceGroup = (payload) => async (dispatch) => {
  try {
    return await PreferenceGroupApiCalls.createPreferenceGroupRequest({
      data: payload,
    });
  } catch (err) {
    handleError(err);
  }
};

export const updatePreferenceGroup = (id, status) => async (dispatch) => {
  try {
    const result = await PreferenceGroupApiCalls.updatePreferenceGroupRequest(
      id,
      { data: { is_active: status } }
    );
    if (result) return result;
    return undefined;
  } catch (err) {
    handleError(err);
  }
};

export const startBatch = (data) => async (dispatch) => {
  try {
    const payload = {
      data: {
        preference_groups: data?.preference_groups,
        // preference_group_id: data?.preference_group_id
        //children: data?.childrenIds,
      },
    };
    return await batchesApiCalls.startBatchRequest(payload);
  } catch (e) {
    return handleError(e);
  }
};
