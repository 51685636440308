const setUserLoginDetail = data => ({
  type: "SET_USER_DETAIL",
  data
});

const setUserAuthToken = data => ({
  type: "SET_USER_AUTH_TOKEN",
  data
})

const setDefaultChild = data => ({
  type: "SET_DEFAULT_CHILD",
  data,
})

const setChildList = data => ({
  type: "SET_CHILD_LIST",
  data
})

const setUserData = data => ({
  type: "SET_USER_DATA",
  data
})

export const mutation = {
  setUserLoginDetail,
  setUserAuthToken,
  setDefaultChild,
  setChildList,
  setUserData
};
