import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Spin, Tooltip, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./index.scss";
import { CheckIcon } from "assets/image/svg";
import Close from "assets/image/close.svg";
import Check from "assets/image/checkmark.svg";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as batchesActions from "store/batches/actions";
import { postRequest } from "../../../../../services/config/request";
import * as classesActions from "store/classes/actions";
import { PencilIcon } from "../../../../../assets/image/svg";
const spinner = <LoadingOutlined style={{ fontSize: 23 }} spin />;

const ClassList = ({ onScrollClasses, loadMore, ...props }) => {
  const [visibleZoomLinkInput, setVisibleZoomLinkInput] = useState(null);
  const [zoomLink, setZoomLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(loadMore);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rangePickerValue, setRangePickerValue] = useState({
    startValue: "",
    endValue: "",
  });

  useEffect(() => {
    //reset zoom link state on tab change
    setVisibleZoomLinkInput(null);
    setZoomLink("");
    setIsModalVisible(false);
  }, [props.type]);

  useEffect(() => {
    setIsLoadingMore(loadMore);
  }, [loadMore]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = (props, data) => {
    setRangePickerValue({ startValue: "", endValue: "" });
    setIsModalVisible(false);
  };

  const handleOk = async (info) => {
    postRequest(`classes/${info.session_id}/reschedule`, {
      data: {
        batch_id: props.match.params.batchId,
        timestamp: rangePickerValue.startValue.valueOf() / 1000,
      },
    })
      .then((data) => {
        console.log(data);
        setRangePickerValue({ startValue: "", endValue: "" });
        setIsModalVisible(false);
        props.refreshData();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const disabledStartDate = (current) => {
    let this_day = current.day();
    let isDayAllowed = this_day in days_array;
    return (current && current < moment().subtract(1, "day")) || !isDayAllowed;
  };

  const renderActionButtons = (info, props, zoomLinkCheck) => {
    //console.log("propsforupcomingnadprev", props, info);
    return (
      <div
        key={info.id}
        className={
          info.class_link || props.type == "previous"
            ? "item-container completed"
            : "item-container"
        }
      >
        {visibleZoomLinkInput !== info.id ? (
          <div style={{ display: "flex" }}>
            {props.type == "upcoming" ? (
              <div style={{ position: "relative" }}>
                <div className="dropdown">
                  <Button className="dropbtn">
                    <PencilIcon width={17} height={17} />
                  </Button>
                  <div className="dropdown-content">
                    <Button
                      onClick={() =>
                        props.history.push({
                          pathname: `/adminBatches/assign-teacher-recommended/${props.match.params.batchId}`,
                          state: { detail: "sessionupdate", info: info },
                        })
                      }
                    >
                      Update Teacher
                    </Button>
                    <Button onClick={showModal}>Update Schedule</Button>
                  </div>
                </div>
              </div>
            ) : null}
            <Button
              onClick={() => {
                !info.class_link && setVisibleZoomLinkInput(info.id);
              }}
            >
              {zoomLinkCheck}{" "}
              {info.class_link || props.type == "previous"
                ? "ASSIGNED"
                : "ASSIGN ZOOM LINK"}
            </Button>
          </div>
        ) : (
          <div
            onScroll={(e) => {
              e.stopPropagation();
            }}
            className={"zoomlink-input-container"}
          >
            <Tooltip defaultVisible={true} title={"Enter the zoom link"}>
              <Input
                className={"zoom-link-input"}
                value={zoomLink}
                onChange={(e) => setZoomLink(e.target.value)}
              />
            </Tooltip>
            {isLoading ? (
              <Spin indicator={spinner} />
            ) : (
              <>
                <Tooltip title={"Save"}>
                  <ReactSVG
                    onClick={async () => {
                      if (zoomLink) {
                        setIsLoading(true);
                        let payload = {
                          data: {
                            class_link: zoomLink,
                          },
                        };
                        try {
                          let res = await props.setZoomLinkRequest(
                            info.id,
                            payload
                          );
                          if (res) {
                            await props.refreshData();
                            setVisibleZoomLinkInput(null);
                            setIsLoading(false);
                            setZoomLink("");
                          }
                        } catch (e) {
                          setZoomLink("");
                          setIsLoading(false);
                        }
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    beforeInjection={(svg) => {
                      svg.classList.add("check-icon");
                    }}
                    onError={(e) => console.log(e)}
                    src={Check}
                  />
                </Tooltip>
                <Tooltip title={"Cancel"}>
                  <ReactSVG
                    onClick={() => {
                      setVisibleZoomLinkInput(null);
                      setZoomLink("");
                    }}
                    style={{ cursor: "pointer" }}
                    beforeInjection={(svg) => {
                      svg.classList.add("close-icon");
                    }}
                    onError={(e) => console.log(e)}
                    src={Close}
                  />
                </Tooltip>
              </>
            )}
          </div>
        )}
        {renderRescheduleModal(info)}
      </div>
    );
  };

  const renderRescheduleModal = (info) => {
    console.log(info.type, "info.type", props);
    if (props.type == "previous") {
      return "";
    }
    return (
      <>
        <Modal
          title="Basic Modal"
          visible={isModalVisible}
          onOk={() => {
            handleOk(info);
          }}
          onCancel={handleCancel}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value={rangePickerValue.startValue}
            placeholder="Start"
            disabledDate={disabledStartDate}
            onChange={(value) => {
              setRangePickerValue({ ...rangePickerValue, startValue: value });
            }}
          />
        </Modal>
      </>
    );
  };
  const days_array = [];
  const listItems =
    props.data?.length > 0 &&
    props.data?.map((info, rowIndex) => {
      let day = new Date(info.event.start_timestamp).getDay();
      if (!(day in days_array)) {
        days_array.push(day);
      }
      const zoomLinkCheck =
        info.class_link || props.type == "previous" ? (
          <CheckIcon height={15} color="#219653" />
        ) : null;
      return (
        <div
          key={info.id}
          className={
            props.type == "previous"
              ? "list-item previous"
              : "list-item upcoming"
          }
        >
          <div className="item-container vertical">
            <h2 className="date">
              <span>
                {getTimeComponents("date", info.event) +
                  " " +
                  getTimeComponents("month", info.event)}
              </span>
            </h2>
            <div className="time">
              {getTimeComponents("timeSlot", info.event)}
            </div>
          </div>
          <div className="item-container vertical pointer">
            <h2>{info.session?.name}</h2>
            <p>{info.session?.description}</p>
          </div>
          {renderActionButtons(info, props, zoomLinkCheck)}
        </div>
      );
    });
  return (
    <div onScroll={onScrollClasses} className="admin-class-list">
      {listItems}
      {isLoadingMore && (
        <div className={"load-more"}>
          <Spin />
          Loading...
        </div>
      )}
    </div>
  );
};

function getTimeComponents(key, timeEvents) {
  if (timeEvents) {
    if (key === "date")
      return moment(timeEvents.start_timestamp).utc(true).format("Do");
    if (key === "month")
      return moment(timeEvents.start_timestamp).utc(true).format("MMM");
    if (key === "timeSlot")
      return `${moment(timeEvents.start_timestamp)
        .utc(true)
        .format("hh:mm A")} - ${moment(timeEvents.end_timestamp)
        .utc(true)
        .format("hh:mm A")}`;
  }
  return "";
}

export default withRouter(
  connect(null, (dispatch) =>
    bindActionCreators({ ...batchesActions, ...classesActions }, dispatch)
  )(ClassList)
);
