export const panelListStudent = [
  {
    label: "Grades",
    value: "grades",
    list: [],
  },
  {
    label: "Status",
    value: "subscription_status",
    list: [
      {
        value: "active",
        label: "Subscribed",
        isActive: false,
      },
      {
        value: "inactive",
        label: "Unsubscribed",
        isActive: false,
      },
    ],
  },
];
