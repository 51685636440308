import React from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

const { Option } = Select;
const { TextArea } = Input;


const SchoolForm = (props) => {
    const { values } = props;
    return (
        <div className="form-container">
            <h3 className="form-title">School</h3>
            <Row gutter={16}>
                <Col span={12} className="form-column">
                    <p>SCHOOL NAME</p>
                    <Field
                        placeholder={"The school your child attends"}
                        as={Input}
                        name={"details.school_name"}
                        value={values.details.school_name}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name="details.school_name"
                            className="error-message"
                        />
                    </span>
                </Col>
                <Col span={12} className="form-column">
                    <p>Approx Monthly Expenses</p>
                    <Field
                        placeholder={"Monthly expenditure on school"}
                        as={Input}
                        name={"details.school_monthly_expense"}
                        value={values.details.school_monthly_expense}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name="details.school_monthly_expense"
                            className="error-message"
                        />
                    </span>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} className="form-column">
                    <p>Other Classes Attended by child</p>
                    <FieldArray
                        name="details.other_classes"
                        render={arrayHelpers => (
                            values.details.other_classes.map((oclass, index) => (
                                <Row gutter={16} style={{ marginBottom: index < values.details.other_classes.length - 1 ? "24px" : "0px" }}>
                                    <Col span={12}>
                                        <Field
                                            placeholder={"Monthly expenditure on school"}
                                            as={Input}
                                            name={`details.other_classes.${index}`}
                                            value={values.details.other_classes[index]}
                                        />
                                        <span className="error-message">
                                            <ErrorMessage
                                                name={`details.other_classes.${index}`}
                                                className="error-message"
                                            />
                                        </span>
                                    </Col>
                                    {(index) > 0 ? <Col span={4} className="center-align">
                                        <Button className='outline-btn' onClick={() => arrayHelpers.remove(index)}> - Remove</Button>
                                    </Col> : null}
                                    {(index) == values.details.other_classes.length - 1 ? <Col span={4} className="center-align">
                                        <Button className='outline-btn' onClick={() => arrayHelpers.push('')}> + Add Another</Button>
                                    </Col> : null}
                                </Row>
                            ))
                        )}
                    />
                </Col>
            </Row>
        </div >
    );

}

export default SchoolForm;