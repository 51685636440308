/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Input, Button, Form, message , Icon} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from 'sweetalert';
import OtpInput from 'react-otp-input';
import * as userActions from "store/user/actions";
import * as pricingActions from "store/pricing/actions";
import LoginImg from "assets/image/login-img.svg";
import FreadomLogo from "assets/image/freadom-logo-white.svg";
import PricingPlanFooter from "assets/image/pricing-plan-footer.png";
import { storage as LocalStorage } from "services/config/storage";
import "./Pricing.scss";
import PricingCard from "./PricingCard";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const otpBoxStyle = {
  // width: '100%',
  width: '56px',
  height: '56px',
  outline: 'none',
  fontSize: '24px',
  border: '1px solid #eee',
  borderRadius: '4px'
}

const bottomContainerRef = React.createRef();

class Pricing extends Component {
  state = {
    loading: false,
    phone: "",
    loggedIn: false,
    isSendingOtp: false,
    otpSent: false,
    otp: "",
    otpVerified: false,
    fetchingUser: false,
    inputFocused: false,
    planSelected: false,
    plans: [],
    selectedPlan: {},
    childData: [],
    selectedChild: {},
    promoCodes: [],
    promoCodeValidated: false,
    validatedPromoCode: null
  };

  componentDidMount() {
    if (LocalStorage.fetch.authToken()) {
      this.setState({
        otpVerified: true,
        fetchingUser: true
      });
      this.updateChildList();
    }
    this.listAllPlans();
  }

  listAllPlans= async ()  => {
    this.setState({
      plans: await this.props.pricingPlans(),
      planSelected: false,
      selectedPlan: {},
      selectedChild: {},
      validatedPromoCode: null,
      promoCodes: new Array(LocalStorage.fetch.childData()? LocalStorage.fetch.childData().list.filter(c=> c.invite_code===null).length: 0)
    })
  }

  sendOtp = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({isSendingOtp: true});
        const otpObj = {
          contact_no: this.state.phone,
          action: "login",
          user_type: "parent",
        }
        const res = await this.props.triggerOtpSend(otpObj);
        if(res === undefined) {
          bottomContainerRef.current.scrollIntoView({ behavior: 'smooth' });
          message.error("Please register");
          this.setState({isSendingOtp: false});
        }
        else if (res.success) {
          this.setState({otpSent: true, isSendingOtp: false});
        }
      }
    })

  }

  verifyOtp = async e => {
    Swal("Verfiyng OTP!", "It may take some time.", "info");

    e.preventDefault();
    const otpVerifyObj = {
      contact_no: this.state.phone,
      otp: this.state.otp,
      action: 'login',
      user_type: 'parent',
      device_type: 'web'
    }
    const res = await this.props.verifyOtpRequest(otpVerifyObj);
    if (res && res.auth_token) {
      this.setState({
        otpVerified: true,
        fetchingUser: true
      });
      this.updateChildList();
    } else {

      Swal({
        icon: 'error',
        title: 'Invalid OTP!',
        timer: 1500
      });

      this.setState({ loading: false });
      message.error(this.state.otp ? "Invalid OTP" : "Invalid Credentials");
    }
  }

  selectPlan= (planId) => {
    this.setState({
      planSelected: true,
      selectedPlan: this.state.plans.filter(p=> p.planId===planId)[0]
    });
    (window.innerWidth<=760) && (window.scrollTo(0, window.scrollY+150));
  }

  resetUser= () => {
    LocalStorage.destroy.all();

    this.setState({
      loading: false,
      phone: "",
      loggedIn: false,
      isSendingOtp: false,
      otpSent: false,
      otp: "",
      otpVerified: false,
      fetchingUser: false,
      childData: [],
      selectedChild: {},
      promoCodes: [],
      promoCodeValidated: false,
      validatedPromoCode: null
    });
  }

  selectChild =(child, mode) => {    
    if(mode>0) {
      message.success("Click on pay now");
      this.setState({ selectedChild: child });
      if(this.state.validatedPromoCode && (this.state.validatedPromoCode.childId === child.id)) {
        const promo= this.state.validatedPromoCode;
        (promo.discount<0) && (promo.discount=0);
        (promo.days<0) && (promo.days=0);
        this.setState(prevState => ({
          selectedPlan: {                   
            ...prevState.selectedPlan,    
            baseAmount: (prevState.selectedPlan.baseAmount-(promo.discount* prevState.selectedPlan.baseAmount/ 100)).toFixed(2),
            baseDays: (prevState.selectedPlan.baseDays + promo.days)
          }
        }))
      }
      else {
        this.setState({
          selectedPlan: this.state.plans.filter(p=> p.planId === this.state.selectedPlan.planId)[0]
        })
      }
    }
    if(mode<0) {
      this.setState({
        selectedChild: [],
        selectedPlan: this.state.plans.filter(p=> p.planId === this.state.selectedPlan.planId)[0]
      })
    }
  }

  updateChildList = () => {
    if (LocalStorage.fetch.childData()) {
      this.setState({
        childData: LocalStorage.fetch.childData().list.filter(c=> c.invite_code===null),
        promoCodes: new Array(LocalStorage.fetch.childData().list.filter(c=> c.invite_code===null).length)
      })
    }
  }

  resetPromoCode= () => {
    this.setState({ 
      validatedPromoCode: null, 
      promoCodeValidated: false,
      selectedPlan: this.state.plans.filter(p=> p.planId === this.state.selectedPlan.planId)[0]
    });
  }

  handlePromoInput= (val, index) => {
    var promoCodes= this.state.promoCodes;
    if(val==="") {
      this.resetPromoCode();
      promoCodes[index]= null;
    } else {
      promoCodes[index]= val;
    }
    this.setState({
      promoCodes: promoCodes
    })
  }

  updatePromoCode= async(index) => {
    // removing previous validated promo and discounted plan
    this.resetPromoCode();

    const promoFetched= await this.props.isValidPromo(this.state.promoCodes[index]);
    if(promoFetched!= null) {
      message.success("Included promo code");
      this.setState({
        validatedPromoCode: {
          ...promoFetched,
          childId: this.state.childData[index].id
        }
      })
      // Reverse Flow: If the user selectes the child and then entered promo code
      this.state.selectedChild && this.selectChild(this.state.selectedChild, 1);
    }
    else {
      // invalid promo: can make server call for future.
      message.error("Invalid Promo Code");
    }
  }

  purchasePlan= async () => {
    // checking if any child is selected before payment
    if(Object.keys(this.state.selectedChild).length===0 && this.state.selectedChild.constructor === Object)  {
      message.error("Please select a child");
    }
    else {
      var paymentObj= {
        web_plan_id: this.state.selectedPlan.planId,
        child: this.state.selectedChild.id,
        web_plan_days: this.state.plans.filter(p=> p.planId===this.state.selectedPlan.planId)[0].baseDays,
        web_plan_amount: this.state.plans.filter(p=> p.planId===this.state.selectedPlan.planId)[0].baseAmount
      };
      
      if(this.state.validatedPromoCode!= null && this.state.selectedChild.id===this.state.validatedPromoCode.childId) {
        // checking again if the promo code is valid(max uses might exceed in between) or not.
        const validatedPromocode= await this.props.isValidPromo(this.state.validatedPromoCode.name);
        paymentObj.promo_code=  validatedPromocode.id;
      } 
      // making server call for initiating payment.
      message.success("Initiating Payment Please wait.");

      const orderObj= await this.props.paymentInitiate(paymentObj);
      if(orderObj) {
        const parentData= LocalStorage.fetch.parentData(); 
        (!parentData.hasOwnProperty("email") || parentData.email=== null) && (parentData.email="");
        (!parentData.hasOwnProperty("contact_no") || parentData.contact_no=== null) && (parentData.contact_no="");
        
        await this.props.makePayment(orderObj, this.state.selectedPlan, this.state.selectedChild, parentData, (response)=> {
          if(response.razorpay_signature) {
            var childList= LocalStorage.fetch.childData().list.filter(c=> c.invite_code===null);

            Swal({
              icon: 'success',
              title: 'Payment Successful!',
              showConfirmButton: false,
              timer: 1500
            })

            childList= childList.map((c) =>{
              if(c.id === this.state.selectedChild.id) {
                c.days_left= this.state.selectedPlan.baseDays+ c.days_left-1
              }
              return c;
            });

            LocalStorage.set.childData({
              list: childList
            });

            this.setState({
              childData: childList,
              selectedChild: {}
            })
            this.resetPromoCode();
          }
        })
      }
      else {
        Swal({
          icon: 'error',
          title: 'Something went wrong!',
          html: 'Please try again.',
          showConfirmButton: false,
          timer: 1500
        })
        message.error("Payment Failed.");
      }

    }
  }

  handleUpdateState = (key, e) => {
    this.setState({ [key]: e.target.value });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { phone, otpSent, otpVerified, planSelected, plans, promoCodes, selectedPlan } = this.state;

    // all plan cards
    const pricingPlansCards = plans && plans.map((plan, index) => <PricingCard key={plan.planId} plan={plan} planSelected={planSelected} selectPlan={this.selectPlan} />);
    // selected plan card
    const selectedPlanCard = (<PricingCard key={selectedPlan.planId} plan={selectedPlan} planSelected={planSelected} purchasePlan={this.purchasePlan} />);
    // list of all child registered with that number
    const childList= this.state.childData && this.state.childData.map((child,index)=> 
      <div key={child.id} className={"childcard "+ (this.state.selectedChild && this.state.selectedChild.id===child.id && " selected-child") }>
        <div className="promo-input">
          <h4> {child.name} </h4>
          <div className="promo-input-container">
            <input 
              className="input-field" 
              type="text" 
              placeholder="Promo Code" 
              value={promoCodes[index]}
              onChange= {e=> this.handlePromoInput(e.target.value, index)}
            />
            <button className="promo-input-button" onClick={() => this.updatePromoCode(index)}>Apply</button>
          </div>
          <p>
          
          {
            (this.state.validatedPromoCode!= null) && (this.state.validatedPromoCode.childId=== child.id) && (this.state.validatedPromoCode.discount>0) &&
            <span> 
              {this.state.validatedPromoCode.discount}% off  
            </span>
          }
          {
            (this.state.validatedPromoCode!= null) && (this.state.validatedPromoCode.childId=== child.id) && (this.state.validatedPromoCode.discount>0) && (this.state.validatedPromoCode.days>0) &&
            <span>
              +
            </span>
          }
          {
            (this.state.validatedPromoCode!= null) && (this.state.validatedPromoCode.childId=== child.id) && (this.state.validatedPromoCode.days>0) &&
            <span> 
              {' '+ this.state.validatedPromoCode.days} more days.
            </span>
          }
          </p>
        </div>
        <div className="bottom-childcard">
        <p> {child.days_left} days left</p>
        {
          (this.state.selectedChild && this.state.selectedChild.id===child.id) ?
          <button className="pricing-button" onClick={()=>this.selectChild(child, -1)}>Unselect Child <Icon type="minus" /></button>
          :<button className="pricing-button" onClick={()=>this.selectChild(child, 1)}>Select Child <Icon type="plus" /></button>
        }
        </div>
      </div>
    );

    return (
      <div className="pricing-container">
        <div className="login-container">
          <div className="left-part">
            <img
              src={LoginImg}
              alt="user illustration"
              className="user-illustration"
            />
            <img src={FreadomLogo} alt="logo" />
            <div className="title">Read. Play. Go!</div>
            <h4 className="title">Anytime. Anywhere.</h4>
          </div>
          <div className="right-part">
            <img src={FreadomLogo} className="mobile-logo" alt="logo" />
            <div className="gradient-text">Let's fuel your reading journey!</div>
            <p className="helper-text">
              Choose the plan that's best for you.
            </p>
            <a href="#pricing-table-container" className="pricing-button" >
              View Plans
            </a>
          </div>
        </div>
        
        <div id="pricing-table-container" className="pricing-table-container">
          <h4 className="pricing-table-header">Simple, Transparent Pricing</h4>
          <p className="pricing-table-subheader">Best Investment on Reading is time.</p>
          {planSelected && 
            (<button className="navButton" onClick={()=>this.listAllPlans()}>
              <Icon type="left" /> All Plans
            </button>)
          }
          <div className="container">
            <div className="panel pricing-table">
              {planSelected? selectedPlanCard : pricingPlansCards }
            </div>

            {(planSelected) && (
              <div className={'right-part' + (this.state.inputFocused ? ' input-focused' : '')}>
              {
                <div className="login-card">
                  {otpVerified ? (
                    <div >
                      <h5 className="change-contact-btn" onClick={this.resetUser}>
                        Change contact number <Icon type="rollback" /> 
                      </h5>  
                      <h4>
                      Please select your children
                      </h4>
                      {(this.state.childData.length? childList: (<div >Loading list</div>))}
                    </div>
                  ) : (
                    <Form layout="vertical" onSubmit={ this.state.otp ? this.verifyOtp : this.sendOtp }>
                      <h4>Please enter registered phone number.</h4>
                      <Form.Item>
                        {getFieldDecorator("phone", {
                          rules: [
                            { required: true, message: "Please enter phone number", validateTrigger: "onBlur" },
                            { len: 10, message: "Must be 10 digits", validateTrigger: "onBlur" },
                            { pattern: '^[0-9]*$', message: "Must be numeric", validateTrigger: "onBlur" }
                          ],
                          validateTrigger: "onBlur",
                        })(
                          <Input
                            size="large"
                            value={phone}
                            placeholder="Enter your phone number"
                            onChange={e => this.handleUpdateState("phone", e)}
                          />
                        )}
                      </Form.Item>
                      <OtpInput
                        onChange={otp => this.setState({otp: otp})}
                        numInputs={4}
                        containerStyle={{ justifyContent: 'space-between', marginBottom: '16px' }}
                        separator={<span></span>}
                        inputStyle={otpBoxStyle}
                        isDisabled={!otpSent}
                      />
                      <Button
                        type="primary"
                        disabled={hasErrors(getFieldsError())}
                        htmlType="submit"
                        loading={this.state.isSendingOtp}
                      >
                        {otpSent ? 'Re-Send OTP' : 'Send OTP'}
                      </Button>
                      <Button
                        type="primary"
                        disabled={!otpSent}
                        onClick={this.verifyOtp}
                      >
                        Verify OTP
                      </Button>
                    </Form>
                  )}
                </div>
              }
            </div>  
            )}
          </div>
        </div>
        
        <div id="bottom-container" className="bottom-container" ref={bottomContainerRef}>
          <div className="bottom-container-card login-container">
            <div className="left-part">
              <img src={PricingPlanFooter} alt={PricingPlanFooter} className="pricing-footer-img" />
            </div>
            <div className="right-part">
              <h4>Sign up for FREE</h4>
              <h3>Start Reading!</h3>
              <p>Download the app now and get <b>Free Trial</b> of 7 Days.  </p>
              <a href="https://play.google.com/store/apps/details?id=com.application.freadom" className="pricing-button">Download App</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const connectedComponent = connect(
  ({ user }) => ({ ...user }),
  dispatch => bindActionCreators({ ...userActions, ...pricingActions }, dispatch)
)(Pricing);

export default Form.create({ name: "Pricing_Form" })(connectedComponent);
