import React from 'react';
import './index.scss';
import {FreadomIcon} from '../../assets/image/svg';

const demoProfileURL = "https://st3.depositphotos.com/14846838/19463/v/1600/depositphotos_194639100-stock-illustration-flat-icon-profile-female-student.jpg"

const AddStudentCard = (props) => {
    const selectedClass = props.selected ? "active" : "";
    return (
        <div className={`add-student-card ${selectedClass}`} onClick={props.openModal}>
            <div className="student-header">
                <div className="name-image">
                    <div className="student-image">
                        <img alt="" src={props.studentInfo?.image || demoProfileURL}/>
                    </div>
                    <div className="student-name">
                        <h4>{props.studentInfo.name}</h4>
                        <p>D/O {props.studentInfo.parent}</p>
                    </div>
                </div>
                <div className="student-grade">
                    <p>
                        <FreadomIcon/>
                        <span>{props.studentInfo.grade}</span>
                    </p>
                </div>
            </div>
            <div className="student-footer">
                <div className="footer-info">
                    <p className="title">School:</p>
                    <p>{props.studentInfo.school}</p>
                </div>
                <div className="v-divider"></div>
                <div className="footer-info">
                    <p className="title">Phone no:</p>
                    <p>{props.studentInfo.phone}</p>
                </div>
                <div className="v-divider"></div>
                <div className="footer-info">
                    <p className="title">Email:</p>
                    <p>{props.studentInfo.email}</p>
                </div>
            </div>
        </div>
    )
}

export default AddStudentCard;
