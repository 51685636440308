import React from 'react';

export const OpenBookIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.54813e-10 12.8881V2.00126C1.45024e-05 1.60965 0.172194 1.23784 0.470842 0.98451C0.76949 0.731185 1.16441 0.621962 1.55078 0.685829L7.33333 1.64156V15.3343L1.09481 14.1999C0.460825 14.0846 -1.25127e-05 13.5325 2.54813e-10 12.8881ZM8.6668 1.64195L14.4494 0.68622C14.8357 0.622353 15.2306 0.731576 15.5293 0.984901C15.8279 1.23823 16.0001 1.61004 16.0001 2.00165V12.8885C16.0001 13.5329 15.5393 14.085 14.9053 14.2003L8.6668 15.3347V1.64195Z" fill="#524E59" />
</svg>

export const CalenderIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 1.33333H14.6663C15.0201 1.33332 15.3593 1.47382 15.6094 1.72394C15.8595 1.97406 16 2.31329 16 2.66701V14.6663C16 15.0201 15.8595 15.3593 15.6094 15.6094C15.3593 15.8595 15.02 16 14.6663 16H1.33366C0.979944 16 0.64071 15.8595 0.390596 15.6094C0.140482 15.3593 -2.12169e-05 15.02 2.40311e-09 14.6663V2.66699C-1.76803e-05 2.31328 0.140487 1.97405 0.3906 1.72393C0.640714 1.47382 0.979946 1.33332 1.33366 1.33333H3.33333V0.666667C3.33333 0.298477 3.63181 0 4 0C4.36819 0 4.66667 0.298477 4.66667 0.666667V1.33333H11.3333V0.666667C11.3333 0.298477 11.6318 0 12 0C12.3682 0 12.6667 0.298477 12.6667 0.666667V1.33333ZM3.3332 3.99948C3.3332 4.36767 3.63168 4.66615 3.99987 4.66615C4.36806 4.66615 4.66654 4.36767 4.66654 3.99948C4.66654 3.63129 4.36806 3.33281 3.99987 3.33281C3.63168 3.33281 3.3332 3.63129 3.3332 3.99948ZM10.5546 8.11263L7.5182 12.6673L4.96353 10.9642C4.66 10.7589 4.57908 10.3471 4.78235 10.0422C4.98561 9.73727 5.39686 9.65358 5.70312 9.8548L7.14845 10.8183L9.44532 7.37301C9.65105 7.07058 10.062 6.99039 10.3664 7.1933C10.6707 7.39621 10.7547 7.80643 10.5546 8.11263ZM11.3332 3.99948C11.3332 4.36767 11.6317 4.66615 11.9999 4.66615C12.3681 4.66615 12.6665 4.36767 12.6665 3.99948C12.6665 3.63129 12.3681 3.33281 11.9999 3.33281C11.6317 3.33281 11.3332 3.63129 11.3332 3.99948Z" fill="#524E59" />
</svg>

export const ChatIcon = () => <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6667 0.998047H1.33333C0.596969 0.998084 3.68155e-05 1.59502 0 2.33138V12.3314C3.68155e-05 13.0677 0.596969 13.6647 1.33333 13.6647H5.33333L8 16.998L10.6667 13.6647H14.6667C15.403 13.6647 16 13.0677 16 12.3314V2.33138C16 1.59502 15.403 0.998084 14.6667 0.998047ZM8.66667 9.66471H4.66667C4.29848 9.66471 4 9.36624 4 8.99805C4 8.62986 4.29848 8.33138 4.66667 8.33138H8.66667C9.03486 8.33138 9.33333 8.62986 9.33333 8.99805C9.33333 9.36624 9.03486 9.66471 8.66667 9.66471ZM4.66667 6.33138H11.3333C11.7015 6.33138 12 6.0329 12 5.66471C12 5.29652 11.7015 4.99805 11.3333 4.99805H4.66667C4.29848 4.99805 4 5.29652 4 5.66471C4 6.0329 4.29848 6.33138 4.66667 6.33138Z" fill="#524E59" />
</svg>

export const PersonIcon = () => <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.05749 2.44335C9.05749 3.54792 8.04371 4.44335 6.79316 4.44335C5.54263 4.44329 4.52889 3.5479 4.52883 2.44335C4.52883 1.33879 5.5426 0.443359 6.79316 0.443359C8.04371 0.443359 9.05749 1.33879 9.05749 2.44335ZM12.3513 2.781C12.6504 2.75701 12.948 2.83896 13.1787 3.00882C13.6589 3.3625 13.7238 3.99304 13.3235 4.41737L9.81205 8.13904V15.4437C9.81205 15.996 9.30516 16.4437 8.67988 16.4437C8.05461 16.4437 7.54772 15.996 7.54772 15.4437V12.4437H6.03817V15.4437C6.03817 15.996 5.53128 16.4437 4.906 16.4437C4.28072 16.4437 3.77384 15.996 3.77384 15.4437V8.23437L0.253488 4.40758C-0.14077 3.97898 -0.0670132 3.34924 0.418229 3.00101C0.903472 2.65277 1.61645 2.71792 2.01071 3.14652L4.43056 5.77704H9.093L11.584 3.13675C11.7762 2.93296 12.0522 2.80499 12.3513 2.781Z" fill="#524E59" />
</svg>

export const LineVector = () => <svg width="2" height="71" viewBox="0 0 2 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L0.999997 70" stroke="#D7D7D7" stroke-width="2" stroke-miterlimit="1.17176" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="7 10" />
</svg>
