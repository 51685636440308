import React from "react";
import "./Avatar.scss";
// Ant Design
import { message, Upload, Form } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class Avatar extends React.Component {
  state = {
    loading: false,
    imageUrl: "",
  };

  handleChange = async (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
    if (info.file.status === "error") {
      this.setState({ loading: false, error: true });
    }
  };

  handleUpload = (file) => {
    console.log(">>>>props", this.props);
    return new Promise(async (resolve, reject) => {
      const fileName = file.name;
      let files = [fileName];
      let response = await this.props.getPresignedUrl({ files });
      let url = response[fileName].put_url;
      this.props.onChange("pic", response[fileName].get_url);
      resolve(url);
    });
  };

  uploadButton = () => (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  render() {
    let imageUrl = this.state.imageUrl;
    if (
      this.props.getFieldValue("pic") &&
      typeof this.props.getFieldValue("pic") === "string"
    )
      imageUrl = this.props.getFieldValue("pic");

    return (
      <Form.Item label="Picture">
        {this.props.getFieldDecorator("pic", {
          rules: [{ required: true, message: "Please upload the picture" }],
        })(
          <Upload
            name="avatar.png"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={this.handleUpload}
            beforeUpload={beforeUpload}
            onChange={this.handleChange}
            customRequest={({ onSuccess, onError, file, action }) => {
              axios
                .put(action, file)
                .then((resp) => {
                  onSuccess(resp, file);
                })
                .catch((err) => {
                  onError(err);
                });
            }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              this.uploadButton()
            )}
          </Upload>
        )}
      </Form.Item>
    );
  }
}

export default Avatar;
