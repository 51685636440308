import {mutation} from "./mutations";
import {Grades} from "../../services/grades";

const handleError = err => {
    console.log("Error in Login action : ", err);
    return err.response || err
};

export const getGradeListRequest = (sessionId) => async dispatch => {
    try {
        let response = await Grades.getGradeList()
        dispatch(mutation.setGradeList(response.data));
        return response.data || response;
    } catch (err) {
        return handleError(err);
    }
}