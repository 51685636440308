/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "store/user/actions";
import Loader from 'components/Loader';
// import { storage as LocalStorage } from "services/config/storage";
import "./EuroLogin.scss";

class EuroLogin extends Component {
  state = {
  };
  componentDidMount () {
    if(this.props.location.search.length > 0) {
      let paramUrl = new URLSearchParams(this.props.location.search)
      const token = paramUrl.get('token');
      const email = paramUrl.get('email');
      this.handleEuroLogin({token, email});
    } else {
      this.props.history.push('/login');
    }
  }
  handleEuroLogin = async token => {
    const res = await this.props.euroLogin(token);
    if (res) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <div className="euro-login-container">
        <Loader />
        <div className="helper-text">Hang On!</div>
        <div className="sub-helper-text">We're preparing your account.</div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({ ...userActions }, dispatch)
)(EuroLogin);
