import React, { Component, useEffect } from "react";

// Libraries
import cloneDeep from "lodash/cloneDeep";
import Has from "lodash/has";
import isEmpty from "lodash/isEmpty";

// Ant Design
import {
  Button,
  Col,
  Form,
  InputNumber,
  Row,
  AutoComplete,
  Icon,
  Input,
} from "antd";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as preferenceGroupsActions from "store/preferenceGroups/actions";
import { toast } from "react-toastify";
import { getTeachers, getZoomIDwithParams } from "../../../../services/teacher";
import { getGradeList } from "../../../../services/grades";
import { getClassLinks } from "../../../../services/classes";

// Local Storage
import { storage as LocalStorage } from "services/config/storage";

// Components
import AdminLayout from "layout/admin";

// SCSS
import "./index.scss";

// Utils
import DatePicker from "./components/DatePicker";
import Dropdown from "../../../../components/Dropdown";
import { generateHoursList } from "../../../../utils/commonUtils";
import WeekdaysList from "./components/WeekdaysList";

const PRODUCT_TYPE = [
  {
    label: "FLIVE",
    value: "FLIVE",
  },
  {
    label: "F1",
    value: "F1",
  },
  {
    label: "SummerCamp",
    value: "SUMMERCAMP",
  },
  {
    label: "FGLOBAL",
    value: "FGLOBAL",
  },
];

const PRODUCT_MAX_STUDENTS = {
  F1: 1,
  FGLOBAL: 1,
  FLIVE: 7,
  SUMMERCAMP: 4,
};

const DURATION = [
  {
    label: "15 mins",
    value: 15,
  },
  {
    label: "30 mins",
    value: 30,
  },
  {
    label: "45 mins",
    value: 45,
  },
  {
    label: "1 hour",
    value: 60,
  },
  {
    label: "1 hour 15 mins",
    value: 75,
  },
  {
    label: "1 hour 30 mins",
    value: 90,
  },
  {
    label: "1 hour 45 mins",
    value: 105,
  },

  {
    label: "2 hours",
    value: 120,
  },
];

const START_TOPIC = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "31",
    value: 31,
  },
  {
    label: "61",
    value: 61,
  },
  {
    label: "91",
    value: 91,
  },
];

class CreatePreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      levelSelected: [],
      isSubmitEnable: false,
      showValidationError: false,
      loading: false,
      startHours: generateHoursList(8),
      ...this.onInit(),
      teacherList: [],
      gradeList: [],
      classLinks: [],
      WeekList:[
        {
          label: 'M W F',
          value: '1 3 5',
        },
        {
          label: 'T Th Sa',
          value: '2 4 6',
        }
      ],
      max_students_state: 0,
      startTimeForSlot: 0,
    };
  }

  async componentDidMount() {
    if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/");
      return;
    }
    this.getGrades();
    this.setClassLinks();
  }

  onInit = () => {
    const initialStates = cloneDeep(this.props.location.state);
    if (initialStates) {
      if (initialStates && Has(initialStates, "formData.user.phone_number")) {
        initialStates.formData.user.phone_number =
          initialStates.formData.user.phone_number.substring(
            initialStates.formData.user.phone_number.length - 10
          );
      }
      return initialStates;
    }
    return {
      formData: {
        activeDays: [],
        startDate: undefined,
        startTime: undefined,
        numOfBatches: 1,
        zoom_account: undefined,
        start_topic: undefined,
        product_type: undefined,
        max_students: undefined,
        teacher_id: undefined,
      },
    };
  };

  updateFormDataState = (key, value) => {
    console.log(key, value);
    if (key) {
      if (key == "product_type") {
        this.state.formData["max_students"] = this.props.form.setFieldsValue({
          max_students: PRODUCT_MAX_STUDENTS[value],
        });
      }
      const formData = { ...this.state.formData };
      formData[key] = value;
      this.setState({ formData });
    }
  };

  onFormSubmit = async () => {
    console.log(this.state.formData, "==>");
    if (this.isFormValid()) {
      this.setState({ loading: true });
      const requestBody = { ...this.state.formData };
      // delete requestBody
      let obj = {
        timeslots: requestBody.activeDays.map((item) => ({
          duration: requestBody.duration,
          start_second: requestBody.startTime,
          //FLV-204
          weekday: item,
          //FLV-204

        })),
        num_of_batches: requestBody.numOfBatches,
      };

      if (requestBody.startDate) {
        obj.start_time =
          new Date(requestBody.startDate * 1000).setHours(0, 0, 0, 0) / 1000;
      }

      if (requestBody.zoom_account) obj.zoom_account = requestBody.zoom_account;
      if (requestBody.start_topic) {
        obj.start_topic = requestBody.start_topic;
      }
      if (requestBody.product_type) {
        obj.product_type = requestBody.product_type;
      }

      if (requestBody.grade_id) {
        obj.grade_id = requestBody.grade_id;
      }
      obj.max_students = 1; //+requestBody.max_students
      // if(requestBody.max_students){
      // }
      if (requestBody.teacher_id) {
        obj.teacher_id = +requestBody.teacher_id;
      }
      let res = await this.props.createPreferenceGroup(obj);

      this.setState({ loading: false });
      if (!isEmpty(res)) {
        toast.success("Preference group successfully created!");
        setTimeout(() => this.props.history.push("/preference-listing"), 700);
      } else toast.error("Could not create preference group!");
    }
  };
  isFormValid = (e) => {
    e && e.preventDefault();
    let isFormValid = false;
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        isFormValid = true;
      }
    });
    return isFormValid;
  };
  renderHeader = () => (
    <div className="create-pref-cta">
      <Button
        loading={this.state.loading}
        type="primary"
        className="ant-btn-md export-btn"
        onClick={this.onFormSubmit}
      >
        Create New
      </Button>
      <Button
        disabled={this.state.loading}
        className="ant-btn-md cancel"
        onClick={() => this.props.history.goBack()}
      >
        Cancel
      </Button>
    </div>
  );

  getTeacher = async (e) => {
    let res = await getTeachers(`pattern=${e}`);
    res = res.data.teachers;
    let teacherList = [];
    res.map((teacher) => {
      teacherList.push({
        text: `${teacher.user.name} (${teacher.teacher_id})`,
        // text: teacher.user.name,

        //FLV-154
        //"value": teacher.id
        value: teacher.user.id,
        //FLV-154
      });
      // teacherList.push(teacher.user.name)
    });
    this.setState({ teacherList });
  };

  getGrades = async () => {
    let res = await getGradeList();

    res = res.data.grades;
    let gradeList = [];
    res.map((grade) => {
      gradeList.push({
        label: grade.name,
        value: grade.id,
      });
    });

    this.setState({ gradeList });
  };

  setClassLinks = async () => {
    let res = await getClassLinks();
    res = res.data.links;
    let classLinks = [];
    res.map((link) => {
      classLinks.push({
        //"label": link.link,
        label: link.zoom_id,
        value: link.id,
      });
    });

    this.setState({ classLinks });
  };
  //FLV-171
  getZoomIdWithPattern = async (e) => {
    let res = await getZoomIDwithParams(`pattern=${e}`);
    res = res.data.links;
    let classLinks = [];
    res.map((zoomId) => {
      classLinks.push({
        //"label": zoomId.link,
        label: zoomId.zoom_id,
        value: zoomId.id,
      });
    });
    this.setState({ classLinks });
  };
  //FLV-171

  render() {
    const { formData, showValidationError } = this.state;
    const {
      activeDays,
      numOfBatches,
      zoom_account,
      start_topic,
      max_students,
      product_type,
      teacher_id,
    } = formData;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    return [
      <AdminLayout title="">
        <div className="add-pref-header">
          <div>
            <div className={"back-btn"}>
              <Button onClick={() => this.props.history.goBack()}>
                &lt; Back
              </Button>
            </div>
            <h1>Create Preference Group</h1>
          </div>
          {this.renderHeader()}
        </div>
        <section className="add-pref-wrapper">
          <Form
            onSubmit={this.isFormValid}
            name="preference-create"
            initialValues={{ remember: true }}
            className="preference-create-form"
          >
            <Row gutter={[16, 16]}>
              <Col lg={16} md={20} sm={24} style={{ padding: 0 }}>
                <Col span={24} style={{ padding: 0 }}>
                  <DatePicker
                    loading={this.state.loading}
                    getFieldDecorator={getFieldDecorator}
                    onChange={(key, value) => {
                      this.updateFormDataState(key, value);
                    }}
                    isRequired={false}
                  />
                  <Col span={9}>
                    <Form.Item label="Batch Time">
                      {getFieldDecorator("startTime", {
                        rules: [
                          {
                            required: true,
                            message: "Please select start time",
                          },
                        ],
                      })(
                        <Dropdown
                          loading={this.state.loading}
                          onChange={(value) => {
                            value = value.toString();
                            let [hours, mins] =
                              value.length === 3
                                ? [value[0], value[1] + "" + value[2]]
                                : [
                                    value[0] + "" + value[1],
                                    value[2] + "" + value[3],
                                  ];
                            let seconds = hours * 60 * 60 + mins * 60;
                            let timezoneOffsetSeconds = -(
                              new Date().getTimezoneOffset() * 60
                            );
                            let totalTimeInSeconds =
                              seconds - timezoneOffsetSeconds;
                            if (totalTimeInSeconds < 0) {
                              let dayInSeconds = 86400;
                              seconds = dayInSeconds + totalTimeInSeconds;
                            } else seconds = totalTimeInSeconds;
                            this.updateFormDataState("startTime", seconds);
                          }}
                          placeholder={"Select Time"}
                          options={this.state.startHours || []}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Col>
                <div className="clearfix"></div>
                <Col span={24} style={{ padding: 0 }}>
                  <Col span={9}>
                    <Form.Item label="Duration">
                      {getFieldDecorator("duration", {
                        rules: [
                          { required: true, message: "Please select duration" },
                        ],
                      })(
                        <Dropdown
                          loading={this.state.loading}
                          onChange={(value) => {
                            this.updateFormDataState("duration", value * 60);
                          }}
                          placeholder={"Select Duration"}
                          options={DURATION}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  {/* <Col span={9}>
                                        <Form.Item
                                            label="No. of batches"
                                        >
                                            {
                                                getFieldDecorator('numOfBatches', {
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please enter number of batches'
                                                    }],
                                                })
                                                    (<InputNumber min={1}
                                                        disabled={true}
                                                        type={"hidden"}
                                                        onChange={(value) => this.updateFormDataState('numOfBatches', value)}
                                                        placeholder="Enter Batches"
                                                        value={numOfBatches}
                                                    />)}
                                        </Form.Item>
                                    </Col> */}
                </Col>
                <Col span={24} style={{ padding: 0 }}>
                  <Col span={9}>
                    <Form.Item label="Zoom Account">
                      {getFieldDecorator("zoom_account", {
                        rules: [
                          {
                            required: false,
                            message: "Please choose zoom account.",
                          },
                        ],
                      })(
                        <Dropdown
                          loading={this.state.loading}
                          onChange={(value) => {
                            this.updateFormDataState("zoom_account", value);
                          }}
                          //FLV-171
                          filter_not_exact={true}
                          showSearch={true}
                          dropdown_zoom_ID={true}
                          onSearch={(e) => {
                            this.getZoomIdWithPattern(e);
                          }}
                          optionFilterProp="children"
                          //FLV-171
                          placeholder={"Select Zoom Account"}
                          options={this.state.classLinks}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item label="Start Topic">
                      {getFieldDecorator("start_topic", {
                        rules: [
                          {
                            required: false,
                            message: "Please enter start topic",
                          },
                        ],
                      })(
                        <Dropdown
                          loading={this.state.loading}
                          onChange={(value) => {
                            this.updateFormDataState("start_topic", value);
                          }}
                          placeholder={"Select Start Topic"}
                          options={START_TOPIC}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={24} style={{ padding: 0 }}>
                  <Col span={9}>
                    <Form.Item label="Product Type">
                      {getFieldDecorator("product_type", {
                        rules: [
                          {
                            required: false,
                            message: "Please choose Product.",
                          },
                        ],
                      })(
                        <Dropdown
                          loading={this.state.loading}
                          onChange={(value) => {
                            console.log(value);
                            this.updateFormDataState("product_type", value);

                            console.log({ formData });
                          }}
                          placeholder={"Select Product Type"}
                          options={PRODUCT_TYPE}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item label="Max No. of Students">
                      {getFieldDecorator("max_students", {
                        rules: [
                          {
                            required: false,
                            message: "Please enter number of students",
                          },
                        ],
                      })(
                        <InputNumber
                          min={1}
                          disabled={true}
                          // onChange={(value) => this.updateFormDataState('max_students', value)}
                          placeholder="Enter Max Number Of Students"
                          value={max_students}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={24} style={{ padding: 0 }}>
                  <Col span={9}>
                    <Form.Item label="Teacher">
                      {getFieldDecorator("teacher_id", {
                        rules: [
                          {
                            required: false,
                            message: "Please choose Teacher.",
                          },
                        ],
                      })(
                        <AutoComplete
                          className="teacherselection"
                          onSelect={(value) => {
                            this.updateFormDataState("teacher_id", value);
                          }}
                          onChange={this.getTeacher}
                          dataSource={this.state.teacherList}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item label="Grade">
                      {getFieldDecorator("gradeId", {
                        rules: [
                          { required: false, message: "Please select batch" },
                        ],
                      })(
                        <Dropdown
                          loading={this.state.loading}
                          onChange={(value) => {
                            this.updateFormDataState("grade_id", value);
                          }}
                          placeholder={"Select Grade"}
                          options={this.state.gradeList}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Col>
                <Col>
                {/* FLV-204 */}
                <Col span={9}>
                    <Form.Item label="Week Days">
                      {getFieldDecorator("weekArr", {
                        rules: [
                          { required: true, message: "Please select WeekDays" },
                        ],
                      })(
                        <Dropdown
                          loading={this.state.loading}
                          onChange={(value) => {
                            this.updateFormDataState("activeDays", value.split(" "));
                          }}
                          placeholder={"Select Weekdays"}
                          options={this.state.WeekList}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Col>
                {/* <WeekdaysList
                  loading={this.state.loading}
                  getFieldDecorator={getFieldDecorator}
                  setFieldsValue={setFieldsValue}
                  onChange={this.updateFormDataState}
                  defaultActiveDays={activeDays}
                  showValidationError={
                    showValidationError && activeDays.length <= 0
                  }
                /> */}
                {/* FLV-204 */}

              </Col>
            </Row>
          </Form>
        </section>
        <div className={"footer-image"}>
          <img
            src={require("../../../../assets/image/pref-create-bottom-image.svg")}
          />
        </div>
      </AdminLayout>,
    ];
  }
}

export default connect(
  ({ lms }) => ({ ...lms }),
  (dispatch) => bindActionCreators({ ...preferenceGroupsActions }, dispatch)
)(Form.create({ name: "teacher-create" })(CreatePreference));
