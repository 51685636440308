import moment from "moment";
import isEmpty from "lodash/isEmpty";

export const generateHoursList = (startHours = 0) => {
  const hours = [];
  for (let hour = startHours; hour < 24; hour++) {
    hours.push({
      value: parseInt(moment({ hour }).format("Hmm")),
      label: moment({ hour }).format("hh:mm A"),
    });
    hours.push({
      value: parseInt(moment({ hour, minute: 15 }).format("Hmm")),
      label: moment({ hour, minute: 15 }).format("hh:mm A"),
    });
    hours.push({
      value: parseInt(moment({ hour, minute: 30 }).format("Hmm")),
      label: moment({ hour, minute: 30 }).format("hh:mm A"),
    });
    hours.push({
      value: parseInt(moment({ hour, minute: 45 }).format("Hmm")),
      label: moment({ hour, minute: 45 }).format("hh:mm A"),
    });
  }
  return hours;
};

export const getVisibleGradesList = (gradesList, selectedGrades) => {
  let arr = [];
  gradesList.forEach((grade) => {
    if (grade.is_active) {
      arr.push(grade);
    }
  });
  return arr;
};
export const generateFilterUrl = (filters) => {
  let mappings = {},
    params = [],
    url = "";
  if (!isEmpty(filters)) {
    Object.keys(filters).map((filterKey) => {
      if (filters?.[filterKey] || filters?.[filterKey] === 0)
        mappings = { ...mappings, ...filters };
      else {
        delete filters[filterKey];
        mappings = { ...filters };
      }
      if (Array.isArray(filters?.[filterKey])) {
        if (isEmpty(filters?.[filterKey])) delete filters[filterKey];
        mappings = { ...filters };
      }
    });
    params = createQueryParamUrl(mappings).join("");
    url = params;
  }
  return url;
};
export const createQueryParamUrl = (filterKeys) => {
  let params = [];
  try {
    let filterKeysArray = Object.keys(filterKeys);
    filterKeysArray.map((key, index) => {
      if (filterKeys[key] !== null) {
        params.push(
          `${key}=${filterKeys[key]}${
            index !== filterKeysArray?.length - 1 ? "&" : ""
          }`
        );
      }
    });
  } catch (e) {}
  return params;
};
export const convertSecondsToTime = (
  startSeconds,
  addAmPm = true,
  fixTimeZone = false
) => {
  try {
    //9000 to 8 A.M.
    if (startSeconds) {
      let timezoneOffsetSeconds = fixTimeZone
        ? -(new Date().getTimezoneOffset() * 60)
        : 0;
      let totalStartTimeInSeconds = timezoneOffsetSeconds + startSeconds;
      let hourIn24 = Math.floor(totalStartTimeInSeconds / 3600);
      let hourIn12 = hourIn24 % 12 || 12;
      let mins = Math.floor((totalStartTimeInSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0");
      if (addAmPm)
        return `${hourIn12}:${mins} ${
          hourIn24 < 12 || hourIn24 === 24 ? "A.M." : "P.M."
        }`;
      return +`${hourIn24}${mins}`;
    }
  } catch (e) {
    return "";
  }
};
export const returnSlot = (item) => ({
  startTime:
    convertSecondsToTime(item?.start_second || item.startTime, false, true) ||
    undefined,
  endTime:
    convertSecondsToTime(
      (item?.start_second || item.startTime) + item.duration,
      false,
      true
    ) || undefined,
  weekday: item.weekday,
  ...item,
});
export const convertStringTimeToSeconds = (time, fixTimezone = true) => {
  //130 to corresponding  1:30 AM seconds
  if (time) {
    let value = time?.toString(),
      seconds;
    value = value?.replace(/:|(A.M.)|(P.M.)/g, "")?.trim();
    if (value === "0") seconds = value;
    else {
      let [hours, mins] =
        value.length === 2
          ? [0, value[0] + "" + value[1]]
          : value.length === 3
          ? [value[0], value[1] + "" + value[2]]
          : [value[0] + "" + value[1], value[2] + "" + value[3]];
      seconds = hours * 60 * 60 + mins * 60;
      if (fixTimezone) seconds = handleTimeZone(seconds);
    }
    return +seconds || seconds;
  } else return "";
};
const handleTimeZone = (seconds) => {
  let timezoneOffsetSeconds = -(new Date().getTimezoneOffset() * 60);
  let totalTimeInSeconds = seconds - timezoneOffsetSeconds;
  if (totalTimeInSeconds < 0) {
    let dayInSeconds = 86400;
    seconds = dayInSeconds + totalTimeInSeconds;
  } else seconds = totalTimeInSeconds;
  return seconds;
};
export const getDurationFromSeconds = (seconds) => {
  let durationObj = moment.duration(seconds, "seconds");
  let duration = "";
  if (durationObj.hours())
    duration =
      durationObj.hours() + (durationObj.hours() === 1 ? " hour " : " hours ");
  if (durationObj.minutes()) {
    duration +=
      durationObj.minutes() + (durationObj.minutes() === 1 ? " min" : " mins");
  }
  return duration;
};

export function returnWeekDay(weekday, fullname = false) {
  switch (weekday) {
    case 1:
      return fullname ? "Monday" : "M";
    case 2:
      return fullname ? "Tuesday" : "T";
    case 3:
      return fullname ? "Wednesday" : "W";
    case 4:
      return fullname ? "Thursday" : "Th";
    case 5:
      return fullname ? "Friday" : "F";
    case 6:
      return fullname ? "Saturday" : "S";
    case 7:
      return fullname ? "Sunday" : "Su";
  }
}
