import { initialState } from "./state";

export const classes = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CLASS_ATTENDANCE":
      return {
        ...state, attendance:action.data
      }
    default:
      return state;
  }
};
