import React from 'react';
import {Button, Col, Input, Row, Select} from 'antd';
import {ErrorMessage, Field, FieldArray} from 'formik';

const {Option} = Select;
const {TextArea} = Input;


const BasicForm = (props) => {
    const {values = {}} = props;
    console.log('', values)
    return (
        <div className="form-container">
            <h3 className="form-title">basic information</h3>
            <Row gutter={16}>
                <Col span={12} className="form-column">
                    <p>EMAIL</p>
                    <Field
                        placeholder={"Email of the parent"}
                        as={Input}
                        name={"parent.email"}
                        value={values?.parent?.email}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name="parent.email"
                            className="error-message"
                        />
                    </span>
                </Col>
                <Col span={12} className="form-column">
                    <p>Flive Grade</p>
                    <Field
                        disabled={true}
                        placeholder={"Grade alloted"}
                        as={Input}
                        name={"grade"}
                        value={values?.grade?.name}
                    />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} className="form-column">
                    <p>Expected Outcome out of Flive</p>
                    {
                        values.details?.program_outcomes?.length > 0 && <FieldArray
                            name="details.program_outcomes"
                            render={(arrayHelpers, errors) => {
                                return (
                                    values.details?.program_outcomes?.map((oclass, index) => (
                                        <Row gutter={16}
                                             style={{marginBottom: index < values.details?.program_outcomes?.length - 1 ? "24px" : "0px"}}>
                                            <Col span={12}>
                                                <Field
                                                    placeholder={""}
                                                    as={Input}
                                                    name={`details.program_outcomes.${index}`}
                                                    value={values.details?.program_outcomes[index]}
                                                />
                                                <span className="error-message">
                                            <ErrorMessage
                                                name={`details.program_outcomes.${index}`}
                                                className="error-message"
                                            />
                                            </span>
                                            </Col>
                                            {(index) > 0 ? <Col span={4} className="center-align">
                                                <Button className='outline-btn'
                                                        onClick={() => arrayHelpers.remove(index)}> -
                                                    Remove</Button>
                                            </Col> : null}
                                            {(index) == values.details?.program_outcomes?.length - 1 ?
                                                <Col span={4} className="center-align">
                                                    <Button className='outline-btn'
                                                            onClick={() => arrayHelpers.push('')}> +
                                                        Add
                                                        Another</Button>
                                                </Col> : null}
                                        </Row>
                                    ))
                                )
                            }
                            }
                        />
                    }
                </Col>
            </Row>
        </div>
    );

}

export default BasicForm;
