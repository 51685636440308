import { initialState } from "./state";

export const preferenceGroups = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PREFERENCE_GROUPS": {
      return {
        ...state,
        preferenceGroups: action.data,
      }
    }
    default:
      return state;
  }
};
