import React, { Component } from "react";
import { Button, Form, Input, Modal, Radio } from "antd";
import "./index.scss";
import { cloneDeep } from "lodash";
import { CrossIcon, TickNoBGIcon } from "assets/image/svg.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as classesActions from "store/classes/actions.js";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import FeedbackForm from "./FeedbackForm";

const { TextArea } = Input;
const ATTENDANCE_OPTIONS = {
  PRESENT: 1,
  ABSENT: 2,
  REPEAT: 3,
};

class AttendanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      markedAttendance: [],
      showFeedBackScreen: false,
      selectedFeedbackStudent: 0,
    };
  }

  async componentDidMount() {
    if (this.props.visible && this.props.currentClassID) {
      this.getAttendance();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.currentClassID) this.getAttendance();
    }
  }

  getAttendance = async () => {
    let res = await this.props.getClassAttendanceRequest(
      this.props.currentClassID
    );
    if (res && res.data && res.data.length > 0) {
      let data = [...res.data];
      this.setState({
        students: res.data,
        markedAttendance: updateMarkedAttendanceObject(data),
      });
    }
  };

  renderAttendanceOptions = (option, index) => {
    return (
      <Radio.Group
        className="attendace-radio"
        name={"attendance_status"}
        onChange={(event) => this.onValueChange(event, index)}
        value={ATTENDANCE_OPTIONS[option] ? option : ""}
        required
      >
        <Radio id={"firstchoice" + `${index}`} value="PRESENT" required>
          <TickNoBGIcon />
        </Radio>
        <Radio id={"secondchoice" + `${index}`} value="ABSENT">
          <CrossIcon />
        </Radio>
      </Radio.Group>
    );
  };

  renderProfilePicture = (location) => (
    <figure
      className="listing-row-profile-pic"
      style={{ backgroundImage: `url(${location})` }}
    ></figure>
  );

  renderCommentField = (comment, index) => (
    <Input.TextArea
      id={`${index}`}
      placeholder="Add Teacher Comments"
      value={comment}
      name={"attendance_comments"}
      onChange={(event) => this.onValueChange(event, index)}
      rows={4}
      required
    />
  );

  onValueChange(event, index) {
    // console.log(event.target.name, event.target, event);

    const newStudents = cloneDeep(this.state.students);
    newStudents[index][event.target.name] = event.target.value;
    let absentcomment =
      event.target.value == "ABSENT"
        ? (newStudents[index][
            "attendance_comments"
          ] = `${this.state.students[index].child.name} was absent today`)
        : null;
    let presentcomment =
      event.target.value == "PRESENT"
        ? (newStudents[index]["attendance_comments"] = "")
        : null;
    let updatedStudents = cloneDeep(newStudents);

    this.setState({
      students: [...newStudents],
      markedAttendance: updateMarkedAttendanceObject(updatedStudents),
    });
  }

  onSubmit = async () => {
    const valueFilled = this.state.students.map((stu, index) => {
      document.getElementById("submit" + `${index}`).click();
      return document.getElementById(`${index}`).value ? true : false;
    });

    const radioFilled = this.state.students.map((stu, index) => {
      let checkvalue = document.getElementById("firstchoice" + `${index}`)
        .checked
        ? true
        : false || document.getElementById("secondchoice" + `${index}`).checked
        ? true
        : false;
      let radiocheck = document.getElementById("secondchoice" + `${index}`)
        .checked
        ? true
        : false;
      let answers = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0 };
      let feedbackIds = Object.keys(answers).join(",");
      let feedbackValues = Object.values(answers).join(",");
      //console.log(feedbackIds);
      let payload = {
        data: {
          child_id: this.props.classes?.attendance[index]?.child.id.toString(),
          session_id:
            this.props.classes?.attendance[index]?.class_session_id.toString(),
          feedback_id: feedbackIds,
          feedback_value: feedbackValues,
        },
      };
      let check = async (dispatch) => {
        if (radiocheck) {
          try {
            let res = await this.props.submitFeedback(payload);
          } catch (e) {
            toast.error("Could not mark atttendance");
          }
        }
      };
      check();
      return checkvalue;
    });

    let radioerror = radioFilled.includes(false)
      ? toast.error("Please mark all attendance!")
      : null;
    const includesblank =
      valueFilled.includes(false) || radioFilled.includes(false);

    if (!includesblank) {
      if (isAttendanceFormValid(this.state.markedAttendance)) {
        this.setState({ loading: true });
        let res,
          data = {
            attendance: this.state.markedAttendance,
          };
        try {
          res = await this.props.markClassAttendanceRequest(
            this.props.currentClassID,
            { data }
          );
        } catch (e) {
          toast.error("Could not mark attendance!");
          this.setState({ loading: false });
        }
        if (res) {
          toast.success("Attendance marked successfully!");
          this.props.closeModal();
          this.setState({ loading: false });
        }
      } else console.log("INVALID");
    } else console.log("NOT COMMENTED");
  };

  render() {
    console.log("ATTENDANCE PROPS : ", this.props);
    const studentRows = this.state.students?.map((stu, index) => {
      const onFinish = (values) => {
        console.log("Success:", values);
      };

      const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
      };

      return (
        <div className="attendance-card" key={index}>
          <div className="d-flex row">
            {this.renderProfilePicture(stu.child.image)}
            <h3>{stu.child.name}</h3>
          </div>
          <div className="row">
            <h5>ATTENDANCE</h5>
          </div>
          <Form
            id="form"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="radio"
              rules={[{ required: true, message: "Please select an option!" }]}
            >
              <div className="row">
                {this.renderAttendanceOptions(stu.attendance_status, index)}
              </div>
            </Form.Item>

            <Form.Item
              name="comments"
              rules={[
                {
                  required: true,
                  message: "Please comment!",
                },
              ]}
            >
              <div className="row">
                {this.renderCommentField(stu.attendance_comments, index)}
              </div>
            </Form.Item>
            <Form.Item>
              <div
                className="row add-feedback-btn"
                onClick={() => {
                  this.setState({
                    showFeedBackScreen: true,
                    selectedFeedbackStudent: {
                      ...stu,
                    },
                  });
                }}
              >
                + Add Rubrics
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                id={"submit" + `${index}`}
                style={{ display: "none" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    });
    const attendanceOptions = (
      <div className="d-flex options">
        <div className="d-flex ml-20">
          <div className="option-box green">
            <TickNoBGIcon color="#fff" />
          </div>
          <p className="ml-10">=</p>
          <h5 className="ml-10">PRESENT</h5>
        </div>
        <div className="d-flex ml-20">
          <div className="option-box red">
            <CrossIcon color="#fff" />
          </div>
          <p className="ml-10">=</p>
          <h5 className="ml-10">ABSENT</h5>
        </div>
      </div>
    );
    const renderTitle = () => (
      <div className={"modal-header"}>
        <div className={"title"}>Mark Attendance</div>
        <div className={"submit-btn"}>
          <Button
            type="primary"
            className="ant-btn-md export-btn"
            onClick={() => this.onSubmit()}
          >
            {"Submit"}
          </Button>
        </div>
      </div>
    );
    return (
      <Modal
        wrapClassName={`attendance-modal mob-modal-type-2 ${
          this.state.showFeedBackScreen
            ? "no-footer-modal no-header-modal no-padding-modal"
            : ""
        }`}
        maskStyle={{
          background: "rgba(0,0,0,0.45)",
        }}
        confirmLoading={this.state.loading}
        title={renderTitle()}
        width={1050}
        visible={this.props.visible}
        // onOk={this.onSubmit}
        onCancel={this.props.closeModal}
        okText="Submit"
        closeIcon={
          <div className={"anticon"}>
            <CrossIcon width={18} height={18} color={"#524E59"} />
          </div>
        }
      >
        {this.state.showFeedBackScreen ? (
          <FeedbackForm
            feedbackStudent={this.state.selectedFeedbackStudent}
            onClose={() =>
              this.setState({
                showFeedBackScreen: false,
                selectedFeedbackStudent: 0,
              })
            }
          />
        ) : (
          <React.Fragment>
            {attendanceOptions}
            <div className="attendance-card-container">{studentRows}</div>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

function updateMarkedAttendanceObject(updatedStudents = []) {
  return updatedStudents.map((item) => {
    let { child, id, ...restKeys } = item;
    restKeys.child_id = item.child.id;
    return restKeys;
  });
}

function isAttendanceFormValid(markedAttendance) {
  let isInvalid = false;
  for (let i = 0; i < markedAttendance.length; i++) {
    if (!markedAttendance[i].attendance_status) {
      isInvalid = true;
      break;
    }
  }
  return markedAttendance.length > 0 ? !isInvalid : false;
}

const connectedComponent = connect(
  ({ classes }) => ({ classes: { ...classes } }),
  (dispatch) => bindActionCreators({ ...classesActions }, dispatch)
)(AttendanceModal);

export default connectedComponent;
