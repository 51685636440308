import React from 'react';
import {Col, Input, Row, Select} from 'antd';
import {ErrorMessage, Field} from 'formik';
import {STATES_INFO} from "../../../../../../utils/constants";

const {Option} = Select;
const {TextArea} = Input;


const AddressForm = (props) => {
    const {values, setFieldValue} = props;
    return (
        <div className="form-container">
            <h3 className="form-title">Address</h3>
            <Row gutter={16}>
                <Col span={24} className="form-column">
                    <p>Line 1</p>
                    <Field
                        placeholder={"House no, street name"}
                        as={Input}
                        name={"details.address"}
                        value={values.details.address}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name="details.address"
                            className="error-message"
                        />
                    </span>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} className="form-column">
                    <p>Line 2</p>
                    <Field
                        placeholder={"Locality"}
                        as={Input}
                        name={"details.locality"}
                        value={values.details.locality}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name="details.locality"
                            className="error-message"
                        />
                    </span>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12} className="form-column">
                    <p>State</p>
                    <Select
                        placeholder={"Select One"}
                        onChange={(val) => {
                            setFieldValue('details.state', val);
                            setFieldValue('details.city', undefined);
                        }}
                        value={values.details.state}
                    >
                        {
                            [...Object.keys(STATES_INFO).map(stateName => (
                                <Option value={stateName}>{stateName}</Option>))]
                        }
                    </Select>
                    <span className="error-message">
                        <ErrorMessage
                            name="details.state"
                            className="error-message"
                        />
                    </span>
                </Col>
                <Col span={12} className="form-column">
                    <p>City</p>
                    <Select
                        placeholder={"Select One"}
                        onChange={(val) => setFieldValue('details.city', val)}
                        value={values.details.city}
                    >
                        {
                            STATES_INFO[values.details.state]?.split("|").map(city => (
                                <Option value={city}>{city}</Option>))
                        }
                    </Select>
                    <span className="error-message">
                        <ErrorMessage
                            name="details.city"
                            className="error-message"
                        />
                    </span>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12} className="form-column">
                    <p>Pincode</p>
                    <Field
                        placeholder={"eg: 110016"}
                        as={Input}
                        name={"details.pincode"}
                        value={values.details.pincode}
                    />
                    <span className="error-message">
                        <ErrorMessage
                            name="details.pincode"
                            className="error-message"
                        />
                    </span>
                </Col>
            </Row>
        </div>
    );

}

export default AddressForm;
