import { User } from "services/user";
import { mutation } from "./mutations";
import { storage as LocalStorage } from "services/config/storage";

const handleError = err => {
  console.log("Error in Login action : ", err);
};

// Actions
export const userLoginRequest = payload => async dispatch => {
  try {
    const { result } = await User.userLogin(payload);
    LocalStorage.set.parentData(result);
    dispatch(mutation.setUserLoginDetail(result));
    await getAndSetChild(dispatch, result);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const triggerOtpSend = payload => async dispatch => {
  try {
    const result = await User.otpSend(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const verifyOtpRequest = payload => async dispatch => {
  try {
    const response = await User.verifyOtp(payload);
    const result = {
      ...response.data,
      auth_token: response.data.id
    };
    LocalStorage.set.parentData(result);
    dispatch(mutation.setUserLoginDetail(result));
    await getAndSetChild(dispatch, result);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const switchChild = payload => async dispatch => {
  try {
    const result = await User.selectChild(payload);
    dispatch(mutation.setUserAuthToken, result.result.auth_token);
    const newDefaultChild = LocalStorage.fetch.childData().list.filter(d => d.id === payload.child_id)[0];
    LocalStorage.set.authToken(result.result.auth_token);
    LocalStorage.set.defaultChild(newDefaultChild);
    return result;
  } catch (err) {
    handleError(err);
  }
}

export const euroLogin = ({ token, email }) => async dispatch => {
  try {
    const euroHeader = {
      Authorization: token,
      "api-version": '1.1.0',
      "Content-Type": "application/json"
    }
    const newToken = await User.getTokenWithEuroData({ email }, euroHeader);
    LocalStorage.set.authToken(newToken.result.auth_token);
    const userData = await User.getUserDetail();
    LocalStorage.set.parentData(userData);
    await getAndSetChild(dispatch, { auth_token: newToken.result.auth_token });
    return true;
  } catch (err) {
    handleError(err);
  }
}

const getAndSetChild = async (dispatch, result) => {
  dispatch(mutation.setUserAuthToken(result.auth_token));
  LocalStorage.set.authToken(result.auth_token);
  const childList = await User.getChildrenList();
  LocalStorage.set.childData({ list: childList });
  if (childList.length > 0) {
    LocalStorage.set.defaultChild(childList[0])
    dispatch(mutation.setChildList(childList));
    dispatch(mutation.setDefaultChild(childList[0]));
    const { result } = await User.selectChild({ child_id: childList[0].id });
    dispatch(mutation.setUserAuthToken(result.auth_token));
    LocalStorage.set.authToken(result.auth_token);
  }
}


export const teacherLogin = payload => async dispatch => {
  try {
    const { data } = await User.teacherLogin({ data: payload });
    if (data && data.id && data.id.length) {
      LocalStorage.set.authToken(data.id);
      LocalStorage.set.userData(data)
      dispatch(mutation.setUserAuthToken(data.id));
      dispatch(mutation.setUserData(data));
      return data.id;
    }
    return undefined;
  } catch (err) {
    handleError(err);
    return undefined;
  }
};


export const adminLoginRequest = payload => async dispatch => {
  try {
    const { data } = await User.adminLogin({ data: payload });
    if (data && data.id && data.id.length) {
      LocalStorage.set.authToken(data.id);
      LocalStorage.set.userData(data)
      dispatch(mutation.setUserAuthToken(data.id));
      dispatch(mutation.setUserData(data));
      return data.id;
    }
    return undefined;
  } catch (err) {
    handleError(err);
    return undefined;
  }
};