import React, {useState} from "react";
import {Col, DatePicker, Form, Input} from 'antd';
import "./index.scss";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

const {MonthPicker} = DatePicker;

const PreviousWork = ({onChange, work_experience}) => {
    const [previousWorkDetails, setPreviousWorkDetails] = useState(!isEmpty(work_experience) ? work_experience : [{
        name: "",
        description: "",
        start_date: undefined,
        end_date: undefined
    }])
    const [isOpen, setIsOpen] = useState({});


    const updateDetails = (indexToUpdate, key, value) => {
        let details = [...previousWorkDetails];
        let obj = details[indexToUpdate]
        obj[key] = value
        setPreviousWorkDetails(details)
        onChange('work_experience', details)
    }

    return (
        <div className={'previous-work'}>
            <Col span={20}>
                <h2 className="heading1">
                    Previous work details
                </h2>
            </Col>
            {
                previousWorkDetails.map((item, index) => {
                    let rowIndex = index;
                    return <div key={rowIndex}>
                        <Col span={20} style={{padding: '8px 8px 8px 0'}}>
                            <Form.Item
                                label="Company Name"
                            >
                                <Input
                                    value={item.name}
                                    placeholder="Enter Company Name" maxLength={100}
                                    onChange={currentVal => {
                                        updateDetails(rowIndex, 'name', currentVal.target.value)
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={22} style={{padding: '0px 8px 0px 0'}}>
                            <Col span={11} style={{padding: '8px 8px 8px 0'}}>
                                <Form.Item
                                    label="Started On"
                                >
                                    <DatePicker
                                        mode="year"
                                        value={item.start_date && moment(item.start_date * 1000)}
                                        format={'YYYY'}
                                        open={isOpen?.[rowIndex]?.start_date}
                                        placeholder={'Year'}
                                        inputReadOnly={false}
                                        showToday={false}
                                        onOpenChange={(status) => {
                                            if (status) {
                                                setIsOpen({
                                                    [rowIndex]: {
                                                        start_date: true
                                                    }
                                                })
                                            } else {
                                                setIsOpen({
                                                    [rowIndex]: {
                                                        start_date: false
                                                    }
                                                })
                                            }
                                        }}
                                        onPanelChange={(val) => {
                                            updateDetails(rowIndex, 'start_date', moment(val).unix())
                                            setIsOpen({
                                                [rowIndex]: {
                                                    start_date: false
                                                }
                                            })
                                        }}
                                        // disabledDate={current => getDisabledDates(current, moment(), 0)}
                                        // onChange={onSelection}
                                        onChange={currentVal => {
                                        }}
                                        rules={[{required: true, message: 'Please select year!'}]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11} style={{padding: '31px 8px 8px 8px'}}>
                                <Form.Item
                                >
                                    <MonthPicker
                                        value={item.start_date && moment(item.start_date * 1000)}
                                        allowClear={false}
                                        inputReadOnly={false}
                                        showToday={false}
                                        placeholder={'Month'}
                                        format={'MMMM'}
                                        onChange={(val) => {
                                            updateDetails(rowIndex, 'start_date', moment(val).unix())
                                        }}
                                        rules={[{required: true, message: 'Please select month!'}]}
                                    />
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={22} style={{padding: '0px 8px 0px 0'}}>
                            <Col span={11} style={{padding: '8px 8px 8px 0px'}}>
                                <Form.Item
                                    label="Ended On"
                                >
                                    <DatePicker
                                        value={item.end_date && moment(item.end_date * 1000)}
                                        mode="year"
                                        open={isOpen?.[rowIndex]?.end_date}
                                        allowClear={false}
                                        format={'YYYY'}
                                        inputReadOnly={false}
                                        showToday={false}
                                        placeholder={'Year'}
                                        onOpenChange={(status) => {
                                            if (status) {
                                                setIsOpen({
                                                    [rowIndex]: {
                                                        end_date: true
                                                    }
                                                })
                                            } else {
                                                setIsOpen({
                                                    [rowIndex]: {
                                                        end_date: false
                                                    }
                                                })
                                            }
                                        }}
                                        onPanelChange={(val) => {
                                            updateDetails(rowIndex, 'end_date', moment(val).unix())
                                            setIsOpen({
                                                [rowIndex]: {
                                                    end_date: false
                                                }
                                            })
                                            console.log(val)
                                        }}
                                        // disabledDate={current => getDisabledDates(current, moment(), 0)}
                                        // onChange={onSelection}
                                        onChange={currentVal => {
                                        }}
                                        rules={[{required: true, message: 'Please select year!'}]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11} style={{padding: '31px 8px 8px 8px'}}>
                                <Form.Item
                                >
                                    <MonthPicker
                                        value={item.end_date && moment(item.end_date * 1000)}
                                        allowClear={false}
                                        inputReadOnly={false}
                                        showToday={false}
                                        placeholder={'Month'}
                                        format={'MMMM'}
                                        // disabledDate={current => getDisabledDates(current, moment(), 0)}
                                        // onChange={onSelection}
                                        onChange={currentVal => updateDetails(rowIndex, 'end_date', moment(currentVal).unix())}
                                        rules={[{required: true, message: 'Please select month!'}]}
                                    />
                                </Form.Item>
                            </Col>
                        </Col>
                        <Col span={20} style={{padding: '8px 8px 8px 0'}}>
                            <Form.Item
                                label="Description"
                            >
                                <Input.TextArea placeholder="Enter description here"
                                                value={item.description}
                                                maxLength={200}
                                                onChange={currentVal => updateDetails(rowIndex, 'description', currentVal.target.value)
                                                }/>
                            </Form.Item>
                        </Col>
                    </div>
                })
            }
            <Col span={12}>
                <span
                    onClick={(e) => {
                        let arr = [...previousWorkDetails]
                        arr.push({
                            name: '',
                            start_date: '',
                            end_date: '',
                            description: '',
                        })
                        setPreviousWorkDetails(arr)
                    }}
                    className="add-another"
                >
                                            +
                    <p>Add Another</p>
                                        </span>
            </Col>
        </div>
    );
}

export default PreviousWork;
