import { initialState } from "./state";

export const grades = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GRADE_LIST":
      return {
        gradesList: action.data.grades,
      }
    default:
      return state;
  }
};
