import React, { Component } from "react";
import Coin from 'assets/image/coin.svg';
import Cross from 'assets/image/cross-icon.svg';
import TruncateString from 'utils/truncate';
import "./SuccessModal.scss";
import { Button, Icon } from "antd";

import { Mixpanel } from '../../Mixpanel';

class SuccessModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      counter: 10,
      stopTimer: false
    }
  }
  componentDidMount() {
    if(this.props.data.recommended_story) {
      this.setCounter()
    }
  }

  setCounter = () => {
    this.setState({ counter: this.state.counter - 1 })
    if(this.state.stopTimer === true) {
      return
    }
    if(this.state.counter < 1) {
      this.props.closeSuccess(this.props.data.recommended_story ? this.props.data.recommended_story.id : '')
      return
    } else {
      setTimeout(this.setCounter, 1000)
    }
  }

  cancelTimer = () => {
    Mixpanel.track('Auto Suggest Recommended Story Cross', this.props.data.recommended_story)
    this.setState({ stopTimer: true })
  }

  openNextStory = () => {
    Mixpanel.track('Auto Suggest Recommended Story Click', this.props.data.recommended_story)
    this.props.closeSuccess(this.props.data.recommended_story.id)
  }

  closeModal = () => {
    Mixpanel.track('Auto Suggest Recommended Story Cross', this.props.data.recommended_story)
    this.props.closeSuccess()
  }

  render() {
    const { data, currentStoryData } = this.props;

    return (
      <div className="component-success-modal">
        <img src={Cross} className="close-icon" alt="close modal" onClick={this.closeModal} />
        <div>
          <div className={ data.recommended_story ? "awesome-card" : "awesome-card-lg" }>
            <div className="awesome-text">Awesome!</div>
            {/* Reward Data */}
            <div className="reward-data">
              <div className="user-data">
                <img src={currentStoryData.image} alt="" />
                <div className="story-name-container">
                  <div style={{fontSize: '20px', color:'#aea6ae'}}>YOU COMPLETED</div>
                  <div style={{fontSize: '26px', fontWeight: 500, lineHeight: 1.2}}>
                    {TruncateString(currentStoryData.name, 32)}
                  </div>
                </div>
              </div>
              <div className="coin-data">
                <div style={{fontSize: '20px', color:'#aea6ae'}}>
                  YOU WON <br />
                  <span style={{color: '#fff', fontSize: '26px'}}>{data.freadom_point_earned} points</span>
                </div>
                <div style={{fontSize: '45px', fontWeight: 500, lineHeight: 1.2, color: '#d6712b'}}>
                  <img src={Coin} alt="illustration" className="coin"/>{data.total_freadom_points}
                </div>
              </div>
            </div>
          </div>
          {/* Recommended Story */}
          { data.recommended_story && <div className="recommended-card">
            <div className="recommended-card-top">
              <div className="about-story">
                <div className="next-story-info">Next Recomended<br/> Story</div>
                <div>
                  <div className="title">{TruncateString(data.recommended_story.name)}</div>
                  <div className="story-author">Written by <b>{data.recommended_story.author_name}</b></div>
                </div>
              </div>
              <img src={data.recommended_story.image} alt="story" />
            </div>
            <div className="recommended-card-bottom">
              <Button
                type="primary"
                className="primary-btn back-btn"
                size="large"
                onClick={this.props.closeSuccess}
              >
                Go back to home
              </Button>
              <Button
                type="normal"
                className="outline-btn next-btn"
                size="large"
                onClick={ this.state.stopTimer ? this.openNextStory : this.cancelTimer }
              >
                { this.state.stopTimer === false && <Icon type="pause"/> }
                Next story&nbsp; { this.state.stopTimer === false && ('in ' + this.state.counter + '...') }
              </Button>
            </div>
          </div> }
        </div>
      </div>
    );
  }
}

export default SuccessModal;
