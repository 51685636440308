import React from 'react';
import "./index.scss";
import {Button} from 'antd';
import {MegaphoneIcon} from 'assets/image/svg.js';
import AnnouncementsEmpty from './AnnouncementsEmpty';
import Pill from 'components/Pill';

const AnnouncementCard = (props) => {
    console.log(props.announcements)

    const renderDate = (date) => {
        const parsedDate = new Date(date * 1000);
        return `${parsedDate.getDate()}/${parsedDate.getMonth() + 1}/${parsedDate.getFullYear()}`
    }

    const convertData = () => {
        return props.announcements.map(announcement => ({
            type: announcement.tags[0],
            title: announcement.title,
            description: announcement.description,
            date: renderDate(announcement.created_at),
            style: 1
        }))
    }
    const announcements = convertData();

    const innerContent = announcements.map((announcement, index) => (
        <div className="announcement" key={index}>
            <div className="type-date-container">
                <Pill type={announcement.style} text={announcement.type}/>
                <p className="date">{announcement.date}</p>
            </div>
            <div className='title'>
                {announcement.title}
            </div>
            {
                announcement?.description !== '' &&
                <div className='desc' dangerouslySetInnerHTML={{__html: announcement?.description}}/>
            }
        </div>
    ));

    const button = (
        props.onClose ?
            null
            :
            (innerContent.length > 0 ? <p className="link" onClick={props.onViewAll}>View all</p> : null)
    );

    return (
        <div className='announcement-card' style={{marginTop: "10px"}}>
            <div className='card-header'>
                <div className="icon-text">
                    <MegaphoneIcon height={17} width={18}/>
                    <h4>Announcements</h4>
                </div>
                {button}
            </div>
            <div className="spacer"/>
            <div className="card-content">
                {innerContent.length > 0 ? innerContent : <AnnouncementsEmpty onAdd={props.onChangeAnnPopup}/>}
            </div>
            <div className="card-footer">
                {
                    innerContent.length > 0 ?
                        <Button onClick={props.onChangeAnnPopup} className="ant-btn add-btn">ADD</Button>
                        :
                        null
                }
            </div>
        </div>
    );
};

const announcements = [
    {
        type: "EVENT",
        title: "Monthly Talent Show Postponed",
        description: "Lorem ipsum dolor sit amet, consectur adipiscing elit abed",
        date: "4/6/20",
        style: 1
    },
    {
        type: "EVENT",
        title: "Monthly Talent Show Postponed",
        description: "Lorem ipsum dolor sit amet, consectur adipiscing elit abed",
        date: "4/6/20",
        style: 2
    },

]

export default AnnouncementCard;
